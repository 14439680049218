import React, { useEffect, useState } from 'react'
import Cancel from "../../assets/images/close.svg";



import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Picker from "react-mobile-picker";
import { useTranslation, Trans } from "react-i18next";

const DropDownPop = ({cancel, getTitle, showDropDownValues, values}) => {

//  console.log("called",values)
const {t} = useTranslation()
  const [selectedItem, setSelectedItem] = useState('')
 const generateVallue = (val) =>{
    var valu = []
    if(values?.length > 0){

    
    for(let i= 0;i <values.length;i++){
        valu.push(values[i])
    }
    return valu
}
else {
    return []
}

 }
//  const value = values?.map((ele) => ele.value)
// console.log(value)
  const [isCancel, setIsCancel] = useState(cancel)
 
  // console.log(optionGroups)
  const maskStyle = {
    display: isCancel? "block" : "none"
  };

  const showError = (error)=> {
    toast.error(error, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const showSuccess = (error) => {
    toast.success(error, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  const [valueGroups, setValueGroups] = useState({
    titles:'a'
  })
  const [optionGroups, setOptionGroups] = useState({
      titles: generateVallue()
  
  })
  useEffect(()=>{
    if(values.length > 0)
    var arr = generateVallue()
    setOptionGroups({'titles': arr})
    setValueGroups({titles:arr[0]})
  },[])

  const isCancelHandler = ()=>{
    setIsCancel(false)
    showDropDownValues()
   
  }
    const doneHandler = ()=>{
        // console.log("clicked")
        // console.log(valueGroups)
        setIsCancel(false)
        getTitle(valueGroups.titles)
        // isCancelHandler()
  }
  const handleChange = (name, value) => {
    // console.log(name, value)
    setValueGroups(preVaue => ({...preVaue,[name]:value}))
    setSelectedItem(valueGroups.titles)
    // console.log("called", selectedItem)
      
  
  }  
  // console.log("156")
  return (
    // <h2>Hello</h2>
    <>
    {/* <div className="dobns_main_div" style={maskStyle}>
        <ToastContainer />
        <div className="dobns_popup">
          <div className="dobns_header">
            
            
          </div>

          <div className="dobns_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="dobns_body_scrollable_component">
            
                
                <div className="picker-modal-container" style={maskStyle}>
                     
                      <div className='picker-modal picker-modal-toggle popMobile'>
                        <div className="ios-datepicker">
                          <div className="ios-datepicker-title">Select Category</div>
                          
                          <div className="dobns_cancel_icon">
                                <img
                                    src={Cancel}
                                    onClick={
                                    isCancelHandler
                                    }
                                />
                            </div>
                            
                        </div>
                        
                        <Picker
                          optionGroups={optionGroups}
                          valueGroups={valueGroups}
                          onChange={handleChange} 
                          wheel='natural'

                          />
                         <div className="doneBtn"> <button onClick={doneHandler}>Done</button></div>
                      </div>
                      
                    </div> 
                </div>
           </div>

                
          </div>
        </div>
      </div> */}




      <div className="jvic_main_div" style={maskStyle}>
        <div className="jvic_popup">
          <div className="">
   
            <div className="dobns_select_date flex">
              {" "}
              <div className="ios-datepicker-title">{t('crm.select_category')}</div>
              <div className="dobns_cancel_icon">
                                <img
                                    src={Cancel}
                                    onClick={
                                    isCancelHandler
                                    }
                                />
                            </div>
            </div>
            <Picker
                          optionGroups={optionGroups}
                          valueGroups={valueGroups}
                          onChange={handleChange} 
                          wheel='natural'

                          />
                   <div className="doneBtn"> <button onClick={doneHandler}>{t('crm.done')}</button></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DropDownPop


// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';

// const  DropDownPop = ({anchor})=> {
//   const [state, setState] = React.useState({
//     top: false,
//     left: false,
//     bottom: false,
//     right: false,
//   });

//   const toggleDrawer = (anchor, open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }

//     setState({ ...state, [anchor]: open });
//   };

//   const list = (anchor) => (
//     <Box
//       sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
//       role="presentation"
//       onClick={toggleDrawer(anchor, false)}
//       onKeyDown={toggleDrawer(anchor, false)}
//     >
//       <List>
//         {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//           <ListItem key={text} disablePadding>
//             <ListItemButton>
//               <ListItemIcon>
//                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//               </ListItemIcon>
//               <ListItemText primary={text} />
//             </ListItemButton>
//           </ListItem>
//         ))}
//       </List>
      
//     </Box>
//   );

//   return (
//     <div>
//       {['left', 'right', 'top', ].map((anchor) => (
//         <React.Fragment key={anchor}>
//           <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
//           <Drawer
//             anchor={anchor}
//             open={state[anchor]}
//             onClose={toggleDrawer(anchor, false)}
//           >
//             {list(anchor)}
//           </Drawer>
//         </React.Fragment>
//       ))}
//     </div>
//   );
// }

// export default DropDownPop