import React, { Fragment } from "react";
import Previous from "../assets/images/previous.svg";
import AddBanner from "../assets/images/add-banner.png";
import RightArrow from "../assets/images/right-arrow.svg";
import { Link } from "react-router-dom";

function ChangePassword(props) {
  return (
    <Fragment>
      <div className="header">
        <div className="back-btn">
          <Link to="/">
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>Change Password</h2>
        <div className="wallet-btn"></div>
      </div>
      <div className="body mCustomScrollbar _mCS_1">
        <div className="form-group">
          <label>Current Password</label>
          <input
            type="text"
            className="form-control"
            placeholder="XXXXXXXXXX"
            required=""
          />
        </div>
        <div className="form-group">
          <label>New password</label>
          <input
            type="text"
            className="form-control"
            placeholder="XXXXXXXXXX"
            required=""
          />
        </div>
        <div className="form-group">
          <label>Confirm password</label>
          <input
            type="text"
            className="form-control"
            placeholder="XXXXXXXXXX"
            required=""
          />
        </div>
        <div className="add-banner">
          <img src={AddBanner} alt="" />
        </div>
        <div className="promo-code-title">
          <h5>Enter your promo code</h5>
        </div>
        <div className="form-group">
          <label>Enter Invite Code</label>
          <input
            type="text"
            className="form-control"
            placeholder="1256HUY89"
            required=""
          />
        </div>
      </div>
      <div className="bottom_btn">
        <button className="btn btn-dark main-btn">
          APPLY
          <img src={RightArrow} alt="" className="mCS_img_loaded" />
        </button>
      </div>
    </Fragment>
  );
}

export default ChangePassword;
