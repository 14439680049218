import React, { Fragment } from 'react';
import Previous from "../assets/images/previous.svg";
import Wallet from "../assets/images/wallet.svg";
import MoneyIcon2 from "../assets/images/money-icon-2.svg";
import { Link } from 'react-router-dom';

function AddBalance(props) {
	return (
		<Fragment>
			<div className="header">
				<div className="back-btn">
					<Link to='/'>
						<img src={Previous} alt="" />
					</Link>
				</div>
				<h2>Add Balance</h2>
				<div className="wallet-btn">
					{/* <Link to=''>
								<img src={Wallet} alt=""/>
								Wallet
							</Link> */}
				</div>
			</div>
			<div className="body mCustomScrollbar _mCS_1">
				<div className="current_balance_box shadow-secondary bg-secondary">
					<img src={MoneyIcon2} alt="" />
					Current Balance
					<b className="ml-auto">₹500</b>
				</div>
				<p className="text-center mb-5"><Link to='' className="text-gray"><b>Add balance to your account</b></Link></p>
				<div className="form-group">
					<label>Add your Amount</label>
					<input type="text" className="form-control" placeholder="₹500.00" required="" />
				</div>
				<div className="ammount_label">
					<div className="item bg-primary shadow-primary">₹100.00</div>
					<div className="item bg-green shadow-green">₹500.00</div>
					<div className="item bg-sky shadow-sky">₹1000.00</div>
				</div>
				<div className="current_balance_box shadow-secondary bg-secondary">
					<img src={MoneyIcon2} alt="" />
					Current Balance
					<b className="ml-auto">₹500</b>
				</div>
				<div className="balance_links">
					<div className="item">
						<Link to='' className="shadow-black">
							Debit  / Credit Card
							<span className="icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
									<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
										<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
									</g>
								</svg>
							</span>
						</Link>
					</div>
					<div className="item">
						<Link to='' className="shadow-black">
							Net Banking
							<span className="icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
									<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
										<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
									</g>
								</svg>
							</span>
						</Link>
					</div>
					<div className="item">
						<Link to='' className="shadow-black">
							Wallet
							<span className="icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
									<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
										<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
									</g>
								</svg>
							</span>
						</Link>
					</div>
					<div className="item">
						<Link to='' className="shadow-black">
							UPI Payment
							<span className="icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
									<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
										<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
									</g>
								</svg>
							</span>
						</Link>
					</div>
					<div className="item">
						<Link to='' className="shadow-black">
							Paytm
							<span className="icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
									<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
										<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
									</g>
								</svg>
							</span>
						</Link>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default AddBalance;