import React, { Fragment, useEffect, useState } from "react";
import Logo from "../assets/images/logo.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import "react-intl-tel-input/dist/main.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { GoogleLogin } from 'react-google-login';
import {
  countryList,
  checkAppUser,
  getsignUpCode,
  onSignUp,
  PrivacyData,
  signIn,
  TermsData,
  signUpInternational,
  getCampaign,
  requestGoogleLogin
} from "../apis";
import OTP from "./otp";
import url from "../constants/url";
import Select from "react-select";
import ReactHtmlParser from "react-html-parser";
import Previous from "../assets/images/previous.svg";
import Loader from "../component/loader";
import Error from "../component/error";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { useTranslation, Trans } from "react-i18next";

function RegisterCampaign(props) {
  const [phonenumber, setPhonenumber] = useState("");
  const [country, setCountry] = useState("");
  const [dialCode, setDialCode] = useState("+91");
  const [countries, setCountries] = useState([]);
  const [allCountriesList, setAllCountriesList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showRegister, setShowRegister] = useState(true);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [errorCodeMessage, setErrorCodeMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [allDialCode, setAllDialCode] = useState({});
  const [confirmationResult, setConfirmationResult] = useState({});
  const [termsData, setTermsData] = useState(false);
  const [privacyData, setPrivacyData] = useState(false);
  const [campaignId, setCampaignId] = useState(false);
  const [campaignURL, setCampaignURL] = useState(false);
  const [error, setError] = useState("");

  const [tag_countries_dialcode_popup, setTagCountriesDialcodePopup] = useState(false);
  const [countries_dialcode, setCountriesDialcode] = useState({ value: '', lable: '', name: '' });
  const {t} = useTranslation()

  const [loginData, setLoginData] = useState(
    localStorage.getItem('loginData')
      ? JSON.parse(localStorage.getItem('loginData'))
      : null
  );

  // var firebaseConfig = {
  //   apiKey: 'AIzaSyBw-xOChQWFv8oS8HF56HffCyc9FaW0I9w',
  //   authDomain: "localhost",
  //   // The value of `databaseURL` depends on the location of the database
  //   databaseURL: 'https://fantasyji-esports.firebaseio.com',
  //   projectId: 'fantasyji-esports',
  //   storageBucket: 'fantasyji-esports.appspot.com',

  //   appId: "1:454874517092:ios:18d91115f592bfa854d971",
  //   // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
  // };

  // const app = initializeApp(firebaseConfig);
  // const auth = getAuth();
  // useEffect(() => {
  //   window.recaptchaVerifier = new RecaptchaVerifier('sign-up-button', {
  //     'size': 'invisible',
  //     'callback': (response) => {
  //       // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       signUp();
  //     }
  //   }, auth);

  // }, []);

  // useEffect(() => {


  // }, []);



  // Initialize Firebase Authentication and get a reference to the service
  const responseGoogleOnSuccess = async (googleData) => {
    setLoginData(googleData);
    localStorage.setItem('loginData', JSON.stringify(googleData));
    let payload = {
      "googleData": googleData.profileObj,
      "campaignId": campaignId,
      "platform": "webapp",
    }
    let response = await requestGoogleLogin(payload);
    if (response) {
      if (response?.success === true) {
        localStorage.setItem('gamerjiToken', response.token);
        if (response.isSingup === true) {
          window.location.href = '/sign-up';
        } else {
          window.location.href = '/';
        }
      } else {
        setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('premium.something_went_wrong_please_try_again'));
      }



      // console.log("responseGoogleOnSuccess====response==>", response);
      //  setLoginData(response);
      // localStorage.setItem('loginData', JSON.stringify(response));
    }
    // const res = await fetch('/api/google-login', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     token: googleData.tokenId,
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });

    // const data = await res.json();


    // console.log("responseGoogleOnSuccess==>", googleData);
  };

  const responseGoogleOnFailure = (response) => {
    // console.log("responseGoogleOnFailure==>", response);
    setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('premium.something_went_wrong_please_try_again'));
  };

  const handleLogout = () => {
    localStorage.removeItem('loginData');
    setLoginData(null);
  };

  useEffect(async () => {
    // console.log("window.location.href:", window.location.href);

    var frontendURL =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];
    setCampaignURL(frontendURL);
    let payload = {
      "frontendURL": frontendURL
    }
    // console.log("campaignURL:", frontendURL);


    let responseA = await getCampaign(payload);


    if (responseA) {
      if (responseA?.success === true) {
        setCampaignId(responseA?.item?._id);

      } else {
        window.location.href = '/';
      }

    }
    let data = {
      filter: {},
      sort: "asc",
      sortBy: "name",
      skip: 0,
      limit: 10,
      page: 1,
    };
    let response = await countryList(data);
    if (response) {
      const options = [];
      response?.list?.map((item, index) => {
        setAllCountriesList(response?.list);
        const imgUrl = url.imageUrl + item?.flag?.default;
        options.push({
          value: item?.dialingCode,
          label: (
            <div>
              <img src={imgUrl} style={{ width: "15px" }} /> {item?.dialingCode}
            </div>
          ),
          name: (
            <div>
              <img src={imgUrl} style={{ width: "25px", marginRight: '10px' }} /> <b>{item?.name}</b> ({item?.dialingCode})
            </div>
          ),
        });
      });
      // setAllDialCode(options?.[0]);
      // setDialCode(response?.list?.[0].dialingCode);
      // setCountries(options);
      setCountriesDialcode(options?.[0])
      // console.log(options?.[0])

      setAllDialCode(options?.[0]);
      setDialCode(options?.[0]?.value);
      setCountries(options);
    }
  }, []);

  const confimOTP = async (otp, data) => {
    // console.log("data=OUT=>", data);
    let country = allCountriesList?.filter(
      (val) => val?.dialingCode === data.phoneCode
    )?.[0]?._id;
    data.country = country;
    confirmationResult.confirm(otp).then(async (result) => {
      // User signed in successfully.
      data.isNewUser = true;
      data.isVerified = true;


      const user = result.user;
      let response = await signUpInternational(data);

      if (response) {
        // Adjust.trackEvent({
        //   eventToken: 'nxuhll'
        // })
        if (response.token) {
          localStorage.setItem('gamerjiToken', response.token);
          window.location.href = '/sign-up';

        } else {
          setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('premium.something_went_wrong'));
        }

      } else {
        setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('premium.something_went_wrong'));
      }
      return true;
      // ...
    }).catch((error) => {
      // console.log("error==>", error);
      return false;
      // User couldn't sign in (bad verification code?)
      // ...
    });
  };

  const onLogin = async () => {
    // let data = {
    //   username: phonenumber,
    //   type: "otpRequest",
    //   phoneCode: dialCode,
    //   campaignId:campaignId
    //   // 'password':'aaa'
    // };
    // console.log("onLogin==>",data);
    // if(dialCode=="+91" || dialCode=="91"){

    //   if (phonenumber === "") {
    //     setError("Please enter valid mobile number");
    //   } else if (phonenumber?.length < 10) {
    //     setError("Please enter valid mobile number");
    //   } else {
    //     let response = await signIn(data);
    //     if (response && response?.status === undefined) {
    //       setRedirect(true);
    //       localStorage.setItem('currentOTPtype', 'login');
    //       let newUrlIS = window.location.origin + "/otp";
    //       window.history.pushState({}, null, newUrlIS);
    //     } else {
    //       setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
    //     }
    //   }

    // }else{


    //   if (phonenumber === "") {
    //     setError("Please enter valid mobile number");
    //   } else if (phonenumber?.length < 8) {
    //     setError("Please enter valid mobile number");
    //   } else {
    //     let response = await checkAppUser(data);
    //     if (response) {
    //       console.log("response", response);
    //       if(response?.item){

    //         if(response?.item?.isUserExists==false){
    //           setError("User not exist");
    //         }else{
    //           console.log("auth", auth);
    //           const appVerifier = window.recaptchaVerifier;
    //           console.log("appVerifier", appVerifier);
    //           signInWithPhoneNumber(auth,dialCode+phonenumber,appVerifier )
    // .then((confirmationResult) => {
    //   setConfirmationResult(confirmationResult);
    //   setRedirect(true);
    //   // confirmationResult.confirm("111111").then((result) => {
    //   //   // User signed in successfully.
    //   //   console.log("result==>",result);
    //   //   const user = result.user;
    //   //   // ...
    //   // }).catch((error) => {
    //   //   console.log("error==>",error);
    //   //   // User couldn't sign in (bad verification code?)
    //   //   // ...
    //   // });
    //   console.log("confirmationResult", confirmationResult);
    //   localStorage.setItem('currentOTPtype', 'login');
    //   localStorage.setItem('confirmationResult', confirmationResult);
    //   let newUrlIS = window.location.origin + "/otp";
    //   window.history.pushState({}, null, newUrlIS);
    //   // SMS sent. Prompt user to type the code from the message, then sign the
    //   // user in with confirmationResult.confirm(code).
    //  // window.confirmationResult = confirmationResult;
    //   // ...
    // }).catch((error) => {
    //   console.log("error==>", error);
    //   // Error; SMS not sent
    //   // ...
    // });


    //         }


    //       }

    //       // setRedirect(true);
    //       // localStorage.setItem('currentOTPtype', 'login');
    //       // let newUrlIS = window.location.origin + "/otp";
    //       // window.history.pushState({}, null, newUrlIS);
    //     } else {
    //       // setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
    //     }
    //   }

    // }

  };

  const handleChangeCountry = (selectedOption) => {
    setDialCode(selectedOption?.value);
    setAllDialCode(selectedOption);
  };

  const onChangePhone = (val) => {
    val.length <= 10 && setPhonenumber(val);
  };

  const onClickPrivacy = async () => {
    setLoader(true);
    let res = await PrivacyData();
    if (res) {
      setPrivacyData(res?.item?.description);
    }
    setShowTerms(false);
    setShowPrivacy(true);
    setLoader(false);
  };

  const onClickTerms = async () => {
    setLoader(true);
    let res = await TermsData();
    if (res) {
      setTermsData(res?.item?.description);
    }
    setShowPrivacy(false);
    setShowTerms(true);
    setLoader(false);
  };

  const goback = () => {
    setShowPrivacy(false);
    setShowTerms(false);
  };

  const fieldChange = (e) => {
    setCouponCode(e.target.value);
  };

  const getSignUpode = async () => {
    setErrorCodeMessage('');
    if (couponCode !== "") {
      let response = await getsignUpCode({ name: couponCode, type: "signupCode" });
      if (response && response?.status === undefined) {
        setErrorCodeMessage('success');
      } else {
        setErrorCodeMessage('notcorrect');
      }
    } else {
      setErrorCodeMessage('error');
    }
  };

  const signUp = async () => {

    let country = allCountriesList?.filter(
      (val) => val?.dialingCode === dialCode
    )?.[0]?._id;
    let countryObj = allCountriesList?.filter(
      (val) => val?.dialingCode === dialCode
    )?.[0];
    // console.log("phonenumber==>", phonenumber);
    setCountry(country);
    let data = {
      type: "otpRequest",
      platform: "webapp",
      username: phonenumber,
      phoneCode: dialCode,
      country: country,
      code: couponCode,
      campaignId: campaignId
    };
    // console.log("data==>", data);
    if (dialCode == "+91" || dialCode == "91") {
      localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
      let response = await onSignUp(data);

      if (response && response?.success === true) {
        setRedirect(true);
        localStorage.setItem('currentOTPtype', 'signup');
        let newUrlIS = window.location.origin + "/otp";
        window.history.pushState({}, null, newUrlIS);
      } else {
        // console.log('err::', response)
        setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('premium.something_went_wrong_please_try_again'));
      }
      // let response = await checkAppUser(data);

      // if (response) {
      //   console.log("response", response);
      //   if (response?.item) {

      //     if (response?.item?.isUserExists == true) {
      //       setError("User already exist");
      //     } else {
      //       console.log("auth", auth);
      //       const appVerifier = window.recaptchaVerifier;
      //       console.log("appVerifier", appVerifier);
      //       signInWithPhoneNumber(auth, dialCode + phonenumber, appVerifier)
      //         .then((confirmationResult) => {
      //           setConfirmationResult(confirmationResult);
      //           setRedirect(true);
      //           setLoader(false);


      //           localStorage.setItem('currentOTPtype', 'signup');

      //           localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
      //           localStorage.setItem('confirmationResult', confirmationResult);
      //           let newUrlIS = window.location.origin + "/otp";
      //           window.history.pushState({}, null, newUrlIS);
      //           // SMS sent. Prompt user to type the code from the message, then sign the
      //           // user in with confirmationResult.confirm(code).
      //           // window.confirmationResult = confirmationResult;
      //           // ...
      //         }).catch((error) => {
      //           console.log("error==>", error);
      //           // Error; SMS not sent
      //           // ...
      //         });


      //     }


      //   }

      //   //   // setRedirect(true);
      //   //   // localStorage.setItem('currentOTPtype', 'login');
      //   //   // let newUrlIS = window.location.origin + "/otp";
      //   //   // window.history.pushState({}, null, newUrlIS);
      // } else {
      //   // setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
      // }
      // let response = await onSignUp(data);

      // if (response && response?.status === undefined) {
      //   setRedirect(true);
      //   localStorage.setItem('currentOTPtype', 'signup');
      //   let newUrlIS = window.location.origin + "/otp";
      //   window.history.pushState({}, null, newUrlIS);
      // } else {
      //   console.log('err::', response)
      //   setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
      // }
    } else {

      if (phonenumber === "") {
        setError("Please enter valid mobile number");
      } else if (phonenumber?.length < 8) {
        setError("Please enter valid mobile number");
      } else {
        localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
        let response = await onSignUp(data);

        if (response && response?.success === true) {
          setRedirect(true);
          localStorage.setItem('currentOTPtype', 'signup');
          let newUrlIS = window.location.origin + "/otp";
          window.history.pushState({}, null, newUrlIS);
        } else {
          // console.log('err::', response)
          setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('premium.something_went_wrong_please_try_again'));
        }
        ///  let response = await checkAppUser(data);

        // if (response) {
        //   console.log("response", response);
        //   if (response?.item) {

        //     if (response?.item?.isUserExists == true) {
        //       setError("User already exist");
        //     } else {
        //       console.log("auth", auth);
        //       const appVerifier = window.recaptchaVerifier;
        //       console.log("appVerifier", appVerifier);
        //       signInWithPhoneNumber(auth, dialCode + phonenumber, appVerifier)
        //         .then((confirmationResult) => {
        //           setConfirmationResult(confirmationResult);
        //           setRedirect(true);
        //           setLoader(false);
        //           // confirmationResult.confirm("111111").then((result) => {
        //           //   // User signed in successfully.
        //           //   console.log("result==>",result);
        //           //   const user = result.user;
        //           //   // ...
        //           // }).catch((error) => {
        //           //   console.log("error==>",error);
        //           //   // User couldn't sign in (bad verification code?)
        //           //   // ...
        //           // });

        //           localStorage.setItem('currentOTPtype', 'signup');

        //           localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
        //           localStorage.setItem('confirmationResult', confirmationResult);
        //           let newUrlIS = window.location.origin + "/otp";
        //           window.history.pushState({}, null, newUrlIS);
        //           // SMS sent. Prompt user to type the code from the message, then sign the
        //           // user in with confirmationResult.confirm(code).
        //           // window.confirmationResult = confirmationResult;
        //           // ...
        //         }).catch((error) => {
        //           console.log("error==>", error);
        //           // Error; SMS not sent
        //           // ...
        //         });


        //     }


        //   }

        //   //   // setRedirect(true);
        //   //   // localStorage.setItem('currentOTPtype', 'login');
        //   //   // let newUrlIS = window.location.origin + "/otp";
        //   //   // window.history.pushState({}, null, newUrlIS);
        // } else {
        //   // setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
        // }
      }
    }

  };

  // const signUp = async () => {

  //   let country = allCountriesList?.filter(
  //     (val) => val?.dialingCode === dialCode
  //   )?.[0]?._id;
  //   let countryObj = allCountriesList?.filter(
  //     (val) => val?.dialingCode === dialCode
  //   )?.[0];
  //   console.log("phonenumber==>", phonenumber);
  //   setCountry(country);
  //   let data = {
  //     type: "otpRequest",
  //     platform: "webapp",
  //     username: phonenumber,
  //     phoneCode: dialCode,
  //     country: country,
  //     code: couponCode, 
  //    campaignId:campaignId
  //   };
  //   console.log("data==>", data);
  //   if (dialCode == "+91" || dialCode == "91") {
  //     localStorage.setItem('userCountryObj', JSON.stringify(countryObj));

  //     let response = await checkAppUser(data);

  //     if (response) {
  //       console.log("response", response);
  //       if (response?.item) {

  //         if (response?.item?.isUserExists == true) {
  //           setError("User already exist");
  //         } else {
  //           console.log("auth", auth);
  //           const appVerifier = window.recaptchaVerifier;
  //           console.log("appVerifier", appVerifier);
  //           signInWithPhoneNumber(auth, dialCode + phonenumber, appVerifier)
  //             .then((confirmationResult) => {
  //               setConfirmationResult(confirmationResult);
  //               setRedirect(true);
  //               setLoader(false);


  //               localStorage.setItem('currentOTPtype', 'signup');

  //               localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
  //               localStorage.setItem('confirmationResult', confirmationResult);
  //               let newUrlIS = window.location.origin + "/otp";
  //               window.history.pushState({}, null, newUrlIS);
  //               // SMS sent. Prompt user to type the code from the message, then sign the
  //               // user in with confirmationResult.confirm(code).
  //               // window.confirmationResult = confirmationResult;
  //               // ...
  //             }).catch((error) => {
  //               console.log("error==>", error);
  //               // Error; SMS not sent
  //               // ...
  //             });


  //         }


  //       }

  //       //   // setRedirect(true);
  //       //   // localStorage.setItem('currentOTPtype', 'login');
  //       //   // let newUrlIS = window.location.origin + "/otp";
  //       //   // window.history.pushState({}, null, newUrlIS);
  //     } else {
  //       // setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
  //     }
  //     // let response = await onSignUp(data);

  //     // if (response && response?.status === undefined) {
  //     //   setRedirect(true);
  //     //   localStorage.setItem('currentOTPtype', 'signup');
  //     //   let newUrlIS = window.location.origin + "/otp";
  //     //   window.history.pushState({}, null, newUrlIS);
  //     // } else {
  //     //   console.log('err::', response)
  //     //   setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
  //     // }
  //   } else {

  //     if (phonenumber === "") {
  //       setError("Please enter valid mobile number");
  //     } else if (phonenumber?.length < 8) {
  //       setError("Please enter valid mobile number");
  //     } else {
  //       localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
  //       // let response = await onSignUp(data);

  //       // if (response && response?.status === undefined) {
  //       //   setRedirect(true);
  //       //   localStorage.setItem('currentOTPtype', 'signup');
  //       //   let newUrlIS = window.location.origin + "/otp";
  //       //   window.history.pushState({}, null, newUrlIS);
  //       // } else {
  //       //   console.log('err::', response)
  //       //   setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
  //       // }
  //       let response = await checkAppUser(data);

  //       if (response) {
  //         console.log("response", response);
  //         if (response?.item) {

  //           if (response?.item?.isUserExists == true) {
  //             setError("User already exist");
  //           } else {
  //             console.log("auth", auth);
  //             const appVerifier = window.recaptchaVerifier;
  //             console.log("appVerifier", appVerifier);
  //             signInWithPhoneNumber(auth, dialCode + phonenumber, appVerifier)
  //               .then((confirmationResult) => {
  //                 setConfirmationResult(confirmationResult);
  //                 setRedirect(true);
  //                 setLoader(false);
  //                 // confirmationResult.confirm("111111").then((result) => {
  //                 //   // User signed in successfully.
  //                 //   console.log("result==>",result);
  //                 //   const user = result.user;
  //                 //   // ...
  //                 // }).catch((error) => {
  //                 //   console.log("error==>",error);
  //                 //   // User couldn't sign in (bad verification code?)
  //                 //   // ...
  //                 // });

  //                 localStorage.setItem('currentOTPtype', 'signup');

  //                 localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
  //                 localStorage.setItem('confirmationResult', confirmationResult);
  //                 let newUrlIS = window.location.origin + "/otp";
  //                 window.history.pushState({}, null, newUrlIS);
  //                 // SMS sent. Prompt user to type the code from the message, then sign the
  //                 // user in with confirmationResult.confirm(code).
  //                 // window.confirmationResult = confirmationResult;
  //                 // ...
  //               }).catch((error) => {
  //                 console.log("error==>", error);
  //                 // Error; SMS not sent
  //                 // ...
  //               });


  //           }


  //         }

  //         //   // setRedirect(true);
  //         //   // localStorage.setItem('currentOTPtype', 'login');
  //         //   // let newUrlIS = window.location.origin + "/otp";
  //         //   // window.history.pushState({}, null, newUrlIS);
  //       } else {
  //         // setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
  //       }
  //     }
  //   }

  // };


  // const signUp = async () => {

  //   let country = allCountriesList?.filter(
  //     (val) => val?.dialingCode === dialCode
  //   )?.[0]?._id;
  //   let countryObj = allCountriesList?.filter(
  //     (val) => val?.dialingCode === dialCode
  //   )?.[0];
  //   localStorage.setItem('userCountryObj',JSON.stringify(countryObj) );

  //   setCountry(country);
  //   let data = {
  //     type: "otpRequest",
  //     platform:"webapp",
  //     username: phonenumber,
  //     phoneCode: dialCode,
  //     country: country,
  //     code: couponCode,
  //     campaignId:campaignId
  //   };
  //   if(dialCode=="+91" || dialCode=="91"){
  //     let response = await onSignUp(data);
  //     if (response && response?.status === undefined) {
  //       setRedirect(true);
  //       localStorage.setItem('currentOTPtype', 'signup');
  //       let newUrlIS = window.location.origin + "/otp";
  //       window.history.pushState({}, null, newUrlIS);
  //     } else {
  //       console.log('err::', response)
  //       setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
  //     }
  //   }else{

  //     if (phonenumber === "") {
  //       setError("Please enter valid mobile number");
  //     } else if (phonenumber?.length < 8) {
  //       setError("Please enter valid mobile number");
  //     } else {
  //       localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
  //       let response = await onSignUp(data);

  //       if (response && response?.status === undefined) {
  //         setRedirect(true);
  //         localStorage.setItem('currentOTPtype', 'signup');
  //         let newUrlIS = window.location.origin + "/otp";
  //         window.history.pushState({}, null, newUrlIS);
  //       } else {
  //         console.log('err::', response)
  //         setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
  //       }
  //   //     let response = await checkAppUser(data);
  //   //     if (response) {
  //   //       console.log("response", response);
  //   //       if(response?.item){

  //   //         if(response?.item?.isUserExists==true){
  //   //           setError("User already exist");
  //   //         }else{
  //   //           console.log("auth", auth);
  //   //           const appVerifier = window.recaptchaVerifier;
  //   //           console.log("appVerifier", appVerifier);
  //   //           signInWithPhoneNumber(auth,dialCode+phonenumber,appVerifier )
  //   // .then((confirmationResult) => {
  //   //   setConfirmationResult(confirmationResult);
  //   //   setRedirect(true);
  //   //   // confirmationResult.confirm("111111").then((result) => {
  //   //   //   // User signed in successfully.
  //   //   //   console.log("result==>",result);
  //   //   //   const user = result.user;
  //   //   //   // ...
  //   //   // }).catch((error) => {
  //   //   //   console.log("error==>",error);
  //   //   //   // User couldn't sign in (bad verification code?)
  //   //   //   // ...
  //   //   // });

  //   //   localStorage.setItem('currentOTPtype', 'signup');


  //   //   localStorage.setItem('confirmationResult', confirmationResult);
  //   //   let newUrlIS = window.location.origin + "/otp";
  //   //   window.history.pushState({}, null, newUrlIS);
  //   //   // SMS sent. Prompt user to type the code from the message, then sign the
  //   //   // user in with confirmationResult.confirm(code).
  //   //  // window.confirmationResult = confirmationResult;
  //   //   // ...
  //   // }).catch((error) => {
  //   //   console.log("error==>", error);
  //   //   // Error; SMS not sent
  //   //   // ...
  //   // });


  //   //         }


  //   //       }

  //   //       // setRedirect(true);
  //   //       // localStorage.setItem('currentOTPtype', 'login');
  //   //       // let newUrlIS = window.location.origin + "/otp";
  //   //       // window.history.pushState({}, null, newUrlIS);
  //   //     } else {
  //   //       // setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
  //   //     }
  //     }
  //   }

  // };

  const goSignUP = () => {
    setShowRegister(!showRegister);
    setPhonenumber('');
    //  setDialCode("+91");
    setError('');
    // let newUrlIS = window.location.origin + "/";
    // window.history.pushState({}, null, newUrlIS);
    window.location.href = "/sign-in/" + campaignURL;

  };
  const goSignUPEmail = () => {
    setShowRegister(showRegister);
    setPhonenumber('');
    //  setDialCode("+91");
    setError('');
    // let newUrlIS = window.location.origin + "/";
    // window.history.pushState({}, null, newUrlIS);
    window.location.href = "/register-email/"+ campaignURL;

  };

  const createCountryListPopup = (countries) => {
    // console.log(countries_dialcode)
    return (
      <div className="country_list_popup_component">
        <div className="country_list_popup">
          <div className="country_list_popup_header">
            <div></div>
            <div className="country_list_popup_header_text">{t('select_country')}</div>
            <div>
              <svg
                onClick={() => setTagCountriesDialcodePopup(false)}
                style={{ cursor: 'pointer', }}
                id="" data-name="cancel (1)" xmlns="http://www.w3.org/2000/svg" width="30.001" height="30" viewBox="0 0 34.001 34">
                <path id="Path_2963" data-name="Path 2963" d="M34,17A17,17,0,1,0,17,34,17,17,0,0,0,34,17Z" transform="translate(0 -0.003)" fill="#ef1f36" />
                <path id="Path_2964" data-name="Path 2964" d="M150.574,135.871l4.97-5.77a1.5,1.5,0,0,0-2.279-1.963l-4.676,5.428-4.676-5.428a1.5,1.5,0,1,0-2.279,1.963l4.97,5.77-4.97,5.77a1.5,1.5,0,0,0,2.279,1.963l4.676-5.428,4.676,5.428a1.5,1.5,0,0,0,2.279-1.963Z" transform="translate(-131.589 -118.87)" fill="#fff" />
              </svg>
            </div>
          </div>

          <div className="country_list_popup_body">
            {
              countries.map((value, i) => {
                return (
                  <div
                    className="country_list_popup_body_text" key={i}
                    onClick={() => {
                      setCountriesDialcode(value);
                      setDialCode(value.value);
                      setTagCountriesDialcodePopup(false);
                    }}
                  >
                    {value.name}
                    {/* Hello */}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  };

  return (
    <div className="inner-wrap">
      <ToastContainer />
      {tag_countries_dialcode_popup ? createCountryListPopup(countries) : ''}
      {redirect ? (
        <OTP code={dialCode} couponCode={couponCode} confimOTP={confimOTP} phonenumber={phonenumber} />
      ) : (
        <Fragment>
          {showTerms === true ? (
            loader === true ? (
              <Loader />
            ) : (
              <Fragment>
                <div className="header">
                  <div className="back-btn">
                    <a onClick={() => goback()}>
                      <img src={Previous} alt="" />
                    </a>
                  </div>
                  <h2>{t('right_hand_drop_down.terms_conditions')}</h2>
                  <div className="wallet-btn"></div>
                </div>
                <div className="body mCustomScrollbar _mCS_1 bottom-btn-body">
                  {ReactHtmlParser(termsData)}
                </div>
              </Fragment>
            )
          ) : showPrivacy === true ? (
            loader === true ? (
              <Loader />
            ) : (
              <Fragment>
                <div className="header">
                  <div className="back-btn">
                    <a onClick={() => goback()}>
                      <img src={Previous} alt="" />
                    </a>
                  </div>
                  <h2>{t('right_hand_drop_down.privacy_policy')}</h2>
                  <div className="wallet-btn"></div>
                </div>
                <div className="body mCustomScrollbar _mCS_1 bottom-btn-body">
                  {ReactHtmlParser(privacyData)}
                </div>
              </Fragment>
            )
          ) : showRegister === true ? (
            <Fragment>
              <div className="header">
                <div className="logo">
                  <Link to="/">
                    <img src={Logo} className="d-block mx-auto" alt="" style={{ width: '150px' }} />
                  </Link>
                </div>
              </div>
              <div className="body mCustomScrollbar _mCS_1 country_drop">
                <div className="row mt-4" style={{ paddingTop: '60px' }}>
                  <div className="col-4 col-md-4 pr-0">
                    <div className="form-group">
                      <label>{t('Profile.country_code')}</label>
                      {/* <Select
                        value={allDialCode}
                        onChange={handleChangeCountry}
                        options={countries}
                        isSearchable={false}
                      /> */}
                      <button className="sign_in_country_code_popup_button"
                        onClick={(e) => { setTagCountriesDialcodePopup(true) }}
                      >
                        {countries_dialcode.label}
                        <svg className="" width="25" height="25" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path id="" d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z" fill="#D3D3D3"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="col-8 col-md-8">
                    <div className="form-group">
                      <label>{t('Profile.mobile_number')}</label>
                      <input
                        type="number"
                        onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        className="form-control"
                        placeholder={t('login.enter_mobile_number')}
                        value={phonenumber}
                        onChange={(e) => onChangePhone(e.target.value)}
                        style={{ height: '42px' }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ borderRadius: "30px", margin: "0 -20px" }}
                >
                  <div className="col-12 col-md-12">
                    <div className="form-group ">
                      <label>{t('login.coupon_code')}</label>
                      <div className="code-field">
                        <input
                          type="text"
                          className="form-control"
                          value={couponCode}
                          onChange={(e) => fieldChange(e)}
                          placeholder={t('login.enter_coupon_code')}
                          required=""
                          style={{ textTransform: 'uppercase' }}
                        />
                        <button
                          type="submit"
                          className="text-dark"
                          style={{ backgroundColor: '#FFC609' }}
                          onClick={getSignUpode}
                        >
                          {t('login.apply')}
                        </button>
                      </div>
                      {errorCodeMessage === 'success' ? (
                        <p className="text-success">
                          {t('coupon_code_applied_successfully')}
                        </p>
                      ) : errorCodeMessage === 'notcorrect' ? (
                        <p className="text-success">
                          {t('please_apply_correct_coupon_code')}
                        </p>
                      ) : errorCodeMessage === 'error' ? (
                        <p className="text-secondary">
                          {t('error_enter_coupon_code')}
                        </p>
                      ) : (<p></p>)}
                    </div>
                  </div>
                </div>
                {error !== "" && <Error error={error} />}
                <div id="sign-up-button"></div>
                <div className="form-group mt-2">
                  <button onClick={signUp} className="btn btn-dark main-btn">
                  {t('premium.register')} <img src={RightArrow} alt="" />
                  </button>
                </div>
                <p className="text-center">
                    {t('premium.by_registering_i_agree_to_gamerJi')}{" "}
                  <b className="pointer" onClick={() => onClickTerms()}>
                    {t('premium.terms_and_conditions')}
                  </b>{" "}
                  {t('premium.and')}{" "}
                  <b className="pointer" onClick={() => onClickPrivacy()}>
                  {t('right_hand_drop_down.privacy_policy')}
                  </b>{" "}
                </p>
                <div id="sign-up-button"></div>
                <p className="text-center mt-2">
                {t('premium.already_user')}{" "}
                  <b
                    className="pointer"
                    onClick={() => goSignUP()}
                  >
                    {t('premium.login_in_now')}
                  </b>
                </p>
                <div className="form-group mt-2">
                  <button onClick={e => goSignUPEmail(e)} className="btn btn-dark main-btn">
                  {t('premium.register_with_email')} <img src={RightArrow} alt="" />
                  </button>
                </div>
                {/* {
                  dialCode !== '+91' && dialCode !== "" &&
                  (
                    loginData ? (
                      <div>
                        <h3>You logged in as {loginData.email}</h3>
                        <button onClick={handleLogout}>Logout</button>
                      </div>
                    ) : (
                      <p className="text-center mt-2">
                        <GoogleLogin
                          clientId={'454874517092-tk61r0pb6f79ecte31ugdcde36old1c5.apps.googleusercontent.com'}
                          onSuccess={responseGoogleOnSuccess}
                          onFailure={responseGoogleOnFailure}
                          cookiePolicy={'single_host_origin'}
                          theme={'dark'}
                          width={'460'}
                          height={'60'}
                          shape={'circle'}
                        // uxMode={'redirect'}
                        // redirectUri={"http://localhost:3006/register/gamerji-chirag"}

                        >

                          <span className="buttonText"> Sign up with Google</span>
                        </GoogleLogin>
                      </p>
                    )
                  )
                } */}
                {/* {campaignURL ==="gamerji-chirag" ?
                  <GoogleLogin
                  clientId={'454874517092-7eokbgohvd2lf6j4irhlv69p2pjs84op.apps.googleusercontent.com'}
                  onSuccess={responseGoogleOnSuccess}
                  onFailure={responseGoogleOnFailure}
                  cookiePolicy={'single_host_origin'}
                >
                  
                  <span> Login with Google</span>
                </GoogleLogin>:"" 
                  
                } */}

              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="header">
                <div className="logo">
                  <Link to="/">
                    <img src={Logo} className="d-block mx-auto" alt="" style={{ width: '150px' }} />
                  </Link>
                </div>
              </div>
              <div className="body mCustomScrollbar _mCS_1 country_drop">
                <div className="row mt-4" style={{ paddingTop: '60px' }}>
                  <div className="col-4 col-md-4 pr-0">
                    <div className="form-group">
                      <label>{t('premium.country_code')}</label>
                      {/* <Select
                        value={allDialCode}
                        onChange={handleChangeCountry}
                        options={countries}
                        isSearchable={false}
                      /> */}
                      <button className="sign_in_country_code_popup_button"
                        onClick={(e) => { setTagCountriesDialcodePopup(true) }}
                      >
                        {countries_dialcode.label}
                        <svg className="" width="25" height="25" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path id="" d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z" fill="#D3D3D3"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="col-8 col-md-8">
                    <div className="form-group">
                      <label>{t('premium.mobile_number')}</label>
                      <input
                        type="number"
                        onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        className="form-control"
                        placeholder={t('premium.enter_mobile_number')}
                        value={phonenumber}
                        onChange={(e) => onChangePhone(e.target.value)}
                        style={{ height: '42px' }}
                      />
                    </div>
                  </div>
                </div>

                {error !== "" && <Error error={error} />}
                <div className="form-group mt-2">

                  <button onClick={onLogin} className="btn btn-dark main-btn">
                  {t('premium.login')} <img src={RightArrow} alt="" />
                  </button>
                </div>
                <p className="text-center">
                {t('premium.by_registering_i_agree_to_gamerJi')}{" "}
                  <b className="pointer" onClick={() => onClickTerms()}>
                  {t('premium.and')}
                  </b>{" "}
                  {t('premium.terms_and_conditions')}{" "}
                  <b className="pointer" onClick={() => onClickPrivacy()}>
                    {t('right_hand_drop_down.privacy_policy')}
                  </b>{" "}
                </p>
                <p className="text-center mt-2">
                {t('premium.don_have_an_account')}{" "}
                  <b
                    className="error pointer"
                    onClick={() => goSignUP()}
                  >
                    {t('premium.sign_up')}
                  </b>
                </p>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
}
export default RegisterCampaign;
