import React, { Fragment } from "react";
import Previous from "../assets/images/previous.svg";
import Verified from "../assets/images/verified.svg";
import UploadCard from "../assets/images/upload-card.svg";
import DateIcon from "../assets/images/date-icon.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import { Link } from "react-router-dom";

function PAN(props) {
  return (
    <Fragment>
      <div className="header">
        <div className="back-btn">
          <Link to="/">
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>Pan</h2>
        <div className="wallet-btn"></div>
      </div>
      <div className="body mCustomScrollbar _mCS_1">
        <div className="pan-btn">
          <Link className="verify-pan" to="#">
            <i>
              <img src={Verified} alt="" />
            </i>
            Verify Your PAN
          </Link>
          <div className="file_upload">
            <input type="file" />
            <Link className="upload-pan" to="#">
              <i>
                <img src={UploadCard} alt="" />
              </i>
              UPLOAD PAN CARD IMAGE
            </Link>
          </div>
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="text"
            className="form-control"
            placeholder="Mike Hussy"
            required=""
          />
        </div>
        <div className="form-group">
          <label>PAN Number</label>
          <input
            type="text"
            className="form-control"
            placeholder="AAECC6548C"
            required=""
          />
        </div>
        <div className="form-group">
          <label>Date of Birth</label>
          <div className="date-field">
            <div className="icon">
              <img src={DateIcon} alt="" />
            </div>
            <input
              type="date"
              className="form-control datepicker"
              placeholder="06/09/1995"
              required=""
            />
          </div>
        </div>
        <div className="form-group">
          <label>State</label>
          <select className="select">
            <option>GUJARAT</option>
            <option>DELHI</option>
            <option>MUMBAI</option>
            <option>PUNE</option>
          </select>
        </div>
        <div className="pan-note">
          <p>Why should I submit my PAN Card?</p>
        </div>
      </div>
      <div className="bottom_btn">
        <button className="btn btn-dark main-btn">
          SUBMIT FOR VERIFICATION
          <img src={RightArrow} alt="" className="mCS_img_loaded" />
        </button>
      </div>
    </Fragment>
  );
}

export default PAN;
