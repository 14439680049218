import { goback } from "../../component/common";

import Previous from "../../assets/images/previous.svg";
import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import { useEffect } from "react";
import { getHowToCreateScrim } from "../../apis";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";

export const Howtocreatescrimbs = () => {
  const navigate = useNavigate();
  const [value, setvalue] = useState('')
  const {t} = useTranslation()

  useEffect(()=>{
    fetchData()
  },[])
    let id = localStorage.getItem('activeGameId')
    const fetchData = async ()=>{
    const res = await getHowToCreateScrim({
      "id" : id // game id
      
        })
        if(res){
          ////console.log(res.data[0].msg)
          setvalue(res.data[0]?.param[0]?.howToCreateScrim)
        }
    
  }
  return (
    <div className="inner-wrap" id="my_contest_main_inner">
      <div className="header">
        <div className="back-btn">
          <a onClick={() => navigate(-1)}>
            <img src={Previous} alt="" />
          </a>
        </div>
        <h2> {t('private_contest.private_contest')}</h2>
        <div className="wallet-btn">
          {/* <Link to="/">
            <img src={Wallet} alt="" />
            Wallet
          </Link> */}
        </div>
      </div>
      <div className="body mCustomScrollbar _mCS_1 bottom-btn-body mycontest_main pt-0">
        <p
          className="text-center w-100"
          style={{ marginTop: "14px", fontSize: "19px" ,fontWeight:"600"}}
        >
          {t('tournaments.how_to_join_a_contest')} <br />
        </p>
        <div>
          {
            value?.length > 0 ? <div className="tab_content">
            <p>{ReactHtmlParser(value)}</p>
          </div> : 
          <h6>{t('notFound.no_records_found')}</h6>
          }
        
        </div>
      </div>
    </div>
  );
};
