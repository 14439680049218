import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import colors from '../constants/colors';
// import Moment from 'react-moment';
import Success from "../assets/images/success-payment.png";
import Previous from "../assets/images/previous.svg";
// import Pending from "../assets/images/pending-payment.png";
import Fail from "../assets/images/fail-payment.png";
// import sha256 from 'crypto-js/sha256';
import moment from 'moment';
// import { phonepeStatus, xsollaStatus } from '../apis';
// import { useSearchParams } from 'react-router-dom';
// import Loader from '../component/loader';
// import getSymbolFromCurrency from "currency-symbol-map";

export const PaymentSuccess = ({ transactionId, amount, message, currency, paymentTimestamp }) => {
    const goCoinStore = () => {
        // if(props.platform && props.platform==="WebApp"){
        //   window.location.href = "/coin-store";
        // }
        // if(props.platform && props.platform==="MobileApp"){
        //   window.ReactNativeWebView.postMessage(JSON.stringify(props))
        // }
        //
        window.location.href = "/coin-store"
    };
    return (
        <>
            <div style={{ backgroundColor: "#36DE4C", textAlign: 'center', height: "80%", width: '80%', borderRadius: '40px' }} className="mt-0">


                {/* Status Icon */}
                <img style={{ marginTop: 42, height: 100, width: 100 }} src={Success} />

                {/* Title */}
                <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Success!</div>

                {/* Amount */}
                {
                    <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
                        {currency} {" "}
                        {amount}
                    </div>
                }

                {/* Date Time */}
                <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} >{moment(paymentTimestamp).format('YYYY-MM-DD HH:MM')}</div>

                {
                    <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >
                        Transaction ID :{transactionId}
                    </div>
                }
                <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{message}</div>
                <button className="btn btn-dark main-btn next  mb-4 ml-2" style={{ width: "96%", marginTop: '80%' }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


            </div>
        </>
    )
}
const PaymentStatusItemFailed = ({ transactionId, amount, message, currency, paymentTimestamp }) => {
    const goCoinStore = () => {
        // if (props.platform && props.platform === "WebApp") {
        //   window.location.href = "/coin-store";
        // }
        // if (props.platform && props.platform === "MobileApp") {
        //   window.ReactNativeWebView.postMessage(JSON.stringify(props))
        // }
        window.location.href = "/";
    };
    // var transaction = props.transaction;
    // var coinStore = props.coinStore;
    return (

        <div style={{ backgroundColor: "#FF4646", textAlign: 'center', height: "80%", width: '80%', borderRadius: '40px' }} className="mt-0">

            {/* Status Icon */}
            <img style={{ marginTop: 42, height: 100, width: 100 }} src={Fail} />

            {/* Title */}
            <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Failure!</div>

            {/* Amount */}
            {
                <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
                    {/* {coinStore?.currency?.symbol}  */}
                    {currency} {" "}
                    {amount}</div>
            }

            {/* Date Time */}
            <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} >{moment(paymentTimestamp).format('YYYY-MM-DD HH:MM')}</div>
            <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >
                Transaction ID :{transactionId}
            </div>
            <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{message}</div>

            <button className="btn btn-dark main-btn next  mb-4 ml-2" style={{ width: "96%", marginTop: '120%' }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


        </div>)
}
const AirPayStatus = () => {
    const location = useLocation();
    //console.log(location);
    const onClickBack = () => {
        window.location.href = "/coin-store";
    };
    return (
        <>
            <div className="pg_main_div">
                <div className="pg_header">
                    <div className="pg_back_arrow_component">
                        <div className="back-btn">
                            <a className="pg_link" onClick={() => onClickBack()}>
                                <img src={Previous} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="pg_header_text">
                        Transaction Status
                    </div>
                    <div className=""></div>
                </div>

                <div className="pg_body">

                    <div className="all_main_screens_div_related_to_ads">
                        <div className="pg_body_scrollable_component" style={{ marginTop: '10%' }}>
                            {
                                location?.state?.data.paymentStatus === 'SUCCESS' ?
                                    <>
                                        <PaymentSuccess transactionId={location?.state?.data.transactionId} amount={location?.state?.data.amount} message={location?.state?.message} currency={location?.state?.data.currency.symbol} /> </>

                                    : <> <PaymentStatusItemFailed transactionId={location?.state?.data.transactionId} amount={location?.state?.data.amount} message={location?.state?.message} currency={location?.state?.data.currency.symbol} /></>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>

    )
}

export default AirPayStatus