import React, { Fragment } from "react";
import Previous from "../assets/images/previous.svg";
import Access from "../assets/images/access.svg";
import Calendar from "../assets/images/calendar.svg";
import Scheme from "../assets/images/scheme.svg";
import Avatar from "../assets/images/avatar.png";
import RightArrow from "../assets/images/right-arrow.svg";
import Close from "../assets/images/close.svg";
import { Link } from "react-router-dom";

function TicketNo(props) {
  return (
    <Fragment>
      <div className="header">
        <div className="back-btn">
          <Link to="/">
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>Ticket No</h2>
        <div className="wallet-btn"></div>
      </div>
      <div className="body mCustomScrollbar _mCS_1">
        <div className="complaint_title shadow-black">Raise a Complaint</div>
        <div className="complaint_list">
          <div className="item shadow-black">
            <div className="box_row">
              <div className="box bg-primary shadow-primary">
                <div className="icon">
                  <img src={Access} alt="" />
                </div>
                Ticket No <br /> <b>#123FGH</b>
              </div>
              <div className="box bg-primary shadow-primary">
                <div className="icon">
                  <img src={Calendar} alt="" />
                </div>
                Date <br /> <b>22/10/2020</b>
              </div>
              <div className="box bg-primary shadow-primary">
                <div className="icon">
                  <img src={Scheme} alt="" />
                </div>
                Status <br /> <b>PENDING</b>
              </div>
            </div>
            <div className="txt bg-secondary shadow-secondary">
              <h6>Subject:</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                sedeiusmod tempor incididunt labore magna aliqua.
              </p>
            </div>
          </div>
        </div>
        <div className="cmt">
          <div className="item">
            <div className="avtar">
              <img src={Avatar} alt="" />
            </div>
            <div className="txt bg-primary shadow-primary">
              <p>Hello John, what are you going to do this weekend?</p>
              <div className="time">17:45</div>
            </div>
          </div>
          <div className="item our_item">
            <div className="txt bg-dark shadow-black">
              <p>Nothing planned, and you?</p>
              <div className="time">18:04</div>
            </div>
          </div>
          <div className="item">
            <div className="avtar">
              <img src={Avatar} alt="" />
            </div>
            <div className="txt bg-primary shadow-primary">
              <p>
                I’m going to San Francisco and I’m booking an Airbnb. Would you
                like to come?
              </p>
              <div className="time">now</div>
            </div>
          </div>
          <div className="item item_loader">
            <div className="inner bg-primary shadow-primary">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>

      <div className="custom-popup bg-primary" id="contest_joining">
        <div className="popup-head mb-0">
          <h3>
            Monthly Championship <br />
            Tournament
          </h3>
          <button className="close-popup">
            <img src={Close} alt="" />
          </button>
        </div>
        <div className="popup-body">
          <h4 className="text-secondary text-center">
            <b>PUBG-SOLO</b>
          </h4>
          <p className="text-center">12th November 2020 to 20th June 2020</p>
          <div className="confirmation text-center mb-4">
            <b>Select Your Game Time</b>
          </div>
          <div className="radio_wrap">
            <label>
              <input type="radio" name="radio" />
              <span></span>
              12th June 2020, 10:00 AM
            </label>
            <label>
              <input type="radio" name="radio" />
              <span></span>
              12th June 2020, 10:00 AM
            </label>
            <label>
              <input type="radio" name="radio" />
              <span></span>
              12th June 2020, 10:00 AM
            </label>
            <label>
              <input type="radio" name="radio" />
              <span></span>
              12th June 2020, 10:00 AM
            </label>
          </div>
          <p className="d-flex flex-wrap justify-content-between p-small">
            <span>
              To Check Round 2 Timings <br />
              <b>
                <Link to="#" className="text-secondary">
                  CLICK HERE!
                </Link>
              </b>
            </span>
            <span className="text-right">
              To Check Round 3 Timings <br />
              <b>
                <Link to="#" className="text-secondary">
                  CLICK HERE!
                </Link>
              </b>
            </span>
          </p>
          <p className="p-small text-center">
            <b>FINALE will be scheduled at 20th June 2020, 10:00 PM</b>
          </p>
          <button className="btn btn-dark main-btn">
            NEXT <img src={RightArrow} alt="" />
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default TicketNo;
