export const handleTextAnimation = () => {
  let animation_component = document.querySelectorAll('.text_animation_container');
  // console.log('animation: ', animation_component);

  animation_component.forEach((value, i) => {
    // console.log(value.clientWidth + ' ' + value.scrollWidth)
    if(value.scrollWidth > value.clientWidth) {
      value.classList.add('text_animation_component');
      value.style.transform = 'translateX(100%)'
    } else {
      value.classList.remove('text_animation_component');
    }        
  })

  animation_component = document.querySelectorAll('.text_animation_container');
  // console.log('animation: ', animation_component);
}