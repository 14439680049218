import React, { Fragment, useEffect, useState } from "react";
import Previous from "../assets/images/previous.svg";
import Loader from "../component/loader";
import { Link } from "react-router-dom";
import { getSearchUserList, getFollowingList, getFollowerList, removeFolowUser } from "../apis";
import url from "../constants/url";
import { useTranslation } from "react-i18next";

import Advertisement from "../component/advertisement";

function Friends(props) {
  const [searchUserList, setSearchUserData] = useState(null);
  const [followingList, setFollowingData] = useState([]);
  const [followerList, setFollowerData] = useState([]);
  const [followingCount, setFollowingCount] = useState(0);
  const [followerCount, setFollowerCount] = useState(0);
  const { t } = useTranslation();

  const [limitData, setlimitData] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const getLoadMore = () => {
    setlimitData(limitData + 20);
  };

  useEffect(() => {
    fetchFollowingData();
    fetchFollowerData();

    return () => {
      setFollowerData([]);
      setFollowingData([]);
      setFollowerCount(0);
      setFollowingCount(0);
    };
  }, []);

  const fetchFollowingData = async () => {
    setLoading(true);
    let data = { skip: followingList.length, limit: 20 };
    let res = await getFollowingList(data);
    if (res) {
      //  setFollowingData(res);
      setFollowingCount(res.count);
      if (res.list) {
        var tempArr = res.list.map((item) => {
          return { ...item };
        });
        setFollowingData([...followingList, ...tempArr]);
      }
      setLoading(false);

      //console.log("fetchFollowingData===>", res);
    }
  };

  const fetchFollowerData = async () => {
    setLoading(true);
    let data = { skip: followerList.length, limit: 20 };
    let res = await getFollowerList(data);
    if (res) {
      //  setFollowerData(res);
      setFollowerCount(res.count);

      if (res.list) {
        var tempArr = res.list.map((item) => {
          return { ...item };
        });
        setFollowerData([...followerList, ...tempArr]);
      }
      setLoading(false);

      //console.log("fetchFollowerData===>", res);
    }
  };

  const onClickRemoveFollower = async (user_id) => {
    let data = { action: "removefollow", followingUser: user_id };
    let res = await removeFolowUser(data);
    if (res) {
      setLoading(true);
      let data = { skip: 0, limit: 20 };
      let res1 = await getFollowerList(data);
      if (res1) {
        //  setFollowerData(res1);
        setFollowerCount(res1.count);

        if (res1.list) {
          var tempArr = res1.list.map((item) => {
            return { ...item };
          });
          setFollowerData(tempArr);
        }
        setLoading(false);

        //console.log("fetchFollowerData===>", res1);
      }
    }
  };

  const onClickRemoveFollowing = async (user_id) => {
    let data = { action: "unfollow", followingUser: user_id };
    let res = await removeFolowUser(data);
    if (res) {
      setLoading(true);
      let data = { skip: 0, limit: 20 };
      let res1 = await getFollowingList(data);
      if (res1) {
        //  setFollowingData(res1);
        setFollowingCount(res1.count);
        if (res1.list) {
          var tempArr = res1.list.map((item) => {
            return { ...item };
          });
          setFollowingData(tempArr);
        }
        setLoading(false);

        //console.log("fetchFollowingData===>", res1);
      }
    }
  };

  const onClickSearch = async () => {
    setLoading(true);
    let data = { skip: 0, limit: 20, type: "appUser", q: searchText };
    let res1 = await getSearchUserList(data);
    //console.log("res1==>", res1);
    if (res1) {
      setLoading(false);
      // setTodayData(res1);
      setSearchUserData(res1);
    }
  };

  return (
    <div className="inner-wrap custom_scroll_leader" style={{ paddingTop: "8px" }}>
      <div className="header bg-dark ">
        <div className="back-btn">
          <Link to="/profile">
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>{t("Profile.friends")}</h2>
        <div className="wallet-btn"></div>
      </div>

      <div className="body mCustomScrollbar _mCS_1 mt-5 " style={{ overflow: "hidden", height: "100vh" }}>
        <div className="all_sub_screens_div_related_to_ads">
          <div className="friends-detail-tabs">
            <div className="list-group center college-tab" id="list-tab" role="tablist" style={{ top: 0 }}>
              <a className="list-group-item list-group-item-action active" id="list-home-list" data-toggle="list" href="#list-1">
                {t("Profile.followers")} ({followerCount})
              </a>
              <a className="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" href="#list-2">
                {t("Profile.following")} ({followingCount})
              </a>
            </div>

            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane active table-wrapper-scroll-y friends-custom-scrollbar" id="list-1">
                <div className="user_list" style={{ top: followerList?.length > 0 ? "20px" : "0" }}>
                  {followerList?.length > 0 ? (
                    followerList.map((item, index) => {
                      return (
                        <div className="item" key={item._id}>
                          <div className="inner inner_big">
                            <div className="icon">
                              <img src={url.imageUrl + item?.level?.featuredImage?.default} alt="" />
                            </div>
                            <b>{item?.user?.gamerjiName}</b>
                          </div>
                          <a href="#" onClick={() => onClickRemoveFollower(item?.user?._id)} className="friend-remove">
                            {" "}
                            {t('login.remove')}
                          </a>
                        </div>
                      );
                    })
                  ) : (
                    <h3 className="no_records">{t('notFound.no_records_found')}</h3>
                  )}
                  <div>
                    {followerCount > followerList?.length ? (
                      loading === true ? (
                        <Loader />
                      ) : (
                        <button className="load-more-btn" onClick={() => fetchFollowerData()}>
                          {t('contest.load_more')}
                        </button>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="tab-pane table-wrapper-scroll-y friends-custom-scrollbar" id="list-2">
                <div className="user_list" style={{ top: followerList?.length > 0 ? "20px" : "0" }}>
                  {followingList?.length > 0 ? (
                    followingList.map((item, index) => {
                      return (
                        <div className="item" key={item._id}>
                          <div className="inner inner_big">
                            <div className="icon">
                              <img src={url.imageUrl + item?.level?.featuredImage?.default} alt="" />
                            </div>
                            <b>{item?.followingUser?.gamerjiName}</b>
                          </div>
                          <a href="#" onClick={() => onClickRemoveFollowing(item?.followingUser?._id)} className="friend-remove">
                            {" "}
                            {t('login.remove')}
                          </a>
                        </div>
                      );
                    })
                  ) : (
                    <h3 className="no_records">{t('notFound.no_records_found')}</h3>
                  )}
                  <div>
                    {followingCount > followingList?.length ? (
                      loading === true ? (
                        <Loader />
                      ) : (
                        <button className="load-more-btn" onClick={() => fetchFollowingData()}>
                          {t('contest.load_more')}
                        </button>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!JSON.parse(localStorage.getItem("premiumUser")) && (
          <Advertisement
            screen={
              localStorage.getItem("apk_screens_list") === null
                ? { code: "no_results" }
                : JSON.parse(localStorage.getItem("apk_screens_list")).friends
            }
            screen_div_class_name=".all_sub_screens_div_related_to_ads"
            screen_type="sub"
          />
        )}
      </div>
    </div>
  );
}

export default Friends;
