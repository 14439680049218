import React, { useCallback } from "react";
import friendShip from "../../assets/images/friendship.svg";
import copyScrim from "../../assets/images/copyScrim.png";
import Pencil from "../../assets/images/pencil.png";
import { useState } from "react";
import {
  getStatusScrim,
  getUploadSS,
  ssUpload,
  statusUpdate,
  updateRoomIdandPassword,
  viewScrimById,
} from "../../apis";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import url from "../../constants/url";
import routes from "../../constants/routes";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Cancel from "../../assets/images/round_not_qualified.png";
import { RWebShare } from "react-web-share";
import Loader from "../loader";
import { useTranslation } from "react-i18next";

const ScrimDetails = ({ handleTabClick }) => {
  const params = useParams();
  // console.log(params);
  const copyableString = useRef();

  const [data, setData] = useState({});
  const [files, setFiles] = useState([]);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [disabled, setDisabled] = useState(!"disabled");
  const [roomId, setRoomId] = useState(null);
  const [password, setPassword] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [ssTime, setSSTime] = useState(false);
  const [cancelScrim, setCancelScrim] = useState(false);
  const [isRoomId, setIsRoomId] = useState(false);
  const [isSSUpload, setIsSSUpload] = useState(false);
  const [status, setStatus] = useState("");
  const [loader, setLoader] = useState(false)
  const [remaining, setRemaining] = useState(null)
  const {t} = useTranslation()
  const handleClose = () => {
    setOpen(false);
  };
  // const fetchStatus = async () => {
  //   setLoader(true)
  //   let res = await getStatusScrim({
  //     scrimId: params?.id,
  //   });
  //   // console.log(res);
  //   if (res.success) {
  //     setStatus(res?.data?.status);
  //     // console.log(status);
  //   } else {
  //     showError(res.data[0].msg);
  //   }
  //   setLoader(false)
  // };
  const [selectedImage, setSelectedImage] = useState("");
  const [uploadLoader, setUploadLoader] = useState(
    t('private_contest.please_upload_screenshot_of_your_game')
  );
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      // console.log("clicked", acceptedFiles[0].path);
      // setUploadLoader("Please wait, Uploading...");
      var formData = new FormData();
      formData.append("file", acceptedFiles[0], acceptedFiles[0].path);

      var requestOptions = {
        method: "POST",
        headers: { "x-access-token": localStorage.getItem("gamerjiToken") },
        body: formData,
        redirect: "follow",
      };
      // console.log(`${url.apiUrl}${routes.uploadSS}`);
      // console.log(JSON.parse(result)?.item?.screenShot)
      fetch(`${url.apiUrl}${routes.uploadSS}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          // console.log(
          //   result,
          //   files,
          //   JSON.parse(result)?.item?.screenShot?.default
          // );
          setFiles([...files, JSON.parse(result)?.item?.screenShot?.default]);
          setIsSSUpload(true);
        })
        .catch((error) => {
          //console.log(error);
          setUploadLoader(t('private_contest.please_upload_again'));
        });
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });
  useEffect(() => {
    fetchData();
    // fetchStatus();

    if (isRoomId) {
      showSuccess(t('room_id_password_updated_successfully'));
    }
    if (isSSUpload) {
      showSuccess(t('screenshot_upload_successfully'));
    }
    return ()=> {
      setFiles([])
      setRoomId(null)
      setPassword(null)
      setData({})
      setRemaining(null)
    }
  }, [isRoomId, isSSUpload]);

  // useEffect(() => {
  //   fetchData();
  //   // console.log(isRoomId);
  //   if (isRoomId) {
  //     showSuccess(t('room_id_password_updated_successfully'));
  //   }
  // }, [isRoomId]);

  // useEffect(() => {
  //   fetchData();
  //   // console.log(isSSUpload);
  //   if (isSSUpload) {
  //     showSuccess(t('screenshot_upload_successfully'));
  //   }
  // }, [isSSUpload]);

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${year}`;
  }
  function getCurrentTime(separator = ":") {
    let newDate = new Date();
    let hr = newDate.getHours();
    let min = newDate.getMinutes();

    return `${hr}${separator}${min < 10 ? `0${min}` : `${min}`}`;
  }
  function getCurrentTime15(date, separator = ":") {
    date = new Date(date);
    let hr = date.getHours();
    let min = date.getMinutes();
    // console.log(min, min - 30);
    return `${hr}${separator}${min < 10 ? `0${min}` : `${min}`}`;
  }
  const fetchData = async () => {
    setLoader(true)
    var res = await viewScrimById({
      id: params?.id, //  scrim id
    });
    // console.log("view Scrim", res)
    if (res?.success) {
      // console.log(res.data[0].param);
      var d = res?.data[0].param.date;
  

      let date = moment(d).format("DD/MM/YYYY");
      let time = moment(d).format("hh:mm A");
      setStatus(res?.data[0].param.status);
      setData(res?.data[0].param);
      setDate(date);
      setTime(time);
      setLoader(false)
      let r = res?.data[0].param.noOfPlayerCapacity - res?.data[0].param.noOfJoinedPlayer;
      //console.log(r);
      setRemaining(r)
      if (res?.data[0].param?.room?.id !== null) {
        setDisabled("disabled");
      }
     
      //  roomm id pwd logic
      const newDate = moment(d).format("y-MM-DD");
      const newTime = moment(d).format("HH:mm:ss");
      const formate = "YYYY-MM-DD h:m A";
      var dateTime = moment(newDate + " " + newTime);
      const currentTime = moment();
      var RPEndTime = moment(moment(dateTime).subtract(15, "minutes"));
      // console.log("Room", res.data[0].param?.gameType.settings.RPTime);
      var RPStartTime = moment(dateTime)
        .subtract(15, "minutes")
        .subtract(
          parseInt(res?.data?.[0]?.param?.gameType.settings.RPTime),
          "minutes"
        );
      let showRIDP = currentTime.isBetween(RPStartTime, RPEndTime);
      if (!showRIDP) {
        setDisabled("disabled");
      } else {
        setDisabled(!"disabled");
      }
      // console.log(currentTime.isBetween(RPStartTime, RPEndTime));
      const startTime = moment(moment(dateTime), formate);
      const SSEndTime = moment(
        moment(dateTime).add(
          res?.data?.[0]?.param?.gameType.settings.SSUploadTime,
          "minutes"
        ),
        formate
      );
      const WDStartTime = moment(moment(dateTime), formate);
      const WDEndTime = moment(
        moment(dateTime).add(
          res?.data?.[0]?.param?.gameType.settings.winningsTime,
          "minutes"
        ),
        formate
      );
      let SSTime = currentTime.isBetween(startTime, SSEndTime);
      setSSTime(SSTime);
      // console.log(ssTime, currentTime.isBetween(startTime, SSEndTime));
      let WDTime = currentTime.isBetween(WDStartTime, WDEndTime);
      localStorage.setItem("win", WDTime);
      // console.log(
      //   RPEndTime,
      //   RPStartTime,
      //   showRIDP,
      //   startTime,
      //   SSEndTime,
      //   WDStartTime,
      //   WDEndTime,
      //   WDTime
      // );
      // console.log(currentTime.isBetween(startTime, SSEndTime));
    }
    
  };

  const showError = (err) => {
    //console.log("showeeror", err);
    toast.error(err, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = (error) => {
    toast.success(error, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmitRIdPwd = async (e) => {
    e.preventDefault();
    if (!roomId || roomId.length <= 0) {
      showError(t('error_please_enter_roomID'));
    } else if (!password || password.lengh <= 0)
      showError(t('error_please_enter_password'));
    else if (roomId.length > 16 || password.length > 16)
      showError(t('max_length_should_be_16'));
    else {
      
      let res = await updateRoomIdandPassword({
        id: params?.id, //  scrim id
        data: {
          roomId: roomId,
          password: password,
        },
      });
      if (res) {
        // console.log(res);
        showSuccess(data.msg);
        setRoomId(roomId);
        setPassword(password);
        setIsRoomId(true);
        // console.log(isRoomId);
        // setTimeout(()=>{
        //   console.log(isRoomId)
        //   showSuccess(data.msg)
        // },2000)
      }
    }
  };

  const copiedHandler = (e) => {
    navigator.clipboard.writeText(copyableString.current.value);
    showSuccess(t('info_Copied'));
  };

  const completeHandler = async () => {
    if (data?.room?.id && files.length > 0) {
      let ssupload = await ssUpload(
        {
          pictures: files,
        },
        params?.id
      );
      // console.log(ssupload);
      handleTabClick("LeaderBoard");
    } else {
      if (!data?.room?.id) {
        showError(t('please_update_Room_ID_Password'));
      }
      if (files.length == 0) {
        showError(t('error_screenshot_upload'));
      }
    }
  };
  const cancelHandler = async () => {
    setOpen(true);
    // console.log(cancelScrim);
  };
  const yesHandler = async () => {
    // console.log("called");
    setOpen(false);

    let res = await statusUpdate(
      {
        status: "Canceled",
      },
      params?.id
    );
    // console.log(res);
    navigate("/private-scrims");
  };

  const removeimage = (file) => {
    let f = files.filter((ele) => ele != file);
    // console.log(f);
    setFiles(f);
  };
  // if(loader) return <Loader />  

  return (
    <>
      <main>
        {
          loader ? <Loader /> : <div
          className="contest-list contest-tournament-list game_tounament "
          style={{ margin: "20px auto 0px" }}
        >
          <div className="box scrimDetail">
            {/* {console.log(">>>",data?.title?.length)} */}
            <h3 style={{ fontSize: "16px", fontWeight: "800", marginTop: "4px" }}>
              {data?.title?.length > 32 ? (
                <marquee> {data?.title}</marquee>
              ) : (
                <>{data?.title}</>
              )}
  
              {data?.status === "Waiting" ? (
                <span className="span">
                  <Link to={`../edit-scrim/${params?.id}`}>
                    <img
                      src={Pencil}
                      alt="Edit"
                      style={{ color: "black !important", width: '20px', height: '20px' }}
                    />
                  </Link>
                </span>
              ) : (
                ""
              )}
            </h3>
  
            <div
              className="row tournament_contest_row"
              style={{ marginBottom: "-20px" }}
            >
              <div className="col-12">
                <div className="inner_row">
                  <div className="inner_item">
                  {t('contest_details.date')} <b>{date}</b>
                  </div>
                  <div className="inner_item">
                  {t('contest_details.time')} <b>{time}</b>
                  </div>
                  {data?.titles?.slice(0, 2).map((ele) =>
                    ele ? (
                      <div className="inner_item">
                        {ele?.name.toLowerCase() === "map" ? t('contest.map') : ele?.name.toLowerCase() === "perspective" ? t('contest.perspective') : ele?.name } <b>{ele.value}</b>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </div>
                <div className="inner_row bg-dark">
                  <div className="inner_item inner_item_medium">
                  {t('contest_details.players')} <br /> <b>{data?.noOfPlayerCapacity}</b>
                  </div>
                  <div className="inner_item inner_item_medium">
                  {t('tournaments.winners')} <br /> <b>{data?.noOfWinner}</b>
                  </div>
                  <div className="inner_item inner_item_medium">
                  {t('contest_details.id')} <br /> <b>{data.shortCode}</b>
                  </div>
                </div>
  
                <div className="avalible_wrap">
                  <div className="inner">
                    <span
                      style={{
                        width: `${
                          (100 * data?.noOfJoinedPlayer) /
                          data?.noOfPlayerCapacity
                        }%`,
                      }}
                    ></span>
                  </div>
                  <p
                    className="d-flex justify-content-between text-light"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <span className="">
                      {t('contest_details.players_remaining',{players: remaining})}
                    </span>
                    <span className="">
                      {t('contest_details.players_joined',{players: data?.noOfJoinedPlayer})}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      
      <form style={{ marginTop: "-10px" }}>
        <div className="flex gap ridpwd">
          <div className="form-group width">
            <label>{t('private_contest.room_id')}*</label>
            <div className="scrim-d" style={{ height: "40px" }}>
              {data?.room?.id ? (
                <input
                  className="dobns_input"
                  style={{ padding: "10px 15px 10px 20px" }}
                  type="text"
                  placeholder=""
                  disabled
                  value={data?.room?.id}
                />
              ) : (
                <input
                  className="dobns_input"
                  type="text"
                  placeholder=""
                  disabled={disabled}
                  onChange={(e) =>{
                    // console.log(e.target.value)
                    setRoomId(e.target.value)}}
                />
              )}
            </div>
          </div>
          <div className="form-group width">
            <label>{t('private_contest.password')}*</label>
            <div className="scrim-d" style={{ height: "40px" }}>
              {data?.room?.password ? (
                <input
                  className="dobns_input"
                  style={{ padding: "10px 15px 10px 20px" }}
                  required="true"
                  disabled
                  value={data?.room?.password}
                  type="text"
                />
              ) : (
                <input
                  className="dobns_input"
                  required="true"
                  type="text"
                  disabled={disabled}
                  onChange={(e) => setPassword(e.target.value)}
                />
              )}
            </div>
          </div>

          {data?.room?.id || status == "Canceled" ? (
            ""
          ) : (
            <button className="rpBtn" onClick={onSubmitRIdPwd}>
              {t('private_contest.submit')}
            </button>
          )}
        </div>
      </form>
      <p style={{ color: "#82878D", fontWeight: "500", textAlign: "center" }}>
        ( {t('private_contest.note_please_Eneter_roomid_password_atleast_15_mins_before_the_game_begin')} ){" "}
      </p>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('private_contest.cancel_contest')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('private_contest.are_you_sure_you_want_to_cancel')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('private_contest.no')}</Button>
          <Button onClick={yesHandler} autoFocus>
          {t('private_contest.yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <div className="flex ssUpload">
        <h6>{t('private_contest.find_the_screenshot')}</h6>

        {!ssTime ||
        data?.status === "In Review" ||
        data?.status == "Canceled" ||
        data?.status == "Completed" ? (
          <button disabled>{t('private_contest.choose_an_image')}</button>
        ) : (
          <button>
            {/* {console.log(getRootProps())} */}
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div style={{ padding: "1px 20px" }}>
                <b>{t('private_contest.choose_an_image')}</b>
              </div>
            </div>
          </button>
        )}
      </div>

      {data?.picture?.length != 0 ? (
        <div className="flex">
          {data?.picture?.map((e) => (
            <img
              style={{ width: "80px", height: "130px", marginRight: "20px" }}
              src={`${url.imageUrl}${e.url}`}
            />
          ))}
        </div>
      ) : (
        <div className="flex">
          {files.length != 0 ? (
            files?.map((file) => (
              <>
                <img
                  style={{
                    width: "80px",
                    height: "130px",
                    marginRight: "20px",
                  }}
                  src={`${url.imageUrl}${file}`}
                />
                <img
                  src={Cancel}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "20px",
                    marginLeft: "-5px",
                  }}
                  onClick={() => removeimage(file)}
                />
              </>
            ))
          ) : status == "Canceled" ? (
            ""
          ) : (
            <p
              style={{
                color: "#82878D",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              ( {t('private_contest.please_add_the_final_screenshot_after_the_match_is_completed')} )
            </p>
          )}
        </div>
      )}

      {status === "Waiting" || status === "Started" ? (
        <>
          <RWebShare
            data={{
              text: "Hey! Join me on Gamerji for this exciting match. Click on hte following link and let's play together",
              url: `${url.siteUrl}?code=${data.shortCode}`,
              title: "Join me on Gamerji",
            }}
            onClick={() => console.info("share successful!")}
          >
            <div className="row friendship_items">
              <div className="col-12">
                <div className="box winning_breakup_popup_toggle">
                  <div className="icon">
                    <img src={friendShip} alt="" />
                  </div>
                  {t('private_contest.share_with_friends')}
                </div>
              </div>
            </div>
          </RWebShare>
          <p
            style={{
              color: "#82878D",
              fontSize: "16px",
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            {t('private_contest.or')}
          </p>
          <div className="friendsCode">
            <input
              ref={copyableString}
              type="text"
              placeholder="3241231289"
              disabled
              value={data.shortCode}
            />
            <button>
              <img
                src={copyScrim}
                alt="copied"
                onClick={copiedHandler}
                style={{ height: "20px" }}
              />
            </button>
          </div>

          <p
            style={{
              color: "#82878D",
              fontWeight: "500",
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            ( {t('private_contest.share_the_above_code_and_ask_your_friend_to_join')} )
          </p>
        </>
      ) : (
        " "
      )}

      <div className="detailsButton">
        {status == "Completed" || status == "Canceled" ? (
          ""
        ) : (
          <button className="rpBtn" onClick={cancelHandler}>
            {t('private_contest.cancel')}
          </button>
        )}

        {status == "Completed" ||
        status === "In Review" ||
        status == "Canceled" ? (
          ""
        ) : (
          <button
            className="rpBtn"
            style={{ backgroundColor: "#070b28" }}
            onClick={completeHandler}
          >
            {t('private_contest.upload')}
          </button>
        )}
      </div>
      {/* <AlertDialog val={false} / > */}
      </main>
    </>
  );
};
export default ScrimDetails;
