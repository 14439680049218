import axios from "axios";
import url from "../constants/url";
import helperFunc from "../helperFuncs";
// import useToken from "../hooks/useToken";

var header = localStorage.getItem('gamerjiToken') ? { 'x-access-token': localStorage.getItem('gamerjiToken') } : { "user-type": "appUser" };
header['language'] = localStorage.getItem('langName')?.toUpperCase() || 'EN'
// header['company-code'] = 'GJ';
// if(helperFunc.getUserCountry()?._id)
// header['country'] =  helperFunc.getUserCountry()?._id;
// console.log("headers", header);
const instance_gj = axios.create({
    baseURL: url.apiUrl,
    timeout: 15000,
    headers: header
});

instance_gj.interceptors.request.use(function (config) {
    if (!!config.data) {
        for (let key of Object.keys(config?.data)) {
            if (config.data[key] === "")
                config.data[key] = null
        }
    }
    return config;
}, function (error) {
    return error //Promise.reject(error);
});

// Add a response interceptor
instance_gj.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("response===axi", response);

    if (response.status === 200) {
        return response.data;
    }
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log("error===axi", error);
    if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.href = '/sign-in';
    }
    return error?.response;
});

export default instance_gj;