import Previous from "../../assets/images/previous.svg";
import { ScrimSummaryCard } from "../../component/ScrimComponent/ScrimSummaryCard";
import coin from "../../assets/images/coin.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import rightArrow from "../../assets/images/right-arrow.svg";
import { createScrimFormSubmission } from "../../apis";
import Parser from "html-react-parser";
import url from "../../constants/url";
import { goback } from "../../component/common";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { routehelp } from "../../config/routehelp";
//import { useTranslation } from "react-i18next";
import { useTranslation, Trans } from "react-i18next";
import { constants } from "../../constants/constants";






export const ScrimSummaryButton = ({ data, coin, showError }) => {
  const { t } = useTranslation();

  // console.log(data);
  const [premiumUser, serPremiumUser] = useState(JSON.parse(localStorage.getItem("premiumUser")));
  const currency = localStorage.getItem("currency");
  // console.log(data);
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    var newTime = data["time"]?.split(" ");
    // console.log(newTime, newTime[0], newTime[1], newTime[2]);
    var temp = newTime[0].split(":");
    if (newTime[1] == "PM" && temp[0] != 12) {
      temp[0] = parseInt(temp[0]) + 12;
      newTime[0] = temp[0].toString() + ":" + temp[1];
    }

    newTime = newTime[0] + ":00";
    // console.log(newTime);
    var newDate = data["date"] + " " + newTime;
    // var newDate = data.payload['date'] + " " + newTime + ":00"
    const [dateString, timeString] = newDate.split(" ");
    const [day, month, year] = dateString.split("/");
    const [hour, minute, second] = timeString.split(":");
    const dateObj = new Date(+year, +month - 1, +day, +hour, +minute, +second);
    // console.log(dateObj.toISOString());
    // console.log(dateObj);
    // console.log("new date=>", newDate);
    var datee = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    // console.log(datee, new Date(datee));
    data = { ...data, date: new Date(datee), time: new Date(datee), platform: "webapp" };
    // console.log(data);
    delete data.currencyImg;
    const res = await createScrimFormSubmission(data);

    if (res?.success) {
      // console.log(res);
      // console.log(res?.data?.[0].data._id);
      localStorage.setItem("scrimId", res?.data?.[0].data._id);
      localStorage.removeItem("payload");
      window.location.href = "/manage-scrim/" + res?.data?.[0].data._id;
    }
    //to="/manage-scrim" state = {{"id":localStorage.getItem('scrimId')}}
    // navigate('/manage-scrim',{id:res.data[0].data._id })
    //routehelp.contestdetails;
    else {
      showError(res?.data?.data?.[0].msg);
    }
  };

  return (
    <div className="bottom_btn" onClick={formSubmitHandler}>
      <div className="btn btn-dark main-btn">
        {premiumUser ? (
          t("private_contest.submit_small")
        ) : (
          <div>
            {t("contest.pay")}
            {"  "}
            <img
              className="ps_currency"
              src={url.imageUrl + data?.currencyImg}
              alt=""
            // style={{ marginTop: 0,width:"25px", height: "20px", margin: "0 8px" }}
            />
            <span className="fw-8">{data?.feeAmount}</span>
          </div>
        )}

        <img src={rightArrow} alt="" className="mCS_img_loaded" />
      </div>
    </div>
  );
};

export const ScrimSummary = () => {
  const { t } = useTranslation();

  const Currency = localStorage.getItem("currency");
  const currencyObject = JSON.parse(Currency);
  // console.log(currencyObject);

  const showError = (err) => {
    // //////console.log("showeeror", err);
    toast.error(err, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [rules, setRules] = useState("");
  const [premiumUser, serPremiumUser] = useState(JSON.parse(localStorage.getItem("premiumUser")));
  useEffect(() => {
    const d = JSON.parse(localStorage.getItem("payload"));
    if (d) {
      setData(d);
      setRules(Parser(d?.rules));
    } else {
      window.location.href = "/create-scrim";
    }
  }, []);
  // console.log(data);
  let curr = data?.currency;
  let currCoin = 0;
  // console.log(curr);
  let wallet = JSON.parse(localStorage.getItem(constants.profileLite))?.item?.wallet;
  // console.log(wallet)
  for (let i = 0; i < wallet.length; i++) {
    if (wallet[i].currencyData?.code === currencyObject) {
      currCoin = wallet[i].winningAmount;
    }
  }
  return (
    <>
      <ToastContainer />
      <div className="inner-wrap" id="my_contest_main_inner">
        <div className="header">
          <div className="back-btn" onClick={() => navigate(-1)}>
            <a>
              <img src={Previous} alt="" />
            </a>
          </div>
          <h2> {t("contest_details.summary")}</h2>
          <div className="wallet-btn">
            {/* <Link to="/">
            <img src={Wallet} alt="" />
            Wallet
          </Link> */}
          </div>
        </div>
        <div className="body mCustomScrollbar _mCS_1 bottom-btn-body mycontest_main pt-0 flex gap flex-direction">
          <ScrimSummaryCard data={data} />

          <div className="scrim-summary-title">{t("private_contest.game_rules")}</div>
          <div className="scrim-summary-rulesBox br-20">
            <div className="scrim-summary-rules">
              {/* {GameRules.map((rules) => (
              <ul className="scrim-rules-list">
                <li>{rules}</li>
              </ul> 
              {}
            ))}*/}
              {rules}
            </div>
          </div>
          <div className="scrim-buttons">
            <div className={`flex gap scrim-balance-button ${premiumUser ? "coin_color" : ""}`}>
              <div className="scrim-title-btn-width ">{t('private_contest.curr_balance', { n: currencyObject?.charAt(0)?.toUpperCase() + currencyObject?.slice(1) })}</div>
              <div>
                {" "}
                <img src={url.imageUrl + data?.currencyImg} alt="" className="balance_img" />
                <span className="fw-8">{currCoin ? currCoin : 0}</span>
              </div>
            </div>
            <div className="flex gap scrim-balance-button coin-used-button  avalible_wrap">
              <div className="scrim-title-btn-width  ">
                {/* {currencyObject.charAt(0).toUpperCase() + currencyObject.slice(1)} Used */}
                {t("private_contest.your_fee")}
              </div>
              <div>
                {" "}
                {premiumUser ? <></> : <img src={url.imageUrl + data?.currencyImg} alt="" className="balance_img" />}
                <span className="fw-8">{premiumUser ? "Free" : data?.feeAmount}</span>
              </div>
            </div>
          </div>
          {premiumUser ? (
            <>
              <div className="ps_coin_saved">
                <p>
                  <b style={{ color: "#000" }}>
                    {" "}
                    {t("tournaments.saved", { coin: currencyObject.charAt(0).toUpperCase() + currencyObject.slice(1) })}{" "}
                    <img src={url.imageUrl + data?.currencyImg} alt="" style={{ marginTop: 0, height: "16px", width: "15px", marginRight: "8px" }} />
                    <span className="fw-8">{data?.feeAmount}</span>
                  </b>
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="ps_summary">
                {" "}
                <p>
                  <Trans
                    i18nKey="private_contest.subsc_premium"
                    t={t}
                    components={[<Link className="link" to={routehelp.premiumSubscription}> {t('private_contest.subsc_premium')} </Link>]}
                  />
                </p>{" "}
              </div>
            </>
          )}

          <ScrimSummaryButton data={data} coin={currCoin} showError={showError} />
        </div>
      </div>
    </>
  );
};
