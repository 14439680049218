import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form } from "react-bootstrap";
import Previous from "../assets/images/previous.svg";
import Access from "../assets/images/access.svg";
import {
  getCategoryLists,
  getCreateComplaint,
  getSearchGames,
  getTicketLists,
  getAllTicketChat,
  updateAllTicketChat,
  userContestList,
  getTimeSlots,
} from "../apis";
import Calendar from "../assets/images/calendar.svg";
import Download from "../assets/images/download.png";
import dateIcon from "../assets/images/date-icon.svg";
import UploadCard from "../assets/images/file-icon.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import Scheme from "../assets/images/scheme.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";

import Loader from "../component/loader";
import {
  getConvertionDateTime,
  getProfiledateFormat,
  getTournamentFullDateTime,
  formatAMPM,
  getHTMLdateFormat,
} from "../component/common";
import url from "../constants/url";
import routes from "../constants/routes";
import moment from "moment";

import Advertisement from "../component/advertisement";
import { CategoryPopup } from "../component/CategoryPopup";
import { SubCategoryPopup } from "../component/SubCategoryPopup";
import { GameCategoryPopup } from "../component/GameCategoryPopup";
import PremiumClose from "../assets/images/cancel_premium.png";
import PremiumLogo from "../assets/images/premium.png";
import TelePhone from "../assets/images/telephone-svgrepo-com.svg";

import Logo from "../assets/images/logo.svg";
import { routehelp } from "../config/routehelp";
import { toast } from "react-toastify";
import { async } from "q";
import { useTranslation, Trans } from "react-i18next";
import { constants } from "../constants/constants";

function CustomerCare(props) {
  const { t } = useTranslation()

  const [showTicket, setShowTicket] = useState(true);
  const [showComplaint, setShowComplaint] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [allData, setAllData] = useState({});
  const [ticketList, setTicketList] = useState([]);
  const [categoryList, setCategoryList] = useState(null);
  const [gamesList, setGamesList] = useState(null);
  const [gameTypesList, setGameTypesList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState(null);
  const [singleChatData, setSingleChatData] = useState(null);
  const [allConversations, setAllConversations] = useState(null);
  const [msgText, setMsgText] = useState("");
  const [files, setFiles] = useState(null);
  const [clickSubmit, setClickSubmit] = useState(false);
  const [showmModal, setShowModal] = useState(false);
  const [showmSubModal, setShowSubModal] = useState(false);
  const [showmGameModal, setShowGameModal] = useState(false);
  const [text, setShowText] = useState(t('crm.select_category'));
  const [subtext, setSubShowText] = useState(t('crm.select_sub_category'));
  const [gametext, setGameText] = useState(t('crm.select_game'));
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [premiumUser, setPremiumUser] = useState(
    JSON.parse(localStorage.getItem("premiumUser"))
  );
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [showTimSlotPopup, setShowTimeSlotPopup] = useState(false);
  const [showTimSlotConfirmPopup, setShowTimeSlotConfirmPopup] =
    useState(false);
  const [showPremiumPopup, setShowPremiumPopup] = useState(
    !JSON.parse(localStorage.getItem("premiumUser"))
  );
  const navigate = useNavigate();
  const [uploadLoader, setUploadLoader] = useState(
    t('private_contest.please_upload_screenshot_of_your_game')
  );
  const [loading, setLoading] = useState(false);
  const divRef = useRef("hy");
  const [divValue, setDivValue] = useState("");

  useEffect(() => {
    const value = divRef?.current?.innerText;
    if (value) setDivValue(value);
  }, [divRef?.current?.innerText]);

  useEffect(async () => {
    var loginData = JSON.parse(
      localStorage.getItem(constants.profileLite)
    )?.item;
    if (loginData) {
      setAllData({
        gamerjiName: loginData?.gamerjiName,
        email: loginData?.email,
        phone: loginData?.phone ? loginData?.phone : "",
        phoneCode: loginData?.phoneCode,
        description: "",
        category: "",
        subcategory: "",
        game: "",
        gametype: "",
        contestDate: "",
        contestTime: "",
      });

      if (loginData.phone) {
        setPhoneDisabled(true);
      }
    }
    setLoading(true);
    let res = await getTicketLists();
    if (res) {
      setTicketList(res?.list);
    }

    // let res1 = await getCategoryLists({ isParent: true });
    // let res1 = await getCategoryLists({
    //   search: {
    //     onlyActive: true,
    //     isParent: true,
    //   },
    //   pagination: {
    //     pageNo: 1,
    //     recordPerPage: 100,
    //   },
    // });

    // if (res1) {
    //   setCategoryList(res1?.data);
    // }
    setLoading(false);
  }, []);

  const getCategoryList = async () => {
    let res1 = await getCategoryLists({
      search: {
        onlyActive: true,
        isParent: true,
      },
      pagination: {
        pageNo: 1,
        recordPerPage: 100,
      },
    });

    if (res1) {
      setCategoryList(res1?.data);
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    setUploadLoader("Please wait, Uploading...");
    var formData = new FormData();
    formData.append("file", acceptedFiles[0], "[PROXY]");

    var requestOptions = {
      method: "POST",
      headers: { "x-access-token": localStorage.getItem("gamerjiToken") },
      body: formData,
      redirect: "follow",
    };

    fetch(`${url.apiUrl}${routes.uploadSS}`, requestOptions)
      .then((response) => response.text())
      .then(
        (result) => setFiles(JSON.parse(result)?.item?.screenShot),
        setUploadLoader(t('screenshot_uploaded_successfully'))
      )
      .catch((error) =>
        setUploadLoader(t('error_something_went_wrong_upload_again'))
      );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const onSubmit = async () => {
    if (allData?.gamerjiName === "") {
      setClickSubmit(true);
      return false;
    }
    if (allData?.email === "") {
      setClickSubmit(true);
      return false;
    }
    if (allData?.phoneCode === "") {
      setClickSubmit(true);
      return false;
    }
    if (allData?.description === "") {
      setClickSubmit(true);
      return false;
    }
    if (allData?.category === "") {
      setClickSubmit(true);
      return false;
    }
    if (allData?.subcategory === "") {
      setClickSubmit(true);
      return false;
    }
    if (gamesList !== null) {
      if (allData?.game === "") {
        setClickSubmit(true);
        return false;
      }
      if (allData?.gametype === "") {
        setClickSubmit(true);
        return false;
      }
    }
    let data = {
      name: allData?.gamerjiName,
      email: allData?.email,
      phone: allData?.phone,
      phoneCode: allData?.phoneCode,
      description: allData?.description,
      category: allData?.category,
      subcategory: allData?.subcategory,
      game: allData?.game,
      gametype: allData?.gametype,
      isSingle: allData?.isSingle,
      gametypeName: allData?.gametypeName,
      customerCareCategoryName: text,
      subCategoryName: subtext,
      // contestDate: allData?.contestDate,
      //contestTime: allData?.contestDate,
      screenShot: files,
    };
    // console.log(">>>>>>>>>>>data", data);
    let res1 = await getCreateComplaint(data);
    if (res1) {
      res1?.success === true && setShowComplaint(false);
      let res = await getTicketLists();
      if (res) {
        setTicketList(res?.list);
        // setShowGameModal(false)
        // setShowSubModal(false)
        // setGameTypesList([])
        // setShowModal(false)
        // setCategoryList(null)
        window.location.href = `${routehelp.customercare}`;
      }
    }
  };

  function containsOnlySpaces(str) {
    return /^\s*$/.test(str);
  }

  const onGetSendMsg = async () => {
    if (!containsOnlySpaces(msgText)) {
      let data = [...allConversations];
      data.push({ message: msgText, from: "appUser", dateAndTime: new Date() });
      let res1 = await updateAllTicketChat(singleChatData?._id, {
        conversations: data,
        status: 3,
      });
      if (res1) {
        let res = await getAllTicketChat(singleChatData?._id);
        setMsgText("");
        if (res) {
          setAllConversations(res?.item?.conversations);
        }
      }
    }
  };

  // const onChangeCat = async () => {
  //   setAllData({ ...allData, category: text });

  //   console.log({ ...allData, category: text });
  // };

  const change = (e) => {
    setShowModal((prev) => !prev);
  };
  const SubCategoryModal = (e) => {
    setShowSubModal((prev) => !prev);
  };
  const SubGameModal = (e) => {
    setShowGameModal((prev) => !prev);
  };

  const handleSelect = async (item) => {
    setShowText(item.label);
    setSubShowText(t('crm.select_sub_category'));
    setGameText(t('crm.select_game'));
    setSubCategoryList(null);
    setGamesList(null);
    setGameTypesList(null);

    setAllData({
      ...allData,
      category: item._id,
      subcategory: "",
      game: "",
      gametype: "",
      gametypeName: "",
    });

    // let res1 = await getCategoryLists({ parent: item._id });
    let res1 = await getCategoryLists({
      search: {
        onlyActive: true,
        parent: item._id,
      },
      pagination: {
        pageNo: 1,
        recordPerPage: 100,
      },
    });

    if (res1) {
      //console.log("sub cate", res1.list);
      setSubCategoryList(res1?.data);
    }
  };

  const handleSubCat = async (item) => {
    setSubShowText(item.label);
    setGameText(t('crm.select_game'));
    setGamesList(null);
    setGameTypesList(null);

    var temp = subCategoryList?.find((v) => v?._id === item._id);
    //console.log(temp);
    setAllData({
      ...allData,
      subcategory: item._id,
      game: "",
      gametype: "",
      gametypeName: "",
    });
    if (temp?.isContestDependency === true) {
      let res1 = await getSearchGames({});
      //console.log("getSearchGames: ", res1);
      if (res1 && res1?.list) {
        setGamesList(res1?.list);
      }
    }
  };

  const handleGame = async (item) => {
    setGameText(item.name);
    setGameTypesList(null);

    setAllData({ ...allData, game: item._id, gametype: "", gametypeName: "" });
    let res1 = await userContestList({
      game: item._id,
      user_id: JSON.parse(localStorage.getItem(constants.profileLite))?.item
        ?.user_id,
    });
    //console.log("getSearchGames: ", res1);

    if (res1) {
      setGameTypesList(res1?.list);
    }
  };

  const onChangeGameType = async (e, isSingle, gametypeName) => {
    //console.log(e.target.value);
    setAllData({
      ...allData,
      gametype: e.target.value,
      isSingle: isSingle,
      gametypeName: gametypeName,
    });
  };

  const getclickOnBox = async (item) => {
    setSingleChatData(item);
    let res1 = await getAllTicketChat(item?._id);
    if (res1) {
      setAllConversations(res1?.item?.conversations);
    }
    setShowTicket(false);
    setShowComplaint(false);
    setShowChat(true);
  };
  const onRequestCallBack = () => {
    if (allData?.gamerjiName === "") {
      setClickSubmit(true);
      return false;
    }
    if (allData?.email === "") {
      setClickSubmit(true);
      return false;
    }
    if (allData?.phoneCode === "") {
      setClickSubmit(true);
      return false;
    }
    if (allData?.description === "") {
      setClickSubmit(true);
      return false;
    }
    if (allData?.category === "") {
      setClickSubmit(true);
      return false;
    }
    if (allData?.subcategory === "") {
      setClickSubmit(true);
      return false;
    }
    if (gamesList !== null) {
      if (allData?.game === "") {
        setClickSubmit(true);
        return false;
      }
      if (allData?.gametype === "") {
        setClickSubmit(true);
        return false;
      }
    }
    fetchTimeSlot();
    setShowMobilePopup(true);
  };

  const [times, setTimes] = useState([]);
  const [dates, setDates] = useState([]);
  const [activeDate, setActiveDate] = useState(dates[0]);
  const [activeTime, setActiveTime] = useState(times[0]);
  const [index, setIndex] = useState(0);
  const onClickDate = (tab, ind) => {
    setActiveDate(tab);
    setActiveTime("");
    setIndex(ind);
    // setActiveTime(times[ind][0])
  };
  const nextHandler = async () => {
    if (activeTime) {
      //console.log(activeDate, activeTime);
      let payload = {
        name: allData?.gamerjiName,
        email: allData?.email,
        phone: allData?.phone,
        phoneCode: allData?.phoneCode,
        userPlatform: "webApp",
        userVersion: "2.2.2",
        isActive: true,
        timeSlotId: activeTime?._id,
        isCallRequest: true,
        isCallRequest: true,
        category: allData?.category,
        subcategory: allData?.subcategory,
        game: allData?.game,
        gametype: allData?.gametype,
        isSingle: allData?.isSingle,
        gametypeName: allData?.gametypeName,
        customerCareCategoryName: text,
        description: allData?.description,
        subCategoryName: subtext,
        screenShot: files,
        callTime: activeTime?.startTime + " - " + activeTime?.endTime,
        callDate: moment(activeDate).format("YYYY-MM-DD"),
      };
      //console.log(payload);
      const res = await getCreateComplaint(payload);
      if (res) {
        //console.log(res);
        setShowTimeSlotPopup(false);
        setShowTimeSlotConfirmPopup(true);
      }
    }
  };
  const fetchTimeSlot = async () => {
    try {
      const res = await getTimeSlots();
      // let date1 =getHTMLdateFormat(res?.data[0]?.param[0]?.date)
      // let date2 =getHTMLdateFormat(res?.data[0]?.param[1]?.date)
      let datess = [];
      let timess = [];

      for (let i = 0; i < res?.data[0]?.param.length; i++) {
        datess.push(res?.data[0]?.param[i]?.date);

        timess.push(res?.data[0]?.param[i]?.data);
      }
      setDates([...datess]);
      setTimes([...timess]);
      setActiveDate(datess[0]);
      // setActiveTime(timess[0][0])
      setIndex(0);
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]?.msg);
    }
  };
  // useEffect(() => {
  //   fetchTimeSlot();
  // }, []);

  return (
    <div className="inner-wrap">
      {showComplaint === true && (
        <>
          <div className="header">
            <div
              className="back-btn"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowComplaint(false);
                setSubCategoryList(null);
                setGamesList(null);
                setGameTypesList(null);
                setFiles(null);
                setUploadLoader("Please upload screenshot of your game");
                setClickSubmit(false);
                setShowTicket(true);

                let temp = { ...allData };
                temp.category = "";
                temp.subcategory = "";
                temp.game = "";
                temp.gametype = "";
                temp.contestDate = "";
                temp.contestTime = "";
                temp.description = "";
                setAllData(temp);
              }}
            >
              <img src={Previous} alt="" />
            </div>
            <h2>{t('crm.customer_care')}</h2>
            <div className="wallet-btn"></div>
          </div>
          <div className="body mCustomScrollbar _mCS_1">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="form-group">
                <label>{t('crm.gamerji_name')}</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setAllData({ ...allData, gamerjiName: e.target.value })
                  }
                  value={allData?.gamerjiName}
                />
                {clickSubmit === true && allData?.name === "" && (
                  <p className="error">{t('crm.please_enter_name')}</p>
                )}
              </div>

              {/* Mobile */}
              <div className="form-group" style={{ position: "relative" }}>
                <label>{t('crm.mobile_number')}</label>
                <div>
                  <label
                    className="crm-phoneNo"

                  >
                    {
                      JSON.parse(localStorage.getItem("userCountryObj"))
                        ?.dialingCode
                    }{" "}
                    <span style={{ marginBottom: "1px", marginLeft: "2px" }}>
                      {" "}
                      -{" "}
                    </span>
                  </label>

                  <input
                    type="number"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    className="form-control crm-no"

                    onChange={(e) =>
                      setAllData({ ...allData, phone: e.target.value })
                    }
                    disabled={phoneDisabled}
                    value={allData?.phone}
                  // placeholder={`${allData?.phone}`}
                  />
                  {clickSubmit === true && allData?.phone === "" && (
                    <p className="error">{t('crm.please_enter_phone')}</p>
                  )}
                </div>
              </div>

              {/* Email */}
              <div className="form-group">
                <label>{t('crm.email')}</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setAllData({ ...allData, email: e.target.value })
                  }
                  value={allData?.email}
                />
                {clickSubmit === true && allData?.email === "" && (
                  <p className="error">{t('crm.please_enter_email')}</p>
                )}
              </div>

              {/* Category */}

              <div className="mb-07">
                <label>{t('crm.category')}</label>

                {showmModal && (
                  <CategoryPopup
                    onSelectItem={handleSelect}
                    categoryList={categoryList}
                  />
                )}
                <div
                  className="category_popup  form-control"
                  onClick={change}
                  ref={divRef}
                >
                  {text}

                  <svg
                    className=""
                    width="25"
                    height="25"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id=""
                      d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z"
                      fill="#D3D3D3"
                    ></path>
                  </svg>
                </div>

                {clickSubmit === true && allData?.category === "" && (
                  <p className="error">{t('crm.please_select_category')}</p>
                )}
              </div>

              {/* Sub Category */}
              {subCategoryList !== null && (
                <div className="mb-07">
                  <label>{t('crm.sub_category')}</label>

                  {showmSubModal && (
                    <SubCategoryPopup
                      onSelectItem={handleSubCat}
                      categoryList={subCategoryList}
                    />
                  )}
                  <div
                    className="category_popup  form-control"
                    onClick={SubCategoryModal}
                    ref={divRef}
                  >
                    {subtext}

                    <svg
                      className=""
                      width="25"
                      height="25"
                      viewBox="0 0 45 45"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id=""
                        d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z"
                        fill="#D3D3D3"
                      ></path>
                    </svg>
                  </div>

                  {clickSubmit === true && allData?.subcategory === "" && (
                    <p className="error">{t('crm.please_select_sub_category')}</p>
                  )}
                </div>
              )}

              {/* Game */}
              {gamesList !== null && (
                <>
                  <div className="">
                    <label>{t('crm.games')}</label>

                    {showmGameModal && (
                      <GameCategoryPopup
                        onSelectItem={handleGame}
                        categoryList={gamesList}
                      />
                    )}
                    <div
                      className="category_popup  form-control"
                      onClick={SubGameModal}
                      ref={divRef}
                    >
                      {gametext}

                      <svg
                        className=""
                        width="25"
                        height="25"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          id=""
                          d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z"
                          fill="#D3D3D3"
                        ></path>
                      </svg>
                    </div>
                    {clickSubmit === true && allData?.game === "" && (
                      <p className="error">{t('crm.please_select_game')}</p>
                    )}
                  </div>

                  {/* Game Time */}
                  {gameTypesList !== null && (
                    <div className="form-group padding-top">
                      <label>{t('crm.select_match')}</label>
                      <Form
                        className="cc_rc_contest_list_form"
                      // value={allData?.gametype}
                      // placeholder="Select Category"
                      // onChange={(e) => onChangeGameType(e)}
                      >
                        {/* <option selected disabled value="">
                          Select Game Type
                        </option> */}
                        {gameTypesList.length > 0 ? (
                          gameTypesList?.map((item, index) => {
                            return (
                              <div
                                className="cc_rc_contest_list_col"
                                key={index}
                              >
                                {/* <input type="radio"
                                  className="cc_rc_contest_list_input"
                                  name={`
                                    ${item?.gameType?.name} - 
                                    ${moment(item.date).format('DD/MM/y')} - 
                                    ${moment(item.time).format('hh:mm')}
                                  `}
                                  value={item?._id}
                                /> */}
                                <Form.Check
                                  className="cc_rc_contest_list_input"
                                  name={`group${index}`}
                                  label={`
                                    ${item?.gameType?.name} - 
                                    ${moment(item.date).format("DD/MM/y")} - 
                                    ${moment(item.time).format("hh:mm a")}
                                    ${!item.isSingle ? " - Tournament" : ""}
                                  `}
                                  type="radio"
                                  value={item?._id}
                                  onChange={(e) =>
                                    onChangeGameType(
                                      e,
                                      item.isSingle,
                                      item.gameType.name
                                    )
                                  }
                                />
                              </div>
                            );
                          })
                        ) : (
                          <label>{t('crm.no_match_found')}!</label>
                        )}
                      </Form>
                      {clickSubmit === true && allData?.gametype === "" && (
                        <p className="error">{t('crm.please_select_match')}</p>
                      )}
                    </div>
                  )}
                </>
              )}

              {subCategoryList !== null && (
                <>
                  {/* <div className="form-group">
                    <label>Contest Date</label>
                    <div className="date-field">
                      <div className="icon">
                        <img src={dateIcon} alt="" />
                      </div>
                      <input
                        type="datetime-local"
                        className="form-control datepicker"
                        onChange={(e) =>
                          setAllData({ ...allData, contestDate: e.target.value })
                        }
                        value={allData?.contestDate}
                      />
                      {clickSubmit === true && allData?.contestDate === '' && <p className="error">Please select contest date-time</p>}
                    </div>
                  </div> */}
                  {/* <div className="form-group">
                <label>Contest Time</label>
                <div className="date-field">
                  <div className="icon">
                    <img src={dateIcon} alt="" />
                  </div>
                  <input
                    type="time"
                    className="form-control datepicker"
                    onChange={(e) => setAllData({ ...allData, contestTime: e.target.value })}
                    value={allData?.contestTime}
                  />
                </div>
              </div> */}
                </>
              )}

              {/* Description */}
              <div className="form-group">
                <label>{t('crm.describe_your_issue')}</label>
                <div>
                  <textarea
                    rows={5}
                    className="text_decribe"
                    placeholder={t('crm.describe_your_issue')}
                    value={allData?.description}
                    onChange={(e) =>
                      setAllData({ ...allData, description: e.target.value })
                    }
                  ></textarea>
                  {clickSubmit === true && allData?.description === "" && (
                    <p className="error">{t('crm.please_enter_description')}</p>
                  )}
                </div>
              </div>

              {/* Image Attachment */}
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="upload_ss bg-primary">
                  <div>
                    <div>
                      <img src={UploadCard} />
                    </div>
                  </div>
                  <p className="mb-0 mt-2">
                    {files ? (
                      <b>{t('crm.img_attach')}</b>
                    ) : (
                      <b>{t('crm.upload_screenshot')}</b>
                    )}
                  </p>
                  <small>{uploadLoader}</small>
                </div>
              </div>

              <p className="mt-2 mb-5">
                {t('crm.any_raise_service_request_will_resolved_within_72_hours')}
              </p>
              {premiumUser ? (
                <button
                  className={`btn main-btn filter_popup_toggle mt-3 premium_active_btn`}
                  onClick={() => onRequestCallBack()}
                  style={{ justifyContent: "center", marginBottom: "20px" }}
                >
                  {t('crm.request_a_callback')}{" "}
                </button>
              ) : (
                <></>
              )}

              <button
                className="btn btn-dark main-btn filter_popup_toggle mt-3"
                onClick={onSubmit}
              >
                {t('crm.submit')}{" "}
                <img src={RightArrow} alt="" className="mCS_img_loaded" />
              </button>
            </div>
            {
              !premiumUser &&
              <Advertisement
                screen={
                  localStorage.getItem("apk_screens_list") === null
                    ? { code: "no_results" }
                    : JSON.parse(localStorage.getItem("apk_screens_list"))
                      .raiseAComplaint
                }
                screen_div_class_name=".all_sub_screens_div_related_to_ads"
                screen_type="sub"
              />
            }

          </div>
        </>
      )}
      {showChat === true && (
        <>
          <div className="header">
            <div className="back-btn">
              <div
                className="back-btn"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowChat(false);
                  // setShowComplaint(false)
                  setShowTicket(true);
                }}
              >
                <img src={Previous} alt="" />
              </div>
            </div>
            <h2>#{singleChatData?.code}</h2>
            <div className="wallet-btn"></div>
          </div>
          <div className="body mCustomScrollbar _mCS_1">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="complaint_list">
                <div className="item shadow-black" style={{ cursor: "unset" }}>
                  {singleChatData?.callTime ? (
                    <div className="box_row">
                      <div
                        className="box bg-primary shadow-primary"
                        style={{ width: "23%" }}
                      >
                        <div className="icon">
                          <img src={Access} alt="" />
                        </div>
                        {t('crm.ticketno')}<br /> <b>#{singleChatData?.code}</b>
                      </div>
                      <div
                        className="box bg-primary shadow-primary"
                        style={{ width: "23%" }}
                      >
                        <div className="icon">
                          <img src={Calendar} alt="" />
                        </div>
                        {t('crm.date')} <br />{" "}
                        <b>{getProfiledateFormat(singleChatData?.createdAt)}</b>
                      </div>
                      <div
                        className="box bg-primary shadow-primary"
                        style={{ width: "23%" }}
                      >
                        <div className="icon">
                          <img src={TelePhone} alt="" />
                        </div>
                        {t('crm.call_back')} <br />{" "}
                        <b>{getProfiledateFormat(singleChatData?.createdAt)}</b>
                      </div>

                      <div
                        className="box bg-primary shadow-primary"
                        style={{ width: "23%" }}
                      >
                        <div className="icon">
                          <img src={Scheme} alt="" />
                        </div>
                        {t('crm.status')} <br />{" "}
                        <b>
                          {singleChatData?.status === "1"
                            ? t('crm.pending')
                            : singleChatData?.status === "2"
                              ? t('crm.replied')
                              : singleChatData?.status === "3"
                                ? t('crm.inprogress')
                                : singleChatData?.status === "4"
                                  ? t('crm.approved')
                                  : t('crm.approved')}
                        </b>
                      </div>
                    </div>
                  ) : (
                    <div className="box_row">
                      <div className="box bg-primary shadow-primary">
                        <div className="icon">
                          <img src={Access} alt="" />
                        </div>
                        {t('crm.ticketno')} <br /> <b>#{singleChatData?.code}</b>
                      </div>
                      <div className="box bg-primary shadow-primary">
                        <div className="icon">
                          <img src={Calendar} alt="" />
                        </div>
                        {t('crm.date')} <br />{" "}
                        <b>{getProfiledateFormat(singleChatData?.createdAt)}</b>
                      </div>

                      <div className="box bg-primary shadow-primary">
                        <div className="icon">
                          <img src={Scheme} alt="" />
                        </div>
                        {t('crm.status')} <br />{" "}
                        <b>
                          {singleChatData?.status === "1"
                            ? t('crm.pending')
                            : singleChatData?.status === "2"
                              ? t('crm.replied')
                              : singleChatData?.status === "3"
                                ? t('crm.inprogress')
                                : singleChatData?.status === "4"
                                  ? t('crm.approved')
                                  : t('crm.approved')}
                        </b>
                      </div>
                    </div>
                  )}

                  {/* {console.log("singleChatData", singleChatData)} */}
                  <div className="txt bg-dark shadow-secondary">
                    <h6>{t('crm.subject')}: {singleChatData?.category?.name}</h6>
                    <p>{singleChatData?.description}</p>
                  </div>
                  {singleChatData?.screenShot?.default && (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      <img
                        src={url.imageUrl + singleChatData?.screenShot?.default}
                        alt={t('crm.screenshot')}
                        style={{ width: "100px" }}
                      />
                    </div>
                  )}
                </div>
                {allConversations === null ? (
                  <Loader />
                ) : (
                  <div className="mt-auto">
                    {allConversations?.map((item, index) => {
                      return (
                        <div className="all_chats" key={index}>
                          {item?.from === "appUser" ? (
                            <div className="sent_chats">
                              <p>{item?.message}</p>
                              <br />
                              <br />
                              <h6>
                                {getConvertionDateTime(item?.dateAndTime)}
                              </h6>
                              {/* 16 Dec 2021 02:45 PM */}
                            </div>
                          ) : (
                            <div className="incoming_chats">
                              <p>{item?.message}</p>
                              <h6 style={{ fontSize: "12px" }}>
                                {getConvertionDateTime(item?.dateAndTime)}
                              </h6>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                {(singleChatData?.status === "1" ||
                  singleChatData?.status === "2" ||
                  singleChatData?.status === "3") && (
                    <div className="enter_chat">
                      <input
                        type="text"
                        placeholder={t('crm.type_message')}
                        value={msgText}
                        onChange={(e) => setMsgText(e.target.value)}
                      />
                      <div onClick={() => onGetSendMsg()}>
                        <img src={Download} />
                      </div>
                    </div>
                  )}
              </div>
            </div>
            {
              !premiumUser && <Advertisement
                screen={
                  localStorage.getItem("apk_screens_list") === null
                    ? { code: "no_results" }
                    : JSON.parse(localStorage.getItem("apk_screens_list"))
                      .customerCare
                }
                screen_div_class_name=".all_sub_screens_div_related_to_ads"
                screen_type="sub"
              />
            }

          </div>
        </>
      )}
      {showTicket === true && (
        <>
          <div className="header">
            <div className="back-btn">
              <Link to="/more">
                <img src={Previous} alt="" />
              </Link>
            </div>
            <h2>{t('crm.customer_care')}</h2>
            <div className="wallet-btn"></div>
          </div>
          <div className="body mCustomScrollbar _mCS_1">
            <div className="all_sub_screens_div_related_to_ads">
              <div
                className="complaint_title shadow-black"
                onClick={() => {
                  setShowChat(false);
                  setShowTicket(false);
                  setShowComplaint(true);
                  getCategoryList()
                }}
              >
                {t('crm.raise_a_complaint')}
              </div>
              {loading ? (
                <Loader />
              ) : ticketList?.length > 0 ? (
                ticketList?.map((item, index) => {
                  return item?.callTime ? (
                    <div className="complaint_list" key={index}>
                      <div
                        className="item shadow-black"
                        onClick={() => getclickOnBox(item)}
                      >
                        <div className="box_row">
                          <div
                            className="box bg-primary shadow-primary"
                            style={{ width: "23%" }}
                          >
                            <div className="icon">
                              <img src={Access} alt="" />
                            </div>
                            {t('crm.ticketno')} <br />{" "}
                            <b className="callback-text">#{item?.code}</b>
                          </div>
                          <div
                            className="box bg-primary shadow-primary"
                            style={{ width: "23%" }}
                          >
                            <div className="icon">
                              <img src={Calendar} alt="" />
                            </div>
                            {t('crm.date')} <br />{" "}
                            <b className="callback-text">
                              {getProfiledateFormat(item?.createdAt)}
                            </b>
                          </div>
                          <div
                            className="box bg-primary shadow-primary"
                            style={{ width: "23%" }}
                          >
                            <div className="icon">
                              <img src={TelePhone} alt="" />
                            </div>
                            {t('crm.call_back')} <br />{" "}
                            <b className="callback-text">{item?.callTime}</b>
                          </div>
                          <div
                            className="box bg-primary shadow-primary"
                            style={{ width: "23%" }}
                          >
                            <div className="icon">
                              <img src={Scheme} alt="" />
                            </div>
                            {t('crm.status')}  <br />{" "}
                            <b>
                              {item?.status === "1"
                                ? t('crm.pending')
                                : item?.status === "2"
                                  ? t('crm.replied')
                                  : item?.status === "3"
                                    ? t('crm.inprogress')
                                    : item?.status === "4"
                                      ? t('crm.approved')
                                      : t('crm.approved')}
                            </b>
                          </div>
                        </div>
                        <div className="txt bg-dark shadow-secondary">
                          <h6>{t('crm.subject')} : {item?.category?.name}</h6>
                          <p>{item?.description}</p>
                        </div>
                        {item?.screenShot?.default && (
                          <div
                            style={{
                              width: "100%",
                              textAlign: "center",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              src={url.imageUrl + item?.screenShot?.default}
                              alt={t('crm.screenshot')}
                              style={{ width: "100px" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="complaint_list" key={index}>
                      <div
                        className="item shadow-black"
                        onClick={() => getclickOnBox(item)}
                      >
                        <div className="box_row">
                          <div className="box bg-primary shadow-primary">
                            <div className="icon">
                              <img src={Access} alt="" />
                            </div>
                            {t('crm.ticketno')} <br /> <b>#{item?.code}</b>
                          </div>
                          <div className="box bg-primary shadow-primary">
                            <div className="icon">
                              <img src={Calendar} alt="" />
                            </div>
                            {t('crm.date')} <br />{" "}
                            <b>{getProfiledateFormat(item?.createdAt)}</b>
                          </div>

                          <div className="box bg-primary shadow-primary">
                            <div className="icon">
                              <img src={Scheme} alt="" />
                            </div>
                            {t('crm.status')} <br />{" "}
                            <b>
                              {item?.status === "1"
                                ? t('crm.pending')
                                : item?.status === "2"
                                  ? t('crm.replied')
                                  : item?.status === "3"
                                    ? t('crm.inprogress')
                                    : item?.status === "4"
                                      ? t('crm.approved')
                                      : t('crm.approved')}
                            </b>
                          </div>
                        </div>
                        <div className="txt bg-dark shadow-secondary">
                          <h6>{t('crm.subject')}: {item?.category?.name}</h6>
                          <p>{item?.description}</p>
                        </div>
                        {item?.screenShot?.default && (
                          <div
                            style={{
                              width: "100%",
                              textAlign: "center",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              src={url.imageUrl + item?.screenShot?.default}
                              alt={t('crm.screenshot')}
                              style={{ width: "100px" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>{t('crm.you_do_not_have_any_active_tickets')}</p>
              )}
            </div>
            {
              !premiumUser && <Advertisement
                screen={
                  localStorage.getItem("apk_screens_list") === null
                    ? { code: "no_results" }
                    : JSON.parse(localStorage.getItem("apk_screens_list"))
                      .customerCare
                }
                screen_div_class_name=".all_sub_screens_div_related_to_ads"
                screen_type="sub"
              />
            }

          </div>
        </>
      )}

      {showMobilePopup === true && (
        <div className="tc_popup_bg">
          <div
            className={`custom-popup  confirm custom_scroll join show premium_active rules`}
            id="contact_popup"
            style={{ minHeight: "200px" }}
          >
            <div className={`popup-head  premium_active`}>
              {/* <marquee> */}
              <h3>{t('crm.enter_mobile_number')}</h3>
              {/* </marquee> */}

              <button
                className="close-popup"
                onClick={() => setShowMobilePopup(false)}
              >
                <img src={PremiumClose} alt="" />
              </button>
            </div>

            <div className="ps_popup_main">
              <div className="ps_customer_input">
                <label>{t('crm.mobile_number')}</label>
                <div style={{ position: "relative" }}>
                  <label
                    className="crm-label"
                    style={{
                      position: "absolute",

                      // marginTop: 37,
                      marginLeft: 15,
                      color: "#70717A",
                      fontWeight: "bold",
                      // bottom: "16px",
                      top: "12%",
                    }}
                  >
                    {
                      JSON.parse(localStorage.getItem("userCountryObj"))
                        ?.dialingCode
                    }{" "}
                    <span
                      style={{ marginBottom: "1px", marginLeft: "2px" }}
                    ></span>
                  </label>
                  <input
                    type="number"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) =>
                      setAllData({ ...allData, phone: e.target.value })
                    }
                    value={allData?.phone}
                  />
                  {/* {console.log(clickSubmit)} */}
                </div>
                {allData?.phone?.length !== 10 ? (
                  <p className="error">{t('crm.please_enter_valid_phone_no')}</p>
                ) : (
                  ""
                )}
              </div>

              <div
                className="ps_popup_p"
                style={{ justifyContent: "flex-start", paddingLeft: "0" }}
              >
                <p style={{ opacity: "50%" }}>
                  {t('crm.you_will_reeceive_a_call_back_on_the_entered_number')}{" "}
                </p>
              </div>
            </div>

            <button
              className={`btn premium_active_btn`}
              onClick={() => {
                if (allData?.phone.length !== 10) {
                  // console.log("enter")
                  // setClickSubmit(true);
                  // return false;
                } else {
                  setShowMobilePopup(false);
                  setShowTimeSlotPopup(true);
                  setActiveTime(times?.[0]?.[0]);
                }
              }}
              style={{
                textAlign: "center",
                width: "80%",
                margin: "0 auto",
                marginBottom: "30px",
              }}
            >
              {t('crm.next')}
            </button>
          </div>
        </div>
      )}
      {showTimSlotPopup === true && (
        <div className="tc_popup_bg">
          <div
            className={`custom-popup  confirm  join show premium_active rules`}
            id="contact_popup"
          // style={{minHeight:'200px'}}
          >
            <div className={`popup-head  premium_active`}>
              {/* <marquee> */}
              <h3>{t('crm.select_time_slot')}</h3>
              {/* </marquee> */}

              <button
                className="close-popup"
                onClick={() => setShowTimeSlotPopup(false)}
              >
                <img src={PremiumClose} alt="" />
              </button>
            </div>

            <div className="ps_popup_main">
              <div
                className="ps_buttonGroup shadow"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  width: "50%",
                }}
              >
                {dates.map((item, ind) => (
                  <button
                    className={` ${activeDate === item ? "active" : ""}`}
                    key={ind}
                    onClick={() => {
                      onClickDate(item, ind);
                      setActiveTime(times?.[ind]?.[0]);
                    }}
                    style={{ width: "50%" }}
                  >
                    {/* {console.log("item", item)} */}
                    {moment(item).format("DD/MM/YYYY")}
                  </button>
                ))}
              </div>
              <div
                className="radio_wrap  scroll_bg"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "90px",
                  marginTop: "20px",
                  width: "50%",
                }}
              >
                <div>
                  {/* {console.log("times", times)} */}
                  {times[index].map((item, ind) => (
                    <label
                      onClick={() => setActiveTime(item)}
                      key={ind}
                      style={{ paddingBottom: "0" }}
                    >
                      <input
                        type="radio"
                        name="radio"
                        checked={activeTime === item}
                      />
                      <span
                        className={`${premiumUser ? "ps_radio" : ""} `}
                        style={{ border: premiumUser ? "1px solid #fff" : "" }}
                      ></span>
                      <p style={{ color: premiumUser ? "#fff" : "#000" }}>
                        {/* {getTournamentFullDateTime(item?.date, item?.time)} */}
                        {/* {moment(item?.startTime).format("hh:mm A")} -{" "}
                        {moment(item?.endTime).format("hh:mm A")} */}
                        {item?.startTime} - {item?.endTime}
                      </p>
                    </label>
                  ))}
                </div>
              </div>
              {!activeTime || activeTime === "" ? (
                <p style={{ color: "red" }}>{t('crm.please_select_time')}</p>
              ) : (
                ""
              )}

              <div className="ps_popup_p">
                <p style={{ opacity: "50%" }}>
                  {t('crm.you_will_receive_a_call_back_on')}{" "}
                  {
                    JSON.parse(localStorage.getItem("userCountryObj"))
                      ?.dialingCode
                  }
                  {"- "}
                  {allData?.phone}
                </p>
              </div>
            </div>

            <button
              className={`btn premium_active_btn`}
              onClick={nextHandler}
              style={{
                textAlign: "center",
                width: "80%",
                margin: "0 auto",
                marginBottom: "30px",
              }}
            >
              {t('crm.next')}
            </button>
          </div>
        </div>
      )}
      {showTimSlotConfirmPopup === true && (
        <div className="tc_popup_bg">
          <div
            className={`custom-popup  confirm custom_scroll join show premium_active rules`}
            id="contact_popup"
          >
            <div className={`popup-head  premium_active`}>
              {/* <marquee> */}
              <h3> </h3>
              {/* </marquee> */}

              <button
                className="close-popup"
                onClick={() => setShowTimeSlotConfirmPopup(false)}
              >
                {/* {console.log(premiumUser)} */}
                <img src={PremiumClose} alt="" />
              </button>
            </div>
            <div className="ps_header" style={{ height: "150px" }}>
              <div className="ps_logo">
                <img src={Logo} />
              </div>
              <div className="ps_name">{t('tournaments.premium')}</div>
            </div>
            <div className="ps_popup_main">
              <div className="ps_popup_p">
                <p style={{ color: "#B39559", fontSize: "16px" }}>
                  {t('crm.your_request_has_been_submitted_succesfully')}
                </p>
              </div>
              <div className="ps_popup_p layer2">
                <p
                //  style={{ marginBottom: "10px" }}
                >
                  {t('crm.you_will_receive_a_call_between')}
                </p>
                {/* {console.log("activeTime", activeTime)} */}
                <p>
                  {activeTime?.startTime} {"-"} {activeTime?.endTime}{" "}
                  {moment(activeDate).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>

            <button
              className={`btn premium_active_btn`}
              onClick={() => {
                setShowTimeSlotConfirmPopup(false);
                setShowComplaint(false);
                // navigate(routehelp.customercare)
                window.location.href = routehelp.customercare;
              }}
              style={{
                textAlign: "center",
                width: "80%",
                margin: "0 auto",
                marginBottom: "30px",
              }}
            >
              {t('crm.done')}
            </button>
          </div>
        </div>
      )}

      {showPremiumPopup === true && (
        <div className="tc_popup_bg">
          <div
            className={`custom-popup  confirm custom_scroll join show premium_active rules`}
            id="contact_popup"
          >
            <div className={`popup-head  premium_active`}>
              {/* <marquee> */}
              <h3> </h3>
              {/* </marquee> */}

              <button
                className="close-popup"
                onClick={() => setShowPremiumPopup(false)}
              >
                {/* {console.log(premiumUser)} */}
                <img src={PremiumClose} alt="" />
              </button>
            </div>
            <div className="ps_header" style={{ height: "150px" }}>
              <div className="ps_logo">
                <img src={Logo} />
              </div>
              <div className="ps_name">{t('tournaments.premium')}</div>
            </div>
            <div className="ps_popup_main">
              {/* <div className="ps_popup_p"><p>Is your request urgent?</p></div> */}
              <div className="ps_popup_p layer2">
                <p>{t('premium.is_your_reuqest_urgent')}</p>
                <p>{t('premium.premium_users_get_their_queries_resolved_one_of_our_dedicated_customer_care_response')}
                </p>
              </div>
              <div className="ps_popup_p">
                <p>{t('premium.premium_users_also_get_access_to_a_host_additional_benefits')}
                </p>
              </div>
            </div>

            <button
              className={`btn premium_active_btn`}
              onClick={() => navigate(routehelp.premiumSubscription)}
              style={{
                textAlign: "center",
                width: "80%",
                margin: "0 auto",
                marginBottom: "30px",
              }}
            >
              {t('Profile.explore_gamerji_premium')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomerCare;
