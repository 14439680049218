import React from 'react'
import Advertisement from '../../component/advertisement'
import { Link } from 'react-router-dom'
import Previous from "../../assets/images/previous.svg";

const DirectLinkPage = () => {
    const name = localStorage.getItem("Name")
    const link = localStorage.getItem("Link")
    // localStorage.removeItem("Link")
    // localStorage.removeItem("Name")

  return (
    <div className="fg_type_main_div">
   

    <div className="fg_type_header">
      <div className="fg_type_back_arrow">
        <Link to='/games-categories/freeGame001'>
          <img src={Previous} alt="" />
                  </Link>
      </div>
      <div className="fg_type_header_text">{name}</div>
      <div className=""></div>
    </div>

    <div className="fg_type_body" style={{padding:'0'}}>
      <div className="all_sub_screens_div_related_to_ads">
        <div className="fg_type_body_scrollable_component">
          <iframe 
          style={{width:'100%', height:'100%'}}
           src={link}
           ></iframe>
        </div>
      </div>
      {
         !JSON.parse(localStorage.getItem("premiumUser")) && 
      
      <Advertisement
          screen={
            localStorage.getItem('apk_screens_list') === null ?
              { code: 'no_results' }
              :
              JSON.parse(localStorage.getItem('apk_screens_list')).gameType
          }
          screen_div_class_name='.all_sub_screens_div_related_to_ads'
          screen_type='sub'
        />
}
    </div>
    
  </div>
  )
}

export default DirectLinkPage