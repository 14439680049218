import React, { Fragment, useEffect, useState } from "react";
import Previous from "../assets/images/previous.svg";
import Loader from "../component/loader";
import { Link } from "react-router-dom";
import {
  getGamesDetails1,
  getContestByGame,
  getRankSummeryByGame,
} from "../apis";
import url from "../constants/url";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Pie, Bar } from "react-chartjs-2";

import Advertisement from "../component/advertisement";
import { useTranslation, Trans } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const data = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export const options = {
  responsive: true,
  fill: true,
  scales: {
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      suggestedMax: 5,
    },
  },
  plugins: {
    legend: {
      position: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const dataBar = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      barThickness: 20,

      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: "rgba(255, 198, 9, 0.5)",
      borderColor: "rgb(255, 198, 9)",
    },
  ],
};

export const dataLine = {
  labels,
  datasets: [
    {
      label: "Dataset 1",

      data: [65, 59, 80, 81, 56, 55, 40],
      fill: true,
      backgroundColor: "rgba(255, 198, 9, 0.2)",
      borderColor: "rgba(255, 198, 9,1)",
    },
  ],
};

function ProfileInsights(props) {
  const [searchUserList, setSearchUserData] = useState(null);

  const [limitData, setlimitData] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const [gameData, setGameData] = useState(null);
  const [gameSettingData, setGameSettingData] = useState(null);
  const [contestByGameData, setContestByGame] = useState(null);
  const [contestRankData, setContestRankData] = useState(null);
  const [contestKillData, setContestKillData] = useState(null);
  const [contestCurrentFormData, setContestCurrentFormData] = useState([]);
  const [rankSummeryByGameData, setRankSummeryByGame] = useState(null);
  const [rankSummeryData, setRankSummeryData] = useState(null);
  const [gameID, setGameID] = useState("");
  const {t} = useTranslation()

  const getLoadMore = () => {
    setlimitData(limitData + 20);
  };

  useEffect(() => {
    var game_id =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    // console.log("game_id===>", game_id);
    setGameID(game_id);
    fetchGameData(game_id);
  }, []);

  const fetchGameData = async (game_id) => {
    if (game_id !== "") {
      let res = await getGamesDetails1(game_id);
      if (res) {
        // console.log("res===>", res);
        setGameData(res);
        // console.log("gameSettingData==>", res?.item?.settings);
        setGameSettingData(res?.item?.settings);

        fetchContestByGameData(game_id);

        fetchRankSummeryByGameData(game_id, res);
      }
    }
  };

  const fetchContestByGameData = async (game_id) => {
    if (game_id !== "") {
      let payload = {
        game: game_id,
        isShowWinLose: false,
      };
      let res = await getContestByGame(payload);
      if (res) {
        // console.log("getContestByGame===>", res);
        var labelsArr = [];
        var killsDataArr = [];
        var rankDataArr = [];
        var currentFormDataArr = [];

        res &&
          res.list.forEach((element) => {
            //console.log({ element });
            var jd = new Date(element.contest.date);
            var joinedDate =
              jd.getDate() + "/" + (jd.getMonth() + 1) + "/" + jd.getFullYear();
            var kills = element.kills || 0;
            var rank = element.rank || 0;
            labelsArr.push(joinedDate);
            killsDataArr.push(kills);
            rankDataArr.push(rank);
            currentFormDataArr.push({
              joinedDate: joinedDate,
              kills: kills,
              rank: rank,
              gameType: element.contest.gameType.name,
            });
          });
        // console.log(currentFormDataArr);
        setContestCurrentFormData(currentFormDataArr);
        let dataBar = {
          labels: labelsArr,
          datasets: [
            {
              label: "Kills Performance",
              barThickness: 20,
              data: killsDataArr,
              backgroundColor: "rgba(255, 198, 9, 0.5)",
              borderColor: "rgb(255, 198, 9)",
            },
          ],
        };
        // console.log("Kills dataBar==>", dataBar);
        setContestKillData(dataBar);
        let dataLine = {
          labels: labelsArr,
          datasets: [
            {
              label: "Rank Performance",
              data: rankDataArr,
              fill: true,
              backgroundColor: "rgba(255, 198, 9, 0.2)",
              borderColor: "rgba(255, 198, 9,1)",
            },
          ],
        };
        // console.log("Rank dataBar==>", dataLine);

        setContestRankData(dataLine);

        setContestByGame(res);
      }
    }
  };

  const fetchRankSummeryByGameData = async (game_id, gameObj) => {
    if (game_id !== "") {
      if (gameObj !== null) {
        // console.log("gameObj===>", gameObj);

        if (gameObj?.item?.settings?.isRankSummaryShow) {
          let payload = {
            game: game_id,
            isShowWinLose: false,
          };
          let res = await getRankSummeryByGame(payload);
          if (res) {
            // console.log("getRankSummeryByGame===>", res);
            var labelsArr = [];
            var dataArr = [];
            res &&
              res.list.forEach((element) => {
                // console.log({ element });
                labelsArr.push(element.name);
                dataArr.push(element.value);
              });
            let data = {
              labels: labelsArr,
              datasets: [
                {
                  label: "# of Votes",
                  data: dataArr,
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            };
            setRankSummeryData(data);
            setRankSummeryByGame(res);
          }
        }
        if (gameObj?.item?.settings?.isWinLoseShow) {
          let payload = {
            game: game_id,
            isShowWinLose: true,
          };
          let res = await getRankSummeryByGame(payload);
          if (res) {
            // console.log("getRankSummeryByGame===>", res);
            var labelsArr = ["Win", "Lose"];
            var totalWin = res.list.totalWin || 0;
            var totalLose = res.list.totalLose || 0;
            var dataArr = [totalWin, totalLose];
            // res && res.list.forEach((element) => {
            //   console.log({ element });
            //   labelsArr.push(element.name);
            //   dataArr.push(element.value);
            // });

            let data = {
              labels: labelsArr,
              datasets: [
                {
                  label: "# of Votes",
                  data: dataArr,
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            };
            setRankSummeryData(data);
            setRankSummeryByGame(res);
          }
        }
      }
    }
  };

  return (
    <div
      className="inner-wrap custom_scroll_leader"
      style={{ paddingTop: "1em" }}
    >
      <div className="profile_insights_main">
        <div className="header">
          <div className="back-btn">
            <Link to="/profile">
              <img src={Previous} alt="" />
            </Link>
          </div>
          <h2 style={{ textTransform: "capitalize" }}>
            {gameData?.item?.name} {t('Profile.stats')}
          </h2>
          <div className="wallet-btn"></div>
        </div>

        <div className="profile_insights_1">
          <div className="all_sub_screens_div_related_to_ads">
            {gameSettingData && gameSettingData?.isCurrentFormShow === true ? (
              <div className="profile-insights-current-form">
                <h6>{t('Profile.current_form')}</h6>

                <div className="row mx-3">
                  <table className="table table-bordered ">
                    <thead className="thead-current-form">
                      <tr>
                        <th>{t('Profile.date')}</th>
                        <th>{t('Profile.game_type')}</th>
                        <th>{t('Profile.rank')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contestCurrentFormData !== null
                        ? contestCurrentFormData?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item?.joinedDate}</td>
                                <td>{item?.gameType}</td>
                                <td>{item?.rank}</td>
                              </tr>
                            );
                          })
                        : contestCurrentFormData.length === 0 && (
                            <tr>
                              <td colSpan={3}>{t('notFound.no_records_found')}</td>
                            </tr>
                          )}
                      {contestCurrentFormData.length === 0 && (
                        <tr>
                          <td colSpan={3}>{t('notFound.no_records_found')}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              ""
            )}
            {gameSettingData &&
            gameSettingData?.isCurrentFormShowWithKill === true ? (
              <div className="profile-insights-current-form">
                <h6>{t('Profile.current_form')}</h6>

                <div className="row mx-3">
                  <table className="table table-bordered ">
                    <thead className="thead-current-form">
                      <tr>
                        <th>{t('Profile.date')}</th>
                        <th>{t('Profile.game_type')}</th>
                        <th>{t('Profile.rank')}</th>
                        <th>{t('Profile.kill')}</th>
                      </tr>
                    </thead>
                    <tbody>



                      {contestCurrentFormData.length > 0
                        ? contestCurrentFormData?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item?.joinedDate}</td>
                                <td>{item?.gameType}</td>
                            <td>{item?.rank === null || item?.rank === 0 ? '-' : item?.rank}</td>
                            <td>{item?.kills === null || item?.kills === 0 ? '-' : item?.kills}</td>
                              </tr>
                            );
                          })
                        : 
                        
                        (
                            <tr>
                              <td colSpan={4}>{t('notFound.no_records_found')}</td>
                            </tr>
                          )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              ""
            )}
            {gameSettingData &&
            gameSettingData?.isKillPerformanceShow === true ? (
              <div className="profile-insights-current-form">
                <h6 style={{ marginTop: "40px", marginBottom: "0px" }}>
                {t('Profile.kill_performance')}
                </h6>

                {contestKillData ? (
                  <div className="row profile-stats-box mx-3 p-3">
                    <div className="col-1 p-0">
                      {" "}
                      <div className="chart-left-text">{t('Profile.kill')}</div>
                    </div>
                    <div className="col-11 p-0">
                      <Bar options={options} data={contestKillData} />

                      <p className="chart-bottom-text">{t('Profile.last_14_matches')}</p>
                    </div>
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            ) : (
              ""
            )}
            {gameSettingData && gameSettingData?.isRankShow === true ? (
              <div className="profile-insights-current-form">
                <h6 style={{ marginTop: "40px", marginBottom: "0px" }}>
                {t('Profile.rank_performance')}
                </h6>

                {contestRankData ? (
                  <div className="row profile-stats-box mx-3 p-3">
                    <div className="col-1 p-0">
                      {" "}
                      <div className="chart-left-text">{t('Profile.rank')}</div>
                    </div>
                    <div className="col-11 p-0 ">
                      <Line options={options} data={contestRankData} />
                      <p className="chart-bottom-text">{t('Profile.last_14_matches')}</p>
                    </div>
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            ) : (
              ""
            )}
            {gameSettingData &&
            (gameSettingData?.isRankSummaryShow === true ||
              gameSettingData?.isWinLoseShow === true) ? (
              <div className="profile-insights-current-form">
                <h6 style={{ marginTop: "40px", marginBottom: "0px" }}>
                {t('Profile.rank_summary')}
                </h6>
                {rankSummeryData ? (
                  <div className="row" style={{ padding: "0 20px 20px 20px" }}>
                    <div className="col profile-stats-box mx-3 p-3">
                      <Pie data={rankSummeryData} />
                    </div>
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            ) : (
              ""
            )}
            <div className="profile-insights-current-form">
              <p>&nbsp;</p>
            </div>
          </div>
          {
             !JSON.parse(localStorage.getItem("premiumUser")) && 
          
          <Advertisement
            screen={
              localStorage.getItem("apk_screens_list") === null
                ? { code: "no_results" }
                : JSON.parse(localStorage.getItem("apk_screens_list"))
                    .profileInsights
            }
            screen_div_class_name=".all_sub_screens_div_related_to_ads"
            screen_type="sub"
          />
}
        </div>
      </div>
    </div>
  );
}

export default ProfileInsights;
