const colors = {
    //white: "#ffffff",
    //gray: "#D3D3D3",
   // orange: "#F05A30",
    
    yellow: '#FFC609',
    red: '#F92C2C',
    green: '#32CD32',
    black: '#070B28',
    grey: '#B4B6C4',
    silver: '#D3D3D3',
    white: '#FFFFFF',
    blue: '#1B5CA7',
    orange: '#F44336',
    sky_blue: '#43F2FF',
    light_green: '#09FFC4',
    light_silver: '#E8E9EB',
    border_color: '#707070',
    dark_green: '#228B22',
    light_orange: '#FF8C00',
    dark_red: '#FF0000',

    processing: '#FF9544',
    succeeded: '#36DE4C',
    failed: '#FF4646',

    // Common Colors
    color_input_text: '#70717A',
    color_input_text_border: '#D5D7E3',
}
export default colors;