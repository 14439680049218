import React, { Fragment } from "react";
import Previous from "../assets/images/previous.svg";
import nounInfo from "../assets/images/noun_Info.svg";
import MoneyIcon2 from "../assets/images/money-icon-2.svg";
import Padlock from "../assets/images/padlock.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import { Link } from "react-router-dom";

function CardDetails(props) {
	return (
		<Fragment>
			<div className="header">
				<div className="back-btn">
					<Link to='/'>
						<img src={Previous} alt="" />
					</Link>
				</div>
				<h2>Enter Card Details</h2>
				<div className="wallet-btn"></div>
			</div>
			<div className="body mCustomScrollbar _mCS_1">
				<div className="form-group">
					<label>Card Number</label>
					<input type="text" className="form-control" placeholder="1234 - 4567 - 7894 - 1234" required="" />
				</div>
				<div className="row">
					<div className="col-md-7 pr-0">
						<div className="form-group">
							<label>Expiry (MM/YY)</label>
							<input type="text" className="form-control" placeholder="10/2019" required="" />
						</div>
					</div>
					<div className="col-md-5">
						<div className="form-group cvv-icon">
							<label>CVV</label>
							<input type="text" className="form-control" placeholder="2578" required="" />
							<div className="icon">
								<img src={nounInfo} alt="" />
							</div>
						</div>
					</div>
				</div>
				<div className="cvv-saved">
					<p><i><img src={Padlock} alt="" /></i> Your CVV will not be saved</p>
				</div>
				<div className="current_balance_box shadow-secondary bg-secondary">
					<img src={MoneyIcon2} alt="" className="mCS_img_loaded" />
					Current Balance
					<b className="ml-auto">₹500</b>
				</div>
				<div className="search-bank">
					<div className="form-group">
						<input type="text" className="form-control" placeholder="Search by bank name" />
					</div>
				</div>
				<div className="bank-name-list">
					<ul>
						<li>
							<Link to='/'>
								Axis Bank
								<span className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
										<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
											<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor"></path>
										</g>
									</svg>
								</span>
							</Link>
						</li>
						<li>
							<Link to='/'>
								ICICI Bank
								<span className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
										<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
											<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor"></path>
										</g>
									</svg>
								</span>
							</Link>
						</li>
						<li>
							<Link to='/'>
								ICICI Bank
								<span className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
										<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
											<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor"></path>
										</g>
									</svg>
								</span>
							</Link>
						</li>
					</ul>
				</div>
			</div>
			<div className="bottom_btn">
				<button className="btn btn-dark main-btn">Save Card & Proceed<img src={RightArrow} alt="" className="mCS_img_loaded" /></button>
			</div>
		</Fragment>
	);
}

export default CardDetails;
