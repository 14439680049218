import React, { Fragment, useEffect, useState } from "react";
import Previous from "../assets/images/previous.svg";
import Loader from "../component/loader";
import { Link } from "react-router-dom";
import { getSearchUserList, getProfile, getCollegeList } from "../apis";
import url from "../constants/url";
import colors from "../constants/colors";

import SchoolIcon from "../assets/images/school.png";
import RankIcon from "../assets/images/rank-collegiate.png";
import TeamIcon from "../assets/images/members-collegiate.png";
import PointIcon from "../assets/images/point-collegiate.png";
import RightArrowIcon from "../assets/images/right_arrow.png";
import InfiniteScroll from "react-infinite-scroller";

import Advertisement from "../component/advertisement";
import { useTranslation, Trans } from "react-i18next";
import Cookies from 'js-cookie'
import { useLocation } from "react-router-dom";

function CollegiateDetails(props) {
  // console.log("props===>", props);
  const { state } = useLocation();
  const [searchUserList, setSearchUserData] = useState([]);
  const [collegeList, setCollegeData] = useState([]);

  // const [allData, setAllData] = useState("");
  const [limitData, setlimitData] = useState(0);
  const [limitCollege, setLimitCollege] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [preLastRank, setPreLastRank] = useState(0);
  const [preLastPOints, setPreLastPoints] = useState(0);
  const {t} = useTranslation()
  const currentLang =  Cookies.get('i18next') || 'EN'
  
  useEffect(() => {
    // fetchData();

    // console.log(state)
    if(state){
    fetchUserData(state._id);
    }
    //fetchLevelData();
  }, []);

  // const fetchData = async () => {
  //   let res = await getProfile();
  //   if (res) {
  //     setAllData(res);
  //     // fetchUserData(res?.college?._id);
  //     fetchCollegeData();
  //     // console.log("res===>", res);
  //   }
  // };

  const fetchUserData = async (college_id) => {
    setLoading(true);
    let data = {
      skip: searchUserList.length,
      limit: 5,
      type: "appUser",
      filter: { college: college_id },
    };
    let obj = {};
    if (searchUserList.length == 0) {
      obj = {
        college: college_id,
      };
    } else {
      obj = {
        college: college_id,
        preLastPoints: preLastPOints,
        preLastRank: preLastRank,
      };
    }
    let payload = {
      skip: searchUserList.length,
      limit: 10,
      filter: obj,
    };
    // if (rank)
    //     payload.filter.preLastRank = members[members.length - 1].rank
    // if (point)
    //     payload.filter.preLastPoints = members[members.length - 1].points
    // console.log("payload", payload);
    let res = await getSearchUserList(payload);
    if (res) {
      // console.log("RESPONSE::getSearchUserList>>>>>>>", res);
      if (res.list && res.list.length > 0) {
        var tempArr = res.list.map((item) => {
          return { ...item };
        });
        setSearchUserData([...searchUserList, ...tempArr]);

        setlimitData(tempArr?.length);
        setPreLastPoints(res.list[tempArr?.length - 1]?.points);
        setPreLastRank(res.list[tempArr?.length - 1]?.rank);
      }
      // setSearchUserData(res.list);
    }
    setLoading(false);
  };

  const fetchCollegeData = async () => {
    setLoading(true);
    let data = { skip: collegeList.length, limit: 10 };
    let res = await getCollegeList(data);
    if (res) {
      //setCollegeData(res.list);
      if (res.list) {
        var tempArr = res.list.map((item) => {
          return { ...item };
        });
        setCollegeData([...collegeList, ...tempArr]);

        setLimitCollege(tempArr?.length);
      }
      setLoading(false);
      // console.log("fetchCollegeData===>", res);
    }
  };

  const getLoadMore = () => {
    setlimitData(limitData + 20);
  };

  const onClickSearch = async () => {
    setLoading(true);
    let data = { skip: 0, limit: 20, type: "appUser", q: searchText };
    let res1 = await getSearchUserList(data);
    // console.log("res1==>", res1);
    if (res1) {
      setLoading(false);
      // setTodayData(res1);
      setSearchUserData(res1);
    }
  };

  return (
    <div
      className="inner-wrap custom_scroll_leader"
      style={{ paddingTop: "8px" }}
    >
      <div className="header bg-dark ">
        <div className="back-btn">
          <Link to="/profile">
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>{t('Profile.collegiate')}</h2>
        <div className="wallet-btn"></div>
      </div>

      <div
        className="body mCustomScrollbar _mCS_1 mt-5 "
        style={{ overflow: "hidden", height: "100vh", paddingTop: "20px" }}
      >
        <div className="all_sub_screens_div_related_to_ads">
          <div className="collegiate-detail-main-box">
            <div className="collegiate-detail-head-box">
              <h5 style={{ fontWeight: "600", marginTop: "10px" }}>
                {state?.name}
              </h5>
            </div>
            <div className="collegiate-detail-bottom-box row ">
              <div className="col-12 row p-0" style={{ marginTop: -30 }}>
                <div className="col-4 p-0">
                  {/* Search */}

                  <img style={{ height: 50, width: 50 }} src={RankIcon} />
                  <p
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      color: colors.black,
                      fontSize: "18px",
                      lineHeight: "20px",
                    }}
                  >
                    <b>{state?.rank}</b>
                  </p>

                  <p
                    style={{
                      color: colors.black,
                      fontSize: "14px",
                      lineHeight: "6px",
                    }}
                  >
                    {t('Profile.rank')}
                  </p>
                </div>
                <div className="col-4 p-0">
                  {/* Share */}

                  <img style={{ height: 50, width: 50 }} src={TeamIcon} />
                  <p
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      color: colors.black,
                      fontSize: "18px",
                      lineHeight: "20px",
                    }}
                  >
                    <b>{state?.members}</b>
                  </p>

                  <p
                    style={{
                      color: colors.black,
                      fontSize: "14px",
                      lineHeight: "6px",
                    }}
                  >
                    {t('Profile.members')}
                  </p>
                </div>
                <div className="col-4 p-0">
                  {/* Edit */}

                  <img style={{ height: 50, width: 50 }} src={PointIcon} />
                  <p
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      color: colors.black,
                      fontSize: "18px",
                      lineHeight: "20px",
                    }}
                  >
                    <b>{state?.points}</b>
                  </p>

                  <p
                    style={{
                      color: colors.black,
                      fontSize: "14px",
                      lineHeight: "6px",
                    }}
                  >
                    {t('Profile.points')}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="collegiate-detail-tabs"
            style={{ paddingBottom: "3rem" }}
          >
            <div
              className="list-group center college-tab"
              id="list-tab"
              role="tablist"
              style={{ margin: "0 0 20px 0", top: "0", width: "100%" }}
            >
              <a
                className="list-group-item list-group-item-action active"
                id="list-home-list"
                data-toggle="list"
                href="#list-1"
              >
                {t('Profile.members')}
              </a>
              <a
                className="list-group-item list-group-item-action"
                id="list-profile-list"
                data-toggle="list"
                href="#list-2"
                onClick={() => fetchCollegeData()}
              >
                {t('Profile.college')}
              </a>
            </div>

            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane active table-wrapper-scroll-y my-custom-scrollbar"
                id="list-1"
                style={{
                  maxHeight: "53vh",
                  minHeight: "53vh",
                  height: "53vh",
                  borderTop: "none",
                }}
              >
                <table className="table   ">
                  <thead className="thead-current-form">
                    <tr>
                      <th style={currentLang?.toLowerCase() == "ar" ? { border: 0, borderTopRightRadius: 20 } : { border: 0, borderTopLeftRadius: 20 }}>
                        {" "}
                        {t('Profile.rank')}
                      </th>
                      <th>{t('Profile.level')}</th>
                      <th>{t('Profile.username')}</th>
                      <th style={currentLang?.toLowerCase() == "ar" ? { border: 0, borderTopLeftRadius: 20 } : { border: 0, borderTopRightRadius: 20 }}>
                      {t('Profile.points')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body-border">
                    {/* {console.log(searchUserList)} */}
                    {searchUserList.length > 0 ? (
                      searchUserList?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.rank}</td>
                            <td>
                              <img
                                src={
                                  url.imageUrl +
                                  item?.level?.featuredImage?.default
                                }
                                style={{ width: "40px" }}
                              />
                            </td>
                            <td>
                              {item?.gamerjiName}
                              <br />
                              <small>
                                {" "}
                                XXXXX
                                {item?.phone &&
                                  item?.phone.substr(item?.phone.length - 5)}
                              </small>
                            </td>
                            <td>{item?.points}</td>
                          </tr>
                        );
                      })
                    ) : (
                      // <Loader />
                      // <div className="center" style={{ backgroundColor: "#ff0", width: "100%" }}>No Record Found</div>
                      <tr>
                        <td colSpan={4} className="center">
                          {t('notFound.no_records_found')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {/* {console.log("limit data", limitData)} */}
                  {loading === true ? (
                    <tr>
                      <td colSpan={4}>
                        {" "}
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    limitData >= 10 &&
                    preLastPOints > 0 && (
                      <tr>
                        <td colSpan={4}>
                          {/* {console.log("searchUserList", limitData)} */}
                          <button
                            className="load-more-btn "
                            onClick={() => fetchUserData(state?._id)}
                          >
                            {t('tournaments.load_more')}
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </table>
              </div>
              <div
                className="tab-pane table-wrapper-scroll-y my-custom-scrollbar "
                id="list-2"
                style={{
                  maxHeight: "53vh",
                  minHeight: "53vh",
                  height: "53vh",
                  borderTop: "none",
                }}
              >
                <table className="table">
                  <thead className="thead-current-form">
                    <tr>
                      <th className="college-radius-left">{t('Profile.rank')}</th>
                      <th>{t('Profile.college_name')}</th>
                      <th className="college-table">{t('Profile.points')}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body-border">
                    {/* {console.log(collegeList.length, "college")} */}
                    {collegeList.length > 0 ? (
                      collegeList?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.rank}</td>
                            <td>{item?.name}</td>
                            <td>{item?.points}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4} className="center">
                        {t('notFound.no_records_found')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {loading === true ? (
                    <tr>
                      <td colSpan={3}>
                        {" "}
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    limitCollege >= 10 && (
                      <tr>
                        <td colSpan={3}>
                          <button
                            className="load-more-btn"
                            onClick={() => fetchCollegeData()}
                          >
                            {t('tournaments.load_more')}
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
        {
           !JSON.parse(localStorage.getItem("premiumUser")) && 
        
        <Advertisement
          screen={
            localStorage.getItem("apk_screens_list") === null
              ? { code: "no_results" }
              : JSON.parse(localStorage.getItem("apk_screens_list"))
                  .collegeLeaguesDetails
          }
          screen_div_class_name=".all_sub_screens_div_related_to_ads"
          screen_type="sub"
        />
}
      </div>
    </div>
  );
}

export default CollegiateDetails;
