import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Previous from "../../assets/images/previous.svg";
import ScrimLeaderBoard from "./ScrimLeaderBoard";
import ScrimDetails from "./ScrimDetails";
import { ManageScrimRules } from "./ManageSrimRules";
import { viewScrimById } from "../../apis";
import { useLocation, useNavigate } from "react-router";
import { goback } from "../common";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

const ScrimMain = () => {
  // const [loading, setLoading] = useState(false);
  // const [alltabData, setAlltabData] = useState(null);
  const navigate = useNavigate();
  const {t} = useTranslation()
  const [activeTab, setActiveTab] = useState("Details");
  // if(!scrimId)
  //   scrimId =
  //         window.location.href.split("/")[
  //         window.location.href.split("/").length - 1
  //         ];
  useEffect(()=>{

  },[activeTab])
 
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="inner-wrap" id="my_contest_main_inner">
      <div className="header">
        <div className="back-btn" onClick={() => navigate('/')}>
          <a>
            <img src={Previous} alt="" />
          </a>
        </div>
        <h2> {t('private_contest.manage_your_contest')} </h2>
        <div className="wallet-btn">
          {/* <Link to="/">
    <img src={Wallet} alt="" />
    Wallet
  </Link> */}
        </div>
      </div>

      <div className="body mCustomScrollbar _mCS_1 bottom-btn-body mycontest_main pt-0">
        <div className="all_sub_screens_div_related_to_ads">
          <div
            className="header_main_leader scrim-leaderboard"
            style={{ marginTop: "20 px", height: "50px" }}
          >
            <div
              className="list-group main-tab leader-board scrim-leaderboard"
              style={{ width: "100%", margin: "0 auto", marginTop: "20px" }}
              id="list-tab"
              role="tablist"
            >
              <a
                className={`list-group-item list-group-item-action  ${
                  activeTab === "Details" ? "active" : ""
                }`}
                id="list-profile-list"
                data-toggle="list"
                // href="#list-1"
                onClick={() => handleTabClick("Details")}
              >
                {t('crm.details')}
              </a>
              <a
                className={`list-group-item list-group-item-action ${
                  activeTab === "LeaderBoard" ? "active" : ""
                }`}
                id="list-profile-list"
                data-toggle="list"
                href="#list-2"
                onClick={() => handleTabClick("LeaderBoard")}
              >
                {t('sidebar.leaderboard')}
              </a>
              <a
                className={`list-group-item list-group-item-action ${
                  activeTab === "Rules" ? "active" : ""
                }`}
                id="list-profile-list"
                data-toggle="list"
                href="#list-3"
                  onClick={() => handleTabClick("Rules")}
              >
                {t('contest_details.rules')}
              </a>
            </div>

            {/* // Yellow  */}






            <ToastContainer />
            <div className="tab-content " id="nav-tabContent">
        
            <div>
              {activeTab === "Details" && (
               <ScrimDetails handleTabClick = {handleTabClick} />
              )}
              {activeTab === "LeaderBoard" && (
              <ScrimLeaderBoard />
              )}
             {activeTab === "Rules" && (
               <ManageScrimRules  />
              )}
            </div>
            </div>

     
          </div>
          {/* <ScrimlistCard/>
      <ScrimButton/> */}
        </div>
      </div>
    </div>
  );
};

export default ScrimMain;
