import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { constants } from "../../constants/constants";

export const ScrimlistCard = ({data}) => {
  const {t} = useTranslation()
  // console.log(data)
  return (
    <>
    {
      data.map((d)=>(
<div className="contest-list contest-tournament-list game_tounament mb-3">
      <p className="status_contest bg-primary">{d.status}</p>
      <div className="box" style={{ cursor: "pointer" }}>
        <h3 style={{ fontSize: "16px", fontWeight: "800",marginTop:"4px" }}>
          {d.title.length > 30 ?  <marquee> {d.title}</marquee>:<>{d.title}</>}
          {/* <span className="pointer">ID: {contest?.shortCode}</span> */}
        </h3>
        <div
          className="row tournament_contest_row"
          style={{ marginBottom: "-20px" }}
        >
          <div className="col-12">
            <div className="inner_row">
              <div className="inner_item">
              {t('contest_details.date')} <b>{moment(d.date).format('DD/MM/YYYY')}</b>
              </div>
              <div className="inner_item">
                {/*  let date = moment(d).format('DD/MM/YYYY')
      let time = moment(d).format('HH:MM') */}
                {t('contest_details.time')} <b>{moment(d.time).format('hh:mm A')}</b>
              </div>
            {d?.titles?.[0] ?  <div className="inner_item">
            { d?.titles?.[0]?.name } <b>{d?.titles?.[0]?.value}</b>
                </div> : ""}
                {d?.titles?.[1] ?  <div className="inner_item">
                { d?.titles?.[1]?.name } <b>{d?.titles?.[1]?.value}</b>
                </div> : ""}
   
            </div>
            <div className="inner_row bg-dark">
            <div className="inner_item inner_item_medium">
            {t('contest_details.players')} <br />{" "}
                <b>
                  {d.noOfPlayerCapacity}
                </b>
              </div>
              <div className="inner_item inner_item_medium">
              {t('tournaments.winners')} <br />{" "}
                <b>
                  {d.noOfWinner}
                </b>
              </div>
              <div className="inner_item inner_item_medium">
              {t('contest_details.id')} <br /> <b>{d.shortCode}</b>
              </div>
            </div>

            <div className="avalible_wrap">
              <div className="inner">
                {/* var per =
                        (100 * item?.totalJoinedPlayers) / item?.totalPlayers +
                        "%*/}
                        
                <span
                  style={{
                    width:`${100* d?.noOfJoinedPlayer/d?.noOfPlayerCapacity}%`,
                  }}
                ></span>
              </div>
              <p className="d-flex justify-content-between text-light">
                <span className="">
                {t('contest_details.players_remaining', {players: d?.noOfPlayerCapacity - d?.noOfJoinedPlayer})}
                </span>
                <span className="">{t('contest_details.players_joined', {players: d?.noOfJoinedPlayer})}</span>
              </p>
            </div>
            <div className="contest_foot contest_details_foot bg-secondary text-light  scrim-card-padding-top scrim-card-padding-bottom scrimcard">
              <div className="inner inner-text " style={{width:'60px'}}>
                <span
                  style={{ textAlign: "center" }}
                  className="scrim-card-padding-bottom"
                >
                  {t('private_contest.hosted_by')} - {d.hostedByName}
                </span>
                <b
                  style={{
                    fontSize: "12px",
                    borderTop: "1px solid white",
                    textAlign: "center",
                  }}
                  className="scrim-card-padding-top"
                >
                  {d.hostedBy === JSON.parse(localStorage.getItem(constants.profileLite))?.item?.user_id ?(<Link to={'/manage-scrim/'+d._id} state={{id:d._id}}>{t('private_contest.manage')}</Link>) : ""}
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      ))
    }
    
    
  </>
  );
};
