import React, { Fragment, useEffect, useState } from "react";
import { getUserProfile } from "../apis";
import url from "../constants/url";
import Dollar from "../assets/images/upload-card.svg";
import Coin from "../assets/images/coin.png";
import Rupee from "../assets/images/rupee.svg";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Close from "../assets/images/close.svg";
import Diamond from "../assets/images/BLUE_Diamond.png";

import Previous from "../assets/images/previous.svg";
import Currency from "../assets/images/ic_currency.png";
import TotalCoinIcon from "../assets/images/total-coin-icon.png";
import DepositCash from "../assets/images/ic_deposit_cash.png";
import MobileEmail from "../assets/images/ic_mobile_email.png";
import BonusCash from "../assets/images/ic_bonus_cash.png";
import WinningCash from "../assets/images/ic_winning_cash.png";
import RewardsNewIcon from "../assets/images/rewards-new-icon.png";
import myRecentTransactionImage from "../assets/images/ic_transactions.png";
import VerifiedImageWhite from "../assets/images/ic_phone_verified_White.png";
import { routehelp } from "../config/routehelp";

import Loader from "../component/loader";
import Advertisement from "../component/advertisement";
import { ToastContainer, toast } from "react-toastify";

import { kFormatter } from "../component/common";
import { useTranslation } from "react-i18next";
import { constants } from "../constants/constants";

function Account(props) {
  const [profileDetails, setProfileDetails] = useState("");
  const {t} = useTranslation()

  const [allData, setAllData] = useState("");
  const [infoTitle, setInfoTitle] = useState("");
  const [infoDesc, setInfoDesc] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setProfileDetails(JSON.parse(localStorage.getItem(constants.profileLite)));

    localStorage.setItem("platform", "WebApp");
    fetchData();
  }, []);

  const getTotalCoin = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "coin");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };
  const getTotalDiamond = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "diamond");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  const getDepositeCash = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "INR" || obj.currencyData?.code == "inr");

    if (index > -1) {
      return wallet[index].depositedAmount ? wallet[index].depositedAmount : 0;
    }
    return 0;
  };

  const getWinningCash = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "INR" || obj.currencyData?.code == "inr");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
    // return parseFloat(accountResponse.wallet && accountResponse.wallet.winningAmount)
  };

  const getBonusCash = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "INR" || obj.currencyData?.code == "inr");

    if (index > -1) {
      return wallet[index].bonusAmount ? wallet[index].bonusAmount : 0;
    }
    return 0;
    // return parseFloat(accountResponse.wallet && accountResponse.wallet.bonusAmount)
  };

  const getTotalCash = (wallet) => {
    //console.log(wallet)
    if (wallet)
      return (
        getDepositeCash(wallet) + getWinningCash(wallet) + getBonusCash(wallet)
      );
    else return 0;
  };
  const showError = (error) => {
    toast.error(error, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const fetchData = async () => {
    setLoading(true);

    let res = await getUserProfile();
    if (res) {
      setAllData(res);
      //console.log("res=>", res);
      // localStorage.setItem('currentCoin', res?.item?.wallet?.coinAmount);
      localStorage.setItem("currentCoin", getTotalCoin(res?.item?.wallet));
      localStorage.setItem(
        "currentDiamond",
        getTotalDiamond(res?.item?.wallet)
      );
      localStorage.setItem("totalDeposite", getDepositeCash(res?.item?.wallet));
      localStorage.setItem("totalWinning", getWinningCash(res?.item?.wallet));
      localStorage.setItem("totalBonus", getBonusCash(res?.item?.wallet));
      localStorage.setItem("totalCash", getTotalCash(res?.item?.wallet));
      // getDepositeCash(res?.item?.wallet)
      // getWinningCash(res?.item?.wallet)
      // getBonusCash(res?.item?.wallet)
      setLoading(false);
    }
  };

  const onClickInfo = (title, desc) => {
    setShowInfo(true);
    setInfoTitle(title);
    setInfoDesc(desc);
  };

  const accountLinkClick = (value) => {
    if (value === routehelp.withdrawalWeb) {
      if (!allData.item.isEmailVerified) {
        showError(t('error_please_verify_your_email_to_withdraw_your_winnings'));
        return;
      }
    }
    window.location.href = value;
  };
  return (
    <div className="inner-wrap" id="account_main">
      <ToastContainer />

      <div
        className="body mCustomScrollbar _mCS_1"
        id={showInfo === true ? "popup_show" : ""}
        style={{ padding: "0 0 25px 0", borderRadius: "0px" }}
      >
        <div className="all_main_screens_div_related_to_ads">
          {loading ? <Loader /> : ""}
          <div className="row m-0 w-100 bg-dark pt-3 px-4 pb-0 justify-content-between align-items-center">
            <div className="back-btn">
              <Link to="/coin-store">
                <img src={Previous} alt="" />
              </Link>
            </div>
            <div
              className="text-white"
              style={{ fontSize: "26px", fontWeight: "1000" }}
            >
              Account
            </div>
            <div style={{ width: "35px" }}></div>
          </div>
          <div className="account_first row">
            <div className="col-4 avatar_img">
              <img
                src={url.imageUrl + allData?.item?.avatar?.img?.default}
                style={{ width: "145px", maxWidth: "145px", minWidth: "0px" }}
              />
            </div>

            <div className="col-8 avatar_detail d-flex flex-column justify-content-center">
              <h4 style={{ fontWeight: "600" }}>
                {allData?.item?.gamerjiName}
              </h4>

              {/* <Link to='/coin-store' className="text-dark coin_hover">
                <Button className="default-btn btn-block text-dark">
                Coin Store
                </Button>
              </Link> */}

              <div
                className="coin_cash d-flex mt-4 pb-2"
                style={{ borderBottom: "1px solid #fff" }}
              >
                <div className="coin_cash_img">
                  <img src={Currency} />
                </div>
                <div className="coin_total">
                  <p>Cash</p>
                  <span>Balance</span>
                </div>
                <div className="coin_val d-flex align-items-center">
                  ₹ {" "}
                  {String(localStorage.getItem("totalCash"))?.length > 4 ? (
                    <marquee>{localStorage.getItem("totalCash")}</marquee>
                  ) : (
                    localStorage.getItem("totalCash")
                  )}
                </div>
              </div>

              <div className="coin_cash d-flex pt-2">
                <div className="coin_cash_img">
                  <img src={TotalCoinIcon} />
                </div>
                <div className="coin_total">
                  <p>Coin</p>
                  <span>Balance</span>
                </div>
                <div className="coin_val d-flex align-items-center">
                  <img
                    src={Coin}
                    className="mr-2"
                    style={{ width: "30px", height: "30px" }}
                  />{" "}
                  {String(localStorage.getItem("currentCoin"))?.length > 4 ? (
                    <marquee>{localStorage.getItem("currentCoin")}</marquee>
                  ) : (
                    localStorage.getItem("currentCoin")
                  )}
                  {/* {kFormatter(allData?.item?.wallet?.coinAmount)} */}
                </div>
              </div>
              <div
                className="coin_cash d-flex mt-4 pb-2"
                style={{ borderTop: "1px solid #fff" }}
              >
                <div className="coin_cash_img">
                  <img src={TotalCoinIcon} />
                </div>
                <div className="coin_total">
                  <p>Diamond</p>
                  <span>Balance</span>
                </div>
                <div className="coin_val d-flex align-items-center">
                  <img
                    src={Diamond}
                    className="mr-2"
                    style={{ width: "30px", height: "30px" }}
                  />
                  {/* {localStorage.getItem("currentDiamond")} */}
                  {String(localStorage.getItem("currentDiamond"))?.length >
                  4 ? (
                    <marquee>{localStorage.getItem("currentDiamond")}</marquee>
                  ) : (
                    localStorage.getItem("currentDiamond")
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="account_second">
            <div className="row cash_box mt-1">
              <div className="col-4 text-center">
                <div className="cash_icon_circle">
                  <img src={DepositCash} />
                </div>
                <p className="mt-3 mb-0">Deposit Cash</p>
                <b>₹{localStorage.getItem("totalDeposite")}</b>
                <i
                  className="fa fa-info"
                  onClick={() =>
                    onClickInfo(
                      "Deposit",
                      "Money deposited by you that you can only be used to buy a coins but cannot be withdrawn."
                    )
                  }
                ></i>
              </div>
              <div className="col-4 brdr-rl text-center">
                <div className="cash_icon_circle">
                  <img src={WinningCash} />
                </div>
                <p className="mt-3 mb-0">Winning Cash</p>
                <b>₹{localStorage.getItem("totalWinning")}</b>
                <i
                  className="fa fa-info"
                  onClick={() =>
                    onClickInfo(
                      "Winning",
                      "Money that you have won. This can be withdrawn."
                    )
                  }
                ></i>
              </div>
              <div className="col-4 text-center">
                <div className="cash_icon_circle">
                  <img src={BonusCash} />
                </div>
                <p className="mt-3 mb-0">Bonus Cash</p>
                <b>₹{localStorage.getItem("totalBonus")}</b>
                <i
                  className="fa fa-info"
                  onClick={() =>
                    onClickInfo(
                      "Bonus",
                      "Usage of bonus is different for every match as set by Gamerji."
                    )
                  }
                ></i>
              </div>
            </div>

            <div className="withdrawal_btn">
              <Button
                className="default-btn btn-block "
                type="submit"
                onClick={() => accountLinkClick(routehelp.withdrawalWeb)}
              >
                Withdrawal
              </Button>
            </div>

            <div className="row reward mt-1">
              {/*   <div className="col-6 px-1 py-1 text-center" onClick={() => accountLinkClick(routehelp.verifyMobileEmail)}>
                <div className="bg-dark">
                  <div className="cash_icon_circle">
                    <img src={MobileEmail} />
                  </div>
                  <p className="" style={{ fontWeight: '600', }}>
                    {
                      profileDetails?.item?.isEmailVerified &&
                      profileDetails?.item?.isMobileVerified &&
                      <img className="mr-2" style={{ width: '15px', }} src={VerifiedImageWhite} />
                    }
                    Mobile & Email
                  </p>
                </div>
              </div> */}

              {/* <div className="col-6 px-1 py-1 text-center" onClick={() => accountLinkClick(routehelp.coinstore)}>
                <div className="bg-dark">
                  <div className="cash_icon_circle">
                    <img src={RewardsNewIcon} />
                  </div>
                  <p className="" style={{ fontWeight: '600', }}>Rewards</p>
                </div>
              </div> */}

              {/* <div className="col-6 px-1 py-1 text-center" onClick={() => accountLinkClick(routehelp.withdrawalWeb)}>
                <div className="bg-dark">
                  <div className="cash_icon_circle">
                    <img src={RewardsNewIcon} />
                  </div>
                  <p className="" style={{ fontWeight: '600', }}>Withdrawal</p>
                </div>
              </div> */}
              {/* <div className="col-4 px-1 py-1 text-center">
            <div className="bg-dark">
              <div className="cash_icon_circle">
                <img src={Rupee} />
              </div>
              <p className="">Withdrawal</p>
              </div>
            </div> */}

              <div
                className="col-12 px-1 py-1 text-center mt-4"
                onClick={() => {
                  localStorage.setItem("myRecentTransactions", "rmg");
                  accountLinkClick(routehelp.myRecentTransactions);
                }}
              >
                <div
                  className="d-flex w-100 justify-content-between align-items-center bg-primary"
                  style={{
                    padding: "10px 20px",
                    borderRadius: "50px",
                    cursor: "pointer",
                  }}
                >
                  <img src={myRecentTransactionImage} />
                  <p
                    className="m-0"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    My Recent Transaction
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.334"
                    height="18.014"
                    viewBox="0 0 21.334 18.014"
                  >
                    <g
                      id="ico"
                      transform="translate(21.334 18.013) rotate(-180)"
                    >
                      <path
                        id="union"
                        d="M8.321,17.721.479,9.879A1,1,0,0,1,.125,8.541,1.005,1.005,0,0,1,.411,8.2L8.321.293A1,1,0,0,1,9.735,1.706L3.417,8.025H20.334a1,1,0,1,1,0,2H3.454l6.281,6.281a1,1,0,0,1-1.414,1.415Z"
                        transform="translate(0 0)"
                        fill="#000"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            {/* <div className="row" style={{ textAlign: "center", margin: "5px 20px", padding: '5px 25px' }}>
              <p className="error">
                <b>Note: </b> For cash withdrawals & a better experience, download our app.
              </p>
            </div> */}
          </div>

          {showInfo === true && (
            <div
              className="custom-popup rules info_acc custom_scroll show"
              id="contact_popup"
            >
              <div className="popup-head">
                <h3>{infoTitle}</h3>
                <button
                  className="close-popup"
                  onClick={() => setShowInfo(false)}
                >
                  <img src={Close} alt="" />
                </button>
              </div>
              <div className="popup-body bg-yellow" style={{ padding: "10px" }}>
                <div className="all_sub_screens_div_related_to_ads">
                  <p
                    className="mb-3 mx-2 my-2"
                    style={{ paddingBottom: "70px" }}
                  >
                    {infoDesc}
                  </p>
                </div>
                {
                  !JSON.parse(localStorage.getItem("premiumUser")) && 
                
                <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                          .infoPopup
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
}
              </div>
            </div>
          )}
        </div>
        {
          !JSON.parse(localStorage.getItem("premiumUser")) && 
        
        <Advertisement
          screen={
            localStorage.getItem("apk_screens_list") === null
              ? { code: "no_results" }
              : JSON.parse(localStorage.getItem("apk_screens_list")).account
          }
          screen_div_class_name=".all_sub_screens_div_related_to_ads"
          screen_type="sub"
        />
}
      </div>
    </div>
  );
}

export default Account;
