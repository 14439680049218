import React, { Fragment } from "react";
function NotFound(props) {
  return (
    <Fragment>
      <h2>Not Found</h2>
    </Fragment>
  );
}

export default NotFound;
