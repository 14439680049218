import React, { useEffect, useRef, useState } from 'react'
import Loader from '../component/loader';
// import { Fragment } from 'react';
// import Diamond from '../assets/images/Diamond.png'
// import Bijus from '../assets/images/bijus.png'
import { getAds } from '../apis';
import url from '../constants/url';
import { useNavigate } from 'react-router';
import { useTranslation, Trans } from "react-i18next";
import { constants } from '../constants/constants';

const EarnCoins = () => {
    const [clickedArrow, setClickedArrow] = useState(false)
    const [index, setIndex] = useState(null)
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [loader, setLoader] = useState(false)
    const { t } = useTranslation()
    const prevIndex = useRef()
    const clickArrowButton = (i) => {
        setIndex(i)
        // console.log(i)

        setClickedArrow(!clickedArrow)

    }

    useEffect(() => {
        let arr = [...data]
        // console.log(prevIndex.current, index)
        arr.map((ele, ind) => {

            if (ind === index && clickedArrow) {
                ele.isOpen = true
            }
            else {
                ele.isOpen = false
            }

            setData([...arr])
            // console.log("arr", arr)

        })
        prevIndex.current = index
    }, [clickedArrow])

    const fetchGetAds = async (page) => {
        setLoader(true)
        const body = {
            userID: JSON.parse(localStorage.getItem(constants.profileLite))?.item?.user_id,
            country: JSON.parse(localStorage.getItem('countryId')),
            pagination: {
                pageNo: page,
                limit: 10
            }
        }
        const res = await getAds(body)
        // console.log("res>>>", res?.data[0].param?.data)
        if (res.success) {
            setData([...data, ...res?.data[0].param?.data])
            setTotalPage(res?.data[0].param?.totalPages)
        }
        setLoader(false)
    }

    // const d = [
    //     {
    //         title1: 'Asdfghjklopqwertyuzxcvbnmlkjhgfds',
    //         title2:'Asdfgh jklopqwertyuzxcvbnmlkjhgfdsAsdfghjklopqwertyuzxcvbnmlkjhgfdsqw',
    //         isOpen:false
    //     },
    //     {
    //         title1: 'Asdfghjkl',
    //         title2:'Asdfghjkghjklopgfdsqw'
    //     },
    //     {
    //         title1: 'Asdf',
    //         title2:'Asdfghj klopqwertyuzxcvbnmlkjhgfd sAsdfghjklopqwertyuzxcvbnmlkjhgfdsqw',
    //         isOpen:false
    //     },
    //     {
    //         title1: 'Asdfghjklopqwertyuzxcvbnmlkjhgfds',
    //         title2:'Asdfghjklopqwertyuzxcvbert yuzxcvbnmlkjhgfdsqw',
    //         isOpen:false
    //     }
    // ]

    useEffect(() => {
        let startTime = localStorage.getItem('startAdTime')
        let endTime = localStorage.getItem('endTime')
        if (startTime && endTime) {
            let time = parseInt(endTime) - parseInt(startTime)

            localStorage.removeItem('startAdTime')
            localStorage.removeItem('endTime')

        }
        // console.log(d)
        // setData(d)
        // console.log("time>>>", startTime, endTime)
        // console.log(page)
        fetchGetAds(page)
    }, [])

    const navigate = useNavigate()
    const onEarnNowHandler = (link, id) => {
        // console.log(link)
        // window.location.href = "https://Google.com/"
        // window.location.href = '/ad-page'
        // window.location.href = link 
        // window.location.replace(link)
        navigate('/ad-page', { state: { link: link, adId: id } })
    }
    const loadHandler = () => {
        let p = page
        setPage(page + 1)

        fetchGetAds(p + 1)

    }
    if (loader) return <Loader />
    return (
        <div className="ad-main" style={{}}>
            {/* {console.log(">>>", data.length)} */}
            {
                data.length < 1 ?
                    <h2 style={{ marginTop: '100px', fontSize: '20px' }}>{t('notFound.no_records_found')}</h2> :
                    data?.map((ele, ind) => (
                        <div className='adSection '>
                            <div className='flex layers firstLayer'>
                                <div className='ad'><p style={{ lineHeight: '90%', color: 'white', fontWeight: '500', fontSize: '12px' }}>AD</p></div>
                                <div className='image'>
                                    <img
                                        src={url.imageUrl + ele?.brandImage?.default} alt={ele?.brandName}
                                    // src={Bijus}
                                    />
                                </div>
                                <div className='titles'>
                                    <span style={{ fontSize: '15px' }}><b>{ele?.title?.length < 32 ? ele.title : <marquee>{ele.title}</marquee>}</b>      </span>
                                    <span style={{ fontSize: '14px', color: '#808c9a', lineHeight: '110%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {ele.description}
                                    </span>
                                </div>
                            </div>
                            <div className='layers secondlayer'>
                                <p style={{ color: '#f92c2c' }}>HOW TO EARN?</p>
                                <p onClick={() => clickArrowButton(ind)} style={{ cursor: 'pointer' }}>
                                    <i className={ind == index && clickedArrow ? "arrow up" : "arrow down"}></i>
                                </p>
                            </div>
                            {/* {console.log("open", ele?.isOpen)} */}
                            {
                                ele?.isOpen ?
                                    <div className='earnSteps'>
                                        {ele?.howToEarn?.map((e, ind) => (
                                            (<p><b>Step {ind + 1} : </b> {e} </p>
                                            )

                                        ))}
                                        {/* <p><b>Step 3 : </b> SIGN UP ON BIJUS</p> */}
                                    </div>
                                    : ""
                            }

                            <div className='layers thirdLayer'>
                                <button style={{ borderRadius: '40px', backgroundColor: 'transparent', color: '#808c9a', textAlign: 'left' }} >
                                    <div style={{ marginRight: '10px' }}> Earn </div>
                                    <div style={{ marginRight: '10px' }}><img style={{ height: '14px', width: '15px' }}
                                        // src={Diamond}
                                        src={url.imageUrl + ele?.adsEarningCurrency?.img?.default}
                                    /> </div>
                                    <div style={{ color: 'black' }}> <b>{ele?.earningAmmount}</b> </div>
                                </button>
                                <button style={{ borderRadius: '40px', backgroundColor: '#070b28', color: 'white', justifyContent: 'center', alignItems: 'center' }} onClick={() => onEarnNowHandler(ele?.link, ele._id)}  > <b>EARN NOW</b></button>
                            </div>
                        </div>
                    ))
            }


            {
                page < totalPage ?
                    <div className="padding-top2" style={{ marginLeft: '-20px' }}>
                        <button className="btn scrim-leaderboard-btn  " onClick={loadHandler} style={{ marginLeft: '-50px' }}>


                            Load more

                        </button>
                    </div>
                    : ""
            }

        </div>
    )
}

export default EarnCoins