import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Icon1 from "../assets/images/menu-1.svg";
import Wallet from '../assets/images/wallet.svg'
import Account from '../assets/images/account.svg'
import CoinStoreGameBagIcon from '../assets/images/game_bag_1.svg'
import CoinStoreGameBagIcon2 from '../assets/images/game_bag_2.svg'
import Icon3 from "../assets/images/menu-3.svg";
import Icon4 from "../assets/images/menu-4.svg";
import Icon5 from "../assets/images/menu-5.svg";
import { routehelp } from "../config/routehelp";

import helperFunc from '../helperFuncs';
import { useTranslation } from "react-i18next";

function Footer(props) {
  var userCountry = helperFunc.getUserCountry();
  const {t} = useTranslation()
  //var userCountry={code:"UAE"};
  // console.log("getUserCountry==>", userCountry.code)
  return (
    <Fragment>
      <div className="footer">
        <ul className="footer-menu">
          <li className={`menu-item ${window?.location?.pathname === '/' && 'active'}`}>
            {/* // eslint-disable-next-line */}
            <Link to="">
              <div className="icon">
                <img src={Icon1} alt="" />
              </div>
              {t('sidebar.all_games')}
            </Link>
          </li>
          <li className={`menu-item ${window?.location?.pathname === '/world-of-esports' && 'active'}`}>
            {/* // eslint-disable-next-line */}
            <Link to={routehelp.worldOfEsports}>
              <div className="icon">
                <img src={Icon4} alt="" />
              </div>
              {t('sidebar.world_of')}
            </Link>
          </li>
          <li className="menu-item-center">
            {/* // eslint-disable-next-line */}
            <Link to={routehelp.profile}>
              <div className="icon">
                <img src={Icon3} alt="" />
              </div>
              {t('sidebar.profile')}
            </Link>
          </li>
          {
            userCountry?.code === "7IN" ?
              <li className={`menu-item ${window?.location?.pathname === '/account' && 'active'}`}>
                {/* // eslint-disable-next-line */}
                <Link to={routehelp.account}>
                  <div className="icon">
                    <img src={CoinStoreGameBagIcon} alt="" />
                  </div>
                  {t('sidebar.account')}
                </Link>
              </li>
              :
              <li className={`menu-item ${window?.location?.pathname === '/coin-store' && 'active'}`}>
                {/* // eslint-disable-next-line */}
                <Link to={routehelp.coinstore}>
                  <div className="icon">
                    {/* {
                      window?.location?.pathname === '/coin-store' ? */}
                        <img src={Wallet} alt="" />
                        {/* :
                        <img src={CoinStoreGameBagIcon2} alt="" />
                    } */}
                  </div>
                  {t('sidebar.rewards')}
                </Link>
              </li>
          }


          <li className={`menu-item ${window?.location?.pathname === '/more' && 'active'}`}>
            {/* // eslint-disable-next-line */}
            <Link to="/more">
              <div className="icon">
                <img src={Icon5} alt="" />
              </div>
              {t('sidebar.more')}
            </Link>
          </li>
        </ul>
      </div>
    </Fragment>
  );
}

export default Footer;
