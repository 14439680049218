import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import Previous from "../../assets/images/previous.svg";
import Loader from '../../component/loader';
import { Link } from 'react-router-dom';
import { updateCoin } from '../../apis';
import Close from '../../assets/images/round_not_qualified.png'
import { constants } from '../../constants/constants';
const AdPage = () => {
    const [countDown, setCountDown] = useState(30);
    //endTime
    //startAdTime
    const location = useLocation()
    const navigate = useNavigate()
    // console.log(location)
    const updateCoins = async()=>{
      const body = {
        "userID":JSON.parse(localStorage.getItem(constants.profileLite))?.item?.user_id,
        "adId": location?.state.adId,
        "country" : JSON.parse(localStorage.getItem('countryId')),
    }
    // console.log(body)
   
      if(countDown === 0){
        const res = await updateCoin(body)
        //console.log(res)
        if(res?.data.success == false){
          window.location.href = '/coin-store'
        }
      }
    }
    
    useEffect(()=>{

        localStorage.setItem('startAdTime',0)
        const interval = setInterval(() => {
            let v = countDown-1
            setCountDown(v);
            localStorage.setItem('endTime',countDown+1)
          }, 1000);
          updateCoins()
          return () => clearInterval(interval);
    
        
    },[countDown])
  return (
    // <div style={{color:'white'}}>AdPage {countDown}</div>
    <div className="fg_category_main_div">
        {/* {this.state.tag_free_game_category_loader && <Loader />} */}
        <div className="fg_category_header">
          <div className="fg_category_back_arrow" onClick={() => window.location.href='/coin-store'}>
          
              <img src={Previous} alt="" style={{width:'24px', height:'22px'}}/>
					
          </div>
          <div className="fg_category_header_text">Ad Page</div>
          <div className="" style = {{color:'white'}}>  {countDown > 0 ? countDown : <img style = {{width:'30px', height:'30px', cursor:'pointer'}} src={Close} onClick={()=> window.location.href = '/coin-store'} />}</div>
        </div>

        <div className="fg_category_body" style={{margin:0}}>
          <div className="all_sub_screens_div_related_to_ads">
            <div className="fg_category_body_scrollable_component">
              {/* {console.log("linkl", location?.state?.link)} */}
            <iframe
            style={{width:'100%', height:'100%'}}
            // src="https://www.google.com/webhp?igu=1"></iframe>
            src={location?.state?.link}></iframe>

            </div>
          </div>
         
        </div>
        
      </div>
  )
}

export default AdPage