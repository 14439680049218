import React, { Fragment } from "react";
import Previous from "../assets/images/previous.svg";
import Wallet from "../assets/images/wallet.svg";
import { Link } from "react-router-dom";

function RecentTransaction(props) {
	return (
		<Fragment>
			<div className="header">
				<div className="back-btn">
					<Link to="/">
						<img src={Previous} alt="" />
					</Link>
				</div>
				<h2>Recent Transactions</h2>
				<div className="wallet-btn">
					{/* <Link to="#">
								<img src={Wallet} alt=""/>
								Wallet
							</Link> */}
				</div>
			</div>
			<div className="body mCustomScrollbar _mCS_1">
				<div className="recent_transaction_items">
					<div className="accordion" id="accordionExample">
						<div className="date_lable">29<sup>th</sup> June, 2020</div>
						<div className="item">
							<div className="accordion-header" data-toggle="collapse" data-target="#a_1" aria-expanded="true">
								<img src="images/Ecommerce-transfer.svg" alt="" />
								<b className="text-secondary">₹50</b>
								Bonus Credited
								<span className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
										<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
											<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
										</g>
									</svg>
								</span>
							</div>
							<div id="a_1" className="txt collapse show" data-parent="#accordionExample">
								<div className="table-responsive">
									<table>
										<tr>
											<th>Transaction Id:</th>
											<td>ABC566465KJL</td>
										</tr>
										<tr>
											<th>Transaction Date:</th>
											<td>12th  June, 18:30 PM</td>
										</tr>
										<tr>
											<th>PUBG Id:</th>
											<td>123456GH</td>
										</tr>
									</table>
								</div>
								<div className="recent_tra_links">
									<Link to="#" className="bg-green">
										<img src="images/Mail.svg" alt="" />
										Email invoice
									</Link>
									<Link to="#" className="bg-sky">
										<img src="images/file-icon.svg" alt="" />
										Download Invoice
									</Link>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="accordion-header" data-toggle="collapse" data-target="#a_2" aria-expanded="false">
								<img src="images/Ecommerce-transfer.svg" alt="" />
								<b className="text-secondary">-₹50</b>
								Joined A Contest
								<span className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
										<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
											<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
										</g>
									</svg>
								</span>
							</div>
							<div id="a_2" className="txt collapse" data-parent="#accordionExample">
								<div className="table-responsive">
									<table>
										<tr>
											<th>Transaction Id:</th>
											<td>ABC566465KJL</td>
										</tr>
										<tr>
											<th>Transaction Date:</th>
											<td>12th  June, 18:30 PM</td>
										</tr>
										<tr>
											<th>PUBG Id:</th>
											<td>123456GH</td>
										</tr>
									</table>
								</div>
								<div className="recent_tra_links">
									<Link to="#" className="bg-green">
										<img src="images/Mail.svg" alt="" />
										Email invoice
									</Link>
									<Link to="#" className="bg-sky">
										<img src="images/file-icon.svg" alt="" />
										Download Invoice
									</Link>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="accordion-header" data-toggle="collapse" data-target="#a_3" aria-expanded="false">
								<img src="images/Ecommerce-transfer.svg" alt="" />
								<b className="text-secondary">₹25</b>
								Won A Contest
								<span className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
										<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
											<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
										</g>
									</svg>
								</span>
							</div>
							<div id="a_3" className="txt collapse" data-parent="#accordionExample">
								<div className="table-responsive">
									<table>
										<tr>
											<th>Transaction Id:</th>
											<td>ABC566465KJL</td>
										</tr>
										<tr>
											<th>Transaction Date:</th>
											<td>12th  June, 18:30 PM</td>
										</tr>
										<tr>
											<th>PUBG Id:</th>
											<td>123456GH</td>
										</tr>
									</table>
								</div>
								<div className="recent_tra_links">
									<Link to="#" className="bg-green">
										<img src="images/Mail.svg" alt="" />
										Email invoice
									</Link>
									<Link to="#" className="bg-sky">
										<img src="images/file-icon.svg" alt="" />
										Download Invoice
									</Link>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="accordion-header" data-toggle="collapse" data-target="#a_4" aria-expanded="false">
								<img src="images/Ecommerce-transfer.svg" alt="" />
								<b className="text-secondary">₹30</b>
								Deposit Balance
								<span className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
										<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
											<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
										</g>
									</svg>
								</span>
							</div>
							<div id="a_4" className="txt collapse" data-parent="#accordionExample">
								<div className="table-responsive">
									<table>
										<tr>
											<th>Transaction Id:</th>
											<td>ABC566465KJL</td>
										</tr>
										<tr>
											<th>Transaction Date:</th>
											<td>12th  June, 18:30 PM</td>
										</tr>
										<tr>
											<th>PUBG Id:</th>
											<td>123456GH</td>
										</tr>
									</table>
								</div>
								<div className="recent_tra_links">
									<Link to="#" className="bg-green">
										<img src="images/Mail.svg" alt="" />
										Email invoice
									</Link>
									<Link to="#" className="bg-sky">
										<img src="images/file-icon.svg" alt="" />
										Download Invoice
									</Link>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="accordion-header" data-toggle="collapse" data-target="#a_5" aria-expanded="false">
								<img src="images/Ecommerce-transfer.svg" alt="" />
								<b className="text-secondary">₹15</b>
								Refunded
								<span className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
										<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
											<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
										</g>
									</svg>
								</span>
							</div>
							<div id="a_5" className="txt collapse" data-parent="#accordionExample">
								<div className="table-responsive">
									<table>
										<tr>
											<th>Transaction Id:</th>
											<td>ABC566465KJL</td>
										</tr>
										<tr>
											<th>Transaction Date:</th>
											<td>12th  June, 18:30 PM</td>
										</tr>
										<tr>
											<th>PUBG Id:</th>
											<td>123456GH</td>
										</tr>
									</table>
								</div>
								<div className="recent_tra_links">
									<Link to="#" className="bg-green">
										<img src="images/Mail.svg" alt="" />
										Email invoice
									</Link>
									<Link to="#" className="bg-sky">
										<img src="images/file-icon.svg" alt="" />
										Download Invoice
									</Link>
								</div>
							</div>
						</div>

						<div className="date_lable">01<sup>th</sup> July, 2020</div>
						<div className="item">
							<div className="accordion-header" data-toggle="collapse" data-target="#a_6" aria-expanded="false">
								<img src="images/Ecommerce-transfer.svg" alt="" />
								<b className="text-secondary">₹30</b>
								Deposit Balance
								<span className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
										<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
											<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
										</g>
									</svg>
								</span>
							</div>
							<div id="a_6" className="txt collapse" data-parent="#accordionExample">
								<div className="table-responsive">
									<table>
										<tr>
											<th>Transaction Id:</th>
											<td>ABC566465KJL</td>
										</tr>
										<tr>
											<th>Transaction Date:</th>
											<td>12th  June, 18:30 PM</td>
										</tr>
										<tr>
											<th>PUBG Id:</th>
											<td>123456GH</td>
										</tr>
									</table>
								</div>
								<div className="recent_tra_links">
									<Link to="#" className="bg-green">
										<img src="images/Mail.svg" alt="" />
										Email invoice
									</Link>
									<Link to="#" className="bg-sky">
										<img src="images/file-icon.svg" alt="" />
										Download Invoice
									</Link>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="accordion-header" data-toggle="collapse" data-target="#a_7" aria-expanded="false">
								<img src="images/Ecommerce-transfer.svg" alt="" />
								<b className="text-secondary">₹15</b>
								Refunded
								<span className="icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="21.493" height="18.154" viewBox="0 0 21.493 18.154">
										<g id="ico" transform="translate(21.493 18.151) rotate(-180)">
											<path id="union" d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z" transform="translate(0 0)" fill="currentColor" />
										</g>
									</svg>
								</span>
							</div>
							<div id="a_7" className="txt collapse" data-parent="#accordionExample">
								<div className="table-responsive">
									<table>
										<tr>
											<th>Transaction Id:</th>
											<td>ABC566465KJL</td>
										</tr>
										<tr>
											<th>Transaction Date:</th>
											<td>12th  June, 18:30 PM</td>
										</tr>
										<tr>
											<th>PUBG Id:</th>
											<td>123456GH</td>
										</tr>
									</table>
								</div>
								<div className="recent_tra_links">
									<Link to="#" className="bg-green">
										<img src="images/Mail.svg" alt="" />
										Email invoice
									</Link>
									<Link to="#" className="bg-sky">
										<img src="images/file-icon.svg" alt="" />
										Download Invoice
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default RecentTransaction;
