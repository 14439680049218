import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
import { Row, Col } from "react-bootstrap";
import {
  woeBlogsList,
} from "../apis";
import url from '../constants/url';
import Loader from "../component/loader";

import { handleTextAnimation } from "../component/textAnimation";
import Advertisement from "../component/advertisement";

import WOEIndividualEsportsNews from "./WOEIndividualEsportsNews";
import './world-of-esports.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import logo from '../assets/images/gamer_logo.svg';
import Previous from "../assets/images/previous.svg";
import { useTranslation, Trans, withTranslation } from "react-i18next";
class WOEAllEsportsNews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      WOEActiveScreen: this.props.WOEActiveScreen,
      WOEAllEsportsNewsActiveScreen: 0,

      skip: 0,
      limit: 10,
      total_count: 0,
      esports_news: [
        // {name: '', src: ''}, 
        // {name: '', src: ''}, 
        // {name: '', src: ''}, 
        // {name: '', src: ''}, 
        // {name: '', src: ''}, 
        // {name: '', src: ''}, 
      ],

      style_color: [
        'var(--woe_color_yellow)',
        'var(--woe_color_sky_blue)',
        'var(--woe_color_purple)',
        'var(--woe_color_light_green)',
        'var(--woe_color_light_orange)',
      ],

      individual_esports_news: [],

      tag_esports_news_loader: true,
    };

    this.onChangeWOEActiveScreen = this.onChangeWOEActiveScreen.bind(this);
  }

  componentDidMount() {
    this.woeBlogsListApiCall();
  }

  componentDidUpdate() {
    // console.log('update')
    handleTextAnimation();
  }

  onChangeWOEActiveScreen(active) {
    // console.log(active)
    this.setState({ WOEAllEsportsNewsActiveScreen: active });
  }

  async woeBlogsListApiCall() {
    this.setState({ tag_esports_news_loader: true, })
    let data = {
      skip: this.state.skip,
      limit: this.state.limit,
      sort: 'desc',
      sortBy: 'createdAt',
    };

    let response = await woeBlogsList(data);
    // console.log(response);
    if (response != undefined) {
      if ('list' in response) {
        let temp_total_count = response?.count;
        response = this.state.esports_news.concat(response.list);
        let skip = this.state.skip + this.state.limit;
        this.setState({ esports_news: response, skip: skip, total_count: temp_total_count, });
      }
    }
    this.setState({ tag_esports_news_loader: false, })
  }

  returnDate(date_str) {
    let year = date_str.split('-')[0];
    let month = date_str.split('-')[1];
    let date = date_str.split('-')[2];

    switch (month.toString()) {
      case '01': month = 'Jan'; break;
      case '02': month = 'Feb'; break;
      case '03': month = 'March'; break;
      case '04': month = 'April'; break;
      case '05': month = 'May'; break;
      case '06': month = 'June'; break;
      case '07': month = 'July'; break;
      case '08': month = 'Aug'; break;
      case '09': month = 'Sep'; break;
      case '10': month = 'Oct'; break;
      case '11': month = 'Nov'; break;
      case '12': month = 'Dec'; break;
    }

    return date.toString() + ' ' + month.toString() + ' ' + year.toString();
  }

  createWOEAllEsportsNews() {
    const { t } = this.props;

    return (
      <div className="inner-wrap woe_main_div_screen_3">
        <div className="header">
          <div className="back-btn"
            //  style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} 
            onClick={(e) => this.props.onChangeWOEActiveScreen(0)}>
            <img src={Previous} alt="" />

            {/* <svg xmlns="http://www.w3.org/2000/svg" width="21.334" height="18.014" viewBox="0 0 21.334 18.014">
              <g id="ico" transform="translate(21.334 18.013) rotate(-180)">
                <path id="union" d="M8.321,17.721.479,9.879A1,1,0,0,1,.125,8.541,1.005,1.005,0,0,1,.411,8.2L8.321.293A1,1,0,0,1,9.735,1.706L3.417,8.025H20.334a1,1,0,1,1,0,2H3.454l6.281,6.281a1,1,0,0,1-1.414,1.415Z" transform="translate(0 0)" fill="#fff" />
              </g>
            </svg> */}
          </div>
          <h2>{t('esports-news')}</h2>
          <div className="wallet-btn">
            {/* <Link to="">
              <img src={Wallet} alt="" />
              Wallet
            </Link> */}
          </div>
        </div>

        <div className="body bottom-btn-body woe_main_div_screen_3_body" style={{ paddingTop: '0', }}>
          <div className="all_sub_screens_div_related_to_ads">
            <Row className="m-0" style={{ padding: '20px 0', justifyContent: 'center' }}>
              {
                this.state.esports_news.map((value, i) => {
                  let j = i % 5;
                  return (
                    <Col className="woe_screens_box_col p-2 m-0" xl={6} lg={6} md={6} sm={6} xs={12} key={i}>
                      <div
                        className="woe_esports_news_box_screen_3"
                        style={{ backgroundColor: this.state.style_color[j] }}
                        onClick={(e) => {
                          this.setState({
                            individual_esports_news: value,
                          }, () => {
                            this.setState({ WOEAllEsportsNewsActiveScreen: 1, });
                          })
                        }}
                      >
                        <img className="woe_esports_news_image_screen_3" src={`${url.imageUrl}${value.img.default}`} />
                        <div className="woe_esports_news_name text_animation_container"></div>

                        <h5 className="news_text">{t('woe.PUBG_monthly_champ')}</h5>
                            
                        {/* <div className="news_icons">
                          <div className="news_view_icon">
                             <VisibilityIcon/>  <p className="news_view">Views</p>
                          </div>

                          <div className="news_fav_icon">
                             <FavoriteIcon/> <p className="news_fav">Like</p>
                          </div>

                          <div className="news_share_icon">
                              <ShareIcon/>  <p className="news_share">Share</p>
                          </div>
                        
                        </div> */}
                      </div>
                    </Col>
                  )
                })
              }
            </Row>

            <Row className="woe_screen_1_load_more_button_row justify-content-center m-0" style={{ padding: '10px' }}>
              {
                this.state.total_count === this.state.esports_news.length ?
                <div></div> 
                : 
                <button className="woe_screen_1_load_more_button" onClick={() => this.woeBlogsListApiCall()}>
                  {t('tournaments.load_more')}
                </button>
              }
            </Row>
          </div>
          {
             !JSON.parse(localStorage.getItem("premiumUser")) && 
          
          <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).viewAllEsportsNews
            }
            screen_div_class_name='.all_sub_screens_div_related_to_ads'
            screen_type='sub'
          />
  }
        </div>
      </div>
    )
  }

  handleWOEAllEsportsNewsScreens(active) {
    if (active === 0) {
      return this.createWOEAllEsportsNews();
    } else {
      return (
        <WOEIndividualEsportsNews
          individual_esports_news={this.state.individual_esports_news}
          onChangeWOEActiveScreen={this.onChangeWOEActiveScreen}
        />
      );
    }
  }

  render() {
    return (
      <div>
        {this.state.tag_esports_news_loader ? <Loader /> : ''}
        {this.handleWOEAllEsportsNewsScreens(this.state.WOEAllEsportsNewsActiveScreen)}
      </div>

    );
  }
}
// export default WOEAllFeaturedVideos;

export default withTranslation()(WOEAllEsportsNews);