import React, { Fragment, useEffect, useState, useRef, } from "react";
import Header from "../component/header";
import Featured from "./featured";
import Allgames from "./allgames";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Logo from "../assets/images/logo.svg"
import { useTranslation } from "react-i18next";

import {
  getDailyRewards,
  getListofDailyRewards,
  getUserProfile,
  homebanner,
  getCollectDailyRewards,
  getUpdateProfile,
  apkScreensList,
  bannerAdLog,
  getUserInGameName,
  getTournamentList,
  isUserPremium,
  getApplyContestCode,
  getApplyEventCode,
  onCreateScrim,
  getUserProfileLite,
  getUserBasicsDetails,
  getUserFavouritesGames,
  userRenewDetails,
} from "../apis";
import { dateFormat, bannerRedirection, getProfiledateFormat, formatAMPM, checkDobNstateConditions, checkDobNstateConditionsContestTournament } from "../component/common";
import url from "../constants/url";
import Loader from "../component/loader";
import DobNState from "./dob-n-state";
import Close from "../assets/images/close.svg";
import rightArrow from "../assets/images/right-arrow.svg";
import Checkmark from "../assets/images/checkmark.svg";
import Advertisement from "../component/advertisement";
import LanguagePopup from "./LanguagePopup";
import { handleTextAnimation } from "../component/textAnimation";
import roller from "../assets/images/rolling.gif";
import { toast } from "react-toastify";
import { func } from "../logDetails";
import moment from "moment";
import { routehelp } from "../config/routehelp";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import DobNStates from "./dobnstate";
import TournamentsStructure from "../component/tournaments-structure";
import ContestStructure from "../component/ContestStructure";
import Lottie from "react-lottie";
import successCheck from "../assets/images/success.json";
import { ScrimSquadRegistration } from "./Scrims/ScrimSquadRegistration";
// import { CookieSharp } from "@mui/icons-material";
import Cookies from 'js-cookie'

import { constants } from "../constants/constants";

function HomePage(props) {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [allData, setAllData] = useState([]);
  const [allTournamentData, setAllTournamentData] = useState([]);
  const [rewardsList, setRewardsList] = useState([]);
  const [avatarData, setAvatarData] = useState({});
  const [loader, setLoader] = useState(false);
  const [dobnstate, setdobnstate] = useState(false);
  const [joinpopup, setjoinpopup] = useState(true);
  const [showDailyRewards, setShowDailyRewards] = useState(false);
  const [showAvatarInfo, setShowAvatarInfo] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showPopupYes, setShowPopupYes] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [currentActiveDay, setCurrentActiveDay] = useState(-1);

  const [showLanguagePopup, setShowLanguagePopup] = useState(true);
  const [isbusy, setIsBusy] = useState(false);
  const [premiumUser, setPremiumUser] = useState(false)
  const [tag_tournamentJoin, setTag_tournamentJoin] = useState(false);
  const [scrimJoin, setScrimJoin] = useState(false);
  const [joinData, setJoinData] = useState([]);
  const [constestJoin, setConstestJoin] = useState(false);
  const [scrimData, setScrimData] = useState([]);
  const [showdobnstate, setShowdobnstate] = useState(false);
  const [dobNstateScreenName, setDobNstateScreenName] = useState('tournaments-contests');
  const [showConfirm, setShowConfirm] = useState(false);
  const [players, setPlayers] = useState([])
  const [showTeam, setShowTeam] = useState(false)
  const navigate = useNavigate()
  const {t} = useTranslation()
  const currentLang = Cookies.get('i18next') || 'EN'
  const [userRenewDetailsData, setUserRenewDetailsData] = useState(null)
  const [showdobnstateInfo, setShowdobnstateInfo] = useState(null);
  
  useEffect(() => {
    let tag = localStorage.getItem('languageData') ?
      JSON.parse(localStorage.getItem('languageData'))?.showLanguagePopup : true;
    if (tag === false)
      setShowLanguagePopup(false);
    else
      setShowLanguagePopup(true);


      const linkTag = document.getElementById('bootstrap_css');
      document.documentElement.dir =
      currentLang.toLowerCase() == "ar"  ? "rtl" : "ltr";
      if (currentLang.toLowerCase()== "ar" ) {
        document.documentElement.setAttribute('lang', 'ar');
        linkTag?.setAttribute(
          'href',
          `${process.env.REACT_APP_CLIENT_BASE_URL}asserts/css/bootstrap.rtl.min.css`
        );
      }
      document.title = t('title')


    fetchData();

  
  }, []);

  const progessWidth = () => {
    var per =
          (100 * scrimData?.noOfJoinedPlayer) /
            scrimData?.noOfPlayerCapacity +
          "%";
          return per
  }
  const showteam = ()=> {
    setShowTeam(false)
  }
  const findUserName = (gameid) => {
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"))
   
    if (gameNames) {
      let index = gameNames.findIndex(obj => obj.game == gameid)
      if (index > -1) {
        return gameNames[index].userINGameName || ""
      } else {
        return ""                                                                 
      }
    }
  }
  const cancelDobNstate = () => {
    setShowdobnstate(false)
  };
  const getdobnstate = (tag) => {
    // setShowjoinpopup(tag);
    var gameid = localStorage.getItem("activeGameId")
    findUserName(gameid)
    setShowdobnstate(false)   
  }
  const getjoin = async () => {
    var data = findUserName(localStorage.getItem('activeGameId'))
    let dobConditionData = await checkDobNstateConditionsContestTournament(dobNstateScreenName)
    if (dobConditionData?.tag_show_dobnsgtate_popup || data == '' || data == undefined){
      setShowdobnstate(true)
      setShowdobnstateInfo(dobConditionData?.gameNamesData);
    }
    else{
      setScrimJoin(false);
      setShowConfirm(true);
    }
  };

  const applyCode = async() => {
    if (code) {
      var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
  
      let payload = {
        search: {
          user: user_data?.item?.user_id,
        },
      };
      let res = await getApplyContestCode(
        code.toUpperCase(),
        payload
      ); 
      if (res?.type === "scrim") {
        // console.log("its scrim");
      }
      if (res?.data && res?.success) {
        let data = {};
        if (res?.data?.isSingle) {
          localStorage.setItem("activeGameId", res?.data?.game);
          data = res?.data;
        } else{
          localStorage.setItem('activeGameId', res?.item?.saasGameId)
          localStorage.setItem('activeGame', res?.item?.game?.name)
          data = {
            id: res?.item?._id,
            img: url.imageUrl + res?.item?.featuredImage?.default, // not present
            coin: url.imageUrl + res?.item?.currency?.img?.default,
            title: res?.item?.title,
            contestsList: res?.item?.contestsList, // not present
            totalPlayers: res?.item?.totalPlayers,
            isJoined: res?.item?.isJoined,
            totalJoinedPlayers: res?.item?.totalJoinedPlayers,
            shortCode: res?.item?.shortCode,
            gameName: res?.item?.game?.name, // not present
            levelNote: res?.item?.game?.settings?.levelNote, // not present
            currency: res?.item?.currency,
            gameId: res?.item?.game?._id, // not present
            winners: res?.item?.totalWinners,
            gameType: res?.item?.gameType?.name,
            gameTypePlayers: res?.item?.gameType?.players,
  
            hostedby: res?.item?.host?.name,
            hostrate: res?.item?.host?.rate,
            entryFee: res?.item?.entryFee,
            titles: res?.item?.titles,
            rules: res?.item?.rules, // not present
            allrounds: res?.item?.rounds, // not present
            round: res?.item?.rounds?.length, // not present
            winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
            startDate: res?.item?.startDate, // not present
            endDate: res?.item?.endDate, // not present
            roundDatetime: res?.item?.rounds[0]?.matches, // not present
            prizepool: res?.item?.prizepool,
            prizePool: res?.item?.prizePool,
            pointpool: res?.item?.pointpool,
            createdAt: dateFormat(res?.item?.createdAt), // not present
            updatedAt: dateFormat(res?.item?.updatedAt), // not present
            saasGameId:res?.item?.saasGameId,
            rewardDisplayText: res?.item?.rewardDisplayText
          };
        }

        if (res?.type === "scrim") {
          let data = res?.data;
          localStorage.setItem("activeGameId", data?.game?._id)
          setScrimJoin(true);
          setScrimData(data);
          setTag_tournamentJoin(false);
          localStorage.setItem('activeGameId', data?.game?._id)
          localStorage.setItem('activeGame', data?.game?.name)
          localStorage.setItem("scrimData", JSON.stringify(data));
  
        } else {
          setJoinData(data);
          setConstestJoin(true);
        }
      } 
      else{
        let data = { 
          code: code.toUpperCase(), 
          user:JSON.parse(localStorage.getItem(constants.profileLite))?.item?.user_id
        };
        let res = await getApplyEventCode(data);
        if (res?.item) {
          data = {};
          if (res?.item?.isSingle) {
            data = res?.item;
          } else {
            localStorage.setItem('activeGame', res?.item?.gameAry[0]?.name);
            localStorage.setItem('tournamentId', res?.item?._id);
            localStorage.setItem('activeGameId', res?.item?.gameAry[0]?._id);
            localStorage.setItem("activeGameType", JSON.stringify(res?.item?.gameTypeAry?.name));
            data = {
              id: res?.item?._id,
              img: url.imageUrl + res?.item?.featuredImage?.default, // not present
              coin: url.imageUrl + res?.item?.currency?.img?.default,
              title: res?.item?.title,
              contestsList: res?.item?.contestsList, // not present
              totalPlayers: res?.item?.totalPlayers,
              isJoined: res?.item?.isJoined,
              totalJoinedPlayers: res?.item?.totalJoinedPlayers,
              shortCode: res?.item?.shortCode,
              gameName: res?.item?.gameAry[0]?.name, // not present
              levelNote: res?.item?.game?.settings?.levelNote, // not present
              currency: res?.item?.currency[0],
              gameId: res?.item?.gameAry[0]?._id,
              winners: res?.item?.totalWinners,
              gameType: res?.item?.gameTypeAry?.name,
              gameTypePlayers: res?.item?.gameTypeAry?.players,
              gameTypeExtraPlayers: res?.item?.gameTypeAry?.extraPlayers, // not present
  
              hostedby: res?.item?.host?.name,
              hostrate: res?.item?.host?.rate,
              entryFee: res?.item?.entryFee,
              titles: res?.item?.titles,
              rules: res?.item?.rules, // not present
              allrounds: res?.item?.rounds, // not present
              round: res?.item?.rounds?.length, // not present
              winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
              startDate: res?.item?.startDate, // not present
              endDate: res?.item?.endDate, // not present
              roundDatetime: res?.item?.rounds[0]?.matches, // not present
              prizepool: res?.item?.prizePoolInfo,
              prizePool: res?.item?.prizePool,
              pointpool: res?.item?.pointpool,
              inGameName: findUserName(res?.item?.gameAry[0]?._id),
              createdAt: dateFormat(res?.item?.createdAt), // not present
              updatedAt: dateFormat(res?.item?.updatedAt), // not present
              rewardDisplayText: res?.item?.rewardDisplayText
            };
          }
          setTag_tournamentJoin(true);
          setJoinData(data);
        } else {
          toast.error(res?.data?.errors?.[0]?.msg, {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTag_tournamentJoin(false);
        }
      }
    }
  }

  function createAfterApplyCodePopup() {
    return (
      <div className="tc_popup_bg">
        <div className="custom-popup home-featured show" id="contact_popup">
          <div className="popup-head bg-light">
            <h3>
            {joinData?.isSingle === true
                ? joinData.title
                : joinData?.gameName}
            </h3>
            <button
              className="close-popup"
              onClick={() =>{
                setTag_tournamentJoin(false);
                setConstestJoin(false);
              }}
            >
              <img src={Close} alt="" />
            </button>
          </div>
          <div className="popup-body">
            {joinData?.isSingle === true
              ? createContestSingleBox(joinData, 1)
              : createTournamentSingleBox(joinData, 1)}
          </div>
        </div>
      </div>
    );
  }

  function createScrimApplyCodePopup() {
    return (
      <div className="tc_popup_bg">
        <div className="custom-popup home-featured show " id="contact_popup">
          <div className="popup-head bg-light">
            <h3> {scrimData?.title?.length > 30 ? (
                <marquee>{scrimData?.title}</marquee>
              ) : (
                <>{scrimData?.title}</>
              )}{" "}
            </h3>
            <button
              className="close-popup"
                onClick={() => setScrimJoin(false)}
            >
              <img src={Close} alt="" />
            </button>
          </div>
          <div className="popup-body">
            <div
              className="contest-list contest-tournament-list game_tounament "
              style={{ margin: "20px auto 0px" }}
            >
              {showdobnstate ?
                    <DobNStates
                      submitDobNstate={getdobnstate}
                      cancelDobNstate={cancelDobNstate}
                      screen={dobNstateScreenName}
                      gameId={scrimData?.gameType}
                      showdobnstateInfo={showdobnstateInfo}
                    /> : ""
              }
              <div className="box scrimDetail">
                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "800",
                    marginTop: "4px",
                  }}
                >
                  {/* {scrimData?.title} */}
                  <span className="span">
                    <div to="/create-scrim">
                      {/* <img
                        // src={Pencil}
                        alt="Edit"
                        style={{ color: "black !important" }}
                      /> */}
                    </div>
                  </span>
                </h3>

                <div
                  className="row tournament_contest_row"
                  style={{ marginBottom: "-20px" }}
                >
                   <div className="col-12">
                    <div className="inner_row">
                      <div className="inner_item">
                        Date
                        <b>
                          {getProfiledateFormat(scrimData?.date)}
                        </b>
                      </div>
                      <div className="inner_item">
                        Time{" "}
                        <b>
                          {formatAMPM(new Date(scrimData?.time))}
                        </b>
                      </div>

                      <div className="inner_item">
                        {scrimData?.title1?.name}
                        <b>{scrimData?.title1?.value}</b>
                      </div>
                      {scrimData?.title2 ? (
                        <div className="inner_item">
                          {scrimData?.title2?.name}{" "}
                          <b>{scrimData?.title2?.value}</b>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="inner_row bg-dark">
                      <div className="inner_item inner_item_medium">
                        Players <br />{" "}
                        <b>{scrimData?.noOfPlayerCapacity}</b>
                      </div>
                      <div className="inner_item inner_item_medium">
                        Winners <br /> <b>{scrimData?.noOfWinner}</b>
                      </div>
                      <div className="inner_item inner_item_medium">
                        ID <br /> <b>{scrimData?.shortCode}</b>
                      </div>
                    </div>

                    <div className="avalible_wrap">
                      <div className="inner">
                        <span
                          style={{
                            width: progessWidth(),
                          }}
                        ></span>
                      </div>
                      <p
                        className="d-flex justify-content-between text-light"
                        style={{ paddingBottom: "1rem" }}
                      >
                        <span className="">
                          {scrimData?.noOfPlayerCapacity -
                            scrimData?.noOfJoinedPlayer}{" "}
                          players remaining
                        </span>
                        <span className="">
                          {scrimData?.noOfJoinedPlayer} players
                          joined
                        </span>
                      </p>
                    </div>
                    <div className="contest_foot p-2">
                      <div className="inner pl-2">
                        <span className="">Hosted By</span>
                        <marquee>
                          <b>
                            {" "}
                            {scrimData?.hostedBy} (
                            <i className="fas fa-star text-dark"></i>)
                          </b>
                        </marquee>
                      </div>
                      {scrimData?.noOfJoinedPlayer >=
                      scrimData?.noOfPlayerCapacity ? (
                        <div className="inner pointer">
                          <a className="contest_joining_popup">FULL</a>
                        </div>
                      ) : scrimData?.isJoined === true ? (
                        <div className="inner pointer">
                          <a className="d-flex justify-content-center align-items-center">
                            <b
                              className="contest_joining_popup d-flex justify-content-center align-items-center"
                              // onClick={() => joinedContest()}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "25px" }}
                                onClick={() => Scrimdetail()}
                              >
                                JOINED
                              </div>

                              <div
                                style={{
                                  width: "20px",
                                  height: "25px",
                                  marginLeft: "5px",
                                }}
                              >
                                <Lottie
                                  options={{
                                    animationData: successCheck,
                                    w: "20px",
                                  }}
                                />
                              </div>
                            </b>
                          </a>
                        </div>
                      ) : (
                        <div
                          className="inner pointer"
                          onClick={() => getjoin(scrimData)}
                        >
                          <a className="contest_joining_popup">JOIN NOW</a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const Scrimdetail = () => {
    window.location.href = "/scrim-details/" + scrimData?._id;
    setScrimJoin(false);
  };

  // Tournaments - Tournament/all (One box)
  function createTournamentSingleBox(data, index) {
    return (
      <TournamentsStructure
        allFeaturedData={data}
        key={index}
        isFromJoinViaCode={true}
      />
    );
  }
  // Contests - Tournament/{id} (all box)
  function createContestSingleBox(data, index) {
    return <ContestStructure allData={data} key={index} />;
  }

  const onJoinScrim = async () => {
    const gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    var players_data = JSON.parse(localStorage.getItem("players_data"));

    let data = {
      scrimId: scrimData?._id,
      userId: user_data.item.user_id,
      usersInGameName: gameNames?.[0].userINGameName,
      gamerJiName: user_data?.item.gamerjiName,
      mobileNo :user_data?.item?.phone ? user_data?.item?.phone : user_data?.item?.email
    };

    if (
      scrimData?.gameType?.players &&
      parseInt(scrimData?.gameType?.players) > 1
    ) {
      var numArray = [];
      var selectedPlayer = [];
      for (
        var i = 2;
        i <= parseInt(scrimData?.gameType?.players);
        i++
      ) {
        numArray.push(i);

        selectedPlayer.push({
          userId: null,
          inGameName: null,
          gamerJiName: user_data?.item.gamerjiName,
          country: null,
          mobileNo: null,
        });
      }
      // setPlayers({selectedPlayer.length});

      setShowTeam(true)
      setJoinData(data);
      setShowConfirm(false);
      setPlayers(selectedPlayer);
      
      // setShowScrimTeam(true);
    } else {
      let res = await onCreateScrim(data);
      // console.log(res);
      // setPlayers(res.gameType)
      {
        res.success === true
          ? (window.location.href =
              "/scrim-details/" + scrimData?._id)
          : toast.error(res.data.data[0]?.msg, {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
      }
    }
  };

  useEffect(() => {
    // console.log('&&&&&&&&&', searchParams.get('code'));
    if (code) {
      applyCode();

      // console.log(`Opening modal for code: ${code}`);
      // Open modal logic here
      setTag_tournamentJoin(true)
    } else{
      setTag_tournamentJoin(false)
    }
  }, []);

  const fetchUserDetails = async()=>{

    const id = JSON.parse(localStorage.getItem(constants.profileLite))?.item?.user_id
    let res = await isUserPremium(id)
    func("res", res)
    if(res?.success){
        setPremiumUser(res?.data[0]?.param)
        localStorage.setItem("premiumUser", res?.data[0]?.param?.isPremium)
        localStorage.setItem("noOfContest", res?.data[0]?.param?.noOfFreeContest)
        localStorage.setItem("noOfTournament", res?.data[0]?.param?.noOfFreeTournament)
      
        if (res?.data[0]?.param?.isPremium && res?.data[0]?.param?.isRenewRequired) {
          let resRenew = await userRenewDetails(id)
          if (resRenew?.success) {
            setUserRenewDetailsData(resRenew?.data)
          }
        }
    }
  }

  
  const getTotalCoin = (wallet) => {
    let index = wallet?.findIndex(obj => obj.currencyData?.code == 'coin')

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0
    }
    return 0
  }
  const getTotalDiamond = (wallet) => {
    let index = wallet?.findIndex(obj => obj.currencyData?.code == 'diamond')

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0
    }
    return 0
  }

  const logout = () => {
    localStorage.removeItem('gamerjiLogindetails');
    localStorage.removeItem(constants.profileLite);
    localStorage.removeItem(constants.profileBasics);
    localStorage.removeItem(constants.profileFavGames);
    localStorage.removeItem('gamerjiToken');
    localStorage.removeItem('activeGame');
    localStorage.removeItem('currentPage');
    localStorage.removeItem('joinTounament');
    localStorage.removeItem('tournamentId');
    localStorage.removeItem('activeGameId');
    localStorage.removeItem('tournamentImgs');
    localStorage.removeItem('currentCoin');
    localStorage.removeItem('userCountryObj');
    localStorage.removeItem('confirmationResult');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('currentOTPtype');
    // localStorage.removeItem('languageData');
    localStorage.removeItem('loginData');
    localStorage.removeItem('userInGameNames');
    localStorage.removeItem('activeGameType');
    localStorage.removeItem('scrimData');
    localStorage.removeItem('players_data');
    localStorage.removeItem('premiumUser');

    window.history.replaceState(null, '', '/');
    window.location.href = '/';
  }
  const fetchData = async () => {
    setLoader(true);

    let res1 = await getDailyRewards();
    if (res1?.success) {
      // comment by jaimin start
      if (res1?.item?.isDailyRewardPopup === true) {
        setShowDailyRewards(true);
        var currentDay = res1?.item?.currentDay;
        let numbers = "";
        if (currentDay <= 4 || currentDay == 2) {
          numbers = [1, 2, 3, 4, 5, 6, 7];
        } else {
          numbers = [
            currentDay - 3,
            currentDay - 2,
            currentDay - 1,
            currentDay,
            currentDay + 1,
            currentDay + 2,
            currentDay + 3,
          ];
        }
        let payload = {
          day: currentDay,
          days: numbers,
          skip: 0,
          sort: "asc",
          sortBy: "day",
        };
        let list = await getListofDailyRewards(payload);
        // console.log('getListofDailyRewards: ', list)
        if (list) {
          setRewardsList(list?.list);
          let dayId = list?.list?.find((val) => val.day === currentDay);
          setSelectedId(dayId?._id);
          setCurrentActiveDay(parseInt(currentDay));
        }
      }
      // comment by jaimin end
    } else {
      if (res1?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid")
        logout()
    }

    let profileLiteResponse = await getUserProfileLite();
    if(profileLiteResponse){
      localStorage.setItem(constants.profileLite,JSON.stringify(profileLiteResponse));
      localStorage.setItem('currentCoin', getTotalCoin(profileLiteResponse?.item?.wallet));
      localStorage.setItem('currentDiamond', getTotalDiamond(profileLiteResponse?.item?.wallet));
      localStorage.setItem("userCountryObj", JSON.stringify(profileLiteResponse?.item?.country));
      // GetInGameNames()
      if (profileLiteResponse?.item?.isDetailsFilled === false) {
        window.location.href = '/sign-up';
      }
      if (res1?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || res1?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      logout()
    }
    //GAMWA-1098
    // let profileBasicResposne = await getUserBasicsDetails();
    // if(profileBasicResposne){
    //   localStorage.setItem(constants.profileBasics,JSON.stringify(profileBasicResposne));
    //   if (profileBasicResposne?.country)
    //     localStorage.setItem("userCountryObj", JSON.stringify(profileBasicResposne.country));
    // }

    // let res = await getUserProfile();
    // if (res) {
    //   console.log('games:temp :', res)
    //   if (res?.country)
    //     localStorage.setItem("userCountryObj", JSON.stringify(res.country));

    //   // localStorage.setItem("userCountryObj", JSON.stringify(res?.country));
    //   localStorage.setItem('currentCoin', getTotalCoin(res?.item?.wallet));
    //   localStorage.setItem('currentDiamond', getTotalDiamond(res?.item?.wallet));
    //   GetInGameNames()
    //   if (res?.item?.isDetailsFilled === false) {
    //     window.location.href = '/sign-up';
    //   }

    //   if (res1?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || res1?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
    //     logout()


    //   let res_getUpdateProfile = await getUpdateProfile({
    //     currentPlatform: "webapp",
    //     currentVersion: "w2.0.0",
    //   })
    //   console.log('res_getUpdateProfile: ', res_getUpdateProfile)

    // }
    
    let allpage = [];
    allpage.push(window.location.pathname);
    localStorage.setItem("currentPage", JSON.stringify(allpage));

    let data = {
      skip: 0,
      limit: 100,
    };
    fetchUserDetails()
    let response = await homebanner(data); // comment by jaimin 
    if (response) { // comment by jaimin 
      // console.log('Banner: ', response)
      let temp = [];  // comment by jaimin 
      response?.list?.length && setAllData(response?.list); // comment by jaimin 
      // response?.list?.map(async (item, index) => {
      //   temp.push({
      //     img: url.imageUrl + item?.img?.default,
      //     gameName: item?.game?.name,
      //     gameType: item?.gameType?.name,
      //     createdAt: dateFormat(item?.createdAt),
      //     updatedAt: dateFormat(item?.updatedAt),
      //     impressions: item.impressions,
      //     url: item.url,
      //     _id: item._id, 
      //   });
      // });
      setLoader(false);
    } // comment by jaimin 

    let response_apk_screens = await apkScreensList({
      skip: 0,
      limit: 100,
    });

    let apk_screens_list = {};
    response_apk_screens?.list?.forEach((element, i) => {
      apk_screens_list[element.code] = element
    });

    // console.log('response_apk_screens: ', response_apk_screens)
    localStorage.setItem('apk_screens_list', JSON.stringify(apk_screens_list));
    // console.log(JSON.parse(localStorage.getItem('apk_screens_list')))

    // fetchTournamnetData()
    // let profileFavGames = await getUserFavouritesGames();
    // if(profileFavGames){
    //   localStorage.setItem(constants.profileFavGames,JSON.stringify(profileFavGames));
    // }
  };


  // const fetchTournamnetData = async () => {
  //   // setLoader(true);
  //   let data = {
  //     skip: 0,
  //     limit: 100,
  //     filter: {
  //       isFeatured: true
  //     }
  //   };
  //   let response = await getTournamentList(data);
  //   if (response) {
  //     // //console.log('Featured response: ', response)
  //     let temp = [];
  //     response?.list?.length &&
  //       response?.list?.map(async (item, index) => {
  //         temp.push({
  //           allData: item,
  //           img: url.imageUrl + item?.featuredImage?.default,
  //           coin: url.imageUrl + item?.currency?.img?.default,
  //           id: item?._id,
  //           title: item?.title,
  //           gameId: item?.game?._id,
  //           gameName: item?.game?.name,
  //           gameType: item?.gameType?.name,
  //           gameTypePlayers: item?.gameType?.players, // not present
  //           gameTypeExtraPlayers: item?.gameType?.extraPlayers, // not present
  //           createdAt: dateFormat(item?.createdAt),
  //           updatedAt: dateFormat(item?.updatedAt), // not present
  //           prizePool: item.prizePool,
  //           currency: item.currency,

  //           earnPerKill: item.eventRateAvg, // not present
  //         });
  //       });
  //     setAllTournamentData(temp);
  //     // setLoader(false);
  //   }
  // };

  const GetInGameNames = async () => {
    // var user_data = JSON.parse(localStorage.getItem(constants.profileLite));

    // let payload = {
    //   "search": {
    //     "user": user_data?.item?.user_id,
    //   }
    // }

    // let userInGameNameResponse = await getUserInGameName(payload)
    // console.log("userInGameNameResponse", userInGameNameResponse);
    // if (userInGameNameResponse?.success)
    //   localStorage.setItem("userInGameNames", JSON.stringify(userInGameNameResponse.data));
  }

  const onCollectRewards = async () => {
    setIsBusy(true);
    let payload = { dailyReward: selectedId };
    let res = await getCollectDailyRewards(payload);
    if (res) {
      let profileLiteResponse = await getUserProfileLite();
      //let res1 = await getUserProfile();
      if (profileLiteResponse) {
        setIsBusy(false);
        localStorage.setItem(constants.profileLite, JSON.stringify(profileLiteResponse));
        localStorage.setItem('currentCoin', getTotalCoin(profileLiteResponse?.item?.wallet));
        localStorage.setItem('currentDiamond', getTotalDiamond(profileLiteResponse?.item?.wallet));

        let profileBasicResposne = await getUserBasicsDetails();
        if(profileBasicResposne){
          localStorage.setItem(constants.profileBasics, JSON.stringify(profileLiteResponse));
        }
        // if (res?.item?.isDetailsFilled === false) {
        //   window.location.href = '/sign-up';
        // }

        // let res_getUpdateProfile = await getUpdateProfile({
        //   currentPlatform: "webapp",
        //   currentVersion: "w2.0.0",
        // })
        toast.success(t('daily_rewards_collected'), {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // console.log('res_getUpdateProfile: ', res_getUpdateProfile)
      } else {
        setIsBusy(false);
      }

      setShowDailyRewards(false);
    }
  };

  // const getdobnstate = (val) => {
  //   setdobnstate(val);
  //   val === false && setjoinpopup(false);
  // };

  const getAvatarInfo = (item) => {
    setAvatarData(item);
    setShowAvatarInfo(true);
  };

  const getShowContacts = (val) => {
    setShowContacts(val);
  };

  const getShowPopupYes = (val) => {
    setShowPopupYes(val);
  };

  // const mounted = useRef();
  // // This useEffect() for componentDidUpdate()
  // useEffect(() => {
  //   // console.log(mounted.current)
  //   if (!mounted.current) {
  //     // do componentDidMount logic
  //     mounted.current = true;
  //   } else {
  //     // do componentDidUpdate logic
  //     handleTextAnimation();
  //   }
  // });

  const onChangeBannerAdOwlCarousel = (e) => {
    // console.log(e.item.index)

    if (e.item.index > 0 && e.item.index <= allData.length) {
      let data = {
        type: "1",
        bannerAd: allData[parseInt(e.item.index) - 1]._id,
        platform: "webapp",
      }
      // console.log('View: ', data);

      bannerAdLogAPICall(data);
    }
  };

  const onClickBannerAdOwlCarousel = (value) => {
   //  console.log("on click banner",value)

    let data = {
      type: "2",
      banner: value._id,
      platform: "webapp",
    }
    // console.log('Click: ', data);
     bannerAdLogAPICall(data);
    if (value.url === "" || value.url === null) {
      var url_redirection = bannerRedirection(value);
    //  console.log('Banner URL: ', url_redirection);
      window.location.href = url_redirection;
    } else {
      window.open(value.url, '_blank');
    }
  };

  const bannerAdLogAPICall = async (data) => {
    bannerAdLog(data);
  }

  return (
    <Fragment>
      {loader ? <Loader /> : ""}
      {/* ******************** comment by jaimin start******************* */}
      {/* {
        showLanguagePopup === true &&
        <LanguagePopup onClickCancelIconSL={setShowLanguagePopup} />
      } */}
      {/* ******************** comment by jaimin end ******************* */}
      
      {dobnstate ? (
        <DobNState dobnstate={getdobnstate} />
      ) : (
        <div className="inner-wrap">
          <Header navMenuClick={props.navMenuClick} />
          {tag_tournamentJoin && createAfterApplyCodePopup()}
      {/* {console.log('*******************', tag_tournamentJoin)} */}
      {scrimJoin  && createScrimApplyCodePopup()}
      {constestJoin && createAfterApplyCodePopup()}
      {showTeam === true && (
          <ScrimSquadRegistration
          players={players.length} 
          setShowTeam ={showteam}
          />
        )}
      {showConfirm === true && (
                  <div className="tc_popup_bg">
                    <div
                      className="custom-popup rules confirm custom_scroll join show scrim-applyCode-Popup"
                      id="contact_popup"
                    >
                      <div className="popup-head">
                        {/* <marquee> */}
                        <h3> {scrimData?.title} </h3>
                        {/* </marquee> */}

                        <button
                          className="close-popup"
                          onClick={() => setShowConfirm(false)}
                        >
                          <img src={Close} alt="" />
                        </button>
                      </div>
                      <div className="popup-body bg-yellow">
                        <div className="all_sub_screens_div_related_to_ads">
                          <p className="game_det">
                            <span>{scrimData?.gameType?.name}</span>
                            <span>
                              {getProfiledateFormat(scrimData?.date)}{" "}
                              {}
                              {formatAMPM(new Date(scrimData?.time))}
                            </span>
                          </p>
                          <p className="game_det">
                            <span>
                              Hosted By: {scrimData?.hostedBy}
                            </span>
                            <span></span>
                          </p>
                          <button
                          className="btn btn-dark main-btn next scrim-tournament-btn"
                          onClick={() => onJoinScrim()}
                        >
                          JOIN CONTEST{" "}
                          <img src={rightArrow} alt="" className="mCS_img_loaded" />
                        </button>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                )}
          <div
            className="body mCustomScrollbar _mCS_1"
            id={
              joinpopup === false ||
                showDailyRewards === true ||
                showAvatarInfo === true ||
                showContacts === true ||
                showPopupYes === true
                ? "popup_show"
                : ""
            }
          >
            <div className="all_main_screens_div_related_to_ads">

              {/* Banner */}
              {/* ******************** comment by jaimin start ******************* */}
              <div className="">
                <OwlCarousel
                  className="main-slider owl-carousel owl-theme"
                  autoWidth={false}
                  items={1}
                  center={true}
                  loop={true}
                  margin={0}
                  stagePadding={25}
                  autoplay={true}
                  autoplayTimeout={10000}
                  dots={false}
                  onChange={(e) => onChangeBannerAdOwlCarousel(e)}
                >
                  {allData?.map((item, index) => {
                    return (
                      <div className="item" key={index} style={{ cursor: 'pointer', }}
                        onClick={() => onClickBannerAdOwlCarousel(item)}
                      >
                        <img src={url.imageUrl + item?.img?.default} alt="" />
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
              {/* ******************** comment by jaimin end ******************* */}
              {
                premiumUser?.isPremium   && premiumUser?.isRenewRequired && userRenewDetailsData
                ?
                <div className='ps_renew' style={{ background: '#1c1c1ecc', border: '1px solid #c5a260' }}>
                <div className='ps_benefits_text' style={{ padding: '10px 0' }}>
                  <div className='layer1' >
                    <div className='ps_badge' >
                      {/* {console.log(JSON.parse(localStorage.getItem(constants.profileBasics))?.level?.level?.featuredImage)} */}
                      <img src={url.imageUrl + userRenewDetailsData?.level?.featuredImage?.default} style={{ width: '40px', height: '40px' }} />
                    </div>
                  </div>
                  <div className='layer2 renew_layer' >
                    <div>
                      <h2>{userRenewDetailsData?.gamerjiName}</h2>
                      <p className='renew_font'>{t('renew_now.premium_since')}: {moment(userRenewDetailsData?.startDate).format("Do MMM YYYY")}</p>
                      <p className='renew_font'>{t('renew_now.expires_on')}: {moment(userRenewDetailsData?.subscriptionEndDate).format("Do MMM YYYY")}</p>
                    </div>
                    
                    <button className='renew_font' onClick={()=> navigate(routehelp?.premiumSubscription)}>{t('renew_now.renew_now')}</button>
                  </div>


                </div>
              </div> 
               : <></>
              }
             
              {/* Featured Games */}
              <Featured
                dobnstate={getdobnstate}
                joinpopup={joinpopup}
                showContacts={getShowContacts}
                showPopupYes={getShowPopupYes}
              />

              {/* Allgames */}

              <Allgames />

              {/* ******************** comment by jaimin start******************* */}
              {showDailyRewards === true && (
                <div
                  className="custom-popup bg-primary home-featured show reward_height"
                  id="contact_popup"
                >
                  <div className="popup-head ">
                    <h3>{t('right_hand_drop_down.daily_login_rewards')}</h3>
                    <button
                      className="close-popup"
                      onClick={() => setShowDailyRewards(false)}
                    >
                      <img src={Close} alt="" />
                    </button>
                  </div>
                  <div className="popup-body">
                    {rewardsList?.map((item, index) => {
                      var current =
                        parseInt(currentActiveDay) > parseInt(item?.day)
                          ? true
                          : false;
                      return (
                        <div
                          className={`row reward_main ${selectedId === item._id ? "active_reward" : ""}`}
                          key={index}
                        >
                          <div className="col-2 pr-1 reward_first mb-0">
                            <p>{t('Profile.day')} - {item?.day}</p>
                          </div>
                          <div className="col-2 pr-1 reward_second mb-0">
                            <h5 className="mb-0">
                              {item?.avatar ? (
                                <img
                                  src={url.imageUrl + item?.avatar?.img?.default}
                                  className="reward_img"
                                />
                              ) : (
                                <p> {item?.amount} </p>
                              )}
                            </h5>
                          </div>
                          <div
                            className="col-4 pr-1 reward_third mb-0"
                            style={{
                              borderRight:
                                selectedId === item._id || current === true
                                  ? "1px solid #ccc"
                                  : "unset",
                            }}
                          >
                            {item?.avatar ? (
                              <small>
                                {item?.avatar?.name}&nbsp;&nbsp;&nbsp;
                                <i
                                  className="fa fa-info"
                                  onClick={() => getAvatarInfo(item?.avatar)}
                                />
                              </small>
                            ) : (
                              <small> {item?.name} </small>
                            )}
                          </div>
                          <div className="col-4 pr-1 reward_fourth mb-0">
                            {current === true && (
                              <div className="inner last_inner">
                                <img src={Checkmark} alt="" /> {t('Profile.claimed')}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <button
                    className="btn btn-dark main-btn next"
                    disabled={isbusy}
                    onClick={() => onCollectRewards()}
                  >
                    {t('Profile.collect_rewards')}{" "}
                    {!isbusy ? <img src={rightArrow} alt="" className="mCS_img_loaded" /> : <img src={roller} alt="" style={{ maxWidth: 30 }} />}
                  </button>
                </div>
              )}

              {/* ******************** comment by jaimin end ******************* */}


              {showAvatarInfo === true && (
                <div
                  className="custom-popup bg-primary home-featured show"
                  id="contact_popup"
                >
                  <div className="popup-head ">
                    <h3>Daily Login Rewards</h3>
                    <button
                      className="close-popup"
                      onClick={() => setShowAvatarInfo(false)}
                    >
                      <img src={Close} alt="" />
                    </button>
                  </div>
                  <div className="popup-body avatar_reward">
                    <img src={url.imageUrl + avatarData?.img?.default} />
                    <h2>{avatarData?.name}</h2>
                  </div>
                </div>
              )}
            </div>

            {/* ******************** comment by jaimin start ******************* */}
            {
               !JSON.parse(localStorage.getItem("premiumUser")) && 
            
            <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).allGames
              }
              screen_div_class_name='.all_main_screens_div_related_to_ads'
              screen_type='main'
            />
}
            {/* ******************** comment by jaimin end ******************* */}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default HomePage;
