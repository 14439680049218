import React, { useEffect, useState } from "react";
import Cancel from "../../assets/images/round_not_qualified.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Picker from "react-mobile-picker";
import { useTranslation, Trans } from "react-i18next";

function generateNumberArray(begin, month, year) {

  let array = [];
  let end = 30;
  if (month === 2) {
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      end = 29;
    } else end = 28;
  } else {
    if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
      end = 31;
    } else {
      end = 30;
    }
  }
  for (let i = begin; i <= end; i++) {
    array.push((i < 10 ? "0" : "") + i);
  }
  return array;
}
function generateArrayOfYears() {
  var min = new Date().getFullYear();
  var max = min + 10;
  var years = [];

  for (var i = min; i <= max; i++) {
    years.push(i);
  }
  return years;
}
function generateMonths(start) {
  var months = [];
  for (let i = start; i <= 12; i++) {
    months.push((i < 10 ? "0" : "") + i);
  }
  return months;
}
const DatePopUp = ({ cancel, getDate, showDateandTime }) => {
  console.log(new Date().getDate());
  let tday = new Date().getDate();
  let tmonth = new Date().getMonth();
  let tyear = new Date().getFullYear();
  let month = ("0" + (new Date().getMonth() + 1)).slice(-2);
  let date = ("0" + new Date().getDate()).slice(-2);

  const [showDate, setShowDate] = useState(false);
  const {t} = useTranslation()

  const [isCancel, setIsCancel] = useState(cancel);
  const [valueGroups, setValueGroups] = useState({
    year: new Date().getFullYear(),
    month: month.toString(),
    day: date.toString(),
  });
  const [datePicked, setDatePicked] = useState(
    valueGroups.day + "/" + valueGroups.month + "/" + valueGroups.year
  );
  const [optionGroups, setOptionGroups] = useState({
    year: generateArrayOfYears(),
    month: generateMonths(tmonth + 1),
    day: generateNumberArray(tday, tmonth + 1, tyear),
  });
  // console.log(optionGroups)
  const maskStyle = {
    display: isCancel ? "block" : "none",
  };

  const showError = (error) => {
    toast.error(error, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = (error) => {
    toast.success(error, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const isCancelHandler = () => {
    setIsCancel(false);
    showDateandTime();
  };
  const doneHandler = () => {
    console.log("clicked");
    console.log(valueGroups);
    setIsCancel(false);
    getDate(datePicked);

    // isCancelHandler()
  };
  const handleChange = (name, value) => {
    console.log("name value", name, value, "clicked");
    let selectedDate = { ...valueGroups, [name]: value };
    setDatePicked(
      selectedDate.day + "/" + selectedDate.month + "/" + selectedDate.year
    );
    console.log(datePicked);
    setValueGroups((preVaue) => ({ ...preVaue, [name]: value }));
    let { day, month, year } = valueGroups;
    console.log("name value", name, value, "clicked", day, month, year);

    if (name === "year" && value === tyear) {
      console.log("120", value, tyear);
      setOptionGroups({
        ...optionGroups,
        day: generateNumberArray(tday, tmonth + 1, tyear),
        month: generateMonths(tmonth + 1),
      });
    } else if (name === "year" && value > tyear) {
      console.log("120");
      setOptionGroups({
        ...optionGroups,
        day: generateNumberArray(1, tmonth + 1, tyear),
        month: generateMonths(1),
      });
    } else if (name === "month") {
      console.log("144", name, month, tmonth, value === tmonth + 1);
      if (parseInt(value) === tmonth + 1) {
        setOptionGroups({
          ...optionGroups,
          day: generateNumberArray(tday, tmonth + 1, tyear),
        });
      } else {
        setOptionGroups({
          ...optionGroups,
          day: generateNumberArray(1, tmonth + 1, tyear),
        });
      }

      console.log(valueGroups);
      // console.log( optionGroups)
    }
  };
  // console.log("156")
  return (
    // <h2>Hello</h2>
    <>
      {/* <div className="dobns_main_div" style={maskStyle}>
        <ToastContainer />
        <div className="dobns_popup">
          <div className="dobns_header">
            
            
          </div>

          <div className="dobns_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="dobns_body_scrollable_component">

                
                <div className="picker-modal-container" style={maskStyle}>
                     
                      <div className='picker-modal picker-modal-toggle popMobile'>
                        <div className="ios-datepicker">
                          <div className="ios-datepicker-title">Select Date</div>
                          
                          <div className="dobns_cancel_icon">
                                <img
                                    src={Cancel}
                                    onClick={
                                    isCancelHandler
                                    }
                                />
                            </div>
                            
                        </div>
                        
                        <Picker
                          optionGroups={optionGroups}
                          valueGroups={valueGroups}
                          onChange={handleChange} 
                          wheel='natural'/>
                         <div className="doneBtn"> <button onClick={doneHandler}>Done</button></div>
                      </div>
                      
                    </div> 
                </div>
           </div>

                
          </div>
        </div>
      </div> */}

      <div className="jvic_main_div" style={maskStyle}>
        <div className="jvic_popup">
          <div className="">
   
            <div className="dobns_select_date flex">
              {" "}
              <div className="ios-datepicker-title">{t('private_contest.select_date')}</div>
              <div className="dobns_cancel_icon">
                <img src={Cancel} onClick={isCancelHandler} />
              </div>
            </div>
            <Picker
              optionGroups={optionGroups}
              valueGroups={valueGroups}
              onChange={handleChange}
              wheel="natural"
            />
            <div className="doneBtn">
              {" "}
              <button onClick={doneHandler}>{t('crm.done')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatePopUp;
