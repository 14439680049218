import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";

import './world-of-esports.css';

export default class WOEIndividualFeaturedVideos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {

  }

  createWOEFeaturedVideos() {
    // console.log(this.props.individual_popular_videos);
    return (
      <div className="woe_individual_popular_video_col">
        <div className="d-flex justify-content-end mb-3">
          <svg
            onClick={(e) => this.props.onChangeWOEAllFeaturedVideosActiveScreen(0)}
            style={{ cursor: 'pointer', }}
            id="cancel_1_" data-name="cancel (1)" xmlns="http://www.w3.org/2000/svg" width="34.001" height="34" viewBox="0 0 34.001 34">
            <path id="Path_2963" data-name="Path 2963" d="M34,17A17,17,0,1,0,17,34,17,17,0,0,0,34,17Z" transform="translate(0 -0.003)" fill="#ef1f36" />
            <path id="Path_2964" data-name="Path 2964" d="M150.574,135.871l4.97-5.77a1.5,1.5,0,0,0-2.279-1.963l-4.676,5.428-4.676-5.428a1.5,1.5,0,1,0-2.279,1.963l4.97,5.77-4.97,5.77a1.5,1.5,0,0,0,2.279,1.963l4.676-5.428,4.676,5.428a1.5,1.5,0,0,0,2.279-1.963Z" transform="translate(-131.589 -118.87)" fill="#fff" />
          </svg>
        </div>
        <div className="woe_individual_popular_video_name">
          {this.props.individual_popular_videos.name}
        </div>
        <iframe className="woe_individual_popular_video_frame" src={this.props.individual_popular_videos.url} allowFullScreen></iframe>
      </div>
    )
  }

  render() {
    return (
      this.createWOEFeaturedVideos()
    );
  }
}
// export default WOEIndividualFeaturedVideos;
