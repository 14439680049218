import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import {
  transactionsList,
  transactionsSendInvoiceEmail,
  transactionsExport,
} from "../apis";
import url from '../constants/url';
import { getConvertionDateTime } from '../component/common';

import Previous from "../assets/images/previous.svg";
import Coin from "../assets/images/coin.png";
import myRecentTransactionImage from '../assets/images/ic_transactions.png';
import emailInvoice from '../assets/images/ic_email_invoice.png';
import downloadInvoice from '../assets/images/ic_download_invoice.png';
import { withTranslation } from 'react-i18next';

import './MyRecentTransactions.css';
import { faTty } from "@fortawesome/free-solid-svg-icons";
import Cookies from 'js-cookie'

class MyRecentTransactions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: '',
      mrt_active_tab: 0,

      mrt_coin_data: [],
      mrt_diamond_data: [],
      mrt_rmg_data: [],
      isLastPageCoin: false,
      isLastPageDiamond: false,
      loader1: false,
      loading: false,
      currentLang: Cookies.get('i18next') || 'EN'
    }

    this.onClickMRTIndTrnsComp = this.onClickMRTIndTrnsComp.bind(this);
    this.onClickEmailInvoice = this.onClickEmailInvoice.bind(this);
    this.onClickDownloadInvoice = this.onClickDownloadInvoice.bind(this);
  }

  componentDidMount() {
    let type = localStorage.getItem('myRecentTransactions');
    // let type = 'coin';
    this.setState({
      type: type,
    }, () => {
      switch (type) {
        case 'coin':
          this.transactionsListAPICall('coin', 0);
          this.transactionsListAPICall('diamond', 0);
          break;

        case 'rmg':
          this.transactionsListAPICall('rmg', 0);
          break;
      }
    });
  }

  onClickMRTIndTrnsComp(index, type) {
    let temp_array = [];
    if (type === 'coin')
      temp_array = this.state.mrt_coin_data;
    if (type === 'rmg')
      temp_array = this.state.mrt_rmg_data;
    if (type === 'diamond')
      temp_array = this.state.mrt_diamond_data;

    if (temp_array[index]?.isCollapse === false || temp_array[index]?.isCollapse === undefined)
      temp_array[index].isCollapse = true;
    else
      temp_array[index].isCollapse = false;

    if (type === 'coin')
      this.setState({ mrt_coin_data: temp_array, });
    if (type === 'rmg')
      this.setState({ mrt_rmg_data: temp_array, });
    if (type === 'diamond')
      this.setState({ mrt_diamond_data: temp_array, });
  }

  onClickEmailInvoice(id) {
    this.transactionsSendInvoiceEmailAPICall(id);
  }

  onClickDownloadInvoice(id) {
    this.transactionsExportAPICall(id);
  }

  async transactionsListAPICall(currencyType, skip) {

    this.setState({ loader1: true });

    let payload = {
      // date: "2022-06-02",
      currencyType: currencyType,
      skip: skip,
      limit: 10,
    }
    let response = await transactionsList(payload);
    //{
    //   currencyType: currencyType,
    //   dateRange: {
    //     from: moment().subtract(5, 'months').toISOString(),
    //     to: moment().toISOString(),
    //   },
    // }
    // );
    // console.log(`mrt-${currencyType}: `, {
    //   from: moment().subtract(5, 'months').toISOString(),
    //   to: moment().toISOString(),
    // })
    //console.log(`mrt-${currencyType}:===> `, response)
    if (response && response?.list) {
      if (response?.list.length < 10) {
        if (currencyType === 'diamond')
          this.setState({ isLastPageDiamond: true });
        else
          this.setState({ isLastPageCoin: true });


      }
      switch (currencyType) {
        case 'coin':
          //this.setState({ mrt_coin_data: response?.list });
          this.setState({
            mrt_coin_data: [...this.state.mrt_coin_data, ...response?.list]
          });
          break;
        case 'diamond':
          // this.setState({ mrt_diamond_data: response?.list });
          this.setState({
            mrt_diamond_data: [...this.state.mrt_diamond_data, ...response?.list]
          });
          break;
        case 'rmg':
          // this.setState({ mrt_rmg_data: response?.list });
          this.setState({
            mrt_rmg_data: [...this.state.mrt_rmg_data, ...response?.list]
          });
          break;
      }

    }

    this.setState({ loader1: false });

  }

  async transactionsSendInvoiceEmailAPICall(id) {
    this.setState({ loading: true });
    let response = await transactionsSendInvoiceEmail(id);
    //console.log('transactionsSendInvoiceEmail: ', response);
    if (response && response?.success === true) {
      this.setState({ loading: false });
      this.showSuccess(response?.item?.message);
    } else {
      this.setState({ loading: false });
      this.showError(response?.data?.errors?.[0]?.msg);
    }
  }

  async transactionsExportAPICall(id) {
    let response = await transactionsExport({});
    //console.log('transactionsExport: ', response);
  }

  showSuccess(success) {
    toast.success(success, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  showError(error) {
    toast.error(error, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  createTransactionsBox(data, currencyType) {
    //console.log("data", data);
    const { t } = this.props;

    return (
      data.length > 0 ?
        data.map((value, i) => {

          let previousItem = i != 0 && data[i - 1]
          let currentDate = moment(value.createdAt).format('Do MMMM YYYY')
          let previousDate = previousItem ? moment(previousItem.createdAt).format('Do MMMM YYYY') : ''

          //console.log("previousItem", previousItem);
          //console.log("currentDate", currentDate);
          //console.log("previousDate", previousDate);
          let style_collapse_box_comp = {};
          let style_ind_trns_comp = {};
          let style_ind_trns_txt_amount = {};
          let style_ind_trns_txt_title = {};
          let style_ind_trns_arrow_direction = {};
          let style_ind_trns_arrow_color = {};

          if (value?.isCollapse === true) {
            style_collapse_box_comp = { border: '1px solid var(--color_black)', };
            style_ind_trns_comp = { border: 'none', backgroundColor: 'var(--color_black)', };
            style_ind_trns_txt_amount = { color: 'var(--color_white)', };
            style_ind_trns_txt_title = { color: 'var(--color_white)', width:'40%'};
            style_ind_trns_arrow_direction = { transform: 'rotate(90deg)' };
            style_ind_trns_arrow_color = '#FFFFFF';
          } else {
            style_collapse_box_comp = { border: 'none', };
            style_ind_trns_comp = { border: '1px solid var(--color_color_input_text_border)', backgroundColor: 'var(--color_white)', };
            style_ind_trns_txt_amount = { color: 'var(--color_black)',minWidth:'80px', textAlign:'left' };
            style_ind_trns_txt_title = { color: 'var(--color_black)',width:"180px" };

            if(this?.state?.currentLang?.toLowerCase() == "ar"){
              style_ind_trns_arrow_direction = { transform: 'rotate(180deg)' };
            }
            else {
              style_ind_trns_arrow_direction = { transform: 'rotate(0deg)' };
            }

            style_ind_trns_arrow_color = '#070B28';
          }

          return (
            <>
              {previousItem && currentDate != previousDate &&
                <div style={{ marginTop: 10, marginBottom: 10 }}>{currentDate}</div>
              }
              {i == 0 &&
                <div style={{ marginTop: 10, marginBottom: 10 }}>{currentDate}</div>

              }
              {/* {value.paymentTypeId === 4 && value.amount === 0 ?
                <></>
                : */}
                 <div className="mrt_individual_transaction_box" key={i}>
                  <div className="mtr_collapse_box_component" style={style_collapse_box_comp}>

                    <div className="mrt_individual_transaction_component"
                      onClick={() => this.onClickMRTIndTrnsComp(i, currencyType)}
                      style={style_ind_trns_comp}
                    >
                      <img className="mrt_individual_transaction_image" src={myRecentTransactionImage} alt="" />
                      <div className="mrt_individual_transaction_text_amount" style={style_ind_trns_txt_amount}>
                        {String(value?.transactionType) === '1' && "+ "}
                        {String(value?.transactionType) === '2' && value?.amount > 0 ? "-" : ""}
                        {value?.paymentTypeId === 2 && value?.amount === 0 ? 'Free' : value?.amount}
                        {
                          (currencyType === 'coin' || currencyType === 'diamond') &&
                          <img className="mrt_individual_transaction_gamerji_coin_image"
                            src={`${url.imageUrl}${value?.currency?.img?.default}`}
                          // src={Coin}
                          />
                        }
                        {currencyType === 'rmg' && value?.currency?.symbol}
                      </div>
                      <div className="mrt_individual_transaction_text_title" style={style_ind_trns_txt_title}>
                        {value?.paymentType}
                 
                      </div>
                      <span className="mrt_individual_transaction_arrow_component" style={style_ind_trns_arrow_direction}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21.493"
                          height="18.154"
                          viewBox="0 0 21.493 18.154"
                        >
                          <g
                            id="ico"
                            transform="translate(21.493 18.151) rotate(-180)"
                          >
                            <path
                              id="union"
                              d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                              transform="translate(0 0)"
                              fill={style_ind_trns_arrow_color}
                            />
                          </g>
                        </svg>
                      </span>
                    </div>

                    {
                      value?.isCollapse === true &&
                      <div className="mtr_collapse_box">
                        <div className={`mtr_collapse_box_1_${this.state.type}`}>
                          <div className="mtr_collapse_box_row">
                            <div className="mtr_collapse_box_col_text">{t('coin_store.transaction_id')} : </div>
                            <div className="mtr_collapse_box_col">{value?.transactionUniqueID}</div>
                          </div>

                          <div className="mtr_collapse_box_row">
                            <div className="mtr_collapse_box_col_text">{t('coin_store.transaction_date')} : </div>
                            <div className="mtr_collapse_box_col">{getConvertionDateTime(value?.createdAt)}</div>
                          </div>
                          {value?.contestDetails?.game &&

                            <div className="mtr_collapse_box_row">
                              <div className="mtr_collapse_box_col_text">{t('coin_store.game')} : </div>
                              <div className="mtr_collapse_box_col">{value?.contestDetails?.game?.name} - {value?.contestDetails?.gameType?.name}</div>
                            </div>
                          }
                        </div>


                      </div>
                    }
                  </div>

                  {
                    value?.isCollapse === true && this.state.type === 'rmg' && (value.paymentTypeId == 17 || value.paymentTypeId == 20) &&
                    <div className="mtr_collapse_box_2">
                      <div className="mtr_collapse_box_row_button">
                        {
                          this.state.loading === true ?
                            <div className="mtr_collapse_box_col_button">
                              Loading...
                            </div>
                            :
                            <div className="mtr_collapse_box_col_button" onClick={() => this.onClickEmailInvoice(value?._id)}>
                              <img className="mrt_collapse_box_image" src={emailInvoice} alt="" />
                              Email Invoice
                            </div>
                        }
                      </div>
                    </div>
                  }

                </div>
              {/* } */}
            </>
          )
        })
        :
        <div className="center">No Transactions Found</div>
    )
  }

  render() {
    const {t} = this.props;

    return (
      <div className="mrt_main_div">
        <ToastContainer />
        <div className="mrt_header">
          <div className="mrt_back_arrow_component">
            <div className="back-btn">
              <Link to={this.state.type === 'coin' ? '/coin-store' : '/account'}>
                <img src={Previous} alt="" />
              </Link>
            </div>
          </div>
          <div className="mrt_header_text">
          {t('reward_store.recent_transactions')}
          </div>
          <div className=""></div>
        </div>

        {this.state.type === 'coin' &&
          <>
            <div className="mrt_sub_header">
              <div className={`mrt_tab_component 
            ${this.state.mrt_active_tab === 0 ? 'mrt_tab_component_active' : ''}`}
                onClick={() => {
                  this.state.mrt_coin_data.length < 0 &&
                    this.setState({ isLastPageCoin: false });

                  this.setState({ mrt_active_tab: 0, })
                }}
              >
                <div>{t('coin_store.coin')}</div>
              </div>
              <div className={`mrt_tab_component 
            ${this.state.mrt_active_tab === 1 ? 'mrt_tab_component_active' : ''}`}
                onClick={() => {
                  this.state.mrt_diamond_data.length < 0 &&
                    this.setState({ isLastPageDiamond: false });

                  this.setState({ mrt_active_tab: 1, })
                }}
              >
                <div>{t('coin_store.diamond')}</div>
              </div>
            </div>
          </>
        }

        <div className="mrt_body">

          <div className="all_main_screens_div_related_to_ads">
            <div className="mrt_body_scrollable_component">



              {this.state.mrt_active_tab === 0 && this.state.type === 'coin' && this.createTransactionsBox(this.state.mrt_coin_data, 'coin')}
              {this.state.mrt_active_tab === 0 && this.state.type === 'rmg' && this.createTransactionsBox(this.state.mrt_rmg_data, 'rmg')}

              {this.state.mrt_active_tab === 1 &&

                this.createTransactionsBox(this.state.mrt_diamond_data, 'diamond')
              }

              {
                !this.state.isLastPageCoin && (
                  <div className="load_more text-center" style={{ marginTop: 10, marginBottom: 10 }}>
                    <button className="btn btn-primary" onClick={() => {
                      // gameId === "all" ? getTournamentListAPICall() : getContestListAPICall(gameId, page + 1);

                      { this.state.mrt_active_tab === 0 && this.state.type === 'coin' && this.transactionsListAPICall('coin', this.state.mrt_coin_data.length) }
                      { this.state.mrt_active_tab === 0 && this.state.type === 'rmg' && this.transactionsListAPICall('rmg', this.state.mrt_rmg_data.length) }

                      // { this.state.mrt_active_tab === 1 && this.transactionsListAPICall('diamond', this.state.mrt_diamond_data.length) }

                    }
                    }>
                      {/* {this.state.mrt_active_tab === 1 && this.state.mrt_diamond_data.length > 0 ?
                        this.state.loader1 === true ? "Loading ..." : "Load More"
                        : <></>
                      } */}
                      {this.state.mrt_active_tab === 0 && this.state.type === 'coin' && this.state.mrt_coin_data.length > 0 ?
                        this.state.loader1 === true ? `${t('contest.loading')}...` : t('tournaments.load_more')
                        : <></>
                      }
                      {this.state.mrt_active_tab === 0 && this.state.type === 'rmg' && this.state.mrt_rmg_data.length > 0 ?
                        this.state.loader1 === true ? `${t('contest.loading')}...` : t('tournaments.load_more')
                        : <></>
                      }
                    </button>
                  </div>
                )
              }
              {
                !this.state.isLastPageDiamond && (
                  <div className="load_more text-center" style={{ marginTop: 10, marginBottom: 10 }}>
                    <button className="btn btn-primary" onClick={() => {
                      // gameId === "all" ? getTournamentListAPICall() : getContestListAPICall(gameId, page + 1);

                      // { this.state.mrt_active_tab === 0 && this.state.type === 'coin' && this.transactionsListAPICall('coin', this.state.mrt_coin_data.length) }
                      // { this.state.mrt_active_tab === 0 && this.state.type === 'rmg' && this.transactionsListAPICall('rmg', this.state.mrt_rmg_data.length) }

                      { this.state.mrt_active_tab === 1 && this.transactionsListAPICall('diamond', this.state.mrt_diamond_data.length) }

                    }
                    }>
                      {this.state.mrt_active_tab === 1 && this.state.mrt_diamond_data.length > 0 ?
                        this.state.loader1 === true ? `${t('contest.loading')}...` : t('tournaments.load_more')
                        : <></>
                      }
                      {/* {this.state.mrt_active_tab === 0 && this.state.type === 'coin' && this.state.mrt_coin_data.length > 0 ?
                        this.state.loader1 === true ? "Loading ..." : "Load More"
                        : <></>
                      }
                      {this.state.mrt_active_tab === 0 && this.state.type === 'rmg' && this.state.mrt_rmg_data.length > 0 ?
                        this.state.loader1 === true ? "Loading ..." : "Load More"
                        : <></>
                      } */}
                    </button>
                  </div>
                )
              }
            </div>
          </div>
          {/* <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).worldOfEsports
            }
            screen_div_class_name='.all_main_screens_div_related_to_ads'
            screen_type='main'
          /> */}
        </div>
      </div>
    )
  }
}

export default withTranslation()(MyRecentTransactions);