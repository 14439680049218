import React, { Fragment } from "react";
function GoHome(props) {
  window.location.href = '/';
  return (
    <Fragment>
      <h2>Not Found</h2>
    </Fragment>
  );
}

export default GoHome;
