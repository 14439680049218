import React from "react";
import "react-intl-tel-input/dist/main.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import validator from "validator";

import {
  checkEmail,
  getUserProfile,
  getUserProfileLite,
} from "../apis";
import url from '../constants/url';

import Previous from "../assets/images/previous.svg";
import RightArrowLight from "../assets/images/right-arrow.svg";
import EmailImage from "../assets/images/ic_email.png";
import MobileImage from "../assets/images/ic_mobile.png";
import VerifiedImage from "../assets/images/ic_phone_verified.png";
import './VerifyMobileEmail.css';
import { constants } from "../constants/constants";

export default class VerifyMobileEmail extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

      phone: '',
      email: '',
      otpNumber: '',

      timer: 30,

      tag_mobile_verified: false,
      tag_email_verified: false,
      tag_otp_popup: false,
    }

    this.onClickVerify = this.onClickVerify.bind(this);
  }

  componentDidMount() {
    let profile_details = JSON.parse(localStorage.getItem(constants.profileLite));
    // console.log('profile_details: ', profile_details);

    let email = '';
    if (profile_details?.item?.isEmailVerified)
      email = profile_details?.item?.email;

    this.setState({
      email: email,
      phone: profile_details?.item?.phone,
      tag_mobile_verified: profile_details?.item?.isMobileVerified,
      tag_email_verified: profile_details?.item?.isEmailVerified,
    });
    // this.fetchUserProfileData();
  }

  startTimer() {
    setInterval(() => this.setState({ timer: this.state.timer - 1 }), 1000);
  }

  onClickVerify() {
    if (this.state.email === '' || this.state.email === null || !validator.isEmail(this.state.email)) {
      this.showError('Please enter valid email address!');
    } else {
      this.checkEmailRequestAPICall();
    }
  }

  onClickSubmit() {
    this.checkEmailValidateAPICall()
  }

  async checkEmailRequestAPICall() {
    var response = await checkEmail({
      email: this.state.email,
      type: "otpRequest",
    });

    if (response && response?.success) {
      this.setState({
        timer: 30,
        tag_otp_popup: true,
      });
      this.startTimer();
    }
  }

  async checkEmailValidateAPICall(type) {
    var response = await checkEmail({
      email: this.state.email,
      type: "validateOTP",
      otp: this.state.otpNumber,
    });

    if (response && response?.success) {
      let profileLiteResponse = await getUserProfileLite();

      localStorage.setItem(constants.profileLite, JSON.stringify(profileLiteResponse));


      let profile_details = profileLiteResponse;
      // console.log('profile_details: ', profile_details);

      let email = '';
      if (profile_details?.item?.isEmailVerified)
        email = profile_details?.item?.email;

      this.setState({
        email: email,
        phone: profile_details?.item?.phone,
        tag_mobile_verified: profile_details?.item?.isMobileVerified,
        tag_email_verified: profile_details?.item?.isEmailVerified,
      });

      this.setState({ tag_otp_popup: false, });
    } else {
      if (response) {
        this.showError(response?.data?.errors?.[0]?.msg);
      }
    }
  }

  showError(error) {
    toast.error(error, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  createOTPPopup() {
    return (
      <div className="vme_otp_popup_main_div">
        <div className="vme_otp_popup_header">
          <div className="vme_otp_popup_back_arrow_component">
            <div className="back-btn" onClick={() => { this.setState({ tag_otp_popup: false, }) }}
              style={{ cursor: 'pointer', }}
            >
              <img className="vme_image" src={Previous} alt="" />
            </div>
          </div>
          <div className="vme_otp_popup_header_text">
            Verify Email
          </div>
          <div className=""></div>
        </div>

        <div className="vme_otp_popup_body">

          <div className="all_main_screens_div_related_to_ads">
            <div className="vme_otp_popup_body_scrollable_component">

              <div className="vme_otp_title">Enter Verification Code</div>

              <div className="vme_otp_text_component">
                <div className="vme_otp_text">We have sent an verification code on your Email</div>
                <div className="vme_otp_text_bold">{this.state.email}</div>
                <div className="vme_otp_text">Enter the verification code you received</div>
              </div>

              <input
                className="otp_input_single_box"
                placeholder="Enter Verification Code" type="number"
                onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                value={this.state.otpNumber}
                onChange={(e) => { e.target.value.length <= 6 && this.setState({ otpNumber: e.target.value, }) }}
              />

              <button className="vme_submit_button" onClick={() => this.onClickSubmit()}>
                <div>Submit</div>
                <img src={RightArrowLight} className="" />
              </button>

              <div>
                <div className="vme_otp_text">Didn't receive the Verification Code?</div>
                {this.state.timer < 0 ?
                  <div className="vme_otp_text">
                    <a className="vme_otp_text text-secondary pointer" onClick={() => { this.checkEmailRequestAPICall("otpRequest") }}>
                      <b>
                        <u>Resend Verification Code</u>
                      </b>
                    </a>
                  </div>
                  :
                  <div className="vme_otp_text">
                    Request for a new one in {this.state.timer} Seconds
                  </div>
                }
              </div>


            </div>
          </div>
          {/* <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).worldOfEsports
            }
            screen_div_class_name='.all_main_screens_div_related_to_ads'
            screen_type='main'
          /> */}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="vme_main_div">
        <ToastContainer />
        {this.state.tag_otp_popup === true && this.createOTPPopup()}
        <div className="vme_header">
          <div className="vme_back_arrow_component">
            <div className="back-btn">
              <Link to='/account'>
                <img src={Previous} alt="" />
              </Link>
            </div>
          </div>
          <div className="vme_header_text">
            Mobile & Email
          </div>
          <div className=""></div>
        </div>

        <div className="vme_body">

          <div className="all_main_screens_div_related_to_ads">
            <div className="vme_body_scrollable_component">

              <div className="vme_account_details_component">
                <div className="d-flex">
                  <div className="vme_image_component">
                    <img className="vme_image" src={MobileImage} />
                  </div>
                  <div className="vme_account_details">
                    <div className="vme_account_details_title">Phone is verified</div>
                    <div className="vme_account_details_data">{this.state.phone}</div>
                  </div>
                </div>
                <img className="vme_image" src={VerifiedImage} />
              </div>

              <div className="vme_account_details_component">
                <div className="d-flex">
                  <div className="vme_image_component">
                    <img className="vme_image" src={EmailImage} />
                  </div>
                  <div className="vme_account_details">
                    <div className="vme_account_details_title">
                      {
                        this.state.tag_email_verified === true ?
                          'Email is verified' : 'Verify Email'
                      }
                    </div>
                    <div className="vme_account_details_data">
                      {
                        this.state.tag_email_verified === true ?
                          this.state.email : ''
                      }
                    </div>
                  </div>
                </div>
                {
                  this.state.tag_email_verified === true ?
                    <img className="vme_image" src={VerifiedImage} /> : ''
                }
              </div>

              {
                this.state.tag_email_verified !== true &&
                <div className="d-flex flex-column w-100 h-100 align-items-center">
                  <div className="vme_or">- OR -</div>

                  <div className="vme_input_component">
                    <label className="vme_input_label">Email</label>
                    <input className="vme_input"
                      placeholder="Enter Your Email ID"
                      value={this.state.email}
                      onChange={(e) => { this.setState({ email: e.target.value, }) }}
                    />
                    <div className="vme_input_note mt-4">
                      We will send you an verification code on this email.
                    </div>
                  </div>

                  <button className="vme_submit_button mt-auto" onClick={() => this.onClickVerify()}>
                    <div>Verify</div>
                    <img src={RightArrowLight} className="" />
                  </button>
                </div>
              }

            </div>
          </div>
          {/* <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).worldOfEsports
            }
            screen_div_class_name='.all_main_screens_div_related_to_ads'
            screen_type='main'
          /> */}
        </div>
      </div>
    )
  }
}