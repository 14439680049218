import { useState, Fragment,useEffect } from "react";
import { getScrimRate } from "../../apis";
import R1 from "../../assets/images/r-1.svg";
import R2 from "../../assets/images/r-2.svg";
import R3 from "../../assets/images/r-3.svg";
import R4 from "../../assets/images/r-4.svg";
import R5 from "../../assets/images/r-5.svg";
import { useTranslation } from "react-i18next";
import { constants } from "../../constants/constants";

export const Rate = ({ id , rating}) => {
  const [rate, setRate] = useState(rating);
  const {t} = useTranslation()

  useEffect(() => {
   //console.log("rate", rating)

  //   const savedId = localStorage.getItem("scrimIdrating");
  //  console.log(savedId);
  //  console.log(id)
  //   const savedRate = localStorage.getItem("userRating");
  //   if (savedId === id) {
  //     setRate(parseInt(savedRate));
  //   } else {
  //     setRate(0);
  //   }
  }, []);




  const onChangeRate = async (rate) => {
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    
    setRate(rate);
    localStorage.setItem("userRating", rate.toString());
    localStorage.setItem("scrimIdrating", id);
    const data = { rating: rate, scrim: id, user: user_data?.item?.user_id };
    //console.log(id)
   
    let res = await getScrimRate(data);
    //console.log(res);
    if (res) {
    }
  };

  return (
    <div>
      {rate === 0 || rate == null ? (
        <Fragment>
          <h2 className="title mb-3 mt-4">
          { t('contest_details.rate_gamerji_e_sports_private_limited') }{" "}
          </h2>
          <div className="r-wrap">
            <div className="r-image">
              <div className="img" onClick={() => onChangeRate(1)}>
                <img src={R1} alt="" />
              </div>
              <div className="img" onClick={() => onChangeRate(2)}>
                <img src={R2} alt="" />
              </div>
              <div className="img" onClick={() => onChangeRate(3)}>
                <img src={R3} alt="" />
              </div>
              <div className="img" onClick={() => onChangeRate(4)}>
                <img src={R4} alt="" />
              </div>
              <div className="img" onClick={() => onChangeRate(5)}>
                <img src={R5} alt="" />
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <h2 className="title mb-3 mt-4">{ t('contest_details.you_provided_ratings') } </h2>
          <div className="r-wrap">
            <div className="text-center">
              <div className="img">
                <img
                  src={
                    rate === 1
                      ? R1
                      : rate === 2
                      ? R2
                      : rate === 3
                      ? R3
                      : rate === 4
                      ? R4
                      : R5
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
