import { useProfile } from "./ProfileContext";
import { Link } from "react-router-dom";
import { colors } from "../../constants/index.js";
import {InsightsIcon}  from "../../assets/images/index.js"
import { Line } from "react-chartjs-2";
export const Stats = () => {

    const {gameStatsList,options} = useProfile()
    return (
        <div className="tab-pane active">
                {gameStatsList && gameStatsList.length > 0 ? (
                  gameStatsList.map((item, index) => {
                    return (
                      <div
                        className="gap-3"
                        style={{ width: "85%", margin: "0 auto 10px auto" }}
                        key={index}
                      >
                        <div className="row">
                          <div className="col-8">
                            <h5 className="game_name_h5">{item?.game?.name}</h5>
                            <h6>{item?.uniqueIGN}</h6>
                          </div>
                          <div className="col-2">
                            <h5 className="game_name_h5">Rank</h5>
                            <h6>{item?.avgRates}</h6>
                          </div>
                          <div className="col-2">
                            <Link
                              to={`/profile-insights/${item?.game?._id}`}
                              className="game_insights_hover"
                              style={{ color: colors.white, fontSize: 13 }}
                            >
                              <img
                                style={{
                                  height: 32,
                                  width: 32,
                                  marginLeft: "14px",
                                }}
                                src={InsightsIcon}
                              />
                              <h6>Insights</h6>
                            </Link>
                          </div>
                        </div>
                        <div className="row profile-stats-box">
                          <div className="col-1 p-0">
                            {" "}
                            <div className="chart-left-text">Played</div>
                          </div>
                          <div className="col-11 p-0">
                            {" "}
                            <p>Played : {item?.played}</p>
                            <Line options={options} data={item.data} />
                            <p className="chart-bottom-text">Last 14 Days</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center" style={{}}>
                    <div
                      style={{
                        marginTop: "10px",
                        padding: "20px",
                        color: "#F92C2C",
                        fontSize: "18px",
                        fontWeight: "1000",
                      }}
                    >
                      No stats available
                    </div>
                  </div>
                )}
              </div>
    )
}