import React from "react";
import { useState } from "react";
import Loader from "../loader";
import Previous from "../../assets/images/previous.svg";
import { Link, useNavigate } from "react-router-dom";
// import Background from "../../assets/images/backgroundSubscription.png";
import Logo from "../../assets/images/logo.svg";
// import Coin from "../../assets/images/coin.png";
import { url } from "../../constants";
import { useEffect } from "react";
import {
  getSubscriptionTypeList,
  getSubscriptionUserDetails,
  createXsollaSubscriptionTransaction,
  createXsollaUnSubscriptionTransaction
} from "../../apis";
// import { func } from "../../logDetails";
import moment from "moment";
import { checkDobNstateConditions } from "../common";
import { ToastContainer, toast } from "react-toastify";
import DobNStates from "../../screens/dobnstate";
import { useTranslation, Trans } from "react-i18next";
import Cookies from 'js-cookie'
import { constants } from "../../constants/constants";
import { companyId } from "../../constants/url";

const SubscriptionUI = () => {
  const [loading, setLoading] = useState(false);
  const [tabTypes, setTabTypes] = useState([]);
  const [active, setActive] = useState(null);
  const [premiumDetails, setPremiumDetails] = useState({});
  const [nonPremiumDeatils, setNonPremiumDeatils] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [premiumUser, setPremiumUser] = useState(
    JSON.parse(localStorage.getItem("premiumUser"))
  );
  const [benefits, setBenefits] = useState([]);
  const [token, setToken] = useState("");

  const [amount, setAmount] = useState(0);
  const [symbol, setSymbol] = useState("₹");
  const [paymentId, setPaymentId] = useState("");
  const [tagShowDobNstate, setTagShowDobNstate] = useState(false);
  const dobNstateScreenName = "subscription-buy-pack";
  const userId = JSON.parse(localStorage.getItem(constants.profileLite))?.item
    ?.user_id;
  const { t } = useTranslation()
  const currentLang = Cookies.get('i18next') || 'EN'

  useEffect(async () => {
    setLoading(true);
    let res = null
    try {
      if (premiumUser) {
        res = await getSubscriptionUserDetails(userId);
        // func(res, "SubscriptionUi 23");
        if (res?.success) {
          setPremiumDetails(res?.data[0]?.param);
          // func(moment(res?.data[0]?.param?.subscription?.startDate).format("Do MMMM YYYY"))
          setStartDate(
            moment(res?.data[0]?.param?.subscription?.startDate).format(
              "Do MMM YYYY"
            )
          );
          setEndDate(
            moment(res?.data[0]?.param?.subscription?.endDate).format(
              "Do MMM YYYY"
            )
          );
          if (premiumUser) setBenefits(res?.data[0]?.param?.benefits);
        }
      }

      if (!premiumUser || res?.data[0]?.param?.isRenewRequired) {
        // console.log("cuntryid", JSON.parse(localStorage.getItem('countryId')), localStorage.getItem('companyId'))
        let payload = {
          country: JSON.parse(localStorage.getItem("countryId")),
          company: companyId,
        };
        const types = await getSubscriptionTypeList(payload);
        if (types?.success) {
          setNonPremiumDeatils(types?.data[0]?.param?.data);
          // func(types?.data[0]?.param?.data, "types 47");
          let ty = [];

          types?.data[0]?.param?.data.forEach((element) => {
            // ty.push(element?.packs[0]?.packName)

            // if (element?.packs[0]?.packName == "1 Month") {
              // console.log(element?.packs[0]?.amount, "packs");

              setActive(element?.packs[0]?.name);
              setBenefits(element?.benefits);
              setAmount(element?.packs[0]?.amount);
              setSymbol(element?.currencyID?.symbol);
              setPaymentId(element?.packs[0]?._id);
            // }
          });
          // console.log("types?.packs", types?.data[0]?.param?.data[0]?.packs);
          setTabTypes(types?.data[0]?.param?.data[0]?.packs);
        }
      }
    } catch (error) {
      // func("SubscriptionUi 27", error);
    }

    setLoading(false);
  }, []);

  const navigate = useNavigate();
  const onClickTab = (type, ind) => {
    setActive(type);
    // console.log(nonPremiumDeatils, "selected pack");
    setSymbol(nonPremiumDeatils[0]?.currencyID?.symbol);
    setAmount(nonPremiumDeatils[0]?.packs[ind]?.amount);
    setBenefits(nonPremiumDeatils[0]?.benefits);
    setPaymentId(nonPremiumDeatils[0]?.packs[ind]?._id);
  };
  const cancelDobNstate = () => {
    setTagShowDobNstate(false);
  };
  const submitDobnState = (tag) => {
    // console.log(tag)
    if (tag) {
    } else {
      setTagShowDobNstate(false);
      // window.location.href = "/payment-gateway-web/" + paymentId;
      movePayment()
    }
  };

  const movePayment = async () => {
    setLoading(true);

    let payload = {
      subscriptionId: paymentId,
      isMobile: false,
    };

    let data = await createXsollaSubscriptionTransaction(payload);
    if (data?.success) {
      let transactionUniqueID = data?.data?.transactionUniqueID;
      let body = {
        transactionUniqueID: transactionUniqueID,
        subscriptionId: paymentId,
        isMobile: false,
      };
      localStorage.setItem("body", JSON.stringify(body));
      setToken(data?.data?.token);
      setLoading(false);
      let url = `https://secure.xsolla.com/paystation4/?token=${data?.data?.token}`;
      window.location.href = url;
    } else {
      setLoading(false);
    }
  }
  const buyPackHandler = async () => {
    // alert(paymentId);

    let details = await checkDobNstateConditions(dobNstateScreenName);
    if (details) {
      setTagShowDobNstate(true);
    } else {
      movePayment()
    }
  };

  const unsubscribePackHandler = async () => {
    setLoading(true);

    let data = await createXsollaUnSubscriptionTransaction();
    if (data?.success) {
      setLoading(false);
      window.location.href = "/";
    } else {
      setLoading(false);
      toast.error(data?.data?.msg, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const redirectHandler = (item) => {
    // navigate(`/${item}`);
    switch (item) {
      case "home":
        navigate(`/`);
        break;
      case "leaderboard":
        navigate(`/leader-board`);
        break;
      case "crm":
        navigate(`/customer-care`);
        break;
      case "profile":
        navigate(`/profile`);
        break;
      default:
        break;
    }
  };

  localStorage.setItem("platform", "WebApp");

  return (
    <div
      className="inner-wrap d-flex flex-column"
      style={{ height: "100vh", overflow: "hidden", padding: "0" }}
    >
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <ToastContainer />
          {tagShowDobNstate === true ? (
            <DobNStates
              submitDobNstate={submitDobnState}
              cancelDobNstate={cancelDobNstate}
              screen={dobNstateScreenName}
            />
          ) : (
            ""
          )}
          <div
            className="body mCustomScrollbar _mCS_1 "
            style={{
              borderRadius: "0",
              padding: "0",
              // paddingBottom: "60px",
            }}
          >
            <div
              className="all_sub_screens_div_related_to_ads"
              style={{ position: "relative" }}
            >
              <div
                className="back-btn subs-back"

                onClick={() => navigate(-1)}
              >
                <a>
                  <img src={Previous} alt="" />
                </a>
              </div>
              <div className="subImage">
                <div className="ps_header">
                  <div className="ps_logo">
                    <img src={Logo} />
                  </div>
                  <div className="ps_name">{t('tournaments.premium')}</div>
                </div>
                {premiumUser ? (
                  <>
                    <div
                      className="ps_renew"
                      style={{
                        background: "#1c1c1ecc",
                        border: "1px solid #c5a260",
                      }}
                    >
                      <div
                        className="ps_benefits_text"
                        style={{ padding: "10px 0" }}
                      >
                        <div className="layer1">
                          <div className="ps_badge">
                            <img
                              src={
                                url.imageUrl +
                                premiumDetails?.userLevelIcon?.featuredImage
                                  ?.default
                              }
                              style={{ width: "40px", height: "40px" }}
                            />
                          </div>
                        </div>
                        <div className="layer2 renew_layer">
                          <div>
                            <h2>{premiumDetails?.gamerjiName}</h2>
                            <p className="renew_font">
                              {t('premium.premium_since')}: {startDate}
                            </p>
                            <p className="renew_font">{t('premium.expires_on')}: {endDate}</p>
                          </div>

                          {premiumDetails?.isRenewRequired ? (
                            <button className="renew_font">{t('premium.renew_now')}</button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    {premiumDetails?.savedCurrency ? (
                      premiumDetails?.savedCurrency.map((item, ind) => (
                        <div
                          className="ps_renew"
                          style={{ background: "#1e1e1e" }}
                          key={ind}
                        >
                          <div
                            className="ps_benefits_text"
                            style={{ padding: "10px 15px" }}
                          >
                            {/* <div className="layer1">
                             
                            </div> */}
                            <div className="layer">
                              <h2>
                                {/* {item?.currencyDetails?.code[0].toUpperCase() +
                                  item?.currencyDetails?.code.slice(1)}{" "} */}
                                {t('premium.you_saved')}
                              </h2>

                              <button style={{ cursor: 'auto' }}>
                                <img
                                  src={
                                    url?.imageUrl +
                                    item?.currencyDetails?.img?.default
                                  }
                                  style={currentLang?.toLowerCase() == "ar" ? { width: "15px", height: "15px", marginLeft: '10px' } : { width: "15px", height: "15px", marginRight: '10px' }}
                                />
                                {item?.totalSavedAmount}
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <div className="ps_section">
                  <div className="ps_heading">
                    <div className="ps_rect ps_rect1"></div>
                    <h2>{t('premium.benefits')}</h2>
                    <div className="ps_rect ps_rect2"></div>
                  </div>
                  <div className="ps_benefits">
                    {benefits ? (
                      benefits.map((item, ind) => (
                        <div className="ps_benefits_text" key={ind}>
                          <div className="layer1">
                            <img src={url?.imageUrl + item?.img?.default} />
                          </div>
                          <div className="layer2">
                            <h2>{item?.title}</h2>
                            <p>{item?.description}</p>
                          </div>
                          {premiumUser && item?.redirectionScreenLink ? (
                            <span

                              className="icon"
                              style={{ color: "#c5a260", cursor: 'pointer' }}
                              onClick={() =>
                                redirectHandler(item?.redirectionScreenLink)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21.493"
                                height="18.154"
                                viewBox="0 0 21.493 18.154"
                              >
                                <g
                                  id="ico"
                                  transform="translate(21.493 18.151) rotate(-180)"
                                >
                                  <path
                                    id="union"
                                    d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                                    transform="translate(0 0)"
                                    fill="currentColor"
                                  />
                                </g>
                              </svg>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="ps_footer">
                  {/* {func("tbatypes 216", tabTypes)} */}
                  {premiumUser && !premiumDetails?.isRenewRequired ? (
                    <div className="ps_button">
                      <button onClick={unsubscribePackHandler}>
                        {t('Profile.unsubscribe')}
                      </button>
                    </div>
                  ) : (
                    <div className="ps_footer">
                      {/* {func("tbatypes 216", tabTypes)} */}
                      <div className="ps_buttonGroup d-flex justify-content-between">
                        {tabTypes.map((item, ind) => (
                          <button
                            className={` ${active === item?.packName ? "active" : ""
                              }`}
                            onClick={() => onClickTab(item?.packName, ind)}
                          >
                            {item?.packName}
                          </button>
                        ))}
                      </div>
                      <div className="ps_button">
                        <button onClick={buyPackHandler}>
                          {t('premium.buy_for', { amount: amount })}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionUI;
