import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "react-intl-tel-input/dist/main.css";
import {
  html5CategoriesList,
} from "../../apis";
import { kFormatter,  } from "../../component/common";
import url from '../../constants/url';
import Loader from "../../component/loader";

import Previous from "../../assets/images/previous.svg";
import GameIconSmall1 from "../../assets/images/game-icon-small1.svg";

import Advertisement from "../../component/advertisement";

import './FreeGames.css';
import { withTranslation } from "react-i18next";

class FreeGames extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      free_games_category_data: [], 

      tag_free_game_category_loader: true, 
    };    
  }

  componentDidMount() {
    this.html5CategoriesListAPICall();
  }

  async html5CategoriesListAPICall() {
    let data = {
      sortby: 'order', 
      sort: 'asc', 
    }
    var response = await html5CategoriesList(data);
    // console.log('Free Games Categories: ', response);
    if(response) {
      var fg_category = [];

      // for(let i=0 ; i<response?.list?.length ; i++) {
      //   let order_item = response?.list?.find((value) => value.order === i+1);
      //   fg_category.push(order_item);
      // }

      this.setState({ free_games_category_data: response?.list, });
    }

    this.setState({ tag_free_game_category_loader: false, });
  }

  render() {
  const { t } = this.props;

    return (
      <div className="fg_category_main_div">
        {this.state.tag_free_game_category_loader && <Loader />}
        <div className="fg_category_header">
          <div className="fg_category_back_arrow">
            <Link to='/'>
              <img src={Previous} alt="" />
					  </Link>
          </div>
          <div className="fg_category_header_text">Free Games</div>
          <div className=""></div>
        </div>

        <div className="fg_category_body">
          <div className="all_sub_screens_div_related_to_ads">
            <div className="fg_category_body_scrollable_component">
              <Row className="p-0 m-0" style={{width:'100%'}}>
                {
                  this.state.free_games_category_data.map((value, i) => {
                    return(
                      <Col className="d-flex justify-content-center align-items-center p-2 m-0" 
                        key={i} xl={6} lg={6} md={6} sm={6} xs={6}
                      >
                        <div className="fg_category_box" 
                          onClick={() => {
                            let free_game_type = {
                              _id: value?._id, 
                              name: value?.name, 
                            };
                            // localStorage.setItem('freeGameType', JSON.stringify(free_game_type));
                            if(value?.isDirect) {
                              localStorage.setItem("Link", value?.link)
                              localStorage.setItem("Name", value?.name)
                              window.location.href = '/direct-link'
                            }
                            else window.location.href = '/game-type/' + value?._id;
                          }}
                        >
                          <div className="fg_category_title">
                            {value?.name}
                          </div>
                          <div className="fg_category_image_joined_players">
                            <img className="fg_category_image" src={`${url.imageUrl}${value?.featuredImage?.default}`} />
                            <div className="fg_category_joined_players">
                              <img className="fg_GameIconSmall1_image" src={GameIconSmall1} alt="" />
                              <div className="fg_category_joined_players_numbers">
                                {kFormatter(value?.totalJoinedPlayers)}
                              </div>
                             { t('home.played') }
                            </div>
                            <div className="fg_category_image_joined_players_gradient"></div>
                          </div>
                        </div>
                      </Col>
                      
                    )
                  })
                }
              </Row>
            </div>
          </div>
          {
             !JSON.parse(localStorage.getItem("premiumUser")) && 
          
          <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).htmlCategories
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            />
  }
        </div>
        
      </div>
    );
  }
}

// export default FreeGames;
export default withTranslation()(FreeGames);