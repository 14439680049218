import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import rightArrow from "../../assets/images/right-arrow.svg";
import TextEditor from './TextEditor'
import { useEffect } from "react";
import { viewScrimRulesById } from "../../apis";
import ReactHtmlParser from "react-html-parser";

export const ManageScrimRules = () => {
  const params = useParams()
  const [rules, setRules] = useState('')
  useEffect(()=>{
    fetchData()
    return ()=> {
      setRules('')
    }
  },[])
  const setRulesData = (rule) =>{
    setRules(rule)
  }
  const fetchData = async() =>{
    var res = await viewScrimRulesById({
      id:params?.id
    })
    if(res){
      
      setRules(res?.data[0]?.param?.rules)
    }
  }
  return (
    <main className="scrim-basicDetails">
      {/* <textarea
        placeholder="Add your rules here"
        className="scrim-input rules-input"
      ></textarea> */}
      <div className="tab_content">
                  <p>{ReactHtmlParser(rules)}</p>
                </div>
    </main>
  );
};
