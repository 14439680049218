import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
// import { Row, Col } from "react-bootstrap";
import {
	getListofDailyRewards,
	getDailyRewards,
	getUserProfile,
	getCollectDailyRewards,
	getUserProfileLite,
	getUserBasicsDetails,
} from "../apis";
import url from '../constants/url';

import Previous from "../assets/images/previous.svg";
import Checkmark from "../assets/images/checkmark.svg";
import { Link } from "react-router-dom";
import rightArrow from "../assets/images/right-arrow.svg";
import roller from "../assets/images/rolling.gif";

import Advertisement from "../component/advertisement";

import './more.css';
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { constants } from "../constants/constants";

class DailyLoginRewards extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dlr_data: [],
			dlr_data_current: [],
			isbusy: false,
			selectedId: ""
		};
	}

	componentDidMount() {
		this.getListofDailyRewardsApiCall();
		this.getTotalCoin = this.getTotalCoin.bind(this);
		this.getTotalDiamond = this.getTotalDiamond.bind(this);
		this.onCollectRewards = this.onCollectRewards.bind(this);


	}

	async getListofDailyRewardsApiCall() {
		let response = await getDailyRewards();
		// console.log('dlr-current: ', response)
		if (response && response?.item) {
			this.setState({ dlr_data_current: response.item, });

			var currentDay = parseInt(response?.item?.currentDay);
			// let days = [];
			// if (currentDay <= 4 || currentDay === 2) {
			// 	for(let i=1 ; i<=30 ; i++, days.push(i));
			// } else {
			// 	days = [
			// 		currentDay - 3,
			// 		currentDay - 2,
			// 		currentDay - 1,
			// 	];
			// 	for(let i=currentDay ; i<=30 ; i++, days.push(i));
			// }

			let data = {
				skip: 0,
				limit: 100,
				day: currentDay,
				sortBy: 'day',
				sort: 'asc',
			}

			response = await getListofDailyRewards(data);
			// console.log('dlr: ', response)
			if (response && 'list' in response)
				this.setState({ dlr_data: response.list, });
			let dayId = response?.list?.find((val) => val.day === currentDay);
			this.setState({ selectedId: dayId?._id });
		}
	}

	getTotalCoin(wallet) {
		let index = wallet?.findIndex(obj => obj.currencyData?.code == 'coin')

		if (index > -1) {
			return wallet[index].winningAmount ? wallet[index].winningAmount : 0
		}
		return 0
	}
	getTotalDiamond(wallet) {
		let index = wallet?.findIndex(obj => obj.currencyData?.code == 'diamond')

		if (index > -1) {
			return wallet[index].winningAmount ? wallet[index].winningAmount : 0
		}
		return 0
	}

	async onCollectRewards() {
		this.setState({ isBusy: true });
		let payload = { dailyReward: this.state.selectedId };
		let res = await getCollectDailyRewards(payload);
		const { t } = this.props;


		if (res) {
			//let res1 = await getUserProfile();
			let profileLiteResponse = await getUserProfileLite();
			

			if (profileLiteResponse) {
				// console.log('games:temp :', profileLiteResponse)
				this.setState({ isBusy: false });
				localStorage.setItem('currentCoin', this.getTotalCoin(profileLiteResponse?.item?.wallet));
				localStorage.setItem('currentDiamond', this.getTotalDiamond(profileLiteResponse?.item?.wallet));
				localStorage.setItem(constants.profileLite, JSON.stringify(profileLiteResponse));


				let profileBasicResposne = await getUserBasicsDetails();
				if(profileBasicResposne){
					localStorage.setItem(constants.profileBasics, JSON.stringify(profileBasicResposne));
				}
				// if (res?.item?.isDetailsFilled === false) {
				//   window.location.href = '/sign-up';
				// }

				// let res_getUpdateProfile = await getUpdateProfile({
				//   currentPlatform: "webapp",
				//   currentVersion: "w2.0.0",
				// })
				// console.log('res_getUpdateProfile: ', res_getUpdateProfile)
				toast.success(t('daily_rewards_collected'), {
					position: "top-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				  });
				  this.getListofDailyRewardsApiCall()
			} else {
				this.setState({ isBusy: false });
			}

			//   setShowDailyRewards(false);
		}
	};

	render() {
		const { t } = this.props;

		return (
			
			<div className="dlr_main_div">
				<ToastContainer />
				<div className="dlr_header">
					<div className="back-btn">
						<Link to='/more'>
							<img src={Previous} alt="" />
						</Link>
					</div>
					
					<div className="dlr_header_text">{t('right_hand_drop_down.daily_login_rewards')}</div>
					<div className="wallet-btn">
						{/* <Link to=''>
								<img src={Wallet} alt=""/>
								Wallet
							</Link> */}
					</div>
				</div>

				<div className="dlr_body">
					<div className="all_sub_screens_div_related_to_ads">
						<div className="dlr_body_scrollable_component">
							{
								this.state.dlr_data.map((value, i) => {
									let dlr_reward_row_css = {};
									let dlr_day_col_css = {};
									let dlr_claimed_css = {};

									if (parseInt(this.state.dlr_data_current.currentDay) === parseInt(value.day)) {
										dlr_reward_row_css = { width: '100%', height: '60px', backgroundColor: 'var(--color_red)', color: 'var(--color_white)', };
										dlr_day_col_css = { backgroundColor: 'var(--color_black)' }
									}
									// else if(parseInt(this.state.dlr_data_current.isDailyRewardPopup) < parseInt(value.day)) {
									// 	dlr_reward_row_css = { width: '100%', height: '60px', backgroundColor: 'var(--color_red)', color: 'var(--color_white)', };
									// 	dlr_day_col_css = { backgroundColor: 'var(--color_black)' }
									// }
									else {
										dlr_reward_row_css = { width: '95%', height: '50px', backgroundColor: 'var(--color_white)', color: 'var(--color_black)', };
										dlr_day_col_css = { backgroundColor: 'var(--color_light_green)' }
									}
									// console.log(this.state.dlr_data_current.currentDay, value.day)
									if (parseInt(this.state.dlr_data_current.currentDay) > parseInt(value.day) )
										dlr_claimed_css = { display: 'flex' }
									else if (parseInt(this.state.dlr_data_current.currentDay) == parseInt(value.day) && !this.state.dlr_data_current?.isDailyRewardPopup )
										dlr_claimed_css = { display: 'flex' }
									
									else
										dlr_claimed_css = { display: 'none' }

									return (
										<div className="dlr_reward_row" key={i} style={dlr_reward_row_css}>
											<div className="dlr_day_col" style={dlr_day_col_css}>
											{t('Profile.day')} - {value.day}
											</div>
											<div className="dlr_reward_col">
												{value.amountType === "1" ? value.amount : ""}
												{value.amountType === "2" ? <img className="dlr_avatar_col" src={`${url.imageUrl}${value.avatar.img.default}`} /> : ""}
											</div>
											<div className="dlr_reward_type_col">
												{value.amountType === "1" ? <div className="dlr_reward_type">{value.name}</div> : ""}
												{value.amountType === "2" ? <div className="dlr_reward_type">{value.avatar.name}</div> : ""}
											</div>
											<div className="dlr_claimed_col">
												<div className="dlr_claimed" style={dlr_claimed_css}>
													<img className="mr-2" src={Checkmark} alt="" />{t('Profile.claimed')}
												</div>
											</div>
										</div>
									)
								})
							}
						</div>
						
						
					</div>
					{
							this.state.dlr_data_current.isDailyRewardPopup ? <>
							<button
							className="btn btn-dark main-btn next"
							disabled={this.state.isbusy}
							onClick={() => this.onCollectRewards()}
						>
							{t('Profile.collect_rewards')}{" "}
							{!this.state.isbusy ? <img src={rightArrow} alt="" className="mCS_img_loaded" /> : <img src={roller} alt="" style={{ maxWidth: 30 }} />}
						</button>
							</>
							: ""
						}
						{
							 !JSON.parse(localStorage.getItem("premiumUser")) && 
						
					<Advertisement
						screen={
							localStorage.getItem('apk_screens_list') === null ?
								{ code: 'no_results' }
								:
								JSON.parse(localStorage.getItem('apk_screens_list')).dailyLoginRewards
						}
						screen_div_class_name='.all_sub_screens_div_related_to_ads'
						screen_type='sub'
					/>
	}

					{/* <div className="reward_day_list">
						<div className="item">
							<div className="inner inner_day">
								Day-1
							</div>
							<div className="inner">
								<b>5</b>
							</div>
							<div className="inner inner_big">
								Gamerji Points
							</div>
							<div className="inner last_inner">
								<img src={Checkmark} alt="" /> Claimed
							</div>
						</div>
						<div className="item active bg-secondary">
							<div className="inner inner_day">
								Day-2
							</div>
							<div className="inner">
								<b>₹10</b>
							</div>
							<div className="inner inner_big">
								Bonus
							</div>
						</div>
						<div className="item">
							<div className="inner inner_day">
								Day-3
							</div>
							<div className="inner">
								<b>₹15</b>
							</div>
							<div className="inner inner_big">
								Reward Deposite
							</div>
						</div>
						<div className="item">
							<div className="inner inner_day">
								Day-4
							</div>
							<div className="inner">
								<b>5</b>
							</div>
							<div className="inner inner_big">
								Gamerji Points
							</div>
						</div>
						<div className="item">
							<div className="inner inner_day">
								Day-5
							</div>
							<div className="inner">
								<b>₹10</b>
							</div>
							<div className="inner inner_big">
								Bonus
							</div>
						</div>
						<div className="item">
							<div className="inner inner_day">
								Day-6
							</div>
							<div className="inner">
								<b>₹15</b>
							</div>
							<div className="inner inner_big">
								Reward Deposite
							</div>
						</div>
						<div className="item">
							<div className="inner inner_day">
								Day-7
							</div>
							<div className="inner">
								<b>5</b>
							</div>
							<div className="inner inner_big">
								Gamerji Points
							</div>
						</div>
						<div className="item">
							<div className="inner inner_day">
								Day-8
							</div>
							<div className="inner">
								<b>₹10</b>
							</div>
							<div className="inner inner_big">
								Bonus
							</div>
						</div>
						<div className="item">
							<div className="inner inner_day">
								Day-9
							</div>
							<div className="inner">
								<b>₹15</b>
							</div>
							<div className="inner inner_big">
								Reward Deposite
							</div>
						</div>
						<div className="item">
							<div className="inner inner_day">
								Day-10
							</div>
							<div className="inner">
								<b>5</b>
							</div>
							<div className="inner inner_big">
								Gamerji Points
							</div>
						</div>
						<div className="item active bg-sky">
							<div className="inner inner_day">
								Day-30
							</div>
							<div className="inner">
								<b>₹500</b>
							</div>
							<div className="inner inner_big">
								Bonus
							</div>
						</div>
					</div> */}
				</div>
			</div>
		);
	}
}
export default  withTranslation()(DailyLoginRewards);

// export default DailyLoginRewards;
