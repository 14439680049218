import React, { Fragment, useState } from "react";
import UpIcon from "../assets/images/up-icon.svg";
import Previous from "../assets/images/previous.svg";
import Wallet from "../assets/images/wallet.svg";
import Promotion from "../assets/images/promotion.svg";
import Close from "../assets/images/close.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import MagnifiyingGlass from "../assets/images/magnifiying-glass.svg";
import GiftBox from "../assets/images/gift-box.svg";
import NounInfo from "../assets/images/noun_Info.svg";
import Dollar from "../assets/images/dollar.svg";
import Pay from "../assets/images/pay.svg";
import { Link } from "react-router-dom";

function ContestList(props) {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <div className="inner-wrap">
      <div className="header">
        <div className="back-btn">
          <Link to="/">
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>PUBG SOLO</h2>
        <div className="wallet-btn">
          {/* <Link to='/'>
								<img src={Wallet} alt=""/>
								Wallet
							</Link> */}
        </div>
      </div>
      <div className="body mCustomScrollbar _mCS_1 bottom-btn-body">
        <div className="announcement">
          <img src={Promotion} alt="" />
          <b>Announcement </b>
          Join 22nd Nov 2019, Free PUBG SOLO tournament & Get a chance to win
          upto ₹14,000
        </div>
        <div className="contest-list">
          <div className="box">
            <h3>
              Money Heist PUBG SOLO Tournament <span>ID: UX5709LE</span>
            </h3>
            <div className="inner_row bg-dark">
              <div className="inner_item">
                Date <b>25/05/2019</b>
              </div>
              <div className="inner_item">
                Time <b>10:45 AM</b>
              </div>
              <div className="inner_item">
                Map <b>Erangel</b>
              </div>
              <div className="inner_item">
                Perspective <b>TPP</b>
              </div>
            </div>
            <div className="inner_row">
              <div className="inner_item">
                Winning <b>₹10,000</b>
              </div>
              <div className="inner_item">
                Winners{" "}
                <b>
                  {" "}
                  5 <i className="fas fa-chevron-down"></i>
                </b>
              </div>
              <div className="inner_item">
                Per Kill <b>₹5</b>
              </div>
              <div className="inner_item">
                Entry Fee <b>₹1000</b>
              </div>
            </div>
            <div className="avalible_wrap">
              <div className="inner">
                <span style={{ width: "75%" }}></span>
              </div>
              <p className="d-flex justify-content-between">
                <span>5 Avalible</span>
                <span>5 Spot left</span>
              </p>
            </div>
            <div className="contest_foot">
              <div className="inner">
                <span>Hosted By</span>
                <b>
                  Initiative eSports (4.2
                  <i className="fas fa-star text-primary"></i>)
                </b>
              </div>
              <div className="inner">
                <Link to="/">
                  <b>JOIN NOW</b>
                </Link>
              </div>
            </div>
          </div>
          <div className="box green_box">
            <h3>
              Money Heist PUBG SOLO Tournament <span>ID: UX5709LE</span>
            </h3>
            <div className="inner_row bg-dark">
              <div className="inner_item">
                Date <b>25/05/2019</b>
              </div>
              <div className="inner_item">
                Time <b>10:45 AM</b>
              </div>
              <div className="inner_item">
                Map <b>Erangel</b>
              </div>
              <div className="inner_item">
                Perspective <b>TPP</b>
              </div>
            </div>
            <div className="inner_row">
              <div className="inner_item">
                Winning <b>₹10,000</b>
              </div>
              <div className="inner_item">
                Winners{" "}
                <b>
                  {" "}
                  5 <i className="fas fa-chevron-down"></i>
                </b>
              </div>
              <div className="inner_item">
                Per Kill <b>₹5</b>
              </div>
              <div className="inner_item">
                Entry Fee <b>₹1000</b>
              </div>
            </div>
            <div className="avalible_wrap">
              <div className="inner">
                <span style={{ width: "75%" }}></span>
              </div>
              <p className="d-flex justify-content-between">
                <span>5 Avalible</span>
                <span>5 Spot left</span>
              </p>
            </div>
            <div className="contest_foot">
              <div className="inner">
                <span>Hosted By</span>
                <b>
                  Initiative eSports (4.2
                  <i className="fas fa-star text-primary"></i>)
                </b>
              </div>
              <div className="inner">
                <Link to="/">
                  <b>JOIN NOW</b>
                </Link>
              </div>
            </div>
          </div>
          <div className="box ">
            <h3>
              Money Heist PUBG SOLO Tournament <span>ID: UX5709LE</span>
            </h3>
            <div className="inner_row bg-dark">
              <div className="inner_item">
                Date <b>25/05/2019</b>
              </div>
              <div className="inner_item">
                Time <b>10:45 AM</b>
              </div>
              <div className="inner_item">
                Map <b>Erangel</b>
              </div>
              <div className="inner_item">
                Perspective <b>TPP</b>
              </div>
            </div>
            <div className="inner_row">
              <div className="inner_item">
                Winning <b>₹10,000</b>
              </div>
              <div className="inner_item">
                Winners{" "}
                <b>
                  {" "}
                  5 <i className="fas fa-chevron-down"></i>
                </b>
              </div>
              <div className="inner_item">
                Per Kill <b>₹5</b>
              </div>
              <div className="inner_item">
                Entry Fee <b>₹1000</b>
              </div>
            </div>
            <div className="avalible_wrap">
              <div className="inner">
                <span style={{ width: "75%" }}></span>
              </div>
              <p className="d-flex justify-content-between">
                <span>5 Avalible</span>
                <span>5 Spot left</span>
              </p>
            </div>
            <div className="contest_foot">
              <div className="inner">
                <span>Hosted By</span>
                <b>
                  Initiative eSports (4.2
                  <i className="fas fa-star text-primary"></i>)
                </b>
              </div>
              <div className="inner">
                <Link to="/">
                  <b>JOIN NOW</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_btn">
        <button
          className="btn btn-dark main-btn filter_popup_toggle"
          onClick={() => setShowFilter(true)}
        >
          FILTERS <img src={UpIcon} alt="" className="mCS_img_loaded" />
        </button>
      </div>
      {showFilter && (
        <div className="custom-popup bg-primary show" id="filter_popup">
          <div className="popup-head popup-head2 justify-content-start">
            <h3>Filters</h3>
            <button className="close-popup">
              Clear All{" "}
              <img src={Close} onClick={() => setShowFilter(false)} alt="" />
            </button>
          </div>

          <div className="popup-body">
            <div className="row filter_tab mb-5">
              <div className="col-4">
                <div className="list-group" id="list-tab" role="tablist">
                  <a
                    className="list-group-item list-group-item-action active"
                    id="list-home-list"
                    data-toggle="list"
                    href="#list-1"
                    role="tab"
                    aria-controls="home"
                  >
                    Entry Fee
                  </a>
                  <a
                    className="list-group-item list-group-item-action"
                    id="list-profile-list"
                    data-toggle="list"
                    href="#list-2"
                    role="tab"
                    aria-controls="profile"
                  >
                    By Host
                  </a>
                  <a
                    className="list-group-item list-group-item-action"
                    id="list-messages-list"
                    data-toggle="list"
                    href="#list-3"
                    role="tab"
                    aria-controls="messages"
                  >
                    Date
                  </a>
                  <a
                    className="list-group-item list-group-item-action"
                    id="list-settings-list"
                    data-toggle="list"
                    href="#list-4"
                    role="tab"
                    aria-controls="settings"
                  >
                    Time
                  </a>
                  <a
                    className="list-group-item list-group-item-action"
                    id="list-settings-list"
                    data-toggle="list"
                    href="#list-5"
                    role="tab"
                    aria-controls="settings"
                  >
                    Prize Pool
                  </a>
                </div>
              </div>
              <div className="col-8 pl-2">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane active"
                    id="list-1"
                    role="tabpanel"
                    aria-labelledby="list-home-list"
                  >
                    <div className="filter-search">
                      <img src={MagnifiyingGlass} alt="" />
                      <input type="text" placeholder="Search" />
                    </div>
                    <div className="checkbox_wrap">
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        MoneyHeist Tournament
                      </label>
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        Kill99 Tournament
                      </label>
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        ROBO Gaming Tournament
                      </label>
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="list-2"
                    role="tabpanel"
                    aria-labelledby="list-profile-list"
                  >
                    <div className="filter-search">
                      <img src={MagnifiyingGlass} alt="" />
                      <input type="text" placeholder="Search" />
                    </div>
                    <div className="checkbox_wrap">
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        MoneyHeist Tournament
                      </label>
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        Kill99 Tournament
                      </label>
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        ROBO Gaming Tournament
                      </label>
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="list-3"
                    role="tabpanel"
                    aria-labelledby="list-messages-list"
                  >
                    <div className="filter-search">
                      <img src={MagnifiyingGlass} alt="" />
                      <input type="text" placeholder="Search" />
                    </div>
                    <div className="checkbox_wrap">
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        MoneyHeist Tournament
                      </label>
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        Kill99 Tournament
                      </label>
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        ROBO Gaming Tournament
                      </label>
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="list-4"
                    role="tabpanel"
                    aria-labelledby="list-settings-list"
                  >
                    <div className="filter-search">
                      <img src={MagnifiyingGlass} alt="" />
                      <input type="text" placeholder="Search" />
                    </div>
                    <div className="checkbox_wrap">
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        MoneyHeist Tournament
                      </label>
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        Kill99 Tournament
                      </label>
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        ROBO Gaming Tournament
                      </label>
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="list-5"
                    role="tabpanel"
                    aria-labelledby="list-settings-list"
                  >
                    <div className="filter-search">
                      <img src={MagnifiyingGlass} alt="" />
                      <input type="text" placeholder="Search" />
                    </div>
                    <div className="checkbox_wrap">
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        MoneyHeist Tournament
                      </label>
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        Kill99 Tournament
                      </label>
                      <label>
                        <input type="checkbox" />
                        <span></span>
                        ROBO Gaming Tournament
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="btn btn-dark main-btn joining_popup_toggle">
              APPLY <img src={RightArrow} alt="" />
            </button>
          </div>
        </div>
      )}
      <div className="custom-popup bg-primary" id="joining_popup">
        <div className="popup-head">
          <h3>
            Money Heist 3X Loot <br />
            Tournament
          </h3>
          <button className="close-popup">
            <img src={Close} alt="" />
          </button>
        </div>
        <div className="popup-body">
          <p className="d-flex flex-wrap justify-content-between">
            <span>PUBG-SOLO</span>
            <span>12th June 2020, 10:00 AM</span>
          </p>
          <div className="confirmation">
            <b>Confirmation</b>
            Unutilized Balance + Winnings = ₹500
          </div>
          <div className="table-responsive total_table">
            <table>
              <tr>
                <th colspan="2">Entry Fee</th>
                <th className="text-right">₹1000</th>
              </tr>
              <tr>
                <td>
                  <img src={GiftBox} alt="" /> Usable Cash Bonus
                </td>
                <td>
                  <img src={NounInfo} alt="" className="noun_Info" />
                </td>
                <th className="text-right">₹500</th>
              </tr>
              <tr>
                <td className="pb-4">
                  <img src={Dollar} alt="" /> Usable Gamerji Coin
                </td>
                <td className="pb-4">
                  <img src={NounInfo} alt="" className="noun_Info" />
                </td>
                <th className="pb-4 text-right">₹500</th>
              </tr>
              <tr className="total_tr">
                <td colspan="2">
                  <img src={Pay} alt="" /> To Pay
                </td>
                <th className="text-right">₹500</th>
              </tr>
            </table>
          </div>
          <p className="text-center join-small-text mt-3 mb-5">
            By Joining this contest, You accept Gamerji's T& C and confirm that
            you are a resident of India except the states of Assam, Odisha,
            Telangana, Nagaland or Sikkim & Your age is 18 years or above.
          </p>
          <div className="form-group">
            <p className="text-secondary text-center form-note">
              <b>
                Note : Minimum PUBG Level required 30 or above. Hacking, Teamup
                & Cheating is strictly prohibited.
              </b>
            </p>
          </div>
          <button className="btn btn-dark main-btn">
            JOIN CONTEST <img src={RightArrow} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContestList;
