import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
import {
  sponsorAdsList,
  sponsorAdLog,
} from "../apis";
import url from '../constants/url';
import Loader from "../component/loader";
import OwlCarousel from "react-owl-carousel";

import '../screens/world-of-esports.css';

export default class Advertisement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: props.screen,

      screen_div_class_name: props.screen_div_class_name,
      screen_type: props.screen_type,

      main_screen_display_ads_true_height: '91%',
      sub_screen_display_ads_true_height: '93%',
      all_screens_display_ads_false_height: '100%',
      main_screens_ad_main_component_margin: '85px',
      sub_screens_ad_main_component_margin: '0px',

      display_ads: false,
      ads_data: [],
      // autoplayTimeout: 5000, 
    };

    this.onChangeAdOwlCarousel = this.onChangeAdOwlCarousel.bind(this);
    this.onClickAdOwlCarousel = this.onClickAdOwlCarousel.bind(this);
  }

  componentDidMount() {
    // //console.log('Advertisement Screen: ', this.state.screen.name)

    // var display_ads = this.state.screen?.code !== 'no_results' && (this.state.screen?.isGamerjiAds === true || this.state.screen?.isFbAds === true);
    // // display_ads ? screen_div.style.height = '100%' : screen_div.style.height = '88%'; 
    // this.setState({ display_ads: display_ads, }, () => {
    //   this.sponsorAdsListAPICall(display_ads)
    // })
  }

  onChangeAdOwlCarousel(e) {
    // //console.log(e.item.index);

    if (e.item.index > 0 && e.item.index <= this.state.ads_data.length) {
      let data = {
        type: "1",
        screen: this.state.screen._id,
        sponsorAd: this.state.ads_data[parseInt(e.item.index) - 1]._id,
        platform: "webapp",
      }
      // //console.log('View: ', data)

      // this.sponsorAdLogAPICall(data);
    }
  }

  onClickAdOwlCarousel(value) {
    // //console.log(e.item.index);

    let data = {
      type: "2",
      screen: this.state.screen._id,
      sponsorAd: value._id,
      platform: "webapp",
    }
    // //console.log('Click: ', data)

    // this.sponsorAdLogAPICall(data);
  }

  async sponsorAdsListAPICall(tag) {
    let screen_div = document.querySelector(this.state.screen_div_class_name);
    // //console.log(this.state.screen_div_class_name)
    if (screen_div) {
      if (tag) {
        if (this.state.screen_type === 'main')
          screen_div.style.height = this.state.main_screen_display_ads_true_height;
        if (this.state.screen_type === 'sub')
          screen_div.style.height = this.state.sub_screen_display_ads_true_height;

        let response = await sponsorAdsList({ skip: 0, limit: 10, sort: 'asc', screen: this.state.screen._id });
        //console.log('Advertisement: ', response);
        if (response && response.list && 'list' in response) {
          if (response.list.length > 1) {
            this.setState({ ads_data: response.list });
          } else if (response.list.length === 1) {
            let temp_ads_data = []
            temp_ads_data.push(response.list[0])
            temp_ads_data.push(response.list[0])
            this.setState({ ads_data: temp_ads_data });
          } else {
            this.setState({ ads_data: [] });
            screen_div.style.height = this.state.all_screens_display_ads_false_height;
          }
        } else {
          this.setState({ ads_data: [] });
          screen_div.style.height = this.state.all_screens_display_ads_false_height;
        }
      } else {
        this.setState({ ads_data: [] });
        screen_div.style.height = this.state.all_screens_display_ads_false_height;
      }
    }
  }

  async sponsorAdLogAPICall(data) {
    sponsorAdLog(data);

    // platform: 'webapp'
  }

  test() {
    //console.log('hello')
  }

  // render() {
  //   return (
  //     this.state.display_ads && this.state.ads_data.length > 0 ?
  //       <div
  //         className="ad_main_component"
  //         style={
  //           this.state.screen_type === 'main' ?
  //             { marginBottom: this.state.main_screens_ad_main_component_margin, } :
  //             this.state.screen_type === 'sub' ?
  //               { marginBottom: this.state.sub_screens_ad_main_component_margin, } :
  //               {}
  //         }
  //       >
  //         <OwlCarousel
  //           className="ad_OwlCarousel"
  //           autoWidth={false}
  //           items={1}
  //           // lazyLoad={true}
  //           loop={true}
  //           // animateIn={true}
  //           // rewindSpeed={0}
  //           // slideTransition='scaleUp'
  //           margin={0}
  //           // stagePadding={50}
  //           autoplay={true}
  //           autoplayTimeout={4000}
  //           dots={false}
  //           onChange={(e) => this.onChangeAdOwlCarousel(e)}
  //         >
  //           {
  //             this.state.ads_data.map((value, i) => {
  //               if (value && value?.banner?.default) {
  //                 if (value && 'url' in value) {
  //                   return (
  //                     <a href={value.url} target='_blank' key={i} onClick={(e) => this.onClickAdOwlCarousel(value)}>
  //                       <img className="ad_individual" src={`${url.imageUrl}${value.banner.default}`} />
  //                     </a>
  //                   )
  //                 } else {
  //                   <img className="ad_individual" src={`${url.imageUrl}${value.banner.default}`} key={i} onClick={(e) => this.onClickAdOwlCarousel(value)} />
  //                 }
  //               }
  //             })
  //           }
  //         </OwlCarousel>
  //         {/* <Carousel  controls={false}>
  //           {
  //             this.state.ads_data.map((value, i) => {
  //               return(
  //                 <Carousel.Item key={i}>
  //                   <img className="ad_individual" src={`${url.imageUrl}${value.banner.default}`} />
  //                 </Carousel.Item>
  //               )
  //             })
  //           }
  //         </Carousel> */}
  //       </div>
  //       :
  //       <div></div>
  //   );
  // }
  render() {
    return <></>
  }
}