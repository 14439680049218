import React, { Fragment } from "react";
import Previous from "../assets/images/previous.svg";
import Trophy from "../assets/images/trophy.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import PaytmIcon from "../assets/images/paytm_icon.svg";
import { Link } from "react-router-dom";

function Bank(props) {
  return (
    <Fragment>
      <div className="header">
        <div className="back-btn">
          <Link to="/">
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>Bank</h2>
        <div className="wallet-btn"></div>
      </div>
      <div className="body mCustomScrollbar _mCS_1">
        <div className="current_balance_box shadow-secondary bg-secondary">
          <img src={Trophy} alt="" className="mCS_img_loaded" />
          Your Winnings
          <b className="ml-auto">₹250.00</b>
        </div>
        <div className="bank-details">
          <h4>HDFC Bank Limited</h4>
          <p>A/C : 0123456789</p>
        </div>
        <div className="form-group">
          <label>Amount</label>
          <input
            type="text"
            className="form-control"
            placeholder="₹2000.00"
            required=""
          />
        </div>
        <div className="pan-note">
          <p>Minimum ₹ 200 and Maximum ₹ 2,00,000 allowed per day</p>
        </div>
        <div className="proceed-link d-flex justify-content-between">
          <h5>Proceed to link</h5>
          <h5>Wallet with this</h5>
        </div>
        <div className="pay-mth-icon">
          <img src={PaytmIcon} alt="" />
        </div>
        <div className="form-group">
          <label>Phone number</label>
          <input
            type="text"
            className="form-control"
            placeholder="+521 6745678546"
            required=""
          />
        </div>
        <div className="pan-note">
          <p>
            This no. is linked to your Fantasyji eSport account and can’t be
            changed
          </p>
        </div>
      </div>
      <div className="bottom_btn">
        <button className="btn btn-dark main-btn">
          WITHDRAW NOW
          <img src={RightArrow} alt="" className="mCS_img_loaded" />
        </button>
      </div>
    </Fragment>
  );
}

export default Bank;
