import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "react-intl-tel-input/dist/main.css";
import {
  html5GamesGet, 
  html5GamesUpdateView, 
} from "../../apis";
import Loader from "../../component/loader";

import Previous from "../../assets/images/previous.svg";

import Advertisement from "../../component/advertisement";

import './FreeGames.css';

export default class FreeGameSingle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      free_game_data: [], 
      log_data: [], 

      tag_free_game_loader: true, 
    };
    
    this.onClickBackArrowFreeGameSingle = this.onClickBackArrowFreeGameSingle.bind(this);
  }

  componentDidMount() {
    this.html5GamesGetAPICall();
  }

  componentWillUnmount() {
    this.html5GamesUpdateViewAPICall('end');
  }

  onClickBackArrowFreeGameSingle() {
    this.html5GamesUpdateViewAPICall('end');
    window.location.href = '/game-type/' + this.state.free_game_data?.html5Category?._id;
  }

  async html5GamesGetAPICall() {
    var free_game_id = window.location.href.split("/")[
      window.location.href.split("/").length - 1
    ];
    
    var response = await html5GamesGet(free_game_id);
    // console.log('Free Game: ', response);
    if(response && response?.success === true) {
      this.setState({ free_game_data: response, }, () => {
        this.html5GamesUpdateViewAPICall('start');
      });
    }

    this.setState({ tag_free_game_loader: false, });
  }

  async html5GamesUpdateViewAPICall(status) {
    var data = {};

    if(status === 'start') {
      data = {
        html5Category: this.state.free_game_data?.html5Category?._id,
        htmlGame: this.state.free_game_data?.item?._id,
        status: "start"
      }
    } else if(status === 'end') {
      data = {
        htmlGame: this.state.free_game_data?.item?._id, 
        logId: this.state.log_data?.item?._id, 
        status: "end", 
      }
    }
    
    var response = await html5GamesUpdateView(data);
    // console.log('Free Game Update View: ', response)
    if(response && response?.success === true) {
      this.setState({ log_data: response, })
    }
  }

  render() {
    return (
      <div className="fg_single_game_main_div">
        <div className="fg_single_game_header">
          <div className="fg_single_game_back_arrow" onClick={() => this.onClickBackArrowFreeGameSingle()}>
            {/* <Link to={`/game-type/${this.state.free_game_data?.html5Category?._id}`}> */}
              <img src={Previous} alt="" />
					  {/* </Link> */}
          </div>
          <div className="fg_single_game_header_text">{this.state.free_game_data?.item?.name}</div>
          <div className=""></div>
        </div>

        <div className="fg_single_game_body">
          <div className="all_sub_screens_div_related_to_ads">
            <div className="fg_single_game_body_scrollable_component">
              <iframe className="fg_single_game_iframe" src={this.state.free_game_data?.item?.url}/>
            </div>
          </div>
          {
             !JSON.parse(localStorage.getItem("premiumUser")) && 
          
          <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).htmlGames
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            />
  }
        </div>
        
      </div>
    );
  }
}

// export default FreeGameSingle;
