import React, { Fragment, useState } from "react";
import Previous from "../assets/images/previous.svg";
import Wallet from "../assets/images/wallet.svg";
import BannerImg from "../assets/images/banner-img.jpg";
import TournamentsImg from "../assets/images/tournaments-img.jpg";
import ContesY from "../assets/images/contest_y.svg";
import CopyIcon from "../assets/images/copy-icon.svg";
import contestI from "../assets/images/contest_i.svg";
import contestX from "../assets/images/contest_x.svg";
import Friendship from "../assets/images/friendship.svg";
import Report from "../assets/images/report.svg";
import R1 from "../assets/images/r-1.svg";
import R2 from "../assets/images/r-2.svg";
import R3 from "../assets/images/r-3.svg";
import R4 from "../assets/images/r-4.svg";
import R5 from "../assets/images/r-5.svg";
import RoundArrowBlack from "../assets/images/round-arrow-black.svg";
import RoundArrowBlue from "../assets/images/round-arrow-blue.svg";
import RoundArrowRed from "../assets/images/round-arrow-red.svg";
import Gamer from "../assets/images/gamer.svg";
import Gun2 from "../assets/images/gun-2.svg";
import Podium from "../assets/images/podium.svg";
import List from "../assets/images/list.svg";
import Picon1 from "../assets/images/p_icon_1.png";
import RightArrow from "../assets/images/right-arrow.svg";
import Close from "../assets/images/close.svg";
import rAvtar from "../assets/images/r_avtar.png";
import E1 from "../assets/images/e-1.svg";
import E2 from "../assets/images/e-2.svg";
import E3 from "../assets/images/e-3.svg";
import E4 from "../assets/images/e-4.svg";
import { Link } from "react-router-dom";
import { useRanger } from "react-ranger";

function MonthlyChnampionship(props) {

	const [allRound, setAllRound] = useState([true, false, false]);
	const [values, setValues] = React.useState([10]);

	const { getTrackProps, handles } = useRanger({
		min: 0,
		max: 50,
		stepSize: 10,
		values,
		onChange: setValues
	});
	return (
		<Fragment>
			<div className="header">
				<div className="back-btn">
					<Link to='/'>
						<img src={Previous} alt="" />
					</Link>
				</div>
				<h2>Monthly Championship</h2>
				<div className="wallet-btn">
					{/* <Link to='/'>
								<img src={Wallet} alt=""/>
								Wallet
							</Link> */}
				</div>
			</div>
			<div className="body mCustomScrollbar _mCS_1 bottom-btn-body">
				<div className="banner-img">
					<img src={BannerImg} alt="" className="d-block w-100" />
				</div>
				<div className="list-group main-tab" id="list-tab" role="tablist">
					<a className="list-group-item list-group-item-action active" id="list-home-list" data-toggle="list" href="#list-1">Details</a>
					<a className="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" href="#list-2">Table</a>
					<a className="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" href="#list-3">Players</a>
					<a className="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" href="#list-4">Rules</a>
				</div>
				<div className="tab-content" id="nav-tabContent">
					<div className="tab-pane active" id="list-1">

						<div className="contest-list contest-tournament-list">
							<div className="box">
								<h3>Money Heist PUBG Tournament</h3>
								<div className="row tournament_contest_row">
									<div className="col-4 pr-1">
										<div className="img">
											<img src={TournamentsImg} alt="" />
											<div className="img-caption">
												<h5>SOLO</h5>
											</div>
										</div>
									</div>
									<div className="col-8 pb-3">
										<div className="inner_row pt-0">
											<div className="inner_item inner_item_big">
												Date <b>25/05/2019 to 30/05/2019</b>
											</div>
											<div className="inner_item">
												ID <b>UX5709LE</b>
											</div>
										</div>
										<div className="inner_row bg-dark">
											<div className="inner_item">
												Map <b>Erangel</b>
											</div>
											<div className="inner_item">
												Perspective <b>TPP</b>
											</div>
											<div className="inner_item">
												Round <b>04</b>
											</div>
										</div>
										<div className="inner_row">
											<div className="inner_item">
												Winning <b>₹10,000</b>
											</div>
											<div className="inner_item">
												Winners <b> 5 <i className="fas fa-chevron-down"></i></b>
											</div>
											<div className="inner_item">
												Entry Fee <b>₹1000</b>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<h2 className="title mb-3">Tournament Rounds</h2>
						<div className="contest-list contest-toggle">

							<div className={`box ${allRound[0] === true && 'show'}`}>
								<div className="contest_toggle_btn" onClick={() => setAllRound([!allRound[0], allRound[1], allRound[2]])} >
									Round 1
									<span><img src={ContesY} alt="" /> Qualified for Next Round</span>
								</div>
								<div className="contest_toggle_txt">
									<div className="inner_row bg-dark">
										<div className="inner_item">
											Date <b>25/05/2019</b>
										</div>
										<div className="inner_item">
											Time <b>10:45 AM</b>
										</div>
										<div className="inner_item">
											Map <b>Erangel</b>
										</div>
										<div className="inner_item">
											Perspective <b>TPP</b>
										</div>
									</div>
									<div className="inner_row">
										<div className="inner_item">
											Winning <b>₹10,000</b>
										</div>
										<div className="inner_item">
											Winners <b> 5 <i className="fas fa-chevron-down"></i></b>
										</div>
										<div className="inner_item">
											Per Kill <b>₹5</b>
										</div>
										<div className="inner_item">
											Entry Fee <b>₹1000</b>
										</div>
									</div>
									<div className="avalible_wrap">
										<div className="inner"><span style={{ width: '75%' }}></span></div>
										<p className="d-flex justify-content-between"><span>5 Avalible</span><span>5 Spot left</span></p>
									</div>
									<div className="contest_foot contest_details_foot">
										<div className="inner">
											<span>Hosted By</span>
											<b>Initiative eSports  (4.2<i className="fas fa-star text-primary"></i>)</b>
										</div>
										<div className="inner inner-text">
											<span>Room ID</span>
											<b>ABC1234 <img src={CopyIcon} alt="" /></b>
										</div>
										<div className="inner inner-text">
											<span>Password</span>
											<b>12345678 <img src={CopyIcon} alt="" /></b>
										</div>
									</div>
								</div>
							</div>
							<div className={`box ${allRound[1] === true && 'show'}`}>
								<div className="contest_toggle_btn" onClick={() => setAllRound([allRound[0], !allRound[1], allRound[2]])} >
									Round 2
									<span><img src={contestI} alt="" /> Pending</span>
								</div>
								<div className="contest_toggle_txt">
									<div className="inner_row bg-dark">
										<div className="inner_item">
											Date <b>25/05/2019</b>
										</div>
										<div className="inner_item">
											Time <b>10:45 AM</b>
										</div>
										<div className="inner_item">
											Map <b>Erangel</b>
										</div>
										<div className="inner_item">
											Perspective <b>TPP</b>
										</div>
									</div>
									<div className="inner_row">
										<div className="inner_item">
											Winning <b>₹10,000</b>
										</div>
										<div className="inner_item">
											Winners <b> 5 <i className="fas fa-chevron-down"></i></b>
										</div>
										<div className="inner_item">
											Per Kill <b>₹5</b>
										</div>
										<div className="inner_item">
											Entry Fee <b>₹1000</b>
										</div>
									</div>
									<div className="avalible_wrap">
										<div className="inner"><span style={{ width: '75%' }}></span></div>
										<p className="d-flex justify-content-between"><span>5 Avalible</span><span>5 Spot left</span></p>
									</div>
									<div className="contest_foot contest_details_foot">
										<div className="inner">
											<span>Hosted By</span>
											<b>Initiative eSports  (4.2<i className="fas fa-star text-primary"></i>)</b>
										</div>
										<div className="inner inner-text">
											<span>Room ID</span>
											<b>ABC1234 <img src={CopyIcon} alt="" /></b>
										</div>
										<div className="inner inner-text">
											<span>Password</span>
											<b>12345678 <img src={CopyIcon} alt="" /></b>
										</div>
									</div>
								</div>
							</div>
							<div className={`box ${allRound[2] === true && 'show'}`}>
								<div className="contest_toggle_btn" onClick={() => setAllRound([allRound[0], allRound[1], !allRound[2]])}>
									Round 3
									<span><img src={contestX} alt="" /> Sorry! You are not Qualified</span>
								</div>
								<div className="contest_toggle_txt">
									<div className="inner_row bg-dark">
										<div className="inner_item">
											Date <b>25/05/2019</b>
										</div>
										<div className="inner_item">
											Time <b>10:45 AM</b>
										</div>
										<div className="inner_item">
											Map <b>Erangel</b>
										</div>
										<div className="inner_item">
											Perspective <b>TPP</b>
										</div>
									</div>
									<div className="inner_row">
										<div className="inner_item">
											Winning <b>₹10,000</b>
										</div>
										<div className="inner_item">
											Winners <b> 5 <i className="fas fa-chevron-down"></i></b>
										</div>
										<div className="inner_item">
											Per Kill <b>₹5</b>
										</div>
										<div className="inner_item">
											Entry Fee <b>₹1000</b>
										</div>
									</div>
									<div className="avalible_wrap">
										<div className="inner"><span style={{ width: '75%' }}></span></div>
										<p className="d-flex justify-content-between"><span>5 Avalible</span><span>5 Spot left</span></p>
									</div>
									<div className="contest_foot contest_details_foot">
										<div className="inner">
											<span>Hosted By</span>
											<b>Initiative eSports  (4.2<i className="fas fa-star text-primary"></i>)</b>
										</div>
										<div className="inner inner-text">
											<span>Room ID</span>
											<b>ABC1234 <img src={CopyIcon} alt="" /></b>
										</div>
										<div className="inner inner-text">
											<span>Password</span>
											<b>12345678 <img src={CopyIcon} alt="" /></b>
										</div>
									</div>
								</div>
							</div>

						</div>

						<div className="row friendship_items">
							<div className="col-6 pr-2">
								<div className="box winning_breakup_popup_toggle">
									<div className="icon"><img src={Friendship} alt="" /></div>
									Share with Friends
								</div>
							</div>
							<div className="col-6 pl-2">
								<div className="box report_issue_toggle">
									<div className="icon"><img src={Report} alt="" /></div>
									Report Issue
								</div>
							</div>
						</div>

						<h2 className="title mb-3 mt-4">Rate Initiative eSports </h2>
						<div className="r-wrap">
							<div className="r-image">
								<div className="img"><img src={R1} alt="" /></div>
								<div className="img"><img src={R2} alt="" /></div>
								<div className="img"><img src={R3} alt="" /></div>
								<div className="img"><img src={R4} alt="" /></div>
								<div className="img"><img src={R5} alt="" /></div>
							</div>
							<div
								{...getTrackProps({
									style: {
										height: "4px",
										background: "#ddd",
										boxShadow: "inset 0 1px 2px rgba(0,0,0,.6)",
										borderRadius: "2px"
									}
								})}
							>
								{handles.map(({ getHandleProps }) => (
									<button
										{...getHandleProps({
											style: {
												width: "16px",
												height: "19px",
												backgroundSize: 'auto 100%',
												// outline: "none",
												// borderRadius: "100%",
												// background: "linear-gradient(to bottom, #eee 45%, #ddd 55%)",
												// border: "solid 1px #888",
												backgroundImage: `url("../assets/images/range-btn.svg")`
											}
										})}
									/>
								))}
							</div>
							{/* <div className="r-range">
										<div id="slider"></div>
										<img src={RangeDot} className="d-block w-100"/>
									</div> */}
						</div>
					</div>
					<div className="tab-pane" id="list-2" >
						<div className="round-box">
							<div className="round-head">
								<div className="item">Round 1</div>
								<div className="item">Round 2</div>
								<div className="item">Round 3</div>
								<div className="item">Round 4</div>
							</div>
							<div className="dtc">
								<div className="round-chart">
									<div className="col col_black">
										<div className="grp">
											<div className="label">Top 50% <img src={RoundArrowBlack} alt="" /></div>
											<div className="item">R 1 A</div>
											<div className="item">R 1 B</div>
										</div>
										<div className="grp">
											<div className="label">Top 50% <img src={RoundArrowBlack} alt="" /></div>
											<div className="item">R 1 C</div>
											<div className="item">R 1 D</div>
										</div>
										<div className="grp">
											<div className="label">Top 50% <img src={RoundArrowBlack} alt="" /></div>
											<div className="item">R 1 E</div>
											<div className="item">R 1 F</div>
										</div>
										<div className="grp">
											<div className="label">Top 50% <img src={RoundArrowBlack} alt="" /></div>
											<div className="item">R 1 G</div>
											<div className="item">R 1 H</div>
										</div>
									</div>
									<div className="col col_blue">
										<div className="grp">
											<div className="label">Top 50% <img src={RoundArrowBlue} alt="" /></div>
											<div className="item">R 2 A</div>
											<div className="item">R 2 B</div>
										</div>
										<div className="grp">
											<div className="label">Top 50% <img src={RoundArrowBlue} alt="" /></div>
											<div className="item">R 2 C</div>
											<div className="item">R 2 D</div>
										</div>
									</div>
									<div className="col col_red">
										<div className="grp">
											<div className="label">Top 50% <img src={RoundArrowRed} alt="" /></div>
											<div className="item">R 3 A</div>
											<div className="item">R 3 B</div>
										</div>
									</div>
									<div className="col col_green">
										<div className="grp">
											<div className="item">FINAL</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane" id="list-3" >
						<div className="player_icons">
							<div className="row no-gutters">
								<div className="col-3">
									<div className="item">
										<div className="icon"><img src={Gamer} alt="" /></div>
										User Name
									</div>
								</div>
								<div className="col-3">
									<div className="item">
										<div className="icon"><img src={Gun2} alt="" /></div>
										Kills
									</div>
								</div>
								<div className="col-3">
									<div className="item">
										<div className="icon"><img src={Podium} alt="" /></div>
										Rank
									</div>
								</div>
								<div className="col-3">
									<div className="item">
										<div className="icon"><img src={List} alt="" /></div>
										Points
									</div>
								</div>
							</div>
						</div>

						<div className="player_list">
							<div className="item active">
								<div className="inner inner_big">
									<div className="icon"><img src={Picon1} alt="" /></div>
									<b>Mike Hussy</b><br />
									<small>xxxxx 12345</small>
								</div>
								<div className="inner">
									<b>05</b>
								</div>
								<div className="inner">
									<b>10</b>
								</div>
								<div className="inner">
									<b>400</b>
								</div>
							</div>
							<div className="item">
								<div className="inner inner_big">
									<div className="icon"><img src={Picon1} alt="" /></div>
									<b>Mike Hussy</b><br />
									<small>xxxxx 12345</small>
								</div>
								<div className="inner">
									<b>05</b>
								</div>
								<div className="inner">
									<b>10</b>
								</div>
								<div className="inner">
									<b>380</b>
								</div>
							</div>
							<div className="item">
								<div className="inner inner_big">
									<div className="icon"><img src={Picon1} alt="" /></div>
									<b>Mike Hussy</b><br />
									<small>xxxxx 12345</small>
								</div>
								<div className="inner">
									<b>05</b>
								</div>
								<div className="inner">
									<b>10</b>
								</div>
								<div className="inner">
									<b>380</b>
								</div>
							</div>
							<div className="item">
								<div className="inner inner_big">
									<div className="icon"><img src={Picon1} alt="" /></div>
									<b>Mike Hussy</b><br />
									<small>xxxxx 12345</small>
								</div>
								<div className="inner">
									<b>05</b>
								</div>
								<div className="inner">
									<b>10</b>
								</div>
								<div className="inner">
									<b>380</b>
								</div>
							</div>
							<div className="item">
								<div className="inner inner_big">
									<div className="icon"><img src={Picon1} alt="" /></div>
									<b>Mike Hussy</b><br />
									<small>xxxxx 12345</small>
								</div>
								<div className="inner">
									<b>05</b>
								</div>
								<div className="inner">
									<b>10</b>
								</div>
								<div className="inner">
									<b>380</b>
								</div>
							</div>
							<div className="item">
								<div className="inner inner_big">
									<div className="icon"><img src={Picon1} alt="" /></div>
									<b>Mike Hussy</b><br />
									<small>xxxxx 12345</small>
								</div>
								<div className="inner">
									<b>05</b>
								</div>
								<div className="inner">
									<b>10</b>
								</div>
								<div className="inner">
									<b>380</b>
								</div>
							</div>
							<div className="load_more text-center">
								<Link to='/' className="btn btn-primary">Load More</Link>
							</div>
						</div>
					</div>
					<div className="tab-pane" id="list-4" >
						<div className="tab_content">
							<ul className="cust_list">
								<li>Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </li>
								<li>A diam sollicitudin tempor id eu nisl nunc. At risus viverra adipiscing at.</li>
								<li>Tincidunt dui ut ornare lectus sit. commodo iverra maecenas accumsan lacus vel. Velit egestas dui id ornare arcu odio ut sem nulla. </li>
								<li>Eget gravida cum sociis natoque penatibus et magnis dis parturient. </li>
								<li>Mauris rhoncus aenean vel elit scelerisq. elerisque mauris pellentesque pulvinar ellentesque abitant morbi tristique.</li>
							</ul>
						</div>
					</div>
				</div>

			</div>
			<div className="bottom_btn">
				<button className="btn btn-dark main-btn">OPEN PUBG APP <img src={RightArrow} alt="" className="mCS_img_loaded" /></button>
			</div>
			<div className="custom-popup bg-primary " id="report_issue_popup">
				<button className="close-popup"><img src={Close} alt="" /></button>
				<div className="popup_avtar_wrap">
					<div className="img"><img src={rAvtar} alt="" /></div>
					<h5>Alyssa Healy</h5>
				</div>
				<div className="popup-body popup-body-scroll r_issue_popup">
					<h3>How was your experience with Gamerji?</h3>
					<div className="emoji_wrap">
						<div className="item">
							<div className="icon"><img src={E1} alt="" /></div>
							Unhappy
						</div>
						<div className="item">
							<div className="icon"><img src={E2} alt="" /></div>
							Natural
						</div>
						<div className="item">
							<div className="icon"><img src={E3} alt="" /></div>
							Satisfied
						</div>
					</div>
					<div className="r_checkbox_box bg-secondary mb-4">
						<div className="icon"><img src={E4} alt="" /></div>
						<h5>Satisfied</h5>
						<div className="checkbox_wrap checkbox_big_wrap">
							<div className="checkbox_item">
								<label>
									<input type="checkbox" />
									<span></span>
									Lorem ipsum dolor sit ame
								</label>
							</div>
							<div className="checkbox_item">
								<label>
									<input type="checkbox" />
									<span></span>
									Lorem ipsum dolor sit ame
								</label>
							</div>
							<div className="checkbox_item">
								<label>
									<input type="checkbox" />
									<span></span>
									Lorem ipsum dolor sit ame
								</label>
							</div>
						</div>
					</div>
					<h4>Do you want to Unlink your PayTM wallet?</h4>
					<div className="row mb-4">
						<div className="col-6 pr-2">
							<button className="btn btn-block btn-dark">YES</button>
						</div>
						<div className="col-6 pl-2">
							<button className="btn btn-block btn-secondary">NO</button>
						</div>
					</div>
					<button className="btn btn-dark main-btn mb-1">Finish <img src={RightArrow} alt="" /></button>
				</div>
			</div>

			<div className="custom-popup bg-primary" id="winning_breakup_popup">
				<div className="popup-head">
					<h3>Winning Breakup</h3>
					<button className="close-popup"><img src={Close} alt="" /></button>
				</div>
				<div className="popup-body">

					<div className="list-group contest-tab" id="list-tab" role="tablist">
						<a className="list-group-item list-group-item-action active" id="list-home-list" data-toggle="list" href="#po_list-1">Prize Pool</a>
						<a className="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" href="#po_list-2">Points</a>
					</div>
					<div className="tab-content" id="nav-tabContent">
						<div className="tab-pane active" id="po_list-1">
							<div className="prize_pool bg-secondary mb-3 text-center p-2">
								<p>Prize Pool</p>
								<h6>₹14,000</h6>
							</div>
							<div className="table-responsive total_table total_table_border mb-3">
								<table>
									<tbody>
										<tr>
											<td>Rank: 1</td>
											<th className="text-right">₹600</th>
										</tr>
										<tr>
											<td>Rank: 2</td>
											<th className="text-right">₹300</th>
										</tr>
										<tr>
											<td>Rank: 3-10</td>
											<th className="text-right">₹100</th>
										</tr>
										<tr>
											<td>Rank: 11-50</td>
											<th className="text-right">₹40</th>
										</tr>
										<tr>
											<td>Rank: 51-130</td>
											<th className="text-right">₹30</th>
										</tr>
										<tr>
											<td>Rank: 131-462</td>
											<th className="text-right">₹25</th>
										</tr>
									</tbody>
								</table>
							</div>
							<p className="text-center"><small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </small></p>
						</div>
						<div className="tab-pane" id="po_list-2" >
							<div className="prize_pool bg-secondary mb-3 text-center p-2">
								<p>Prize Pool</p>
								<h6>₹14,000</h6>
							</div>
							<div className="table-responsive total_table total_table_border mb-3">
								<table>
									<tbody>
										<tr>
											<td>Rank: 1</td>
											<th className="text-right">₹600</th>
										</tr>
										<tr>
											<td>Rank: 2</td>
											<th className="text-right">₹300</th>
										</tr>
										<tr>
											<td>Rank: 51-130</td>
											<th className="text-right">₹30</th>
										</tr>
										<tr>
											<td>Rank: 131-462</td>
											<th className="text-right">₹25</th>
										</tr>
									</tbody>
								</table>
							</div>
							<p className="text-center"><small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </small></p>
						</div>

					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default MonthlyChnampionship;
