import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
import { Row, Col } from "react-bootstrap";
import {
  woeLiveStreamingsList,
} from "../apis";
import Loader from "../component/loader";

import { handleTextAnimation } from "../component/textAnimation";
import Advertisement from "../component/advertisement";

import WOEIndividualFeaturedVideos from "./WOEIndividualFeaturedVideos";
import './world-of-esports.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import logo from '../assets/images/gamer_logo.svg';
import Previous from "../assets/images/previous.svg";
import { useTranslation, Trans, withTranslation } from "react-i18next";
class WOEAllFeaturedVideos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      WOEActiveScreen: this.props.WOEActiveScreen,
      WOEAllFeaturedVideosActiveScreen: 0,

      skip: 0,
      limit: 10,
      total_count: 0,
      popular_videos: [
        // {name: '', src: ''}, 
        // {name: '', src: ''}, 
        // {name: '', src: ''}, 
        // {name: '', src: ''}, 
        // {name: '', src: ''}, 
        // {name: '', src: ''}, 
      ],

      individual_popular_videos: { url: '', name: '' },

      tag_featured_videos_loader: true,
    };

    this.onChangeWOEAllFeaturedVideosActiveScreen = this.onChangeWOEAllFeaturedVideosActiveScreen.bind(this);
  }

  componentDidMount() {
    this.woeLiveStreamingsListApiCall();
  }

  componentDidUpdate() {
    // console.log('update')
    handleTextAnimation();
  }

  onChangeWOEAllFeaturedVideosActiveScreen(active) {
    // console.log(active)
    this.setState({ WOEAllFeaturedVideosActiveScreen: active });
  }

  async woeLiveStreamingsListApiCall() {
    this.setState({ tag_featured_videos_loader: true, })
    let data = {
      skip: this.state.skip,
      limit: this.state.limit,
      filter: {
        isLive: false,
      },
    };

    let response = await woeLiveStreamingsList(data);
    // console.log(response);
    if (response != undefined) {
      if ('list' in response) {
        let temp_total_count = response?.count;
        response.list.forEach((value, i) => {
          if (value.url.includes('embed')) {

          } else if (value.url.includes('watch')) {
            let str = value.url.split('=');
            response.list[i]['url'] = 'https://www.youtube.com/embed/' + str[str.length - 1];
          } else if (value.url.includes('youtu.be')) {
            let str = value.url.split('/');
            response.list[i]['url'] = 'https://www.youtube.com/embed/' + str[str.length - 1];
          }
        });
        response = this.state.popular_videos.concat(response.list);
        let skip = this.state.skip + this.state.limit;
        this.setState({ popular_videos: response, skip: skip, total_count: temp_total_count, });
      }
    }
    this.setState({ tag_featured_videos_loader: false, })
  }

  createWOEAllFeaturedVideos() {
    const { t } = this.props;

    return (
      <div className="inner-wrap woe_main_div_screen_1">
        <div className="header">
          <div className="back-btn"
            // style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
            onClick={(e) => this.props.onChangeWOEActiveScreen(0)}>
            <img src={Previous} alt="" />

            {/* <svg xmlns="http://www.w3.org/2000/svg" width="21.334" height="18.014" viewBox="0 0 21.334 18.014">
              <g id="ico" transform="translate(21.334 18.013) rotate(-180)">
                <path id="union" d="M8.321,17.721.479,9.879A1,1,0,0,1,.125,8.541,1.005,1.005,0,0,1,.411,8.2L8.321.293A1,1,0,0,1,9.735,1.706L3.417,8.025H20.334a1,1,0,1,1,0,2H3.454l6.281,6.281a1,1,0,0,1-1.414,1.415Z" transform="translate(0 0)" fill="#fff" />
              </g>
            </svg> */}
          </div>
          <h2>{t('woe.videos')}</h2>
          <div className="wallet-btn">
            {/* <Link to="">
              <img src={Wallet} alt="" />
              Wallet
            </Link> */}
          </div>
        </div>

        <div className="body bottom-btn-body woe_main_div_screen_1_body" style={{ paddingTop: '0', }}>
          <div className="all_sub_screens_div_related_to_ads">
            <Row className="m-0" style={{ padding: '20px 0', justifyContent: 'center' }}>
              {
                this.state.popular_videos.map((value, i) => {
                  return (
                    <Col className="woe_screens_box_col p-2 m-0" xl={6} lg={6} md={6} sm={6} xs={12} key={i}>
                      <div
                        className="featured_videos_box_screen_1"
                        onClick={(e) => {
                          this.setState({
                            individual_popular_videos: value,
                          }, () => {
                            this.setState({ WOEAllFeaturedVideosActiveScreen: 1, });
                          })
                        }}
                      >
                        <iframe className="woe_popular_videos_screen_1" src={value.url} allowFullScreen></iframe>


                        <marquee><h5 className="pop_text">{value.name}</h5></marquee>
                            

                        {/* <div className="pop_icons">
                          <div className="pop_view_icon">

                              <VisibilityIcon/>  <p className="pop_view">Views</p>

                          </div>

                          <div className="fav_icon">

                             <FavoriteIcon/>  <p className="fav">Like</p>

                          </div>

                           <div className="share_icon">
                              <ShareIcon/> <p className="share">Share</p>
                           </div>

                        </div> */}


                       
                     


                        <div className="woe_popular_videos_name text_animation_container" style={{ marginTop: '3px' }}>{value.name}</div>


                       
  
                      </div>
                            

                      {/* <div className="featured_subs">

                      <div className="pop_bg_img">

                        <img
                          src={logo}
                          // style={{ width: '35px', height: '35px' }}
                          alt="profile"
                          className="pop_logo_img"
                        />
                        </div>


                        <h5 className="pop_subs_text">GamerJi</h5>
                        <h5 className="pop_subs_count">15.9k Subscriber</h5>
                        <div className="pop_line"></div>

                        <h4 className="pop_subs_now">SUBSCRIBE</h4>
                      </div> */}
                     
                    </Col>
                  )
                })
              }
            </Row>

            <Row className="woe_screen_1_load_more_button_row justify-content-center m-0" style={{ padding: '10px' }}>
              {
                this.state.total_count === this.state.popular_videos.length ?
                <div></div> 
                : 
                <button className="woe_screen_1_load_more_button" onClick={(e) => this.woeLiveStreamingsListApiCall()}>
                  {t('tournaments.load_more')}
                </button>
              }
            </Row>
          </div>
          {
             !JSON.parse(localStorage.getItem("premiumUser")) && 
          
          <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).viewAllVideos
            }
            screen_div_class_name='.all_sub_screens_div_related_to_ads'
            screen_type='sub'
          />
  }
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.state.tag_featured_videos_loader ? <Loader /> : ''}
        {this.createWOEAllFeaturedVideos()}
        <div
          className="woe_individual_popular_video_component"
          hidden={this.state.WOEAllFeaturedVideosActiveScreen === 0}
        >
          <WOEIndividualFeaturedVideos
            individual_popular_videos={this.state.individual_popular_videos}
            onChangeWOEAllFeaturedVideosActiveScreen={this.onChangeWOEAllFeaturedVideosActiveScreen}
          />
        </div>
      </div>
    );
  }
}
// export default WOEAllFeaturedVideos;
export default withTranslation()(WOEAllFeaturedVideos);
