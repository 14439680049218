import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "../component/loader";

import RightArrowLight from "../assets/images/right-arrow.svg";
import Cancel from "../assets/images/round_not_qualified.png";

import AR from "../assets/images/arabic.png";
import EN from "../assets/images/english.png";
import HI from "../assets/images/hindi.png";
import ARW from "../assets/images/arabic_white.png";
import ENW from "../assets/images/english_white.png";
import HIW from "../assets/images/hindi_white.png";
import Filipino from "../assets/images/filipino.png";
import FilipinoW from "../assets/images/filipino_white.png";
import Malay from "../assets/images/Malay.png";
import MalayW from "../assets/images/malayW.png";
import Thai from "../assets/images/Thai.png";
import ThaiW from "../assets/images/thai_white.png";

import ARText from "../assets/images/arabic-text.png";
import ENText from "../assets/images/english-text.png";
import HIText from "../assets/images/hindi-text.png";
import malayText from "../assets/images/malay-text.png";
import FilipinoText from "../assets/images/filipino-text.png";
import ThaiText from "../assets/images/thai-text.png";

import "./LanguagePopup.css";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getLanguage } from "../apis";
import {companyId} from "../constants/url";

export default function LanguagePopup(props) {
  const [selectedLanguageVal, setSelectedLanguage] = useState("en");
  // const [languageData, setLanguageData] = useState([
  //   { letter: English, letter1: EnglishW, language: EnglishText, value: 'en', isSelected: false, },
  //   { letter: Arabic, letter1: ArabicW, language: ArabicText, value: 'ar', isSelected: false, },
  //   { letter: Hindi, letter1: HindiW, language: HindiText, value: 'hi', isSelected: false, },
  //   { letter: Malay, letter1: MalayW, language: malayText, value: 'ms', isSelected: false, },
  //   { letter: Filipino, letter1: FilipinoW, language: FilipinoText, value: 'tl', isSelected: false, },
  //   { letter: Thai, letter1: ThaiW, language: ThaiText, value: 'th', isSelected: false, },

  // ]);
  const [languages, setLanguages] = useState([]);

  const [loader, setLoader] = useState(false);
  const [lang, setLang] = useState(
    localStorage.getItem("langName") || "English"
  );
  const currentLang = Cookies.get('i18next') || 'EN';
  const { t } = useTranslation();

  const fetchLanguage = async () => {
    const body = {
      companyId: companyId,
    };
    try {
      const res = await getLanguage(body);
      // console.log("fetchLanguage", currentLang);
      let data = res?.data?.map((ele, ind) => {
        return {
          ...ele,
          isSelected: currentLang.toLowerCase() == ele?.code?.toLowerCase() ? true : false,
          letter1: ele?.code + "W",
          language: ele?.code + "Text",
          letter: ele?.code,
        };
      });
      // console.log(data);
      setLanguages(data);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchLanguage();
    let language_data = JSON.parse(localStorage.getItem("languageData"));

    let temp = [...languages];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].value === language_data?.selectedLanguage)
        temp[i].isSelected = true;
      else temp[i].isSelected = false;
    }
    setLanguages(temp);
  }, []);
  // console.log("--------", languages);

  const onClickLanguageBox = (value) => {
    // console.log("Selected Language: ", value);
    setSelectedLanguage(value.value);
    let temp = [...languages];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].code === value.code) temp[i].isSelected = true;
      else temp[i].isSelected = false;
    }
    // console.log(temp)
    setLanguages(temp);
  };

  const onClickProceed = () => {
    let selected_language = languages.find(
      (val) => val.isSelected === true
    )?.code;
    // console.log('Selected Language: ', selected_language)
    if (selected_language === null || selected_language === undefined) {
      showError(t('select_any_language_to_proceed'));
    } else {
      // setLoader(true);
      // console.log("selected_language222==>",selected_language);
      //setLoader(false);
      updateLanguage(selected_language);
      props.onClickCancelIconSL(false);
      let language_data = {
        showLanguagePopup: false,
        selectedLanguage: selected_language,
      };
      localStorage.setItem("languageData", JSON.stringify(languages));
    }
  };

  const showError = (error) => {
    toast.error(error, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updateLanguage = (value) => {
    setLoader(true);
    const lang_code = value.toLowerCase();
    i18next.changeLanguage(lang_code);
    setLang(lang_code);
    localStorage.setItem("langName", lang_code);
    localStorage.setItem("i18nextLng", lang_code);
    Cookies.set("i18nextLng", lang_code);
    const linkTag = document.getElementById('bootstrap_css');
    document.documentElement.dir =
    lang_code == "ar"  ? "rtl" : "ltr";
    
    if (lang_code == "id" ) {
      document.documentElement.setAttribute('lang', 'id');
    }
    if (lang_code== "ar" ) {
      document.documentElement.setAttribute('lang', 'ar');
      linkTag?.setAttribute(
        'href',
        `${process.env.REACT_APP_CLIENT_BASE_URL}asserts/css/bootstrap.rtl.min.css`
      );
    }
    document.title = t('title')
    document.documentElement.setAttribute('lang', lang_code);
    // window.location.href = "/more";
    setLoader(false);
  };

  return (
    <div className="language_popup_main_div">
      {loader === true && <Loader />}
      <ToastContainer />
      <div className="language_popup_popup">
        <div className="language_popup_header">
          <div className=""></div>
          <div className="language_popup_header_text">{t('select_language')}</div>
          <div className="language_popup_cancel_icon">
            <img
              src={Cancel}
              onClick={() => {
                props.onClickCancelIconSL(false);
                let language_data = {
                  showLanguagePopup: false,
                  selectedLanguage: JSON.parse(
                    localStorage.getItem("languageData")
                  )?.selectedLanguage
                    ? JSON.parse(localStorage.getItem("languageData"))
                        ?.selectedLanguage
                    : "",
                };
                localStorage.setItem(
                  "languageData",
                  JSON.stringify(language_data)
                );
              }}
            />
          </div>
        </div>

        <div className="country_list_popup_body">
          {/* <div className="all_sub_screens_div_related_to_ads">
            <div className="language_popup_body_scrollable_component">

              <div className="row m-0"> */}
          {languages?.map((value, i) => {
            return (
              // <div className="col-4 mb-4" key={i}>
              //   <div className="language_popup_alphabet_box"
              //     style={{ backgroundColor: value?.isSelected ? 'var(--secondary)' : 'var(--dark)' }}
              //     onClick={() => onClickLanguageBox(value)}
              //   >
              //       <img src={value.letter} alt="" />

              //   </div>
              //   <div className="language_popup_language_name">
              //     <img src={value.language} alt="" />
              //   </div>
              // </div>

              <div
                className="language_list_popup_body_text"
                key={i}
                onClick={() => onClickLanguageBox(value)}
                style={{
                  backgroundColor: value?.isSelected
                    ? "var(--secondary)"
                    : "",
                }}

                // onClick={() => {
                //   setCountriesDialcode(value);
                //   setDialCode(value.value);
                //   setTagCountriesDialcodePopup(false);
                // }}
              >
                {/* <div
                  className="language_popup_alphabet_box"
                  style={{
                    backgroundColor: value?.isSelected
                      ? "var(--secondary)"
                      : "var(--dark)",
                  }}
                  onClick={() => onClickLanguageBox(value)}
                >
                  {value?.isSelected ? (
                    <img src={value.letter} alt="" />
                  ) : (
                    <img src={value.letter1} alt="" />
                  )}

                </div> */}
                {/* <img src={value.language} alt="" style={{ height: 60, marginTop: '-4px', marginLeft: 10 }} /> */}
                <h5 className={currentLang?.toLowerCase() == "ar" ? "mr-2" : "ml-2"}>{value?.name}</h5>
               
                {/* {value.name} */}
                {/* Hello */}
              </div>
            );
          })}
        </div>

        <button
          id="language_popup_submit_button_id"
          className="language_popup_submit_button"
          data-lang={selectedLanguageVal}
          onClick={() => onClickProceed()}
        >
          {t('proceed')}
          {/* {
                  languageData.find(val => val.isSelected === true)?.value &&
                  <div>
                    {" - "}
                    {languageData.find(val => val.isSelected === true)?.value}
                    {" - "}
                  </div>
                } */}
          <img src={RightArrowLight} alt="" className="arrow-deg" />
        </button>
        {/* </div>
          </div> */}
        {/* <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).dobAndStateValidation
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            /> */}
        {/* </div> */}
      </div>
    </div>
  );
}
