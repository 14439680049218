import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getUserProfile,
  requestLevelList,
  requestGameStats,
  getCollegeList,
  getUpdateProfile,
} from "../../apis";
const ProfileContext = createContext();

export const useProfile = () => {
  return useContext(ProfileContext);
};

export const ProfileProvider = ({ children }) => {
  const [allData, setAllData] = useState({});
  const [loading, setLoading] = useState(false);
  const [infoTitle, setInfoTitle] = useState("");
  const [collegeList, setCollegeList] = useState(null);
  const [levelProgressValue, setLevelProgressValue] = useState(0);
  const [gameStatsList, setGameStatsList] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [infoDesc, setInfoDesc] = useState("");
  const [activeTab, setActiveTab] = useState("Stats");

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const HandleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const topLinkClick = (value) => {
    window.location.href = value;
  };

  const getTotalCoin = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "coin");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  const getTotalDiamond = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "diamond");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  const fetchData = async () => {
    setLoading(true);
    let res = await getUserProfile();
    if (res) {
      setAllData(res);
      //console.log("ALL_Data: ", res);
      localStorage.setItem("currentCoin", getTotalCoin(res?.item?.wallet));
      localStorage.setItem(
        "currentDiamond",
        getTotalDiamond(res?.item?.wallet)
      );
      if (res.item.level) {
        let currentLevelPoints =
          res.item.level?.level?.endPoint -
          (res.item.level?.level?.startPoint - 1);

        let userPointsAsPerLevel =
          res.item.level?.points - (res.item.level?.level?.startPoint - 1);

        let lpVal = (userPointsAsPerLevel * 100) / currentLevelPoints;

        setLevelProgressValue(lpVal);
      }
      if (res?.item?.gameNames && res?.item?.gameNames.length > 0) {
        fetchGameStats(res?.item?.gameNames);
      }
      // call college list api
      let collegeList = await getCollegeList();
      if (collegeList) {
        //console.log("College: ", collegeList.list);
        setCollegeList(collegeList?.list);
      }
      setLoading(false);
    }
  };

  const fetchGameStats = async (gameNames) => {
    setLoading(true);
    let arrGameStats = [];
    let games = [];
    let gameNamesArr = [];
    if (gameNames && gameNames.length > 0) {
      gameNames.forEach(async function (element) {
        //console.log("element==>", element);
        if (element.uniqueIGN != "" && element.uniqueIGN != undefined) {
          games.push(element.game);
          gameNamesArr[element.game] = element.uniqueIGN;
        }
      });

      let res = await requestGameStats({ games: games, appType: "webapp" });

      if (res && res.list) {
        var gameDataArr = [];
        res.list.forEach(async function (element, index) {
          var gameDataDateArr = [];
          var gameDataPlayedArr = [];
          element.stateatistic.forEach(function (element1) {
            var d = new Date(element1.date);

            gameDataDateArr.push(element1.date);

            gameDataPlayedArr.push(element1.played);
          });
          gameDataArr.push({
            avgRates: element.avgRates,
            game: element.game,
            played: element.played,
            uniqueIGN: gameNamesArr[element.game._id.toString()],
            data: {
              labels: gameDataDateArr,
              datasets: [
                {
                  label: "Played " + element.played,
                  data: gameDataPlayedArr,
                  borderColor: "rgb(255, 198, 9)",
                  backgroundColor: "rgb(255, 198, 9)",
                },
              ],
            },
          });
        });

        setGameStatsList(gameDataArr);
      }
      setLoading(false);
    }
  };

  const onClickInfo = (title, desc) => {
    setShowInfo(true);
    setInfoTitle(title);
    setInfoDesc(desc);
  };

  const options = {
    responsive: true,
    fill: true,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 5,
        
      },
    },
    plugins: {
      legend: {
        position: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  
  return (
    <ProfileContext.Provider
      value={{
        onClickInfo,
        topLinkClick,
        fetchData,
        allData,
        loading,
        collegeList,
        levelProgressValue,
        gameStatsList,
        showInfo,
        HandleTabClick,
        activeTab,
        options
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
