import React from "react";
import { Link } from "react-router-dom";
import { onCreateContest, checkUserDetails, getUserInGameName } from "../apis";
import Previous from "../assets/images/previous.svg";
import rightArrow from "../assets/images/right-arrow.svg";
import url from "../constants/url";
import squadDummy from "../assets/images/squad-dummy.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";

import Advertisement from "../component/advertisement";
import { constants } from "../constants/constants";
import { Captioncard } from "./Scrims/Captioncard";
import { SquadPlayerCard } from "./squadPlayerCard";
import { routehelp } from "../config/routehelp";
import PremiumLogo from "../assets/images/premium.png";
import PremiumClose from "../assets/images/cancel_premium.png";
import Logo from "../assets/images/logo.svg";
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie'
import { InGameNameApiCall } from "../component/common";
class SquadRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user_data: [],
      players_data: [],
      teamName: "",
      total_players: 0,
      extra_players: 0,
      uniqueIgn: "",
      selectedContest: "",
      disabledCards: [],
      showPremiumPopup:false,
      premiumUser:JSON.parse(localStorage.getItem("premiumUser")),
      currentLang:  Cookies.get('i18next') || 'EN'
    };

    this.onChangePhoneNo = this.onChangePhoneNo.bind(this);
    this.onClickApply = this.onClickApply.bind(this);
    this.onClickRemove = this.onClickRemove.bind(this);
    this.onClickSaveTeam = this.onClickSaveTeam.bind(this);
    this.hasDuplicate = this.hasDuplicate.bind(this);
  }

  handleInputChange = (event) => {
    const value = event.target.value;
    // setTeamName(value);
    this.setState({ teamName: value });

    //console.log(event.target.value);
    //console.log(this.state.teamName);
  };

  findUserName = async(game_ID) => {
    // let activeGameId = localStorage.getItem("activeGameId");
    // console.log("activeGameId", activeGameId);
    // let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    // console.log("gameNames", gameNames);
    // if (gameNames) {
    //   let index = gameNames.findIndex((obj) => obj.game === activeGameId);
    //   console.log("index gamename", index);
    //   if (index >= 0) {
    //     console.log(
    //       "index gameNames[index].userINGameName",
    //       gameNames[index].userINGameName
    //     );

    //     // setCurrentName(gameNames[index].userINGameName)
    //     return gameNames[index].userINGameName || "";
    //   } else {
    //     return "";
    //   }
    // }
    // console.log(game_ID)
   
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    let payload = {
      "search": {
        "user": user_data?.item?.user_id,
        "game": localStorage.getItem('activeGameId')
      }
    }
  
    let gameNamesData = await getUserInGameName(payload)
    //console.log(gameNamesData,"*****************")
    return gameNamesData?.[0]?.uniqueIGNName;

  };

  componentDidMount() {
    let allFeaturedData = JSON.parse(localStorage.getItem("joinTounament"));
    //console.log(allFeaturedData);

    var uniqueI = "";

    var gameid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];

    var game_ID =
      gameid === "all" ? allFeaturedData?.gameId : allFeaturedData?.game?._id;

    // console.log("allFeaturedData: ", allFeaturedData);
    // console.log("allFeaturedData?.gameId: ", allFeaturedData?.gameId);
    // console.log("allFeaturedData?.game?._id: ", allFeaturedData?.game?._id);
    // let games = JSON.parse(
    //   localStorage.getItem(constants.profileLite)
    // )?.item?.gameNames.find((val) => val?.game === game_ID);
    // console.log("games", games);
    uniqueI = this?.props?.currentName;

    var total_players = this.props.total_players;
    //console.log(total_players);
    var extra_players = this.props.extra_players;
    var players_data = [];
    for (let i = 0; i < total_players + extra_players; i++) {
      players_data.push({
        user_id: "",
        gamerjiName: "",
        levelImage: "",
        in_game_name: "",
        isClickOnApplyPlayer: false,
        phone: "",
        email: "",
      });
    }
    //console.log(total_players);
    this.setState({
      user_data: JSON.parse(localStorage.getItem(constants.profileLite)),
      players_data: players_data,

      total_players: total_players,
      extra_players: extra_players,
      uniqueIgn: uniqueI,
      selectedContest: this.props.selectedContest,
    });
  }

  onChangePhoneNo(e, index) {
    var players_data = this.state.players_data;
    players_data[index].playerPhone = e.target.value;
    this.setState({ players_data: players_data });
  }

  checkMobileWithTeam(number, index) {
    //addLog("checkMobileWithTeam", number)
    // console.log("checkMobileWithTeam number", number)
    // console.log(" this.state.players_data", this.state.players_data);

    // for (let i = 0; i < this.state.players_data; i++) {
    //   let obj = this.state.players_data[i]
    //   if (index != i) {
    //     if (obj?.playerPhone === number)
    //       return true
    //     else
    //       return false
    //   }
    //   else {
    //     return false
    //   }
    // }
    let record = this.state.players_data.filter(
      (obj) => obj?.playerPhone === number
    );
    if (record.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  onClickRemoveEmail = (index) => {
    var players_dataTemp = [...this.state.players_data];

    players_dataTemp[index] = {
      userId: "",
      inGameName: "",
      gamerJiName: "",
      country: "",
      mobileNo: "",
      isClickOnApplyPlayer: false,
      email: "",
    };

    this.setState({ players_data: players_dataTemp });
    this.setState((prevState) => ({
      disabledCards: prevState.disabledCards.filter(
        (cardIndex) => cardIndex !== index
      ),
    }));
    // setPhone('');
  };
  onClickAddEmail = async (index) => {
    const {t} = this.props;

    if (
      this.state.players_data[index]?.email === undefined ||
      !this.state.players_data[index].email
    ) {
      this.showError(t('error_please_enter_email'));
    } 
    else if (!validator.isEmail(this.state.players_data[index]?.email)){
      this.showError(t('error_Please_enter_a_valid_email_address'))
    }
    else if (
      this.state.user_data?.item?.email === this.state.players_data[index].email
    ) {
      this.showError(t('error_enter_other_than_yours'));
    } else if (this.checkMobileWithTeam(this.state.players_data[index].email)) {
      this.showError(t('error_enter_already_added'));
    } else {
      let payload = {
        search: {
          phone: this.state.players_data[index].email,
          //   game:scrim_data.game._id,
        },
      };
      let res = await checkUserDetails(payload);
      //console.log(res);

      if (res && res.success === true) {
        //console.log("Add Players: data ", res.data);
        if (res.data.length > 0) {
          // console.log(playersData, "test1");
          var players_data = this.state.players_data;
          players_data[index] = {
            user_id: res?.data[0]?.user,
            gamerjiName: res?.data[0]?.gamerjiName,
            levelImage: res?.data[0]?.userLevelFeaturedImage,
            inGameName: res?.data[0]?.userINGameName,
            isClickOnApplyPlayer: true,
            playerPhone: players_data[index].playerPhone,
            email: players_data.email,
          };
          // console.log(this.state.players_data, "test2");
          // console.log(players_data, "test3");

          this.setState({ players_data: players_data });
          this.setState(prevState => ({
            disabledCards: [...prevState.disabledCards , index]
          }));
        } else {
          this.showError(t('error_please_ask_user_to_add_game_name'));
        }
      } else {
        //console.log("User not found!");
        this.showError(res.data.errors[0]?.msg);
      }
    }
  };

  async onClickApply(index) {
    //console.log(this.state.players_data);
    const {t} = this.props;

    if (
      this.state.players_data[index].playerPhone === "" ||
      this.state.players_data[index].playerPhone === undefined
    ) {
      // console.log('Please enter the phone number!')
      this.showError(t('error_Please_enter_the_phone_number'));
    } else if (
      this.state.user_data?.item?.phone ===
      this.state.players_data[index].playerPhone
    ) {
      this.showError(t('error_enter_other_than_yours'));
    } else if (
      this.checkMobileWithTeam(
        this.state.players_data[index].playerPhone,
        index
      )
    ) {
      this.showError(t('error_enter_already_added'));
    } else {
      return false
    }


  }
  async onClickApply(index) {
    const {t} = this.props;

    //console.log(this.state.players_data)

    if (this.state.players_data[index].playerPhone === '' || this.state.players_data[index].playerPhone === undefined) {
      // console.log('Please enter the phone number!')
      this.showError(t('error_Please_enter_the_phone_number'));
    }
    else if (this.state.user_data?.item?.phone === this.state.players_data[index].playerPhone) {
      this.showError(t('error_enter_other_than_yours'))
    }
    else if (this.checkMobileWithTeam(this.state.players_data[index].playerPhone, index)) {
      this.showError(t('error_enter_already_added'))
    }
    else {
      // let data = { phone: this.state.players_data[index].playerPhone, "dialingCode": this.state.user_data?.item?.phoneCode };
      let allFeaturedData = JSON.parse(localStorage.getItem("joinTounament"));
      let payload = {
        search: {
          phone: this.state.players_data[index].playerPhone,
          phoneCode: this.state.user_data?.item?.phoneCode,
          game: allFeaturedData.game,
        },
      };
      let response = await checkUserDetails(payload);
      // console.log("Add Players: ", response);

      if (response && response?.success === true) {
        // console.log('response?.success === true')
        //console.log("Add Players: data ", response.data);

        // if (response.item?.gameNames?.length > 0) {
        if (response.data.length > 0) {
          // console.log('response.item?.gameNames?.length > 0')

          var players_data = this.state.players_data;
          players_data[index] = {
            user_id: response?.data[0]?.user,
            gamerjiName: response?.data[0]?.gamerjiName,
            levelImage: response?.data[0]?.userLevelFeaturedImage,
            inGameName: response?.data[0]?.userINGameName,
            isClickOnApplyPlayer: true,
            playerPhone: players_data[index].playerPhone,
          };

          this.setState({ players_data: players_data });
          this.setState(prevState => ({
            disabledCards: [...prevState.disabledCards , index]
          }));
        } else {
          // console.log("Please ask user to add game name!");
          this.showError(t('error_please_ask_user_to_add_game_name'));
        }
      } else {
        // console.log("User not found!");
        this.showError(response.data.errors[0]?.msg);
      }
    }
  }

  onClickRemove(index) {
    var players_data = this.state.players_data;
    players_data[index] = {
      user_id: "",
      gamerjiName: "",
      levelImage: "",
      inGameName: "",
      isClickOnApplyPlayer: false,
      playerPhone: "",
    };

    this.setState({ players_data: players_data });
    this.setState((prevState) => ({
      disabledCards: prevState.disabledCards.filter(
        (cardIndex) => cardIndex !== index
      ),
    }));
  }

  hasDuplicate(array, param) {
    const values = array.map(obj => obj[param]);
    const uniqueValues = new Set(values);

    return values.length !== uniqueValues.size;
  }

  async onClickSaveTeam() {
    const {t} = this.props;

    var gameid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];

    if (this.state.teamName.trim() === "") {
      if (this.state.teamName.trim() === "") {
        this.showError(t('error_team_name_is_required'));
      } else {
        this.showError(t('error_please_players_details'));
      }
      return;
    }
    // console.log("total_players: ", this.state.total_players);
    // console.log("gameid: ", gameid);
    var all_players_id = [];
    let players = [];
    this.state.players_data.forEach((element) => {
      var player = {
        phone: element.playerPhone,
        in_game_name: element.inGameName,
        user_id: element.user_id,
        gamerjiName: element.gamerjiName,
        email: element.gamerjiName,
      };
      players.push(player);
      if (element?.user_id !== "") all_players_id.push(element?.user_id);
    });
    // console.log("all_players_id: ", all_players_id);
     let allFeaturedData = JSON.parse(localStorage.getItem("joinTounament"));

    // for (let index = 0; index < (this.state.total_players + this.state.extra_players); index++) {
    //   console.log("index====>===========", index);

    //   if (index != 0 && this.state.total_players > index) {
    //     console.log("index====>", index);
    //     if (!players[index]) {
    //       this.showError(`Please add player ${index + 1} detail`)
    //       return
    //     }
    //   }
    // }
    if (all_players_id.length >= this.state.total_players) {
      const isUserIdDuplicated =   this.hasDuplicate(this.state.players_data, "user_id");
      if(!isUserIdDuplicated)
      {
        let data = {};
        var temp_allTeamsIds = all_players_id;

         if (gameid === "all") {
          data = {
            team: temp_allTeamsIds,
            contest: this.state.selectedContest,
            isActive: true,
            event: allFeaturedData?.id,
            type: "event",
            uniqueIGN: this.state.uniqueIgn,
            teamName: this.state.teamName,
            // phoneNo :user_data?.item?.phone,
          };
        } else {
          data = {
            players_data: players,
            contest: allFeaturedData?._id,
            // type: "contest",
            inGameUserName: this.state.uniqueIgn,
            joinedDate: new Date(),
            JoinedTimestamp: new Date().getTime(),
            user: this.state.user_data?.item?.user_id,
            teamName: this.state.teamName,
            // phoneNo :user_data?.item?.phone,
          };
        }

        // console.log("SquadRegistration Payload: ", data);

        let res = await onCreateContest(data);
        // console.log("SquadRegistration: ", res);

        if (res && res?.success === true) {
          // setCreateData(res?.item);
          if (gameid === "all") {
            window.location.href = "/tounament-details/" + allFeaturedData?.id; //routehelp.contestdetails;
          } else {
            window.location.href = "/contest-details/" + allFeaturedData?._id; //routehelp.contestdetails;
          }
        } else {
          // this.showError(res?.data?.errors?.[0]?.msg);
        this.setState({showPremiumPopup:true})

        }
      }
      else{
        this.showError(t('error_enter_already_added'));
      }
    } else {
      // console.log("all_players_id.length: ", all_players_id.length);
      // console.log("allFeaturedData: ", allFeaturedData);
      this.showError(t('error_please_players_details'));
      // console.log("toast");
      this.setState({showPremiumPopup:true})
    }
    // if(numPlayers?.length === allTeamsIds?.length){
    //   setErrorMsg('')
    //   setShowJoinTeam(false)

    // let res = await onCreateContest(data);
    // if (res) {
    //   setCreateData(res?.item);

    // }
    // } else {
    //   setErrorMsg('Please add required player...')
    // }
  }

  showError(error) {
    //console.log("Squad")
    toast.error(error, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  render() {
    const {t} = this.props;

    return (
      <div
        className="custom-popup join-team custom_scroll show"
        id="contact_popup"
        style={{ padding: "0px", height: "100vh" }}
      >
        {/* <ToastContainer /> */}
        <div className="header">
          <div className="back-btn">
            <Link to="#" onClick={() => this.props.backButtonSetShowJoinTeam()}>
              <img src={Previous} alt="" />
            </Link>
          </div>
          <h2>{t('team_register.team_registeration')}</h2>
          <div className="wallet-btn"></div>
        </div>
        <div
          className="body bottom-btn-body"
          style={{
            width: "100%",
            padding: 0,
            margin: 0,
            top: 65,
            position: "absolute",
          }}
        >
          <div className="all_sub_screens_div_related_to_ads">
            <div className="squad_registration_body_scrollable_component mb-4">
              <div className="width8 relative">
                <input
                  className="width scrim-team-name"
                  type="text"
                  maxLength={64}
                  onChange={this.handleInputChange}
                />
                <div className="scrim-team-name-title">{t('team_register.team_name')} :</div>
              </div>
              <Captioncard data={this.state} userINGameName={this?.props?.currentName}/>

              <SquadPlayerCard
                total_players={this.state.total_players}
                extra_players={this.state.extra_players}
                selectedContest={this.state.selectedContest}
                backButtonSetShowJoinTeam={this.backButtonSetShowJoinTeam}
                playersData={this.state.players_data}
                onClickApply={this.onClickApply}
                onChangePhone={this.onChangePhoneNo}
                onClickRemove={this.onClickRemove}
                onClickAddEmail={this.onClickAddEmail}
                onClickRemoveEmail={this.onClickRemoveEmail}
                disabledCards={this.state.disabledCards}
              />

              {/* {
                errorMsg !== '' &&
                <div className="row">
                  <h6 className="error" style={{ fontWeight: 'bold', textAlign: 'center', width: '100%' }}>
                    {errorMsg}
                  </h6>
                </div>
              } */}
              <div className="row squad_registration_caption_player_row">
                <button
                  style={this.state.currentLang?.toLowerCase() == "ar" ? { width: "80%", margin: "0px 48px 20px 0px" } : { width: "80%", margin: "0px 0px 20px 48px" }}
                  className="btn btn-dark main-btn"
                  onClick={() => this.onClickSaveTeam()}
                >
                  {t('team_register.save_team')}{" "}
                  <img src={rightArrow} alt="" className="mCS_img_loaded" />
                </button>
              </div>

              {/* <div className="row">&nbsp;</div> */}
            </div >
          </div >
          {this.state.showPremiumPopup === true && (
          <div className="tc_popup_bg">
            <div
              className={`custom-popup  confirm custom_scroll join show premium_active rules`}
              id="contact_popup"
            >
              <div className={`popup-head  premium_active`}>
                {/* <marquee> */}
                <h3> {JSON.parse(localStorage.getItem("joinTounament"))?.title} </h3>
                {/* </marquee> */}

                <button
                  className="close-popup"
                  onClick={() => this.setState({showPremiumPopup:false})}
                >
                  <img src={PremiumClose} alt="" />
                </button>
              </div>
              <div className="ps_header" style={{ height: "150px" }}>
                <div className="ps_logo">
                  <img src={Logo} />
                </div>
                <div className="ps_name">{t('tournaments.premium')}</div>
              </div>
              <div className="ps_popup_main">
                <div className="ps_popup_p">
                <p>{t('premium.you_have_already_joined_today',{title: 'tournament', total: localStorage?.getItem('noOfTournament') || 1})}</p>
                </div>
                <div className="ps_popup_p layer2">
                <p>{t('premium.to_join_a_tournament')}</p>
                  <p>{t('premium.subscribe_to_gamerji')}</p>
                </div>
                <div className="ps_popup_p">
                <p>{t('premium.premium_users_get_to_join_unlimited_contests_tournaments_for_free_and_many_more_other_benefits')}
                  </p>
                </div>
              </div>

              <button
                className={`btn premium_active_btn`}
                onClick={() => window.href.location = routehelp.premiumSubscription}
                style={{
                  textAlign: "center",
                  width: "80%",
                  margin: "0 auto",
                  marginBottom: "30px",
                }}
              >
               {t('Profile.explore_gamerji_premium')}
              </button>
            </div>
          </div>
        )}
        {
           !JSON.parse(localStorage.getItem("premiumUser")) && 
        
          <Advertisement
            screen={
              localStorage.getItem("apk_screens_list") === null
                ? { code: "no_results" }
                : JSON.parse(localStorage.getItem("apk_screens_list"))
                  .squadRegistration
            }
            screen_div_class_name=".all_sub_screens_div_related_to_ads"
            screen_type="sub"
          />
  }
        </div >
      </div >
    );
  }
}
export default withTranslation()(SquadRegistration);

// export default SquadRegistration;
