import React, { Fragment } from "react";
import Previous from "../assets/images/previous.svg";
import { Link } from "react-router-dom";

function Legality(props) {
  return (
    <Fragment>
      <div className="header">
        <div className="back-btn">
          <Link to="/">
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>Legality</h2>
        <div className="wallet-btn"></div>
      </div>
      <div className="body mCustomScrollbar _mCS_1">
        <div className="legality-card">
          <h4>1. Introduction</h4>
          <p>
            1.1. These Terms and Conditions are a legally binding document and
            are an electronic record in the form of an electronic contract
            formed under Information Technology Act, 2000 and rules made
            thereunder. These Terms and Conditions must be read in conjunction
            with the Privacy Policy (hereinafter referred to as “Privacy
            Policy”) and any other terms set out on our website or application.
          </p>
          <p>
            1.2. These Terms and Conditions apply to all users of the Portal,
            including any contributors of content (the “Users”).
          </p>
          <p>
            1.3. By registering on, browsing, downloading, using or accessing
            any part of the Portal or utilizing the Services, the Users agree to
            be bound by these Terms and Conditions, as well as the Privacy
            Policy and all other additional or modified terms and conditions and
            policies in relation to the Portal or any current or future service
            that may be offered by GAMERJI including but not limited to all the
            terms defined herein below.
          </p>
          <p>
            1.4. If at any time the Users do not accept or agree to any of the
            Terms and conditions or policies mentioned in Clause 1.3, on a
            continuing basis in relation to any of the Services offered, they
            may not access the Portal or use any of the Services.
          </p>
          <p>
            1.5. These Terms and Conditions were posted on 18/7/2019. GAMERJI
            reserves the right to periodically review, update, change or replace
            any part of these Terms and Conditions as it may consider
            appropriate.
          </p>
          <p>
            1.6. Any changes to these Terms and Conditions will be posted on
            this page and where appropriate and/or notified to the Users by
            e-mail.
          </p>
        </div>
      </div>
    </Fragment>
  );
}

export default Legality;
