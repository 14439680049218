import React, { Fragment, useEffect, useState } from "react";
import AllGames from "../assets/images/all-games.svg";
import GameIconSmall1 from "../assets/images/game-icon-small1.svg";
import GameIconSmall2 from "../assets/images/game-icon-small2.svg";
import { Link } from "react-router-dom";
import { allGames, getUserInGameName, html5SettingsList, getExternalGamePBE, getExternalGamePBL } from "../apis";
import { convertNumberToMillions, dateFormat, kFormatter } from "../component/common";
import url from "../constants/url";
import Loader from "../component/loader";
import { useTranslation, Trans, withTranslation } from "react-i18next";


function Allgames(props) {

  const [loader, setLoader] = useState(false);
  const [allData, setAllData] = useState([]);
  const {t} = useTranslation()

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    setLoader(true);
    let temp = [];

    let response = await html5SettingsList({type: "appUser"});
    //  console.log('Free Games: ', response);

    if (response) {
      response?.list?.length &&
        temp.push({
          id: "freeGame001",
          img: url.imageUrl + response?.list?.[0]?.featuredImage?.default,
          name: response?.list?.[0]?.title,
          tournaments:   'games-categories',
        //  isTournament: response?.list?.[0]?.isTournament ,
          join: convertNumberToMillions(response && 'totalJoinedPlayers' in response ? 
            response?.totalJoinedPlayers : 0),
        })
    }

    // let responsePBE = await getExternalGamePBE();
    // let responsePBL = await getExternalGamePBL();

    // let token = localStorage.getItem("gamerjiToken")
    
    // if (responsePBE?.success && responsePBE?.data?.order === 0) {
    //   temp.push({
    //     id: responsePBE?.data?._id,
    //     img: url.imageUrl + responsePBE?.data?.featuredImage?.default,
    //     name: responsePBE?.data?.name,
    //     tournaments: '',
    //     redirectLink: token ? responsePBE?.data?.redirectionLink+"?token="+token+"&platform=web" : responsePBE?.data?.redirectionLink,
    //     join: convertNumberToMillions(responsePBE?.data?.playedCount || 0),
    //   })
    // }

    // if (responsePBL?.success && responsePBL?.data?.order === 0) {
    //   temp.push({
    //     id: responsePBL?.data?._id,
    //     img: url.imageUrl + responsePBL?.data?.featuredImage?.default,
    //     name: responsePBL?.data?.name,
    //     tournaments: '',
    //     redirectLink: token ? responsePBL?.data?.redirectionLink+"?token="+token+"&platform=web" : responsePBL?.data?.redirectionLink,
    //     join: convertNumberToMillions(responsePBL?.data?.playedCount || 0),
    //   })
    // }

    let data = {  pagination: {
      pageNo: 1,
      recordPerPage: 100,
      sortBy: "order",
      sortDirection: "asc"
  } };
    response = await allGames(data);

    if (response) {
      response?.data?.length &&
        response?.data?.map(async (item, index) => {
          temp.push({
            id: item._id,
            img: url.imageUrl + item?.featuredImage?.default,
            tournamentFeaturedImage: url.imageUrl + item?.tournamentFeaturedImage?.default,
            name: item?.name,
            tournamentGameID:item?.tournamentGameID,
            isTournament: item?.isTournament,
            tournaments: 'tournaments',
            join: convertNumberToMillions(item && 'playedCount' in item ? item?.playedCount : 0),
          });
        });
      let featured = [];
      response?.data?.length &&
        response?.data?.map(async (item, index) => {
          featured.push({
            id: item._id,
            tournamentFeaturedImage: url.imageUrl + item?.tournamentFeaturedImage?.default,
          });
        });
      localStorage.setItem('tournamentImgs', JSON.stringify(featured));

      setLoader(false);
    }

    // if (responsePBE?.success && responsePBE?.data?.order > 0) {
    //   temp.push({
    //     id: responsePBE?.data?._id,
    //     img: url.imageUrl + responsePBE?.data?.featuredImage?.default,
    //     name: responsePBE?.data?.name,
    //     tournaments: '',
    //     redirectLink: token ? responsePBE?.data?.redirectionLink+"?token="+token+"&platform=web" : responsePBE?.data?.redirectionLink,
    //     join: convertNumberToMillions(responsePBE?.data?.playedCount || 0),
    //   })
    // }

    // if (responsePBL?.success && responsePBL?.data?.order > 0) {
    //   temp.push({
    //     id: responsePBL?.data?._id,
    //     img: url.imageUrl + responsePBL?.data?.featuredImage?.default,
    //     name: responsePBL?.data?.name,
    //     tournaments: '',
    //     redirectLink: token ? responsePBL?.data?.redirectionLink+"?token="+token+"&platform=web" : responsePBL?.data?.redirectionLink,
    //     join: convertNumberToMillions(responsePBL?.data?.playedCount || 0),
    //   })
    // }

    setAllData(temp);
  }

  return (
    <Fragment>
      <h2 className="icon-title">
        <span className="icon">
          <img src={AllGames} alt="" />
        </span>
        { t('home.all_games') }
      </h2>

      {loader ? <Loader /> :
        <div className="games_items">
          {allData?.map((item, index) => {
            return (
              <div className="item" key={index}>
                {/* // eslint-disable-next-line */}
                {item?.redirectLink ? 
                  <a href={`${item?.redirectLink}`} target="blank" className="d-block">
                    <div className="box">
                      {/* <marquee> */}
                      <b className="all_games_page_all_games_title text_animation_container">{item?.name}</b>
                      {/* </marquee> */}
                      <div className="img">
                        <img src={item.img} alt="" />
                        <div className="img-caption">
                          <div className="img-caption-item text_animation_container">
                            <img src={GameIconSmall1} alt="" />
                            <p ><span style={{ fontWeight: '700' }}>{item?.join}</span> <span>{ t('home.played') }</span> </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                :
                  <Link to={
                 `/${item?.tournaments}/${item?.id}`} state={{
                  tournamentGameID:item?.tournamentGameID,
                  _id: item?.id,
                  name: item?.name,
                  isTournament:item?.isTournament


                  }} className="d-block">

                
                    <div className="box">
                      {/* <marquee> */}
                      <b className="all_games_page_all_games_title text_animation_container">{item?.name}</b>
                      {/* </marquee> */}
                      <div className="img">
                        <img src={item.img} alt="" />
                        <div className="img-caption">
                          <div className="img-caption-item text_animation_container">
                            <img src={GameIconSmall1} alt="" />
                            <p ><span style={{ fontWeight: '700' }}>{item?.join}</span> <span>{ t('home.played') }</span> </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                }
              </div>
            )
          })}
        </div>
      }
    </Fragment>
  );
}
export default Allgames;
