import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import "react-intl-tel-input/dist/main.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Row, Col } from "react-bootstrap";
import getYoutubeChannelId from "get-youtube-channel-id";
import {
  userVideoRequestsList,
  userVideoRequestsVideoList,
  userVideoRequestsCheckChannel,
  userVideoRequestsCreate,
} from "../../apis";
import url from '../../constants/url';
import { kFormatter } from "../../component/common";
import Loader from "../../component/loader";

import Previous from "../../assets/images/previous.svg";
import RightArrow from "../../assets/images/right-arrow.svg";
import ApprovedStatus from "../../assets/images/success-payment.png";
import PendingStatus from "../../assets/images/pending-payment.png";
import RejectedStatus from "../../assets/images/fail-payment.png";

import Advertisement from "../../component/advertisement";

import '../more.css';
import { withTranslation } from 'react-i18next';

class MyVideos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      skip: 0,

      channel_id: "",
      channel_data_from_yt: [],
      channel_data_from_backend: [],

      tag_loader: false,
      tag_show_form: false,
      tag_show_form_submit_button: false,
    };

    this.onClickValidate = this.onClickValidate.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  componentDidMount() {
    this.userVideoRequestsListAPICALL();
  }

  showError(error) {
    toast.error(error, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  onClickValidate() {
    if (this.state.channel_id === undefined || this.state.channel_id === null ||
      this.state.channel_id === "") {
      this.showError("Please Enter Channel ID!!");
    } else {
      this.userVideoRequestsCheckChannelAPICall();
    }
  }

  onClickSubmit() {
    this.userVideoRequestsCreateAPICall();
  }

  async userVideoRequestsListAPICALL() {
    let response = await userVideoRequestsList({
      skip: this.state.skip,
      limit: 20,
    });
    //console.log('userVideoRequestsList: ', response);

    if (response) {
      if (response.count === 0) {
        this.setState({ tag_show_form: true, })
      } else {
        let status = response?.list?.[0]?.status;

        this.setState({ channel_data_from_backend: response?.list?.[0], });

        if (status === '1') {
          this.setState({ tag_show_form: false, });
        } else if (status === '2') {
          this.setState({ tag_show_form: false, });
          this.userVideoRequestsVideoListAPPICall();
        } else if (status === '3') {
          this.setState({ tag_show_form: false, });
        } else if (status === '4') {
          this.setState({ tag_show_form: true, });
        } else {
          this.setState({ tag_show_form: true, });
        }
      }
    }
  }

  async userVideoRequestsVideoListAPPICall() {
    let response = await userVideoRequestsVideoList({
      skip: this.state.skip,
      limit: 20,
    });
    //console.log('userVideoRequestsVideoList: ', response);

    if (response) {

    }
  }

  async userVideoRequestsCheckChannelAPICall() {
    const {t} = this.props;

    let channelResponse = await getYoutubeChannelId(this.state.channel_id);
    //console.log('getYoutubeChannelId: ', channelResponse);

    if (channelResponse && channelResponse?.id && channelResponse?.error === false) {
      let response = await userVideoRequestsCheckChannel({
        channel: channelResponse?.id,
      });
      //console.log('userVideoRequestsCheckChannel: ', response);

      if (response) {
        if (response?.item?.[0]?.snippet?.title && response?.item?.[0]?.snippet?.publishedAt) {
          this.setState({
            channel_data_from_yt: response?.item?.[0],
            tag_show_form_submit_button: true,
          })
        } else {
          this.showError(t('myVideos.error_channel_does_not_exists'));
        }
      }
    } else {
      this.showError(t('myVideos.error_channel_does_not_exists'));
    }
  }

  async userVideoRequestsCreateAPICall() {
    let response = await userVideoRequestsCreate({
      channel: this.state.channel_id,
    });
    //console.log('userVideoRequestsCreate: ', response);

    if (response) {
      if (response && response?.item) {
        this.userVideoRequestsListAPICALL();
      }
    }
  }

  createMyVideosStatus() {
    const {t} = this.props;

    if (this.state.channel_data_from_backend?.status === '1' ||
      this.state.channel_data_from_backend?.status === '2' ||
      this.state.channel_data_from_backend?.status === '3' ||
      this.state.channel_data_from_backend?.status === '4') {
      return (
        <div className="myvd_channel_status_section">
          <div className="row m-0 align-items-center">
            <img className="myvd_channel_status_channel_img col-3 p-0"
              src={this.state.channel_data_from_backend?.thumbnails?.default?.url}
            />

            <div className="myvd_channel_status_name_subs col-5 pr-0">
              <div className="myvd_channel_status_name">
                {this.state.channel_data_from_backend?.title}
              </div>
              <div className="myvd_channel_status_subs">
                {kFormatter(this.state.channel_data_from_backend?.subscriberCount)} {t('myVideos.Subscribers')}
              </div>
            </div>

            <div className="myvd_channel_status col-4">
              {
                this.state.channel_data_from_backend?.status === '1' &&
                <div>
                  <img className="myvd_channel_status_img" src={PendingStatus} /> {t('myVideos.Pending')} 
                </div>
              }
              {
                this.state.channel_data_from_backend?.status === '2' &&
                <div>
                  <img className="myvd_channel_status_img" src={ApprovedStatus} /> {t('myVideos.Approved')}
                </div>
              }
              {
                this.state.channel_data_from_backend?.status === '3' &&
                <div>
                  <img className="myvd_channel_status_img" src={PendingStatus} /> {t('myVideos.On_hold')}
                </div>
              }
              {
                this.state.channel_data_from_backend?.status === '4' &&
                <div>
                  <img className="myvd_channel_status_img" src={RejectedStatus} /> {t('myVideos.Rejected')}
                </div>
              }
            </div>
          </div>

          {
            this.state.channel_data_from_backend?.status === '4' &&
            <div className="row m-0 align-items-center">
              <div className="myvd_channel_status_reason">
                {this.state.channel_data_from_backend?.reason}
              </div>
            </div>
          }
        </div>
      )
    }
  }

  createMyVideosForm() {
    const {t} = this.props;

    return (
      <div className="myvd_form_component">
        <div className="myvd_form_text">{t('myVideos.youtube_channel_link')}</div>
        <input
          className="myvd_form_input"
          placeholder={t('myVideos.enter_your_youtube_channel_link')}
          disabled={this.state.tag_show_form_submit_button}
          value={this.state.channel_id}
          onChange={(e) => { this.setState({ channel_id: e.target.value, }) }}
        />
        <button
          className="myvd_form_button"
          style={{ opacity: this.state.tag_show_form_submit_button ? '0.7' : '1' }}
          disabled={this.state.tag_show_form_submit_button}
          onClick={() => this.onClickValidate()}
        >
          {t('myVideos.Validate')} <img src={RightArrow} alt="" className="mCS_img_loaded" />
        </button>

        {
          this.state.tag_show_form_submit_button &&
          <div className="myvd_form_channel_details row mx-0">
            <div className="myvd_form_channel_details_title col-6">{t('myVideos.title')}:</div>
            <div className="myvd_form_channel_details_data col-6">
              {this.state.channel_data_from_yt?.snippet?.title}
            </div>

            <div className="myvd_form_channel_details_title col-6">{t('myVideos.subscriber_count')}:</div>
            <div className="myvd_form_channel_details_data col-6">
              {this.state.channel_data_from_yt?.statistics?.subscriberCount}
            </div>

            <div className="myvd_form_channel_details_title col-6">{t('myVideos.video_count')}:</div>
            <div className="myvd_form_channel_details_data col-6">
              {this.state.channel_data_from_yt?.statistics?.videoCount}
            </div>

            <div className="myvd_form_channel_details_title col-6">{t('myVideos.view_count')}:</div>
            <div className="myvd_form_channel_details_data col-6">
              {this.state.channel_data_from_yt?.statistics?.viewCount}
            </div>
          </div>
        }

        {
          this.state.tag_show_form_submit_button &&
          <button className="myvd_form_button" onClick={() => this.onClickSubmit()}>
            {t('myVideos.Submit')} <img src={RightArrow} alt="" className="mCS_img_loaded" />
          </button>
        }
      </div>
    )
  }

  render() {
    const {t} = this.props;

    return (
      <div className="myvd_main_div">
        {this.state.tag_loader && <Loader />}
        <ToastContainer />
        <div className="myvd_header">
          <div className="back-btn">
            <Link to='/more'>
              <img src={Previous} alt="" />
            </Link>
          </div>
          <div className="myvd_header_text">{t('myVideos.my_videos')}</div>
          <div className="wallet-btn"></div>
        </div>

        <div className="myvd_body">
          <div className="all_sub_screens_div_related_to_ads">
            <div className="myvd_body_scrollable_component">
              {this.createMyVideosStatus()}
              {this.state.tag_show_form && this.createMyVideosForm()}
            </div>
          </div>
          {/* <Advertisement
          screen={
            localStorage.getItem('apk_screens_list') === null ?
              { code: 'no_results' }
              :
              JSON.parse(localStorage.getItem('apk_screens_list')).dailyLoginRewards
          }
          screen_div_class_name='.all_sub_screens_div_related_to_ads'
          screen_type='sub'
        /> */}
        </div>
      </div>
    );
  }

}

export default withTranslation()(MyVideos);
