import React, { Fragment } from 'react';
import ShapeTop from "../assets/images/shape-top.svg";
import ShapeBottom from "../assets/images/shape-bottom.svg";
import Android from "../assets/images/android-icon.svg";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function Sidebar(props) {
	const {t} = useTranslation()

	return (
		<Fragment>
			<div className="shap-top"><img src={ShapeTop} alt="" /></div>
			<div className="shap-bottom"><img src={ShapeBottom} alt="" /></div>
			<div className="inner">
				<h2>{t('sideBar.title1')}</h2>
				<h4>{t('sideBar.subText')}</h4>
				<div className="app-btn-set">
					{/* // eslint-disable-next-line */}
					{/* <Link to="" className="active">
							<i className="fab fa-apple"></i>
							<span>Download on the</span>App Store
						</Link> */}
					{/* // eslint-disable-next-line */}
					<a href="https://download.gamerji.pro/android_apk/pro/GamerJi-Pro.apk" target='_blank'>
						<i><img src={Android} alt="" /></i>
						<span>Download</span>Android App
					</a>
				</div>
			</div>
		</Fragment>
	)
}

export default Sidebar;