import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  // ElementsConsumer,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import url from "../constants/url";
import RightArrowLight from "../assets/images/right-arrow.svg";
import { isMobile  } from 'react-device-detect';
import { constants } from "../constants/constants";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    let countryData = JSON.parse(localStorage.getItem(constants.profileLite)).item;
    //console.log(countryData);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: isMobile ? url.returnMobileUrl + props.coinStoreObjID : url.returnWebUrl + props.coinStoreObjID,
        payment_method_data: {
          billing_details: {
            name: countryData.gamerjiName,
  
            address: {
              city: countryData.phoneCode === "+91" ? "India" :"Dubai",
              country: countryData.phoneCode === "+91" ? "IN" : "AE",
              state: countryData.phoneCode === "+91" ? "India" : "Dubai",
              postal_code: countryData.phoneCode === "+91" ? "000000" : "00000",
              line1: countryData.phoneCode === "+91" ? "India City" : "Dubai City",
              line2: "",
            }
          }
        },
      }
    

    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    //console.log(error);
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(error.message);
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-dark main-btn mt-4 mb-4">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
        <img src={RightArrowLight} alt="" className="mCS_img_loaded" />
      </button>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
