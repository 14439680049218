


import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import colors from '../constants/colors';
import Moment from 'react-moment';
import Success from "../assets/images/success-payment.png";
import Previous from "../assets/images/previous.svg";
import Pending from "../assets/images/pending-payment.png";
import Fail from "../assets/images/fail-payment.png";
import sha256 from 'crypto-js/sha256';
import axios from 'axios';
import { phonepeStatus, xsollaStatus } from '../apis';
import { useSearchParams } from 'react-router-dom';
import Loader from '../component/loader';
import getSymbolFromCurrency from "currency-symbol-map";

export const PaymentSuccess = ({ transactionId, amount, message, currency }) => {
  const goCoinStore = () => {
    // if(props.platform && props.platform==="WebApp"){
    //   window.location.href = "/coin-store";
    // }
    // if(props.platform && props.platform==="MobileApp"){
    //   window.ReactNativeWebView.postMessage(JSON.stringify(props))
    // }
    //
    window.location.href = "/coin-store"
  };
  useEffect(() => {
    let redirectUrl = localStorage.getItem('returnUrl')
    if (
      redirectUrl != undefined &&
      redirectUrl != "undefined" &&
      redirectUrl != null &&
      redirectUrl != "null" &&
      redirectUrl != ""
    ) {
      setTimeout(() => {
        // Assuming you want to remove the item with key "returnUrl"
        localStorage.removeItem("returnUrl");

        window.location.href = redirectUrl
      }, 5000);
    }
  }, [])
  return (
    <>
      <div style={{ backgroundColor: "#36DE4C", textAlign: 'center', height: "80%", width: '80%', borderRadius: '40px' }} className="mt-0">


        {/* Status Icon */}
        <img style={{ marginTop: 42, height: 100, width: 100 }} src={Success} />

        {/* Title */}
        <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Success!</div>

        {/* Amount */}
        {
          <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
            {/* {coinStore?.currency?.symbol} {coinStore?.item?.amount} */}
            {localStorage.getItem("currCode")} {" "}
            {amount}
          </div>
        }

        {/* Date Time */}
        <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
          {/* {transaction?.createdAt} */}
        </Moment>   |  <Moment format="hh:mm A">
            {/* {transaction?.createdAt} */}
          </Moment></div>

        {/* Transaction ID */}
        {
          <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >
            Transaction ID :{transactionId}
            {/* #{transaction?.transactionUniqueID} */}
          </div>
        }
        {/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
        {/* Payment Status */}
        <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{message}</div>


        {/* Done */}
        <button className="btn btn-dark main-btn next  mb-4 ml-2" style={{ width: "96%", marginTop: '80%' }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


      </div>
    </>
  )
}

const PaymentStatusItemPending = ({ transactionId, amount, message }) => {
  const goCoinStore = () => {
    // if (props.platform && props.platform === "WebApp") {
    //   window.location.href = "/coin-store";
    // }
    // if (props.platform && props.platform === "MobileApp") {
    //   window.ReactNativeWebView.postMessage(JSON.stringify(props))
    // }
    //
    window.location.href = "/coin-store";

  };
  // var transaction = props.transaction;
  // var coinStore = props.coinStore;
  return (<div style={{ backgroundColor: "#FF9544", textAlign: 'center', height: "100%", borderRadius: 'inherit', }} className="mt-0">




    {/* Status Icon */}
    <img style={{ marginTop: 42, height: 100, width: 100 }} src={Pending} />

    {/* Title */}
    <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Pending</div>

    {/* Amount */}
    {
      <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
        {/* {coinStore?.currency?.symbol} */}
        {/* {coinStore?.item?.amount} */}
        {amount}
      </div>
    }

    {/* Date Time */}
    <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
      {/* {transaction?.createdAt} */}
    </Moment>   |  <Moment format="hh:mm A">
        {/* {transaction?.createdAt} */}
      </Moment></div>

    {/* Transaction ID */}
    {
      <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID : #{transactionId}</div>
    }
    {/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
    {/* Payment Status */}
    <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{message}</div>


    {/* Done */}
    <button className="btn btn-dark main-btn next mt-4 mb-4 ml-2" style={{ width: "96%" }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


  </div>)
}
const PaymentStatusItemProcessing = ({ transactionId, amount, message }) => {
  const goCoinStore = () => {
    // if (props.platform && props.platform === "WebApp") {
    //   window.location.href = "/coin-store";
    // }
    // if (props.platform && props.platform === "MobileApp") {
    //   window.ReactNativeWebView.postMessage(JSON.stringify(props))
    // }
    //
    window.location.href = "/coin-store";

  };
  // var transaction = props.transaction;
  // var coinStore = props.coinStore;
  return (<div style={{ backgroundColor: "#FF9544", textAlign: 'center', height: "100%", borderRadius: 'inherit', }} className="mt-0">




    {/* Status Icon */}
    <img style={{ marginTop: 42, height: 100, width: 100 }} src={Pending} />

    {/* Title */}
    <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Processing...</div>

    {/* Amount */}
    {
      <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
        {/* {coinStore?.currency?.symbol} */}
        {localStorage.getItem("currCode")} {" "}
        {amount}
      </div>
    }

    {/* Date Time */}
    <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
      {/* {transaction?.createdAt} */}
    </Moment>   |  <Moment format="hh:mm A">
        {/* {transaction?.createdAt} */}
      </Moment></div>

    <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{message}</div>

    <div className="btn btn-dark main-btn next mt-4 mb-4 ml-2"></div>


  </div>)
}
const PaymentStatusItemFailed = ({ transactionId, amount, message, currency }) => {
  const goCoinStore = () => {
    // if (props.platform && props.platform === "WebApp") {
    //   window.location.href = "/coin-store";
    // }
    // if (props.platform && props.platform === "MobileApp") {
    //   window.ReactNativeWebView.postMessage(JSON.stringify(props))
    // }
    window.location.href = "/";
  };
  useEffect(() => {
    let redirectUrl = localStorage.getItem('returnUrl')
    if (
      redirectUrl != undefined &&
      redirectUrl != "undefined" &&
      redirectUrl != null &&
      redirectUrl != "null" &&
      redirectUrl != ""
    ) {
      setTimeout(() => {
        // Assuming you want to remove the item with key "returnUrl"
        localStorage.removeItem("returnUrl");

        window.location.href = redirectUrl
      }, 5000);
    }
  }, [])
  
  // var transaction = props.transaction;
  // var coinStore = props.coinStore;
  return (

    <div style={{ backgroundColor: "#FF4646", textAlign: 'center', height: "80%", width: '80%', borderRadius: '40px' }} className="mt-0">




      {/* Status Icon */}
      <img style={{ marginTop: 42, height: 100, width: 100 }} src={Fail} />

      {/* Title */}
      <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Failure!</div>

      {/* Amount */}
      {
        <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
          {/* {coinStore?.currency?.symbol}  */}
          {localStorage.getItem("currCode")} {" "}
          {amount}</div>
      }

      {/* Date Time */}
      <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
        {/* {transaction?.createdAt} */}
      </Moment>   |  <Moment format="hh:mm A">
          {/* {transaction?.createdAt} */}
        </Moment></div>

      {/* Transaction ID */}
      {/* {
			<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID : #{transaction?.transactionUniqueID}</div>
		} */}
      {/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
      {/* Payment Status */} <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{message}</div>



      {/* Done */}
      <button className="btn btn-dark main-btn next  mb-4 ml-2" style={{ width: "96%", marginTop: '120%' }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


    </div>)
}
const XsollaStatus = () => {
  //user_id=GSGJ000068115&status=done&foreignInvoice=NPJ0O615136UUPM3Z&invoice_id=1208016814

  const location = useLocation();
  const [status, setStatus] = useState('');
  const [loader, setLoader] = useState(false)
  // const [amount, setAmount] = useState('')
  // const [transactionId, setTransactionId] = useState('')
  const [data, setData] = useState({})
  const [message, setMessage] = useState('')
  const [currencySymbol, setCurrencySymbol] = useState('')
  useEffect(async () => {

    const queryParams = new URLSearchParams(location.search);

    let body = JSON.parse(localStorage.getItem('body'))
    //console.log(body)
    let pgData = {}
    queryParams.forEach((key, element) => {
      if (element == 'invoice_id' || element == 'status')
        pgData[element] = key
    });

    body['pgData'] = pgData

    // setAmount('100')
    // console.log("currency", localStorage.getItem('currecySymbol'), getSymbolFromCurrency(localStorage.getItem('currecySymbol')))
    setCurrencySymbol(getSymbolFromCurrency(localStorage.getItem('currecySymbol')));
    // console.log(body)
    setData({ ...data, ...body })
    fetchData(body)

  }, [])

  const fetchData = async (body) => {
    setLoader(true)
    //xsolla-status?user_id=GSGJ001466321&status=done&foreignInvoice=Z8L0V5123527ZHRHG8&invoice_id=1211550854
    //xsolla-status?user_id=GSGJ001466321&status=done&foreignInvoice=Z8L0V5123527ZHRHG8&invoice_id=1211550854
    let res = await xsollaStatus(body)
    // console.log(res)
    if (res.success) {
      setStatus(res.data.paymentStatus)
      setMessage(res.message)
    }
    setLoader(false)
  }
  const onClickBack = () => {

    window.location.href = "/coin-store";


  };
  return (
    // <div style={{color:'white', width:'100%',height:'100%'}}>phonepeStatus</div>
    <>

      <div className="pg_main_div">


        <div className="pg_header">
          <div className="pg_back_arrow_component">
            <div className="back-btn">
              <a className="pg_link" onClick={() => onClickBack()}>
                <img src={Previous} alt="" />
              </a>
            </div>
          </div>
          <div className="pg_header_text">
            Transaction Status
          </div>
          <div className=""></div>
        </div>

        <div className="pg_body">

          <div className="all_main_screens_div_related_to_ads">
            {loader ? <Loader /> :
              <div className="pg_body_scrollable_component" style={{ marginTop: '10%' }}>
                {
                  status === 'SUCCESS' ? 
                  <> 
                  <PaymentSuccess transactionId={data?.transactionUniqueID} amount={data?.amount} message={message} currency={currencySymbol} /> </>

                    : <> <PaymentStatusItemFailed transactionId={data?.transactionUniqueID} amount={data?.amount} message={message} currency={currencySymbol} /></>
                }
                {/*  : status === 'PAYMENT_PENDING' ? <><PaymentStatusItemPending transactionId={transactionId} amount={amount} message='Payment is pending. It does not indicate failed payment. Merchant needs to call Check Transaction Status to verify the transaction status.' /></>
                      : status === 'PAYMENT_DECLINED' ? <> <PaymentStatusItemFailed transactionId={transactionId} amount={amount} message='Payment declined by user' /> </>
                        : status === 'PAYMENT_CANCELLED' ? <> <h6>	Payment cancelled by the merchant using Cancel API</h6></>
                          : status === 'TRANSACTION_NOT_FOUND' ? <></>
                            : status === 'BAD_REQUEST' ? <><h6>Invalid request</h6></>
                              : status === 'AUTHORIZATION_FAILED' ? <> <h6>X-VERIFY header is incorrect</h6></>
                                : "")
              
 */}


              </div>
            }
          </div>

        </div>
      </div>
    </>

  )
}

export default XsollaStatus