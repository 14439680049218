import React from 'react'
import ReactSpinner from 'react-bootstrap-spinner'


function Error(props) {

    return ( 
        <div className='error' style={{marginTop:'5px'}}>
            <p>{props?.error}</p>
        </div>
    )
}

export default Error;